import React, { useEffect, useState, lazy, Suspense, useCallback, useMemo } from "react";
import { Spin } from "antd";
import { setNavItem } from "../../../utils/localStorage";
import { leaguesData, standingsData } from "../../../utils/service";
import "./Insights.scss";

// Lazy load components
const Dropdowns = lazy(() => import("./Dropdowns"));
const RenderStandings = lazy(() => import("./RenderStandings"));
const Results = lazy(() => import("./Results"));
const Fixtures = lazy(() => import("./Fixtures"));
const Matches = lazy(() => import("../../commonComponents/Matches"));
const HomeNews = lazy(() => import("../../commonComponents/HomeNews"));
const BasketballStats = lazy(() =>
  import("./Basketball").then((module) => ({ default: module.BasketballStats }))
);
const BasketballStandings = lazy(() =>
  import("./Basketball").then((module) => ({ default: module.BasketballStandings }))
);

const Insights = React.memo(() => {
  const [sport, setSport] = useState({ id: 3, name: "Football" });
  const [league, setLeague] = useState("Premier League");
  const [leaguesResponse, setLeaguesResponse] = useState([]);
  const [standings, setStandings] = useState(null);
  const [leagueType, setLeagueType] = useState(null);
  const [leagueId, setLeagueId] = useState();

  const dropdownOptions = useMemo(() => [
    { value: 3, label: "Football" },
    { value: 5, label: "Basketball" },
  ], []);

  const fetchLeaguesData = useCallback(async (sportId) => {
    const res = await leaguesData(sportId);
    setLeaguesResponse(res?.data?.data || []);
  }, []);

  const fetchStandingsData = useCallback(async (leagueIndex) => {
    const league = leaguesResponse[leagueIndex];
    const res = await standingsData(league?.current_season_id);

    if (sport.id === 3) {
      setStandings(res?.data?.data);
      setLeagueType(res?.data?.league_type);
      setLeagueId(league?.current_season_id);
    } else if (sport.id === 5) {
      setStandings(res?.data?.data);
    }
  }, [leaguesResponse, sport.id]);

  useEffect(() => {
    fetchLeaguesData(3);
    setNavItem(2);
  }, [fetchLeaguesData]);

  const handleSportChange = useCallback((value) => {
    const selectedSport = dropdownOptions.find((opt) => opt.value === value);
    setSport({ id: value, name: selectedSport.label });
    fetchLeaguesData(value);
    if (value === 5) {
      setLeague("NBA");
    } else {
      setLeague("Premier League");
    }
  }, [dropdownOptions, fetchLeaguesData]);

  useEffect(() => {
    if (leaguesResponse.length > 0) {
      if (sport.id === 3) {
        const premierLeagueIndex = leaguesResponse.findIndex(
          (league) => league.name === "Premier League"
        );
        if (premierLeagueIndex !== -1) {
          const premierLeague = leaguesResponse[premierLeagueIndex];
          fetchStandingsData(premierLeagueIndex);
        }
        setLeagueId(leaguesResponse[0]?.current_season_id);
      }
      if (sport.id === 5) {
        const nbaLeagueIndex = leaguesResponse.findIndex(
          (league) => league.name === "NBA"
        );
        if (nbaLeagueIndex !== -1) {
          const nbaLeague = leaguesResponse[nbaLeagueIndex];
          fetchStandingsData(nbaLeagueIndex);
        }
        setLeagueId(leaguesResponse[0]?.current_season_id);
      }
    }
  }, [leaguesResponse, sport.id,handleSportChange, fetchStandingsData]);


  const handleLeagueChange = useCallback((value) => {
    setLeague(value);
    fetchStandingsData(value);
  }, [fetchStandingsData]);

  return (
    <div className="white-color insights">
      <div className="main-padding text-center">
        <div className="insights-hey-champ font-cairo-medium">
          YOUR GAME'S INSIGHTS
        </div>
        <div className="insights-intro-text font-cairo-extrabold">
          Stay Updated, Stay Ahead!
        </div>
        <div className="insights-standings-title">
          <div className="font-cairo-extrabold insights-standings-title-text">
            Standings
          </div>
          <Suspense fallback={<Spin className="loader" />}>
            <Dropdowns
              sport={sport}
              league={league}
              dropdownOptions={dropdownOptions}
              leaguesResponse={leaguesResponse}
              onSportChange={handleSportChange}
              onLeagueChange={handleLeagueChange}
            />
          </Suspense>
        </div>
        <div
          className={`insights-standings background-blur ${
            sport.id === 5 ? "insights-basketballstandings" : ""
          }`}
        >
          <Suspense fallback={<Spin className="loader" />}>
            <RenderStandings
              sport={sport}
              standings={standings}
              leagueType={leagueType}
            />
          </Suspense>
        </div>
        <div className="font-cairo-extrabold insights-fixtures-title-text">
          Fixtures
        </div>
        <div className="insights-fixtures background-blur">
          <Suspense fallback={<Spin className="loader" />}>
            <Fixtures leagueId={leagueId} footballValue={sport.id} />
          </Suspense>
        </div>
        <div className="font-cairo-extrabold insights-fixtures-title-text">
          Results
        </div>
        <div className="insights-fixtures background-blur">
          <Suspense fallback={<Spin className="loader" />}>
            <Results leagueId={leagueId} footballValue={sport.id} />
          </Suspense>
        </div>
        <div className="main-padding">
          <div className="join-action font-cairo-medium primary-color">
            MASTER THE GAME
          </div>
          <div className="intro-text2 font-cairo-extrabold line-height-63">
            {sport.id === 5 ? "Teams Statistics" : "Head To Head Stats"}
          </div>
        </div>
        <div className="insights-matches background-blur container-border">
          <Suspense fallback={<Spin className="loader" />}>
            {sport.id === 3 ? (
              <Matches leagueStandings={standings}/>
            ) : (
              <BasketballStats basketballStandings={standings} />
            )}
          </Suspense>
        </div>
        <div className="insights-news main-padding">
          <Suspense fallback={<Spin className="loader" />}>
            <HomeNews />
          </Suspense>
        </div>
      </div>
    </div>
  );
});

export default Insights;