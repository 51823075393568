import React, { useEffect, useState } from "react";
import { Tabs, Spin } from "antd";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw/Withdraw";
import { useNavigate } from "react-router-dom";
import { sendGoogleAnalyticsEvents } from "../../../helpers/genericFunctions.js";
import {
  getWalletData,
  createPayment,
  handlePaymentAmountCheck,
  externalPaymentWithdraw,
  withdrawAmount,
  userData,
} from "../../../utils/service";
// import PaymentMethods from "./PaymentMethods/PaymentMethods";
import { FilledButton } from "../../commonComponents";
import { Payment } from "truelayer-embedded-payment-page";
import { useMyContext } from "../../../helpers/Context/MyContext.js";
import DepositAlert from "../../modals/DepositAlert/DepositAlert.jsx";
import { sendMetaEvents } from "../../../helpers/genericFunctions.js";
import WithdrawSuccess from "../../modals/WithdrawSuccess/WithdrawSuccess.jsx";
import notify from "../../../utils/notificationService.js"
import "./Payment.scss";

const PaymentScreens = () => {
  const [loading, setLoading] = useState();
  const [btnLoading, setBtnLoading] = useState();
  const [activeTab, setActiveTab] = useState(1);
  const [addCard, setAddCard] = useState(false);
  const [userProfileData, setUserProfileData] = useState();
  const [userInfo, setUserInfo] = useState();
  const [transferAmount, setTransferAmount] = useState();
  const [errorCheck, setErrorCheck] = useState("");
  const [errorWithdrawCheck, setErrorWithdrawCheck] = useState("");
  const [withdrawAccount, setWithdrawAccount] = useState();
  const [withdrawAccountError, setWithdrawAccountError] = useState("");
  const [sortCode, setSortCode] = useState({
    one: "",
    two: "",
    three: "",
  });
  const [isWithdrawSuccessOpen, setIsWithdrawSuccessOpen] = useState(false);
  const [depositAlertText, setDepositAlertText] = useState("");
  const [depositAlertTitle, setDepositAlertTitle] = useState("");
  const [isDepositAlertOpen, setDepositAlertOpen] = useState(false);
  const { contextData, setContextData } = useMyContext();
  const navigate = useNavigate();

  const openWithdrawSuccessModal = () => {
    setIsWithdrawSuccessOpen(true);
  };
  const closeWithdrawSuccessModal = () => {
    setIsWithdrawSuccessOpen(false);
    navigate("/");
  };
  const openDepositAlertModal = () => {
    setDepositAlertOpen(true);
  };
  const closeDepositAlertModal = () => {
    setDepositAlertOpen(false);
  };
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const handleErrors = async (value, type) => {
    var amount = new FormData();
    amount.append("amount", value);
    amount.append("type", type);
    const res = await handlePaymentAmountCheck(amount);
    setTransferAmount(value);
    if (!res.success) {
      if (type === "deposit") {
        setErrorCheck(res?.message);
      } else {
        setErrorWithdrawCheck(res?.message);
      }
    } else {
      setErrorCheck("");
      setErrorWithdrawCheck("");
    }
  };
  function isValidAccountNumber(number) {
    const numberLength = number.toString().length;
    return numberLength >= 6 && numberLength <= 8;
  }

  const handleWithdrawAccount = (value) => {
    setWithdrawAccount(value);
    const regex = /^\d*$/;
    if (!regex.test(value)) {
      setWithdrawAccountError("Account number must be numeric");
      return;
    }
    if (isValidAccountNumber(value)) {
      setWithdrawAccountError("");
    } else {
      setWithdrawAccountError("Must be between 6 & 8 digits long");
    }
  };

  const getUserData = async () => {
    const res = await userData();
    if (res) {
      setUserInfo(res);
    }
  };

  const getUserWalletData = async () => {
    const res = await getWalletData();
    if (res?.success) {
      setUserProfileData(res?.data);
    }
  };
  const startUserPayment = async () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Deposit", // You can name this anything
      conversionId: "11420071760",
      conversionLabel: "t28ICPmb58gZENDuwcUq"
    });
    var amount = new FormData();
    amount.append("amount", transferAmount);
    const res = await createPayment(amount);
    if (res?.success) {
      amount.append("event_name", "Deposit");
      sendMetaEvents(amount);
      const payment = Payment({
        payment_id: res?.payment_data?.id,
        resource_token: res?.payment_data?.resource_token,
        return_uri:
          process.env.REACT_APP_CUSTOM_ENV === "production"
            ? "https://www.championschoice.co.uk/payment?payment_done=true"
            : "https://develop.championschoice.co.uk/payment?payment_done=true", //for develop environemnt
        // "https://www.championschoice.co.uk/payment?payment_done=true", //for live environement
        production:
          process.env.REACT_APP_CUSTOM_ENV === "production" ? true : false, //for only live environemnt
      });
      payment.start();
      window.addEventListener("message", function (event) {
        // Check the origin of the message (optional but recommended for security)
        if (event.data.type !== "[TRUELAYER]") {
          return; // Ignore messages from unexpected origins
        }
        // Check the message content
        if (event.data?.result_status === "success") {
          const returnUri =
            process.env.REACT_APP_CUSTOM_ENV === "production"
              ? "https://www.championschoice.co.uk/payment?payment_done=true"
              : "https://develop.championschoice.co.uk/payment?payment_done=true";

          // Redirect to the return URI
          window.location.href = returnUri;
        }
      });
    } else {
      if(res?.success === false && res?.message === 'Self-Excluded From GamStop'){
       notify("Action restricted due to self-exclusion.","error")
      }
      if (res?.errors) {
        setDepositAlertTitle(res?.message);
        setDepositAlertText(res?.errors);
        openDepositAlertModal();
      } else if (res?.payment_status == 1) {
        setDepositAlertTitle("Cooling-Off Period Active");
        setDepositAlertText(res?.message);
        openDepositAlertModal();
      } else if (res?.payment_status == 2) {
        notify(res?.message,"error")
      }
    }
  };

  const handleExternalWithdrawAmount = async () => {
    setBtnLoading(true);
    var details = new FormData();
    let response;
    if (contextData?.userData?.payment_source_id === "") {
      details.append("amount", transferAmount);
      details.append(
        "sort_code",
        `${sortCode.one}${sortCode.two}${sortCode.three}`
      );
      details.append("account_number", withdrawAccount);
      response = await externalPaymentWithdraw(details);
    } else {
      details.append("amount", transferAmount);
      response = await withdrawAmount(details);
    }
    if (response?.success) {
      openWithdrawSuccessModal();
      setBtnLoading(false);
    } else {
      notify(response?.message,"error")
      setWithdrawAccount();
      setWithdrawAccountError("");
      setSortCode({ one: "", two: "", three: "" });
      setTransferAmount();
      setBtnLoading(false);
    }
  };

  const tabItems = [
    {
      label: "Deposit",
      key: 1,
      children: (
        <Deposit
          handleTabChange={handleTabChange}
          setAddCard={setAddCard}
          addCard={addCard}
          setTransferAmount={setTransferAmount}
          handleErrors={handleErrors}
          errorCheck={errorCheck}
        />
      ),
    },
    // {
    //   label: "Payment Methods",
    //   key: 2,
    //   children: (
    //     <PaymentMethods
    //       setAddCard={setAddCard}
    //       addCard={addCard}
    //       //   setUserUpdatedData={setUserUpdatedData}
    //       //   userUpdatedData={userUpdatedData}
    //     />
    //   ),
    // },
    {
      label: "Withdrawal",
      key: 2,
      children: (
        <Withdraw
          setTransferAmount={setTransferAmount}
          handleErrors={handleErrors}
          errorWithdrawCheck={errorWithdrawCheck}
          userData={contextData?.userData}
          handleWithdrawAccount={handleWithdrawAccount}
          withdrawAccountError={withdrawAccountError}
          setSortCode={setSortCode}
        />
      ),
    },
  ];
  const handlebankCharge = async () => {
    let params = new URLSearchParams(window.location.search);
    const paymentDone = params.get("payment_done");
    const eventDetails = {
      category: "payment",
      action: "deposit_button",
      label: "Deposit",
      email: contextData?.userData?.email,
      username: contextData?.userData?.username,
    };
    if (paymentDone) {
      setLoading(true);
      if (userInfo?.is_payment === true) {
        setLoading(false);
        sendGoogleAnalyticsEvents(eventDetails);
        navigate(`/game?payment_amount=${userInfo?.deposit_amount}`);
      } else if (userInfo?.is_payment === false) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getUserWalletData();
    getUserData();
  }, []);

  useEffect(() => {
    setTransferAmount();
  }, [activeTab]);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    const paymentDone = params.get("payment_done");
    if (!(paymentDone && userInfo?.is_payment === null)) {
      return;
    }
    const id = setInterval(() => {
      getUserData();
    }, 3000);

    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [userInfo]);

  useEffect(() => {
    handlebankCharge();
  }, [userInfo]);

  function formatNumber(num) {
    if (num >= 1000) {
      return num;
    } else {
      return num?.toFixed(2);
    }
  }

  return (
    <div className="payment main-padding">
      <WithdrawSuccess
        visible={isWithdrawSuccessOpen}
        closeModal={closeWithdrawSuccessModal}
      />
      <DepositAlert
        visible={isDepositAlertOpen}
        closeModal={closeDepositAlertModal}
        description={depositAlertText}
        title={depositAlertTitle}
      />
      <div className="payment-top">
        <div className="payment-top-title font-cairo-extrabold">Payments</div>
        <div className="payment-top-cont">
          <div className="payment-top-cont-title font-cairo-normal">
            Balance:&nbsp;
          </div>
          <div className="payment-top-cont-balance font-cairo-normal">
            <span className="primary-color"> £</span>{" "}
            {userProfileData?.balance > 1000
              ? userProfileData?.balance.toLocaleString()
              : formatNumber(userProfileData?.balance)}
          </div>
        </div>
      </div>
      {loading ? (
        <div className="spinner-loading font-cairo-medium">
          <Spin className="loader" />
        </div>
      ) : (
        <>
          <div
            className="payment-cont background-blur"
            style={{
              height:
                contextData?.userData?.payment_source_id === "" &&
                activeTab === 2
                  ? "570px"
                  : "370px",
            }}
          >
            <Tabs
              activeKey={activeTab}
              onChange={handleTabChange}
              items={tabItems}
              className="payment-cont-tab"
            />
          </div>
          <div className="payment-buttons">
            <FilledButton
            id="depositBtn"
              name={
                btnLoading ? (
                  <div className="spinner-loading font-cairo-medium">
                    <Spin className="login-loading-spinner" />
                  </div>
                ) : activeTab == 2 ? (
                  `Withdraw ${transferAmount ? `£ ${transferAmount}` : ""}`
                ) : (
                  `Deposit
              ${transferAmount ? `£ ${transferAmount}` : ""}
               `
                )
              }
              btnClass="primary-background payment-buttons-btnUpdate gtm-deposit-submit"
              functionCall={
                activeTab == 2 ? handleExternalWithdrawAmount : startUserPayment
              }
              type={3}
              disable={!(errorCheck || errorWithdrawCheck) ? false : true}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentScreens;
