import React, { useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import { Spin } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ButtonShine from "../../../assets/images/shine3.svg";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { passwordValidationSchema } from "../ValidationsSchema/ValidationsSchema";
import { ReactComponent as ErrorIcon } from "../../../assets/images/error-icon.svg";
import { checkEnvironemnt } from "../../../helpers/genericFunctions";
import notify from "../../../utils/notificationService"

const SignupSchema = Yup.object().shape({
  newpassword: passwordValidationSchema.fields.password,
  confirmnewpassword: Yup.string().oneOf(
    [Yup.ref("newpassword"), null],
    "Passwords must match"
  ),
});

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
  const [isPasswordFieldFocused, setIsPasswordFieldFocused] = useState(false);
  const clientIdRef = useRef(null);
  const tokenRef = useRef(null);
  const uidRef = useRef(null);

  clientIdRef.current = searchParams.get("client_id");
  tokenRef.current = searchParams.get("token");
  uidRef.current = searchParams.get("uid");
  const showPassword = () => {
    setTogglePassword(!togglePassword);
  };

  const showConfirmPassword = () => {
    setToggleConfirmPassword(!toggleConfirmPassword);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    var data = new FormData();
    data.append("password", values.newpassword);
    data.append("password_confirmation", values.confirmnewpassword);
    const URL = checkEnvironemnt();
    const res = await fetch(`${URL}/api/v1/auth/password`, {
      method: "PUT",
      headers: {
        client: clientIdRef.current,
        uid: uidRef.current,
        "access-token": tokenRef.current,
      },
      body: data,
    });
    if (res.status === 200) {
      setLoading(false);
      notify("Password successfully changed","success")
      navigate("/login");
    } else if (!res.success) {
      notify("Sorry! Something went wrong!","error")
      setLoading(false);
      navigate("/login");
    } else {
      console.log("Failed==>", res);
    }
  };
  useEffect(() => {
    clientIdRef.current = searchParams.get("client_id");
    tokenRef.current = searchParams.get("token");
    uidRef.current = searchParams.get("uid");
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          newpassword: "",
          confirmnewpassword: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          values,
          setFieldError,
          setFieldValue,
          handleBlur,
        }) => (
          <Form>
            {/* <div className="resetpassword-fields-cont position-relative aligncontent-baseline">
            <label htmlFor="newpassword" className="resetpassword-field-label">
              New Password
            </label>
            <Field
              type={togglePassword ? "text" : "password"}
              id="newpassword"
              name="newpassword"
              className="resetpassword-input-field"
              placeholder="••••••••"
            />
            {togglePassword ? (
              <EyeOutlined
                className="resetpassword-password-icon"
                onClick={showPassword}
              />
            ) : (
              <EyeInvisibleOutlined
                className="resetpassword-password-icon"
                onClick={showPassword}
              />
            )}

            <ErrorMessage name="newpassword" component="div">
              {(msg) => (
                <div className="error-message">
                  <ErrorIcon className="error-icon-margin" />
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div> */}
            <div
              className="signup-fields-cont position-relative"
              style={{
                height:
                  touched.newpassword && isPasswordFieldFocused && "120px",
              }}
            >
              <Field
                type={togglePassword ? "text" : "password"}
                id="newpassword"
                name="newpassword"
                className={`signup-input-field no-outline ${
                  errors.password && touched.password ? "error" : ""
                }`}
                placeholder="Password"
                onFocus={(e) => {
                  setIsPasswordFieldFocused(true);
                  handleBlur(e);
                }}
                onBlur={(e) => {
                  setIsPasswordFieldFocused(false);
                  handleBlur(e);
                }}
              ></Field>
              {togglePassword ? (
                <EyeOutlined
                  className="signup-password-icon"
                  onClick={showPassword}
                />
              ) : (
                <EyeInvisibleOutlined
                  className="signup-password-icon"
                  onClick={showPassword}
                />
              )}
              {touched.newpassword && isPasswordFieldFocused && (
                <div className="password-conditions font-cairo-medium">
                  <div
                    className={`condition ${
                      values.newpassword.length >= 8 ? "valid" : "invalid"
                    }`}
                  >
                    {values.newpassword.length >= 8 ? (
                      <CheckOutlined className="signup-correct" />
                    ) : (
                      <CloseOutlined className="signup-wrong" />
                    )}{" "}
                    At least 8 characters
                  </div>
                  <div
                    className={`condition ${
                      /[a-z]/.test(values.newpassword) ? "valid" : "invalid"
                    }`}
                  >
                    {/[a-z]/.test(values.newpassword) ? (
                      <CheckOutlined className="signup-correct" />
                    ) : (
                      <CloseOutlined className="signup-wrong" />
                    )}{" "}
                    At least 1 lowercase letter
                  </div>
                  <div
                    className={`condition ${
                      /\d/.test(values.newpassword) ? "valid" : "invalid"
                    }`}
                  >
                    {/\d/.test(values.newpassword) ? (
                      <CheckOutlined className="signup-correct" />
                    ) : (
                      <CloseOutlined className="signup-wrong" />
                    )}{" "}
                    At least 1 number
                  </div>
                  <div
                    className={`condition ${
                      /[A-Z]/.test(values.newpassword) ? "valid" : "invalid"
                    }`}
                  >
                    {/[A-Z]/.test(values.newpassword) ? (
                      <CheckOutlined className="signup-correct" />
                    ) : (
                      <CloseOutlined className="signup-wrong" />
                    )}{" "}
                    At least 1 uppercase letter
                  </div>
                  {/* ... (other password conditions) */}
                </div>
              )}
              {/* <ErrorMessage name="password" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage> */}
            </div>
            <div className="resetpassword-fields-cont position-relative aligncontent-baseline">
              <label
                htmlFor="confirmnewpassword"
                className="resetpassword-field-label"
              >
                Confirm Password
              </label>
              <Field
                type={toggleConfirmPassword ? "text" : "password"}
                id="confirmnewpassword"
                name="confirmnewpassword"
                className="resetpassword-input-field"
                placeholder="••••••••"
              />
              {toggleConfirmPassword ? (
                <EyeOutlined
                  className="resetpassword-password-icon"
                  onClick={showConfirmPassword}
                />
              ) : (
                <EyeInvisibleOutlined
                  className="resetpassword-password-icon"
                  onClick={showConfirmPassword}
                />
              )}
              <ErrorMessage
                name="confirmnewpassword"
                className="resetpassword-error-message"
              >
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div className="back-to-login">
              <Link to={"/login"} className="link-style white-color">
                Back to Login
              </Link>
            </div>
            <div className="resetpassword-button-cont">
              <div className={`position-relative reset-button-container5`}>
                <div className={`reset-button-top-shine5`}>
                  <img src={ButtonShine} alt="Button Shine" />
                </div>
                <button
                  className={`reset-button tranform-skew20 font-cairo-bold btntype5`}
                  type="submit"
                >
                  <div className="remove-transform font-cairo-bold">
                    {loading ? (
                      <div className="auth-loading">
                        <Spin className="loader" />
                      </div>
                    ) : (
                      "Reset Password"
                    )}
                  </div>
                </button>
                <div className={`reset-button-bottom-shine5`}>
                  <img src={ButtonShine} alt="Button Shine" />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
