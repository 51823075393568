import React from "react";
import { Modal } from "antd";
import { BorderedButton, FilledButton } from "../../commonComponents";
import "./PrivateAlert.scss";

const PrivateAlert = ({
  visible,
  closeModal,
  handleGameDeletion,
  openContactUsModal,
}) => {
  return (
    <>
      <Modal
        open={visible}
        title=""
        onCancel={closeModal}
        wrapClassName="alert-form-modal"
        className="alert-form-modal-inner"
        footer={null}
        maskStyle={{
          backdropFilter: "blur(5px)",
        }}
        closeIcon="false"
        maskClosable={false}
      >
        <div className="alert">
          <div className="alert-title font-cairo-medium">
            Are you sure you want to exit? Your private game creation is not yet
            complete.
          </div>
          <div className="alert-buttons">
            <BorderedButton
              name={"Exit Anyway"}
              btnClass="alert-buttons-discard"
              functionCall={handleGameDeletion}
            />
            <FilledButton
              name={"Finalise My Game"}
              btnClass="primary-background home-buttons-read-all"
              type={2}
              functionCall={() => {
                openContactUsModal();
                closeModal();
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default PrivateAlert;
