import React, { useState } from "react";
import { Link } from "react-router-dom";
import SiteLogo from "../../../assets/images/auths/logo.svg";
import Gambling1 from "../../../assets/images/gambling-1.png";
import Gambling2 from "../../../assets/images/gambling-2.png";
import Gambling3 from "../../../assets/images/gambling-3.png";
import Gambling4 from "../../../assets/images/gambling-4.png";
import Gambling5 from "../../../assets/images/game_stopicon.png";
import ContactUsModal from "../../modals/ContactUs/ContactUsModal";
import { ReactComponent as FacebookIcon } from "../../../assets/images/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/images/instagram.svg";
import { setNavItem } from "../../../utils/localStorage";
import "./Footer.scss";

const companyData = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Games",
    link: "/game",
  },
  {
    name: "Insights",
    link: "/insights",
  },
  {
    name: "Dashboard",
    link: "/dashboard",
  },
  {
    name: "Rules",
    link: "/rules",
  },
];
const helpData = [
  {
    name: "Safer Gambling",
    link: "safergambling",
  },
  {
    name: "Contact Us",
    link: "#",
  },
  {
    name: "Terms & Conditions",
    link: "/termsandconditions",
  },
  {
    name: "Privacy Policy",
    link: "/privacypolicy",
  },
];
const gamblingIcon = [
  {
    key: 0,
    icon: Gambling1,
    link: "https://www.gamcare.org.uk/get-support/talk-to-us-now/#:~:text=Call%20us%20free%20on%200808%208020%20133",
  },
  {
    key: 1,
    icon: Gambling2,
    link: "https://www.gamblingcommission.gov.uk/public-and-players/safer-gambling",
  },
  {
    key: 2,
    icon: Gambling3,
    link: "https://ibas-uk.com/",
  },
  {
    key: 3,
    icon: Gambling4,
    link: "https://www.begambleaware.org/",
  },
  {
    key: 4,
    icon: Gambling5,
    link: "https://www.gamstop.co.uk",
  },
];
const saferGamblingNavigation = () => {
  setNavItem(8);
};
const Footer = ({ backgroundImageUrl }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <ContactUsModal visible={isModalVisible} closeModal={closeModal} />
      <footer
        className={`footer ${backgroundImageUrl == "" && "footer-background"}`}
      >
        <div className="d-flex width-100">
          <div className="footer-logo">
            <img
              src={SiteLogo}
              alt="Champions Choice Logo"
              className="image-cover-full"
            />
          </div>
          <div className="footer-right">
            <div className="footer-navigation">
              <nav className="footer-navigation-divs">
                <div className="footer-navigation-divs-title font-cairo-bold ">
                  Quick Links
                </div>
                <ul className="footer-links">
                  {companyData.map((item, index) => {
                    return (
                      <li key={index} className="footer-nav-items">
                        <Link to={item.link} className="footer-links-list">
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </nav>
              <nav className="footer-navigation-divs">
                <div className="footer-navigation-divs-title font-cairo-bold">
                  Resources
                </div>
                <ul className="footer-links">
                  {helpData.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="footer-nav-items"
                        onClick={
                          index === 1
                            ? showModal
                            : item.link === "safergambling"
                            ? saferGamblingNavigation
                            : null
                        }
                      >
                        <Link to={item.link} className="footer-links-list">
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
            <div className="mobileonly">
              <div className="footer-gambling">
                {gamblingIcon.map((item, index) => {
                  return (
                    <a
                      className="footer-gambling-div"
                      href={item.link}
                      target="_blank"
                      key={index + 1}
                      rel="noreferrer"
                      style={{ marginTop: index == 4 ? "8px" : "" }}
                    >
                      <img
                        src={item.icon}
                        alt="Gambling Icon"
                        className="image-cover-full"
                      />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="desktoponly">
          <div className="footer-gambling">
            {gamblingIcon.map((item, index) => {
              return (
                <a
                  className="footer-gambling-div"
                  href={item.link}
                  target="_blank"
                  key={index + 1}
                  rel="noreferrer"
                >
                  <img
                    src={item.icon}
                    alt="Gambling Icon"
                    className="image-cover-full"
                  />
                </a>
              );
            })}
          </div>
        </div>

        <div className="footer-second font-cairo-normal">
          <div>
            Champion’s Choice reserves the right to retain a percentage of the
            total pot of each game by way of commission fees.
          </div>
          <div>
            Champion’s Choice Ltd is a company registered in the United Kingdom
            of Great Britain & Northern Ireland with its registered office at
            86-90 Paul Street, London, England, EC2A 4NE. Company number
            14788870.
          </div>
          <div>
            Champion’s Choice Ltd is licensed and regulated by the British
            Gambling Commission ( Account Number  &nbsp; 
              <a
              href="https://www.gamblingcommission.gov.uk/public-register/business/detail/63051"
              target="_blank"
              className="footer-link"
              rel="noreferrer"
            >
               63051&nbsp;
            </a>
            )
          </div>
          <div>
            This app involves real money gambling. It is important to gamble
            responsibly. If you or someone you know requires assistance or
            support regarding gambling addiction, visit BeGamble Aware at &nbsp;
            <a
              href="https://www.begambleaware.org"
              target="_blank"
              className="footer-link"
              rel="noreferrer"
            >
              www.begambleaware.org
            </a>
            &nbsp; or call 0808 8020 133.
          </div>
          <div>
            Underage gambling is a criminal offence. Under 18s are strictly
            prohibited to play on Champion’s Choice.
          </div>
        </div>
        <div className="footer-copyright font-cairo-normal">
          <div className="footer-social-icons">
            <a
              href="https://www.facebook.com/profile.php?id=61561565724993"
              target="_blank"
              rel="noreferrer"
            >
              <div className="footer-social-icons-image">
                <FacebookIcon />
              </div>
            </a>

            {/* <div className="footer-social-icons-image">
              <TwitterIcon />
            </div> */}
            <a
              href="https://www.instagram.com/championschoice.app/"
              rel="noreferrer"
              target="_blank"
            >
              <div className="footer-social-icons-image">
                <InstagramIcon />
              </div>
            </a>

            {/* <div className="footer-social-icons-image">
              <GithubIcon />
            </div> */}
          </div>
          <div className="footer-copyright-text text-center font-cairo-normal">
            © 2024, Champion’s Choice Ltd.
            <br /> All Rights Reserved{" "}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
