import { useState } from "react";
import * as Yup from "yup";
import { Modal, Spin } from "antd";
import { contactUsApi } from "../../../utils/service";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CheckCircleOutlined } from "@ant-design/icons";
import { sendMetaEvents } from "../../../helpers/genericFunctions";
import notify from "../../../utils/notificationService";
import "./ContactUsModal.scss";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const contactFormSchema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegex, "Invalid email address")
    .required("Email is required"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
});

const ContactUsModal = ({ visible, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [contactedUs, setContactedUs] = useState(false);

  const handleFormSubmit = async (values, resetForm) => {
    setLoading(true);
    var data = new FormData();
    data.append("email", values.email);
    data.append("subject", values.subject);
    data.append("message", values.message);
    const res = await contactUsApi(data);
    if (res.status === 200) {
      setLoading(false);
      setContactedUs(true);
      resetForm();
      var data = new FormData();
      data.append("event_name", "Contact us");
      sendMetaEvents(data);
    } else if (!res.success) {
      notify("Sorry! Something went wrong!", "error");
      setLoading(false);
    } else {
      console.log("Failed==>", res);
    }
  };

  return (
    <>
      <Modal
        open={visible}
        title={
          !contactedUs ? (
            "Contact Us"
          ) : (
            <CheckCircleOutlined className="contactus-form-tickicon" />
          )
        }
        onCancel={closeModal}
        wrapClassName="contactus-form-modal"
        className="contactus-form-modal-inner"
        footer={null}
        maskStyle={{
          backdropFilter: "blur(5px)",
        }}
      >
        {!contactedUs ? (
          <Formik
            initialValues={{
              email: "",
              subject: "",
              message: "",
            }}
            validationSchema={contactFormSchema}
            onSubmit={(values, { resetForm }) => {
              handleFormSubmit(values, resetForm);
            }}
          >
            <Form className="contactus-form">
              <div className="contactus-form-fieldscont">
                <label htmlFor="email" className="field-label">
                  Your Email
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="contactus-form-field"
                  placeholder="e.g. phil@gmail.com"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="contactus-form-fieldscont position-relative">
                <label htmlFor="subject" className="field-label">
                  Subject
                </label>
                <Field
                  type="text"
                  id="subject"
                  name="subject"
                  className="contactus-form-field"
                  placeholder="e.g. Private Game Issue"
                ></Field>
                <ErrorMessage
                  name="subject"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="contactus-form-textarea">
                <label htmlFor="subject" className="field-label">
                  Message
                </label>
                <Field
                  as="textarea"
                  id="message"
                  name="message"
                  className="contactus-form-textarea-field"
                  placeholder="Please enter your message here"
                ></Field>
                <ErrorMessage
                  name="message"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="login-button-cont">
                <button
                  type="submit"
                  className="contactus-form-button primary-background"
                >
                  {loading ? (
                    <Spin className="login-loading-spinner" />
                  ) : (
                    <div className="contactus-form-button-text">Send</div>
                  )}
                </button>
              </div>
            </Form>
          </Formik>
        ) : (
          <div className="font-cairo-medium font20 white-color text-center">
            Thank you for reaching out! We’re reviewing your message and will be
            in touch shortly.
          </div>
        )}
      </Modal>
    </>
  );
};
export default ContactUsModal;
