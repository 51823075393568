import React, { useEffect } from "react";
import LoginForm from "./LoginForm";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ExternalNavbar } from "../../commonComponents";
import { getToken, setNavItem, getNavItem } from "../../../utils/localStorage";

import "./Login.scss";

const Login = () => {
  const user_token = getToken();
  const navItem = getNavItem();
  const navigate = useNavigate();
  const isAuthenticated = user_token ? true : false;

  useEffect(() => {
    // if (isAuthenticated) {
    //   navigate("/");
    // }
    if (navItem == 8) {
      setNavItem(8);
    } else if (navItem == 3) {
      return;
    } else {
      setNavItem(null);
    }
  }, []);
  return (
    <div className="black-background white-color padding-bottom2 login-background">
      <ExternalNavbar />
      <div>
        <div className="login-page-title">Login</div>
      </div>
      <div className="main-cont2">
        <div className="background-blur main-cont">
          <div className="login-steps-cont"></div>
          <div className="login-form-cont">
            <div className="login-form-title">Login to Your Account</div>
            <LoginForm />
            <div className="already-member">
              Not a member yet?
              <Link
                to={"/signup"}
                className="grey-color link-style-underline"
                onClick={() => setNavItem(null)}
              >
                &nbsp;Sign up here!
              </Link>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
