import React from "react";
import { Table } from "antd";
import "./GameHistory.scss";

const GameHistory = ({ allTransactionResponse }) => {
  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => {
        return <div>£ {record.amount}</div>;
      },
    },
    {
      title: "Withdrawal Method",
      dataIndex: "payment_method",
      key: "balance",
    },
    {
      title: "Transaction ID",
      dataIndex: "payment_id",
      key: "payment_id",
    },
  ];
  return (
    <>
      <div className="tableHistory">
        <Table
          columns={columns}
          pagination={false}
          className="withdraw-table"
          dataSource={allTransactionResponse}
          scroll={{
            x: 767,
          }}
        />
      </div>
    </>
  );
};
export default GameHistory;
