import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Collapse, Modal, Spin } from "antd";
import Cookies from "js-cookie";
import "./CookiesPreferences.scss";

const { Panel } = Collapse;

const CookiesPreferences = ({
  visible,
  closeModal,
  setCookiesBannerVisible,
}) => {
  const [functionalButton, setFunctionalButton] = useState();
  const [performanceButton, setPerformanceButton] = useState();
  const [cookies, setCookie] = useCookies();
  const [loading, setLoading] = useState(false);

  const onChange = (key) => {
    // console.log(key);
  };
  const handleSaveButton = () => {
    closeModal();
    setCookiesBannerVisible("hidden");
    Cookies.set("myCookieConsent", true);
  };
  const CustomToggleButton = ({ buttonState, setButtonState, cookieName }) => {
    return (
      <div className="cookies-tiltbuttons">
        <div
          className={`${
            buttonState ? "cookies-tiltbuttons-on" : "cookies-tiltbuttons-off"
          }`}
          onClick={(event) => {
            setButtonState(true);
            event.stopPropagation();
            Cookies.set(cookieName, true);
          }}
        >
          Enable
        </div>
        <div
          className={`${
            !buttonState ? "cookies-tiltbuttons-on" : "cookies-tiltbuttons-off"
          }`}
          onClick={(event) => {
            setButtonState(false);
            Cookies.set(cookieName, false);
            event.stopPropagation();
          }}
        >
          Disable
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (
      cookies.allow_functional_cookies == undefined &&
      cookies.allow_performance_cookies == undefined
    ) {
      Cookies.set("allow_functional_cookies", false);
      Cookies.set("allow_performance_cookies", false);
    }
  }, []);

  return (
    <>
      <Modal
        open={visible}
        title="Privacy Overview"
        onCancel={closeModal}
        wrapClassName="cookies-modal"
        className="cookies-modal-inner"
        footer={null}
        maskStyle={{
          backdropFilter: "blur(5px)",
        }}
      >
        <div className="cookies">
          <div className="cookies-title font-cairo-medium">
            Our website uses cookies to ensure the smooth operation of its
            features and enhance your browsing experience. These cookies help us
            tailor content to your preferences, improve site performance, and
            offer personalized interaction based on your usage patterns
          </div>
          <div className="cookies-buttons">
            <Collapse
              onChange={onChange}
              expandIconPosition="start"
              className="cookies-collapse"
            >
              <Panel
                key={1}
                header={"Strictly Necessary Cookies"}
                extra={
                  <div style={{ transform: "skew(-12deg)" }}>
                    Always Enabled
                  </div>
                }
              >
                These cookies are essential for the operation of our website.
                They enable basic functions like page navigation, access to
                secure areas of the website, and secure login. Without these
                cookies, the website cannot function properly.
              </Panel>
              <Panel
                key={2}
                header={"Analytical/Performance Cookies"}
                extra={
                  <CustomToggleButton
                    buttonState={functionalButton}
                    setButtonState={setFunctionalButton}
                    cookieName={"allow_functional_cookies"}
                  />
                }
              >
                These cookies help us understand how visitors interact with our
                website by collecting and reporting information anonymously.
                They track things like the number of visitors, which pages are
                visited most often, and how users navigate through the site.
                This data helps us improve the user experience and the
                performance of our website.
              </Panel>
              <Panel
                key={3}
                header={"Functionality Cookies"}
                extra={
                  <CustomToggleButton
                    buttonState={performanceButton}
                    setButtonState={setPerformanceButton}
                    cookieName={"allow_performance_cookies"}
                  />
                }
              >
                Functionality cookies remember your preferences and settings to
                provide a more personalized experience on our website. They may
                remember your choice of language, region, or other
                personalization features. These cookies help make your return
                visits more convenient and tailored to your preferences.
              </Panel>
            </Collapse>
          </div>
          <div className="cookies-button">
            <button
              type="submit"
              className="cookies-button-item primary-background"
              onClick={handleSaveButton}
            >
              {loading ? (
                <Spin className="login-loading-spinner" />
              ) : (
                <div className="contactus-form-button-text">SAVE & EXIT</div>
              )}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CookiesPreferences;
