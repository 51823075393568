import React from "react";
import * as Yup from "yup";
import PlacesSearch from "../../../authComponents/SignUp/Places/Places";
import "./ResidentailAddress.scss";
import { Formik, Form, Field } from "formik";

const infoSchema = Yup.object().shape({
  address: Yup.string().trim().required("Address is required"),
  city: Yup.string()
    .trim()
    .required("City name is required")
    .matches(/^[^\s].*\S$/, "Please add valid city name"),
  postcode: Yup.string()
    .required("Please add your postcode")
    .matches(/^[A-Z]{1,2}\d{1,2}[A-Z]?\s\d[A-Z]{2}$/i, "Add a valid postcode"),
});
const ResidentialAddress = ({
  userProfileData,
  setUserProfileData,
  userUpdatedData,
  setUserUpdatedData,
}) => {
  const handleFormSubmit = async (values) => {
    const updatedValues = {
      ...userUpdatedData,
      address: values.address,
      city: values.city,
      post_code: values.postcode,
    };
    const updatedProfileValues = {
      ...userProfileData,
      address: values.address,
      city: values.city,
      post_code: values.postcode,
    };
    setUserProfileData(updatedProfileValues);
    setUserUpdatedData(updatedValues);
  };

  return (
    <div className="residential-address">
      <div className="residential-address-title font-cairo-medium">
        Residential Address
      </div>
      <div className="position-relative">
        <Formik
          initialValues={{
            address: "",
            city: "",
            postcode: "",
          }}
          validationSchema={infoSchema}
          onSubmit={handleFormSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="signup-infomation">
                To set up your account successfully, please make sure your home
                address matches your official and personal documentation.
              </div>
              <Field name="address">
                {({ field, form }) => (
                  <PlacesSearch
                    setFormValues={form.setFieldValue}
                    setUserUpdatedData={setUserUpdatedData}
                    userUpdatedData={userUpdatedData}
                    userProfileData={userProfileData}
                  />
                )}
              </Field>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResidentialAddress;
