import React, { useEffect, useState } from "react";
import PromoCounter from "../PromoCounter";
import "./BannerPromo.scss";
import FilledButton from "../FilledButton";

const BannerPromo = (props) => {
  const [isGameExpired, setIsGameExpired] = useState(false);

  const gameStartTimestamp = new Date(
    "2024-10-19T11:30:00.000+01:00"
  ).getTime();

  // Function to check if the game has expired
  const checkGameExpiration = () => {
    const now = new Date().getTime();
    if (now >= gameStartTimestamp) {
      setIsGameExpired(true);
    }
  };

  useEffect(() => {
    checkGameExpiration();
    const intervalId = setInterval(checkGameExpiration, 1000);
    return () => clearInterval(intervalId);
  }, [gameStartTimestamp]);

  const handlePlay = (e) => {
    props.handleJoinGameButton();
  };

  return (
    <div className="banner-mainbox">
      <div className="banner-box">
        {!isGameExpired ? (
          <div className="banner-text">
            £500 POT <span>FREE GAME</span>
          </div>
        ) : props?.screenWidth > 756 ? (
          <div className="banner-text">
            <span>NEXT GAME</span>
          </div>
        ) : (
          <div className="banner-text">
            <span>NEXT GAME</span> STARTS IN
          </div>
        )}
        <PromoCounter
          record={"2024-10-19T11:30:00.000+01:00"}
          eventDate={props?.events}
          screenWidth={props.screenWidth}
        />
      </div>
      <FilledButton
        name={"Play Now!"}
        btnClass="primary-background home-buttons-read-all promo-btn"
        type={1}
        functionCall={handlePlay}
      />
    </div>
  );
};

export default BannerPromo;
