import React from "react";
import { Modal } from "antd";
import "./DepositSuccess.scss";

const DepositSuccess = ({ visible, closeModal, amount }) => {
  return (
    <>
      <Modal
        open={visible}
        title={"Deposit Successful"}
        onCancel={closeModal}
        wrapClassName="depositsuccess-modal"
        className="depositsuccess-modal-inner"
        footer={null}
        maskStyle={{
          backdropFilter: "blur(5px)",
        }}
      >
        <div className="depositsuccess-modal-details font-cairo-medium">
          £{amount} added to your account. Good luck! &#128170; &#129504;
        </div>
      </Modal>
    </>
  );
};
export default DepositSuccess;
