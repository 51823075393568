import React from "react";
import { Modal } from "antd";
import "./VerificationProgress.scss";

const VerificationProgress = ({ visible, closeModal }) => {
  return (
    <>
      <Modal
        open={visible}
        title={"Document Verification In Progress"}
        onCancel={closeModal}
        wrapClassName="verification-warning-modal"
        className="verification-warning-modal-inner"
        footer={null}
        maskStyle={{
          backdropFilter: "blur(5px)",
        }}
      >
        <div className="verification-warning-modal-details font-cairo-medium">
          Your documents are currently in review. We aim to finalize this
          process very soon. Thank you for your patience.
        </div>
      </Modal>
    </>
  );
};
export default VerificationProgress;
