import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Result } from "antd";
import { useNavigate } from "react-router-dom";
import { FilledButton } from "../../../commonComponents";
import { getValidEmail } from "../../../../utils/service";
import { getApiWithoutAuth } from "../../../../utils/api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { GET_Availability } from "../../../../utils/apiEndPoints";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { ReactComponent as ErrorIcon } from "../../../../assets/images/error-icon.svg";
import { passwordValidationSchema } from "../../ValidationsSchema/ValidationsSchema";

const SignupStepOneForm = ({
  setFormStep,
  setStepOneData,
  stepOneData,
  promoCode,
}) => {
  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(
        /^[a-zA-Z]+(?: [a-zA-Z]+)?$/,
        "First name should contain only alphabets"
      )
      .required("First name is required"),
    lastName: Yup.string()
      .matches(
        /^[a-zA-Z]+(?: [a-zA-Z]+)?$/,
        "Last name should contain only alphabets"
      )
      .required("Last name is required"),
    userName: Yup.string()
      .required("Username is required")
      .matches(
        /^[a-zA-Z0-9]+$/,
        "Username should contain only letters and numbers"
      )
      .max(10, "Username must be at most 10 characters")
      .test("username", "Username is already taken", async (value) => {
        if (value) {
          const isAvailable = await getName(value);
          return isAvailable ? true : false;
        }
        return false; // Username is required
      }),
    email: Yup.string()
      .email("Email is invalid")
      .test("email", "Email is already taken", async (value) => {
        if (Yup.string().email().isValidSync(value)) {
          const isAvailable = await getEmail(value);
          return isAvailable ? true : false;
        }
        return true; // Skip the availability check for invalid email format
      })
      .required("Email is required"),
    password: passwordValidationSchema.fields.password,
  });

  const [togglePassword, setTogglePassword] = useState(false);
  const [usernameError, setUsernameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [apiResponseStatus, setApiResponseStatus] = useState(null);
  const [emailResponseStatus, setEmailResponseStatus] = useState(null);
  const [isUsernameFocused, setIsUsernameFocused] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [userNameValue, setUserNameValue] = useState();
  const [emailValue, setEmailValue] = useState();
  const [isPasswordFieldFocused, setIsPasswordFieldFocused] = useState(false);
  const navigate = useNavigate();

  const handleUsernameFocus = () => {
    setIsUsernameFocused(true);
  };
  const handleEmailFocus = () => {
    setIsEmailFocused(true);
  };
  const getName = async (username) => {
    setUserNameValue(username);
    try {
      const response = await getApiWithoutAuth(GET_Availability(username));
      setApiResponseStatus(response?.success);
      setUsernameError(response?.message);
      if (response.data.success) {
        return true; // Username is available
      }
      return false; // API call failed or other error
    } catch (error) {
      setApiResponseStatus(false);
      return false;
    }
  };
  const getEmail = async (email) => {
    setEmailValue(email);
    try {
      const response = await getValidEmail({ email: email });
      setEmailResponseStatus(response?.success);
      setEmailError(response?.message);
      if (response.data.success) {
        setEmailResponseStatus(response.data.success);
        return true;
      }
      return false;
    } catch (error) {
      setEmailResponseStatus(false);
      return false;
    }
  };

  const handleUsernameBlur = () => {
    setIsUsernameFocused(false);
  };
  const handleEmailBlur = () => {
    setIsEmailFocused(false);
  };

  const handleFormSubmit = async (values, { setFieldError }) => {
    setFormStep(1);
    setStepOneData(values);

    // Construct the new URL with the promo query parameter
    if (promoCode) {
      const newUrl = `/signup?promo=${encodeURIComponent(promoCode)}`
      navigate(newUrl);
    }

    if (isUsernameFocused) {
      const isUsernameAvailable = await getName(values.userName);
      if (!isUsernameAvailable) {
        setUsernameError("Username is already taken");
      } else {
        setUsernameError(null);
      }
    }
    if (isEmailFocused) {
      const isEmailAvailable = await getEmail(values.email);
      if (!isEmailAvailable) {
        setEmailError("Email is already taken");
      } else {
        setEmailError(null);
      }
    }
  };

  const showPassword = () => {
    setTogglePassword(!togglePassword);
  };

  return (
    <div>
      <Formik
        initialValues={{
          firstName: stepOneData ? stepOneData.firstName : "",
          lastName: stepOneData ? stepOneData.lastName : "",
          userName: stepOneData ? stepOneData.userName : "",
          email: stepOneData ? stepOneData.email : "",
          password: stepOneData ? stepOneData.password : "",
        }}
        validationSchema={SignupSchema}
        onSubmit={handleFormSubmit}
        validateOnChange={true}
        validateOnBlur={true}
        validate={(values) => {
          const errors = {};
          if (isUsernameFocused) {
            if (!values.userName) {
              errors.userName = "Username is required";
            }
          }
          if (isEmailFocused) {
            if (!values.email) {
              errors.email = "Email is required";
            }
          }
          return errors;
        }}
      >
        {({
          errors,
          touched,
          values,
          setFieldError,
          setFieldValue,
          handleBlur,
        }) => (
          <Form>
            <div className="signup-info-text font-cairo-normal">
              Please enter your personal details as per your passport, national
              ID or driver’s license.
            </div>
            <div className="signup-fields-cont">
              <Field
                type="text"
                id="firstName"
                name="firstName"
                className={`signup-input-field no-outline ${
                  errors.firstName && touched.firstName ? "error" : ""
                }`}
                placeholder="First Name"
              />
              <ErrorMessage name="firstName" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div className="signup-fields-cont">
              <Field
                type="text"
                id="lastName"
                name="lastName"
                className={`signup-input-field no-outline ${
                  errors.lastName && touched.lastName ? "error" : ""
                }`}
                placeholder="Last Name"
              />
              <ErrorMessage name="lastName" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div className="signup-fields-cont position-relative">
              <Field
                type="text"
                id="userName"
                name="userName"
                className={`signup-input-field no-outline ${
                  errors.userName && touched.userName ? "error" : ""
                }`}
                placeholder="Username"
                onFocus={handleUsernameFocus}
                onBlur={handleUsernameBlur}
                style={{ paddingRight: "3.5rem" }}
              />
              {userNameValue &&
                (apiResponseStatus !== false ? (
                  <Result status="success" className="signup-fname-result" />
                ) : (
                  <Result status="error" className="signup-fname-error" />
                ))}
              {usernameError ? (
                <div className="error-message">
                  <ErrorIcon className="error-icon-margin" />
                  {usernameError}
                </div>
              ) : (
                <ErrorMessage name="userName" component="div">
                  {(msg) => (
                    <div className="error-message">
                      <ErrorIcon className="error-icon-margin" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              )}
            </div>
            <div className="signup-fields-cont position-relative">
              <Field
                type="email"
                id="email"
                name="email"
                className={`signup-input-field no-outline ${
                  errors.email && touched.email ? "error" : ""
                }`}
                placeholder="Email"
                onFocus={handleEmailFocus}
                onBlur={handleEmailBlur}
                style={{ paddingRight: "3.5rem" }}
              />
              {emailValue &&
                (emailResponseStatus === true ? (
                  <Result status="success" className="signup-fname-result" />
                ) : (
                  <Result status="error" className="signup-fname-error" />
                ))}
              {emailError ? (
                <div className="error-message">
                  <ErrorIcon className="error-icon-margin" />
                  {emailError}
                </div>
              ) : (
                <ErrorMessage name="email" component="div">
                  {(msg) => (
                    <div className="error-message">
                      <ErrorIcon className="error-icon-margin" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              )}
            </div>
            <div className="signup-fields-cont position-relative">
              <Field
                type={togglePassword ? "text" : "password"}
                id="password"
                name="password"
                className={`signup-input-field no-outline ${
                  errors.password && touched.password ? "error" : ""
                }`}
                placeholder="Password"
                onFocus={(e) => {
                  setIsPasswordFieldFocused(true);
                  handleBlur(e);
                }}
                onBlur={(e) => {
                  setIsPasswordFieldFocused(false);
                  handleBlur(e);
                }}
              />
              {togglePassword ? (
                <EyeOutlined
                  className="signup-password-icon"
                  onClick={showPassword}
                />
              ) : (
                <EyeInvisibleOutlined
                  className="signup-password-icon"
                  onClick={showPassword}
                />
              )}
              {touched.password && isPasswordFieldFocused && (
                <div className="password-conditions font-cairo-medium">
                  <div
                    className={`condition ${
                      values.password.length >= 8 ? "valid" : "invalid"
                    }`}
                  >
                    {values.password.length >= 8 ? (
                      <CheckOutlined className="signup-correct" />
                    ) : (
                      <CloseOutlined className="signup-wrong" />
                    )}{" "}
                    At least 8 characters
                  </div>
                  <div
                    className={`condition ${
                      /[a-z]/.test(values.password) ? "valid" : "invalid"
                    }`}
                  >
                    {/[a-z]/.test(values.password) ? (
                      <CheckOutlined className="signup-correct" />
                    ) : (
                      <CloseOutlined className="signup-wrong" />
                    )}{" "}
                    At least 1 lowercase letter
                  </div>
                  <div
                    className={`condition ${
                      /\d/.test(values.password) ? "valid" : "invalid"
                    }`}
                  >
                    {/\d/.test(values.password) ? (
                      <CheckOutlined className="signup-correct" />
                    ) : (
                      <CloseOutlined className="signup-wrong" />
                    )}{" "}
                    At least 1 number
                  </div>
                  <div
                    className={`condition ${
                      /[A-Z]/.test(values.password) ? "valid" : "invalid"
                    }`}
                  >
                    {/[A-Z]/.test(values.password) ? (
                      <CheckOutlined className="signup-correct" />
                    ) : (
                      <CloseOutlined className="signup-wrong" />
                    )}{" "}
                    At least 1 uppercase letter
                  </div>
                </div>
              )}
            </div>
            <div
              className="signup-button-cont"
              style={{
                marginTop: touched.password && "3rem",
              }}
            >
              <FilledButton
                name={"Next"}
                btnClass="primary-background"
                type={5}
                btnType={"submit"}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignupStepOneForm;
