import React, { useState, useEffect } from "react";
import { Select } from "antd";
import "./AccountClosure.scss";

const timePeriodOptions = [
  {
    value: "Not interested in online gambling anymore",
    label: "Not interested in online gambling anymore",
  },
  {
    value: "Not satisfied with your product ",
    label: "Not satisfied with your product ",
  },
  { value: "Prefer another provider", label: "Prefer another provider" },
  {
    value: "Would like to allocate time for something else",
    label: "Would like to allocate time for something else",
  },
  { value: "Prefer not to specify", label: "Prefer not to specify" },
];
const AccountClosure = ({
  userProfileData,
  setUserProfileData,
  userUpdatedData,
  setUserUpdatedData,
}) => {
  const [accountClosureValue, setAccountClosureValue] = useState();
  const handleMonthChange = (value) => {
    const updatedValues = {
      // ...userUpdatedData,
      closure_reason: value,
    };
    setUserUpdatedData(updatedValues);
    setAccountClosureValue(value);
  };
  useEffect(() => {
    if (userProfileData) {
      setAccountClosureValue(
        userProfileData?.closure_reason == ""
          ? "Please Select Reason"
          : `${userProfileData?.closure_reason}`
      );
    }
  }, [userProfileData]);
  return (
    <div className="account-closure">
      <div className="account-closure-title font-cairo-medium">
        Account Closure
      </div>
      <div className="account-closure-description">
        <div>
          If you want to stop playing on Champion's Choice, you may close your
          account. Before you proceed, here are a few key points to consider:
        </div>
        <div>- Ensure to withdraw any remaining balance in your account.</div>
        <div className="account-closure-padding">
          - Please note we will retain your data for a minimum of 5 years as per
          our privacy policy.
        </div>
        <div className="account-closure-padding">
          Alternatively, If you're considering taking a break rather than a
          permanent step, our Time Out option is available, allowing you to
          suspend your account for up to 30 days.
        </div>
        <div>
          If you feel you may be developing a gambling addiction, our
          Self-Exclusion feature can block access to your account for up to 5
          years. We recommend using Gamstop for further support.
        </div>
      </div>
      <div className="account-closure-select">
        <div className="account-closure-select-label font-cairo-medium">
          Close My Account
        </div>
        <Select
          value={accountClosureValue}
          options={timePeriodOptions}
          onChange={handleMonthChange}
          className="account-closure-dropdown-filter"
          popupClassName="dropdown-popup-style account-closure-dropdown-filter-popupmenu"
          placement="topRight"
        />
      </div>
    </div>
  );
};

export default AccountClosure;
