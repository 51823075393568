import React, { useState, useEffect } from "react";
import { Modal, Spin } from "antd";
import {
  UserDepositLimit,
  SimpleFilledButton,
  BorderedButton,
} from "../../commonComponents";
import { useMyContext } from "../../../helpers/Context/MyContext";
import { updateSaferGambling } from "../../../utils/service";
import notify from "../../../utils/notificationService"
import "./DepositLimitModal.scss";

const DepositLimitModal = ({ visible, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [userProfileData, setUserProfileData] = useState({
    deposit_limit_daily: 0,
    deposit_limit_monthly: 0,
    deposit_limit_weekly: 0,
  });
  const [userUpdatedData, setUserUpdatedData] = useState();
  const { contextData, setContextData } = useMyContext();

  const getGamblingData = async () => {
    setContextData((prevState) => {
      const newState = { ...prevState };
      delete newState.signupData;
      return newState;
    });
    closeModal();
  };
  const handleUpdateSaferGambling = async () => {
    setContextData((prevState) => {
      const newState = { ...prevState };
      delete newState.signupData;
      return newState;
    });
  
    setLoading(true);
  
    // Set default values if userUpdatedData is empty or fields are missing
    const dataToSend = {
      deposit_limit_daily: userUpdatedData?.deposit_limit_daily ?? "-1",
      deposit_limit_monthly: userUpdatedData?.deposit_limit_monthly ?? "-1",
      deposit_limit_weekly: userUpdatedData?.deposit_limit_weekly ?? "-1",
    };
  
    const response = await updateSaferGambling(dataToSend);
    
    if (response) {
      notify(response?.message, "success");
      getGamblingData();
      setUserUpdatedData(); // Clear the updated data after saving
      setLoading(false);
    } else {
      notify("Something went wrong. Try again later", "error");
      setLoading(false);
    }
  
    closeModal();
  };
  useEffect(() => {
    getGamblingData();
  }, []);
  return (
    <>
      <Modal
        open={visible}
        title={"Set your deposit limits"}
        onCancel={closeModal}
        wrapClassName="deposit-modal"
        className="deposit-modal-inner"
        footer={null}
        maskStyle={{
          backdropFilter: "blur(5px)",
        }}
      >
        <div className="deposit-modal-details font-cairo-medium">
          <UserDepositLimit
            userProfileData={userProfileData}
            setUserProfileData={setUserProfileData}
            userUpdatedData={userUpdatedData}
            setUserUpdatedData={setUserUpdatedData}
            title={""}
          />
        </div>
        <div className="deposit-modal-buttons">
          <SimpleFilledButton
            name={"Cancel"}
            btnClass="safer-gambling-buttons-cancel font-cairo-medium"
            functionCall={getGamblingData}
          />
          <BorderedButton
            name={
              loading ? (
                <div className="auth-loading">
                  <Spin className="loader" />
                </div>
              ) : (
                "Save My Limits"
              )
            }
            btnClass="safer-gambling-buttons-confirm font-cairo-medium"
            type={7}
            functionCall={handleUpdateSaferGambling}
          />
        </div>
      </Modal>
    </>
  );
};
export default DepositLimitModal;
