import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { getProbabilityData } from "../../../utils/service";
import "./ProbabilityTooltip.scss";

const ProbabilityTooltip = ({ fixtureID, teamOne, teamTwo }) => {
  const [probabilityData, setProbabilityData] = useState();
  const [probabilityError, setProbabilityError] = useState();

  const handleProbabilityData = async () => {
    const response = await getProbabilityData(fixtureID);
    if (response?.data) {
      setProbabilityData(response?.data);
    } else {
      setProbabilityError(response?.error);
    }
  };
  useEffect(() => {
    if (fixtureID) {
      handleProbabilityData();
    }
  }, [fixtureID]);

  return (
    <div className="probability">
      <div className="probability-title font-cairo-bold">
        Full-time Probability
      </div>
      {probabilityData ? (
        <div className="probability-cont">
          <div className="probability-cont-image">
            <img src={teamOne} alt="Home Team" className="image-cover-full" />
          </div>
          <div className="probability-cont-bar">
            <div
              style={{ width: `${Math.round(probabilityData?.home)}%` }}
              className="probability-cont-bar-win font-cairo-medium"
            >
              Win {probabilityData?.home.toFixed(1)}%
            </div>
            <div
              style={{ width: `${Math.round(probabilityData?.draw)}%` }}
              className="probability-cont-bar-draw font-cairo-medium"
            >
              Draw {probabilityData?.draw.toFixed(1)}%
            </div>
            <div
              style={{ width: `${Math.round(probabilityData?.away)}%` }}
              className="probability-cont-bar-lose font-cairo-medium"
            >
              Win {probabilityData?.away.toFixed(1)}%
            </div>
          </div>
          <div className="probability-cont-image">
            <img src={teamTwo} alt="Away Team" className="image-cover-full" />
          </div>
        </div>
      ) : probabilityError ? (
        <div className="spinner-loading font-cairo-medium">
          {probabilityError}
        </div>
      ) : (
        <div className="spinner-loading font-cairo-medium">
          <Spin className="spinner-loading-spinner" />
        </div>
      )}
    </div>
  );
};

export default ProbabilityTooltip;
