import React from "react";
import { Modal } from "antd";
import "./RestrictedAccess.scss";

const RestrictedAccess = ({ visible, closeModal }) => {
  return (
    <>
      <Modal
        open={visible}
        title={"Restricted Access"}
        onCancel={closeModal}
        wrapClassName="restrictedaccess-modal"
        className="restrictedaccess-modal-inner"
        footer={null}
        maskStyle={{
          backdropFilter: "blur(5px)",
        }}
        closeIcon="false"
        maskClosable={false}
        keyboard={false}
      >
        <div className="restrictedaccess-modal-details font-cairo-medium">
          We’re sorry, but Champion’s Choice is not available in your region. If
          you believe you’re receiving this message in error, please contact our
          support team for assistance at{" "}
          <a href="mailto:support@championschoice.co.uk" className="link-style">
            support@championschoice.co.uk
          </a>
        </div>
      </Modal>
    </>
  );
};
export default RestrictedAccess;
