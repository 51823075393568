import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { BorderedButton } from "../../commonComponents";
import "./PrivateGameLinkModal.scss";

const PrivateGameModal = ({
  visible,
  closeModal,
  privateGameLink,
  isCopied,
  setIsCopied,
}) => {
  const navigate = useNavigate();
  const [baseUrl, setBaseUrl] = useState();
  const handleCopyClick = () => {
    const textToCopy = document.querySelector("#linkToCopy");
    const range = document.createRange();
    range.selectNode(textToCopy);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    try {
      document.execCommand("copy");
      setIsCopied(true);
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
    window.getSelection().removeAllRanges();
  };
  const handleLinkModalClose = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    const URL =
      process.env.REACT_APP_CUSTOM_ENV === "production"
        ? "https://www.championschoice.co.uk"
        : "https://develop.championschoice.co.uk";
    setBaseUrl(URL);
  }, []);

  return (
    <>
      <Modal
        open={visible}
        title="Share Your Private Game Link!"
        onCancel={closeModal}
        wrapClassName="private-game-form-modal"
        className="private-game-form-modal-inner"
        footer={null}
        maskStyle={{
          backgroundColor: "rgba(0,0,0,0.60)",
        }}
        afterClose={handleLinkModalClose}
      >
        <div className="private-game-link">
          <div className="private-game-link-copy">
            <div id="linkToCopy" className="private-game-link-copy-div">
              {baseUrl}/dashboard?private_token=
              {privateGameLink}
            </div>
            <BorderedButton
              name={isCopied ? "Copied!" : "Copy"}
              functionCall={handleCopyClick}
              btnHeight={"32px"}
              btnClass={"private-game-link-button"}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default PrivateGameModal;
