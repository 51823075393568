import React from "react";
import "./TermAndConditions.scss";

const TermAndConditions = () => {
  return (
    <div className="terms font-cairo-normal">
      <div className="font-cairo-bold terms-title">Champion’s Choice Ltd</div>
      <div className="font-cairo-extrabold terms-heading">
        GENERAL TERMS AND CONDITIONS
      </div>
      <div className="terms-subhead font-cairo-bold">
        Material Terms Summary
      </div>
      <ul>
        <li>
          To use our Services, You must register and open an Account with us. By
          registering an Account with Champion’s Choice Ltd through our website
          www.championschoice.co.uk or App / by using the Services offered by
          Champion’s Choice Ltd www.championschoice.co.uk, you will enter
          into a legally binding agreement with us which incorporates the
          General Terms and Conditions, our Privacy Policy and the Game Rules
          for each of the products and services that we offer and any applicable
          third-party terms and conditions in respect of the use of their
          software. (See Clause 1)
        </li>
        <li>
          You must be 18 years of age or older and meet the other eligibility
          and verification criteria to use our services. (See Clauses 4, 6
          and 7)
        </li>
        <li>
          We collect and may use your personal information in accordance with
          our Privacy Policy. (See Clause 5)
        </li>
        <li>
          We will not tolerate fraudulent activity or cheating, and we may
          suspend and/or close your account and share information (together with
          your identity) with the police and other appropriate authorities if we
          have reason to believe you are partaking in fraudulent activity or
          cheating. Where we close or suspend a customer’s account for reasons
          of fraud or cheating, the customer may be liable for all claims,
          losses, liabilities, damages, costs and expenses which we incur in
          connection with the fraud, dishonesty or criminal act. (See Clause 12)
        </li>
        <li>
          We will not be responsible for any errors or events outside of our
          control, which may result in any [entries or wagers] being declared
          void. (See Clauses 12 and 16)
        </li>
      </ul>
      <div className="terms-subhead font-cairo-bold text-center">
        IF YOU DO NOT ACCEPT THESE TERMS AND CONDITIONS OR DO NOT WANT TO BE
        BOUND BY THEM, IN WHOLE OR IN PART, DO NOT CONTINUE TO USE OUR SERVICES
      </div>
      <div className="terms-subhead font-cairo-bold text-center">
        In such a case, you should immediately discontinue all use of the
        website and mobile / tablet applications.
      </div>
      <div className="terms-subhead font-cairo-normal">
        These Terms and Conditions were last updated on 29th Dec 2023.  
      </div>
      <div className="terms-subhead font-cairo-bold text-center">
        Champion’s Choice Ltd GENERAL TERMS AND CONDITIONS
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">1. General</div>
      <div className="privacy-policy-description font-cairo-normal">
        1.1. By using our Services or registering an Account with Champion’s
        Choice Ltd through our website or mobile/tablet application (see Clause
        4 (Registering and opening an Account), you will enter into a legally
        binding agreement which incorporates these general terms and conditions
        ("General Terms and Conditions"), our Privacy Policy and the rules for
        each of the products and services that we offer ("Game Rules"). The
        General Terms and Conditions, Privacy Policy and Game Rules are together
        referred to as the "Terms and Conditions".
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        <br />
        For a non-binding summary of the material terms and conditions please
        <span
          className="font-cairo-bold link-style white-color cursor-pointer"
          onClick={() => {
            window.scroll(0, 0);
          }}
        >
          &nbsp;click here.
        </span>
        <br />
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        1.2. The Terms and Conditions govern your use of the Services and
        supersede any and all prior agreements between you and us in respect of
        the same. 
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        1.3. You agree to the use of electronic communications in order to enter
        into these Terms and Conditions and you waive any rights or requirements
        under applicable laws or regulations in any jurisdiction that requires
        an original (non- electronic) signature, to the extent permitted under
        applicable law.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        1.4. The products and services that Champion’s Choice Ltd offers (via
        its websites, tablet, mobile applications or otherwise) are together
        referred to as the "Services". Your agreement under these Terms and
        Conditions applies to all Services that you use.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        1.5. The Game Rules can be found in Appendix 1 of these Terms &
        Conditions
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        1.6. Please note that these General Terms and Conditions shall prevail
        in the event of any conflict between the General Terms and Conditions
        and any of the Game Rules.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        1.7. Your use of the Services, and these Terms and Conditions, may also
        be subject to any applicable terms and conditions of platform providers
        or other third parties including without limitation: Apple Inc., Google
        Inc., Amazon Digital Services, Inc. Such other terms are not our
        responsibility and are a matter between you and such third parties.
        However, it is a condition of the General Terms and Conditions that you
        fully comply with and adhere to such third-party terms and conditions of
        use.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">2. Parties</div>
      <div className="privacy-policy-description font-cairo-normal">
        2.1. The Terms and Conditions are a binding legal agreement between you
        and Champion’s Choice Ltd, a company registered in England
        & Wales (registered company number 14788870) with its registered office
        at 86-90 Paul Street, 3rd floor, London, EC2A 4NE, England.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        2.2. References to "you" and "your" in the Terms and Conditions are to
        you as the end user of the Services
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        2.3. The Terms and Conditions are a binding legal agreement between you
        and Champion’s Choice Ltd, a company registered in England
        & Wales (registered company number 14788870) with its registered office
        at 86-90 Paul Street, 3rd floor, London, EC2A 4NE, England.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        3. Changes to Terms and Conditions
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        3.1. We reserve the right to modify these General Terms and Conditions
        from time to time (including to comply with applicable law or a change
        in our regulatory requirements). If we modify these General Terms and
        Conditions, we will notify you of such modifications by providing a
        prompt with a link to the revised terms on our websites. Your continued
        use of the Services following such notification will be deemed binding
        acceptance of the changes. If any change is unacceptable to you, you
        should stop using our Services. It is your responsibility to review the
        Terms and Conditions and any amendments to them each time you use
        the Services.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        4. Registering and opening an Account
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        4.1. To use our Services, you need to register and open an account with
        us ("Account"). You can open an Account by clicking the "Sign Up" link
        on our website or mobile and tablet application. There are no set-up
        charges for opening an Account with us.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        4.2. When you open your Account, you will be asked to provide personal
        information, including (but not limited to) your first and last name,
        postal address, email address, date of birth and telephone number.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        4.3. You shall ensure that the details provided by you at registration
        are accurate and kept up to date. If any of these details change, please
        notify us using: (1) the facility provided on our website or mobile and
        tablet application; or (2) via the details provided in Clause 20
        (Contact Us).
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        <br />
        For further information about our collection and use of your personal
        information, please refer to our Privacy Policy.
        <br />
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        4.4. When opening an Account with us, you must fulfil our eligibility
        criteria and pass our anti-money laundering and verification checks (the
        “Requirements”) as described in Clause 6 (Eligibility) and Clause
        7 (Verification) below. You must at all times continue to satisfy the
        Requirements, while you have an active account with us.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        4.5. You can only register one (1) Account with us, which can then be
        used across all of our  Services. If we identify that You have acted
        fraudulently or dishonestly in opening multiple/duplicate accounts, we
        reserve the right to close any and all accounts.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        4.6. We reserve the right to refuse to open an Account for any reason,
        including failure to meet the Requirements.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        4.7. Any bets or wagers placed via your Account must be for your own
        benefit and not for the benefit of any third party.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        5. Information we collect about you and how it is used
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        5.1. We process information about you in accordance with our Privacy
        Policy. Our Privacy Policy contains details on the types of information
        we collect and what we do with that information, which includes who it
        may be shared with and why.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        5.2. We are entitled to share the information we hold on you which
        includes but is not limited to personal data and betting history with
        regulators and other bodies, including the police, and law enforcement
        bodies in order to investigate fraud or money laundering and to comply
        with our regulatory duties.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        5.3. We are entitled to share the information we hold on you which
        includes but is not limited to personal data, financial information and
        betting history with us and, for all general business purposes,
        marketing, assisting with identifying problem gambling, the prevention
        of fraud and financial crime and in order to satisfy our legal and
        regulatory obligations. For further information on how personal data is
        used please see our Privacy Policy.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        6. Eligibility
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        6.1. You must be (18) eighteen years of age or older to use the
        Services. By registering with us / by engaging with our Services, You
        confirm that you are aged (18) eighteen years of age or older.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        6.2.   Access to certain Services may be prohibited  to residents of, or
        persons located in, certain countries. We do not intend that those
        Services be used by persons in countries in which such activities are
        illegal. Our Services do not constitute an offer, solicitation or
        invitation by us for the use of, or subscription to, betting, gaming or
        other services in any jurisdiction in which such activities are
        prohibited by law. In particular, residents of or persons located in the
        United States must not use any of the Services. We currently only accept
        customers based in the United Kingdom; but we may increase this from
        time to time. These Terms and Conditions will be updated accordingly if
        and when this happens.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        6.3. If you access any of the Services from a country other than the
        country specified as your country of residence in the customer
        registration procedure, it is your responsibility to check that
        accessing and using the Services are not prohibited and/or restricted by
        local laws. We accept no liability if your use of our Services is in
        contravention of the laws of the country in which you are located.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        6.4.If we discover or reasonably believe that you are accessing the
        services in a country in which the use of the Services is prohibited or
        in jurisdictions from which we do not accept customers, we shall be
        entitled to immediately suspend your use of Services and/or close your
        Account.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        7. Verification
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        7.1. We reserve the right to ask for proof of age and/or address from
        you, and may suspend or restrict your use of our Services and your
        Account until you provide the requested documents.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        7.2. By accepting the Terms and Conditions, You authorise us to conduct
        any identification, credit or other verification checks that we may
        require (either for our own purposes or as required by applicable law or
        a regulatory body). In particular, we may verify that you are (18)
        eighteen years of age or older and that you are resident in the United
        Kingdom. You agree to provide us with any information we may reasonably
        need in relation to such checks.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        7.3. We may supply the information that you have given us to authorised
        credit reference agencies, who may check the details we provide against
        any databases (public or private) to which they have access and may keep
        a record of that check. We may share information with credit reference
        and fraud prevention agencies for use in credit decisions,
        identification checks and for fraud detection and prevention purposes.
        You agree that we may process, use, record and disclose the personal
        information which you provide in connection with your registration in
        accordance with our Privacy Policy, and that such personal data may be
        recorded by us or the credit reference agencies.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        7.4. If we are unable to confirm that your details (including, without
        limitation, that you are (18) eighteen years of age or older) are
        correct we will restrict your further use of our Services and suspend
        your Account. No further participation in, or interaction with, our
        Services will be permitted until age and identity verification checks
        have been successfully completed to our reasonable satisfaction.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        7.5.As well as the other checks we may undertake, you may be required to
        provide proof of identity and /or proof of address to assist us with
        verification. If requested by us, you will need to go through our GBG’s
        ID Scan document verification service integrated in our website, mobile
        or tablet and send us a copy of your passport, driver's licence, birth
        certificate or other appropriate proof of address documentation for
        these purposes.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        7.6.We reserve the right to request any documentation
        reasonably required in order to meet our legal and regulatory
        requirements or comply with our internal fraud prevention procedures,
        should circumstances dictate. Without prejudice to any other right or
        remedy, we reserve the right to suspend, delete, restrict or modify your
        account where, in our absolute discretion, we believe in good faith that
        there has been a breach of the Terms and Conditions or any regulatory or
        legal requirement by you.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        8. User email and password
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        8.1. When you register with us, you will enter an email address and
        password for your Account. You will also choose a unique username. Once
        you've set your email address, we're unable to change it for you. If you
        wish to change your password, you may do so within the my
        Account section.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        8.2. You may not in any circumstances nominate or allow any person to be
        an authorised user of your Account. It is your responsibility to ensure
        that you do not reveal your email address and password to anyone else.
        We shall be entitled to assume that all games entered / pools joined /
        wagers placed / Services used via your Account have been correctly
        entered by you, whether or not such transactions were authorised by you.
        We shall not be liable for any claims in the event that you disclose
        your email address and password to anyone else or where your negligence
        or deliberate act has contributed to such third party access to your
        Account.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        8.3. It is your responsibility to maintain the confidentiality and
        security of your Account information, email address and password. If
        young persons are sharing or have access to Your devices, You may wish
        to install parental control software. You should change your password on
        a regular basis via the link on our websites and/or mobile and tablet
        applications. You should notify us of any unauthorised use of your
        Account as soon as you suspect it or otherwise become aware of it.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        8.4. f you repeatedly enter your password incorrectly, your Account will
        be locked for security purposes. If you lose or forget your email
        address and/or password You can retrieve the details or if you need to,
        unlock your Account using the tools provided on our website and/or
        mobile and tablet application. Alternatively, You can contact our
        Customer Care Team by email at support@championschoice.co.uk and subject
        to sufficient security and verification checks we will reset Your
        password for You. If You suspect that someone else has obtained Your
        email address and PIN, You must contact our Customer Support Team
        immediately via the ‘Contact Us’ section on our website
        www.championschoice.co.uk or mobile/tablet application, or via email at
        support@championschoice.co.uk
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        9. Use of Services
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        9.1. We reserve the right to suspend, modify, remove and/or add a
        product to the Services at our reasonable discretion on prior notice to
        you wherever possible, however some amendments to products or the
        Services may be required to be made with immediate effect and
        without notice.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        9.2. You must not misuse our website by knowingly introducing viruses,
        trojans, worms, logic bombs or other material which is malicious or
        technologically harmful. You must not attempt to gain unauthorised
        access to: our website; the servers on which our website are stored; or,
        any server computer or database connected to our website or mobile and
        tablet application. We will report any such breach to the relevant law
        enforcement authorities and we will co-operate with those authorities by
        disclosing your identity to them.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        9.3. You agree that you will not use the services in any way that may
        lead to the encouragement, procurement or carrying out of any criminal
        or unlawful activity, or cause distress, harm or inconvenience to any
        other person.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        9.4. You agree that you will not use the Services in any way other than
        for your personal use and for Your own benefit. Any entries, bets or
        wagers placed through your Account that are not for your own benefit
        (including for the avoidance of doubt placed in connection with any bet
        management or brokerage service) are forbidden unless agreed by us (in
        writing) in advance.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        9.5. You agree not to give any indication that you have any commercial
        relationship with us or that you are our agent.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        9.6. The information which we or third parties provide (including but
        not limited to results, statistics, fixture lists, standings, head to
        head insights) on our website, mobile and tablet application and
        Services is for your personal use only and the distribution or
        commercial exploitation of such information by you is strictly
        prohibited. We do not give any commitment or warranty in relation to the
        uninterrupted provision of such information, its accuracy or the results
        obtained through its use. No information which we or third parties
        provide on our website, mobile and tablet application  and Services is
        intended to amount to advice or recommendations and is provided for
        information purposes only. All games entered, pools joined or wagers
        made, are made at your own risk.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        9.7. We do not guarantee which products will be available on our
        Services. The Services are provided on an 'as is' and 'as available'
        basis. Whilst we use reasonable efforts to ensure that our Services run
        smoothly, we cannot promise that the Services will be free from errors
        or omissions nor that they will be available uninterrupted and/or in a
        fully operating condition.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        9.8. We will provide the Services with the reasonable skill and care as
        described in these Terms and Conditions. We do not make any other
        promises about how the Services will be provided.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        9.9. We receive commentaries, news feeds, scores and other content from
        a number of third parties, which may carry terms and conditions
        governing their use. We do not accept any liability in respect of such
        content. Where the Services link to third party websites, content and
        resources this does not constitute an endorsement by us of that third
        party’s website or their products or services and we do not accept any
        liability in respect of your use of such sites.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        9.10. You are strictly prohibited from using the Services for any
        unlawful, illegal, immoral or illegitimate purpose. Without prejudice to
        the generality of this clause 9, you may not while using the Services,
        except to the extent expressly permitted by law:
      </div>
      <ul>
        <li>
          cheat, rig, fix, circumvent rules or processes, use multiple accounts,
          exploit ‘loopholes’ or bugs, or use the Services in a way which is not
          within the spirit intended by the Terms and Conditions or in a way
          which may harm the experience of other users of the Services;
        </li>
        <li>
          modify or adapt the website and or mobile / tablet application or
          hack, merge, translate, create derivatives from the same, mimic,
          disable the same or otherwise tamper with them;
        </li>
        <li>
          reverse engineer, decompile, disassemble, decipher or otherwise
          attempt to derive the source code for any underlying software, code or
          other intellectual property used to provide the website and or mobile
          / tablet applications;
        </li>
        <li>
          delete, obscure, remove or otherwise prevent the proper display of
          intellectual property (including without limitation copyright and
          trademark notices or other legal lines or credits) notices in the
          Services;
        </li>
        <li>
          do anything (or attempt to do anything) which might disrupt use of the
          Services by us or other users, or which could threaten, harass or
          upset other users of the Services or user community; and or
        </li>
        <li>
          make anything available on or through the website and or mobile /
          tablet applications that violates the rights of third parties
          (including without limitation their intellectual property or privacy
          rights).
        </li>
      </ul>
      <div className="privacy-policy-description font-cairo-normal">
        9.11. Your use of the Services is entirely at your own risk. We do not
        recommend using the Services if you have a history of, or propensity
        towards, gambling addiction. Where you do use the Services, you warrant
        that you will do so responsibly, legally and prudently. If you are in
        any doubt as to your ability to do so, you should seek professional
        advice before using the Services.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        10. Entering a game
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        10.1. It is your responsibility to ensure that you only use the Services
        that you intend to interact with. Any bids made, pools joined, wagers
        made or games entered are final once they have been confirmed by our
        website or by any form of email or on-screen confirmation, as per the
        relevant Game Rules.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        10.2. Subject to any specific exceptions within the relevant Game Rules
        applicable to the Service you have used, you may change your entry
        without our consent only up to the advertised deadline. Any modification
        after the advertised deadline will not be permitted.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        10.3. Once a participant has entered a game by enrolling and making
        their initial team selection, they are committed to participating in
        that game. Participants may not withdraw, cancel their entry, or remove
        themselves from the game once they have enrolled.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        10.4. While participants cannot withdraw from a game, they do maintain
        the ability to purchase additional entries (up to the maximum number of
        entries allowed per user per game as specified in the Games rules), as
        well as edit their team selections. Adding entries and changes to team
        selections must be made in accordance with the established rules and
        deadlines.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        11. Refusing your entry
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        11.1. We reserve the right, acting in good faith, to refuse or cancel
        your use of any of our Services in the following circumstances:
      </div>
      <div className="terms-subdescription font-cairo-normal">
        11.1.1 &nbsp; you are under (18) eighteen years of age;
      </div>
      <div className="terms-subdescription font-cairo-normal">
        11.1.2 &nbsp; you are involved in fraud, money laundering, collusion,
        match rigging or cheating of any kind;
      </div>
      <div className="terms-subdescription font-cairo-normal">
        11.1.3 &nbsp; you are in a jurisdiction (or a resident of a
        jurisdiction) that renders the provision of our products to you or your
        use of them illegal;
      </div>
      <div className="terms-subdescription font-cairo-normal">
        11.1.4 &nbsp; you are in breach of the relevant Game Rules, or the Terms
        and Conditions;
      </div>
      <div className="terms-subdescription font-cairo-normal">
        11.1.5 &nbsp; you are found to be using multiple Accounts or a duplicate
        Account; or
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        11.2. In the event of a warning being received by us in relation to
        irregular interactions with our Services and possible instances of event
        manipulation, we reserve the right, at our reasonable discretion, to:
      </div>
      <div className="terms-subdescription font-cairo-normal">
        11.2.1 &nbsp; suspend our offering on any event or series of events in
        any of our markets; and
      </div>
      <div className="terms-subdescription font-cairo-normal">
        11.2.2 &nbsp; delay and/or withhold payment on any event or series of
        events until the integrity of such event or series of events has been
        confirmed
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        12. Fraud and cheating
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        12.1. We will not tolerate any fraudulent activity or cheating. If we
        consider in our absolute discretion (acting in good faith) that you
        have: (1) dishonestly manipulated our Services or taken an unfair
        advantage of us or our Services; or (2) attempted to defraud us or any
        other customer or legal entity, we reserve the right to suspend any
        further use of our Services to you, and/or close Your account and share
        information (together with Your identity) with the police and other
        appropriate authorities. A non-exhaustive list of what we consider to be
        fraudulent activity or cheating is set out below:
      </div>
      <ul>
        <li>identity fraud;</li>
        <li>identity fraud;</li>
        <li>forgery;</li>
        <li>collusion with other individuals;</li>
        <li>utilisation of software to gain an advantage;</li>
        <li>chip dumping;</li>
        <li>exploitation of loopholes within our software & functionality;</li>
        <li>betting on fixed sporting events; or</li>
        <li>insider information.</li>
      </ul>
      <div className="privacy-policy-description font-cairo-normal">
        12.2. You will not in any way interfere, interrupt, attempt to
        interrupt, or attempt to manipulate the operations of our Services or
        the normal play of any of our products. Failure to comply with this rule
        may lead to Your exclusion from our Services, forfeiture of any winnings
        from such prohibited behaviour and to possible criminal and civil
        investigations. In particular, You will not use or attempt to use any
        artificial intelligence, automated players (bots) or player assistance
        software but will play personally via the interfaces provided by us
        only.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        12.3. Collusion between you and any of our other customers, as
        determined at our reasonable discretion, by any methods is strictly
        forbidden. To detect collusion, we use sophisticated software to analyse
        game play history and suspicious activities. Our system monitors betting
        patterns, player history and gameplay, including all bets, wagers and
        entries, for all customers. We also look at various other sources of
        information as part of our anti-collusion checks.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        12.4. Where we reasonably believe that "match-rigging" has taken place,
        either as a result of the size or volume of bets or wagers received, or
        from other evidence (including where you are suspected of improper
        activity with any other online  gaming or gambling site or services), or
        where the integrity of an individual event is called to question, we
        reserve the right to withdraw an offer and ultimately to declare bets on
        that event void.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        12.5. We strictly prohibit the use of any third-party external player
        assistance programs or software ("EPA Programs") which are designed to
        provide an "Unfair Advantage" to customers. EPA Programs include
        computer software, websites, subscription services and
        non-software-based databases, systems or profiles which are used by
        customers in connection with our Services. An "Unfair Advantage" means
        any instance in which a customer accesses or compiles information on
        other customers (including the tracking of customers through the use of
        such automated software) beyond that which the customer has personally
        observed through the customer’s own game play (other than information or
        services provided to customers directly by us) and/or otherwise exploits
        or participates in the exploitation of a fault, loophole or error in our
        or any third party's software and uses it to the disadvantage of us, the
        game play or other customers.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        12.6. You agree that we may take steps to detect and prevent the use of
        prohibited EPA Programs.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        12.7. These steps may include, but are not limited to, examination of
        software programs running concurrently with our software on your
        computer. You agree that you will not attempt to bypass, interfere with,
        or block such steps, including, without limitation, the use of
        third-party software that bypasses, interferes with, or blocks such
        steps.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        12.8. If we reasonably consider that any of the events referred to in
        Sections 12.1 to 12.6 above may have occurred or are likely to occur, we
        reserve the right to:
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        12.8.1 &nbsp; suspend your access to our Services, or cease providing
        Services to you;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        12.8.2 &nbsp; close or suspend Your Account;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        12.8.3 &nbsp; If we close or suspend Your Account for any of the reasons
        referred to above in this Clause 12, you shall be liable for any and all
        claims, losses, liabilities, damages, costs and expenses incurred or
        suffered by us (together “Claim”) arising as a result of or in
        connection with Your fraud, dishonesty or criminal act and You agree to
        fully compensate us for any costs, charges or losses sustained or
        incurred by us (including any direct, indirect or consequential losses,
        any loss of profit and any loss of reputation) in respect of such
        Claims.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        13. Remote Gambling
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        13.1. You are gambling via an electronic form of communication and
        consequently you should be aware that:
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        13.1.1 &nbsp; You may be using a connection or equipment which is slower
        than such equipment used by others and this may affect your performance
        in time critical products (e.g. in-play betting); and
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        13.1.2 &nbsp; You may encounter system flaws, faults, errors or service
        interruption caused by unexpected flaws, faults or errors in the
        software, hardware or networks used to provide the Services. Where such
        flaws, faults or errors cause a product to be interrupted in
        circumstances where it cannot be restarted from exactly the same
        position without any detriment to you or we will take all reasonable
        steps as soon as practicable to remedy the problem and ensure that you
        are treated fairly according to the circumstances.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        14. Closing Your Account 
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        14.1. You have the right to close your Account at any time from the
        ‘Safer Gambling’ section on our website or mobile/tablet application.
        You remain responsible for activities using your Account until it is
        closed.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        14.2. You have the right to close your Account at any time from the
        ‘Safer Gambling’ section on our website or mobile/tablet application.
        You remain responsible for activities using your Account until it is
        closed.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.1 &nbsp; we, acting in good faith, have reason to believe that you
        are in breach of a material provision of these Terms and Conditions
        including but not limited to Section 6 (Eligibility) in respect of
        underage gambling and the use of our Services in contravention of the
        laws of the United Kingdom and Section 12 (Fraud and Cheating);
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.2 &nbsp; we become aware that you have used or attempted to use the
        Services for the purposes of fraud, collusion or unlawful or improper
        activity;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.3 &nbsp; we become aware that you have played at any other online
        gambling site or services and, in connection with the same, are
        suspected of fraud, collusion, cheating or unlawful or improper
        activity;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.4 &nbsp; you fail to provide us with accurate information in
        relation to your Account;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.5 &nbsp; we, acting in good faith, have reason to believe that you
        have opened or are using multiple Accounts or a duplicate Account;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.6 &nbsp; where we have taken the steps to void a bet or wager for
        one of the reasons set out in Section 11 (Refusing Your Bet or
        Wager) above, your actions leading to that shall be considered a breach
        of these Terms and Conditions and shall entitle us to close your
        Account;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.7 &nbsp; you misuse our websites;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.8 &nbsp; we are required to do so by any regulatory authority or
        court;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.9 &nbsp; we discover that you are accessing the Services in
        contravention of the laws of the United Kingdom; or
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.10 &nbsp; you become bankrupt, if you do not make payment of a
        court judgment on time, if you make an arrangement with your creditors,
        or if any of your assets are the subject of any form of seizure or if
        analogous proceedings are brought in relation to you anywhere in the
        world.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        14.3. You agree to compensate us for any costs, charges or losses
        sustained or incurred by us (including any direct, indirect or
        consequential losses, any loss of profit and any loss of reputation)
        arising where we close Your Account in accordance with this Clause 14.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        15. Dormant Accounts
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        15.1. Should Your Account become dormant through lack of use, we will
        continue to contact you (where you have "opted-in") with promotional
        messages until such time as you instruct us to stop.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        15.2. Please note that after a period of no less than 24 (twenty-four)
        months of Account inactivity we reserve the right to close dormant
        Accounts.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        16. Events outside our control
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        16.1. We are not responsible for any events beyond our reasonable
        control. Such events might include, but are not limited to, network
        failures, malfunctions to our systems or third party systems, war,
        terrorist activity, riots, malicious damage, fire, flood, storm, nuclear
        accident or compliance with any new law or governmental order, rule,
        regulation or direction.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        16.2. We may also suspend or cancel the Services or any part of the
        Services if, despite making reasonable efforts to do so, we are not able
        to provide that part of the Service to You as a result of events beyond
        our reasonable control.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        17. Limitations and Exclusions
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        17.1. Nothing in these Terms and Conditions is intended to:
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        17.1.1 &nbsp; exclude or limit our liability for fraud or for death or
        personal injury resulting from our negligence; or
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        17.1.2 &nbsp; limit your statutory rights (statutory rights include, for
        example, that we will provide our Service to a reasonable standard and
        within a reasonable time). For more information about your statutory
        rights, contact your local consumer protection organisation (the
        Citizens Advice Bureau (http://www.citizensadvice.org.uk/) or Trading
        Standards Office (http://www.tradingstandards.gov.uk/advice/) if You are
        located in the UK).
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        17.2. We may also suspend or cancel the Services or any part of the
        Services if, despite making reasonable efforts to do so, we are not able
        to provide that part of the Service to You as a result of events beyond
        our reasonable control.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        17.2.1 &nbsp; any use of our Services in breach of these Terms and
        Conditions (including any use of our Services for commercial or business
        purposes);
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        17.2.2 &nbsp; failures caused by the equipment you use to access our
        website, or mobile and tablet application or failures in any network
        (including failures by your internet service provider);
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        17.2.3 &nbsp; damage to your equipment (e.g. desktop, laptop, mobile,
        smartphone, tablet, games console or other internet-enabled device) or
        for any loss or corruption of data that results from your use of our
        website or mobile and tablet application (and we cannot and do not
        guarantee that any files that You download are free from viruses,
        contamination or destructive features) EXCEPT THAT if any defective
        digital content that we have supplied to You damages a device or digital
        content belonging to you and this is caused by our failure to use
        reasonable care and skill, we will either repair the damage or pay you
        compensation;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        17.2.4 &nbsp; the accuracy, completeness or currency of any information
        services provided by us or third parties (including but not limited to
        prices, times, results or general statistics);
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        17.2.5 &nbsp; any loss whatsoever arising from your abuse or misuse of
        your Account or our Services;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        17.2.6 &nbsp; any failure on our part to observe any self-exclusion
        policies that we may have in place from time to time;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        17.2.7 &nbsp; any failure on our part to interact with you where we may
        have concerns about Your activities;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        17.2.8 &nbsp; any event outside our control as set out in Clause 16
        (Events Outside Our Control); or
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        17.2.9 &nbsp; our closure or suspension of your Account in accordance
        with these Terms and Conditions.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        17.3. You agree to compensate us for any costs, charges or losses
        sustained or incurred by us (including any direct, indirect or
        consequential losses, any loss of profit and any loss of reputation)
        arising directly from your fraud, dishonesty, criminal act or breach of
        these Terms and Conditions.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        17.4. Without limiting any other rights or remedies available to us, we
        may at any time set off any positive balance owed to you, whether in
        your Account or otherwise, against any amount owed to us by you. you
        agree that any such balance may be used to finance any costs incurred as
        a result of your fraudulent activity, such as chargebacks on your
        Account or the reimbursement of funds back to the customer(s) who were
        colluded against.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        17.5. We hereby disclaim all warranties that can be legally disclaimed,
        whether express or implied.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        17.6. Other than as mentioned above, and to the extent permitted by
        applicable law, our overall liability to you, unless excluded elsewhere
        herein, is limited to the sums you spent within your Account within the
        twelve (12) month period prior to the relevant date of alleged
        liability.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        17.7. Under no circumstances shall we be liable to you for any loss or
        damage of an indirect or consequential nature, such as (without
        limitation) loss of profit, loss of trade, loss of data, loss of
        contract, loss of reputation etc.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        18. Intellectual Property
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        18.1. All intellectual property rights in our website and mobile and
        tablet application and all material and/or content made available on or
        for the Services (including but not limited to rights in the products
        and services offered, all code, software, animations, graphics, music,
        sound, photographs, video content or text, and the selection and
        arrangement thereof) or otherwise by us shall remain at all times our
        property or the property of our third party licensors.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        18.2. The names, images and logos identifying us, our partners or third
        parties and our/their products and services contained in our websites
        and mobile and tablet applications are proprietary marks and may not be
        reproduced or otherwise used without express permission. The same is
        protected by copyright law, international copyright treaties and
        conventions and other laws. All rights are asserted and reserved.
        Nothing contained in these Terms and Conditions shall be construed as
        conferring by implication any licence or right to use any trademark,
        patent, design right or copyright that belongs to us or any third party.
        You may not translate, reverse engineer, modify, adapt, exploit,
        disassemble, decompile, merge or create derivative based on our website
        or tablet applications unless expressly permitted by applicable law but
        if you do, the product and all end results of those acts shall belong
        to, vest in and be the exclusive property of us on creation.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        19. Promotions and offers
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        19.1. From time to time we offer promotions and offers to new and
        existing customers. These promotions and offers will have their own
        additional terms and conditions.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        19.2. Please note that the terms and conditions for a promotion or offer
        shall prevail in the event of any conflict between the General Terms and
        Conditions and the terms and conditions for a promotion or offer.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        19.3. All promotions and offers are limited to one per person.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        19.4. We reserve the right to amend the terms and conditions of or
        withdraw any promotion or offer at any time at our reasonable discretion
        on prior notice to You wherever possible, however the promotion may be
        amended or withdrawn without notice, temporarily or permanently, if
        reasonably necessary to do so.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        20. Contact Us
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        20.1. If You need to contact us, you can send us an enquiry from the
        ‘Contact Us’ section on our website or mobile/tablet application,
        alternatively you can email us at  support@championschoice.co.uk, or
        write to us at Champion’s Choice Ltd, 3rd floor 86-89 Paul Street,
        London EC2A 4NE, England.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        21. Miscellaneous
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        21.1. If we need to notify you under these Terms and Conditions, we will
        do so by email to the email address provided to us / registered to your
        Account.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        21.2. We may wish to transfer our rights or obligations or sub-contract
        our obligations under these Terms and Conditions to another legal
        entity. You agree that we may do so provided that this will not
        adversely affect the standard of service you receive under these Terms
        and Conditions to a material extent. In the case of transfer only, after
        we notify you of the date on which we will transfer our rights and
        obligations under these Terms and Conditions to another legal entity,
        Your only rights under or in connection with these Terms and Conditions
        will be against the new legal entity and not against us. As set out in
        Clause 14, You may close Your Account with us at any time.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        21.3. These Terms and Conditions are personal to you. You may not
        transfer your rights or obligations under these Terms and Conditions to
        anyone else.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        21.4. If you breach these Terms and Conditions and we take no action
        against you, we will still be entitled to use our rights and remedies in
        any other situation where you breach these Terms and Conditions.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        21.5.If any part of these Terms and Conditions is disallowed or found to
        be ineffective by any court or regulator, the other provisions shall
        continue to apply.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        21.6. These Terms and Conditions are not intended to give rights to
        anyone except you and us. This does not affect our right to transfer
        these Terms and Conditions under Clause 21.2.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        21.7. These Terms and Conditions are only available in the English
        language.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        21.8. These Terms and Conditions contain the whole agreement between you
        and us. You warrant and confirm that you have not entered into these
        Terms and Conditions upon the basis of any representations that are not
        expressly incorporated into the General Terms and Conditions.  
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        21.9. Nothing contained within these Terms and Conditions is intended to
        be enforceable pursuant to the Contracts (Rights of Third Parties) Act
        1999 by any third party.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        21.10. Any notice due or required to be served under these Terms and
        Conditions shall be served on us using our registered address as set out
        above or on you using whatever contact details you provide. Notices may
        be served by us by electronic means or by recorded delivery. Notices may
        only be served by you on us by recorded delivery.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        22. Miscellaneous
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        22.1. These Terms and Conditions are governed by and interpreted in
        accordance with the laws of England and Wales.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        22.2. Unless otherwise specified in the Game Rules, disputes arising in
        connection with these Terms and Conditions shall be subject to the
        exclusive jurisdiction of the courts of England and Wales.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        23. Responsible Gambling
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        23.1. We are committed to upholding the principle of responsible
        gambling and have a number of self-help tools to help you manage your
        gambling.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        23.2.We make available the facility for You to
      </div>
      <ul>
        <li>
          limit the amount of monies you deposit onto your account through
          deposit limits,
        </li>
        <li>
          stop gambling at any time. 24 (twenty-four) hour to 30 (thirty) day
          cooling off periods
        </li>
        <li>
          self-exclude yourself for a minimum of 6 (six) months to a maximum of
          5 (five) years
        </li>
      </ul>
      <div className="privacy-policy-description font-cairo-normal">
        Information on deposit limits, cooling off and self-exclusion may be
        found within the "Safer Gambling" section of our website, mobile and/or
        tablet application.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        23.3.We will use all reasonable endeavours to ensure compliance with
        self-exclusion. We implement reasonable checks and safeguards to ensure
        that whilst you are self-excluded you are not sent marketing material
        about gambling from us and you cannot access our Services. Consequently,
        we cannot be held liable to you or any third party if you are able to
        continue to gamble on our website or mobile and tablet application, or
        if any marketing material inadvertently gets sent to you or if you
        continue to gamble on any other party's sites or premises. You accept
        that we have no responsibility or liability whatsoever (including in
        respect of any losses You may incur) if You continue to gamble with us
        or any third party, in particular where you have sought to circumvent a
        self-exclusion via additional accounts; or where you have changed any of
        the registration details.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        23.4.The National Association for Gambling Care Educational Resources
        and Training (GAMCARE) (www.gamcare.org.uk) provides information, advice
        and counselling to individuals, their family and friends who have
        concerns about problem gambling. The free Helpline number for GAMCARE
        (available 8am – midnight, 7 days a week) is 0808 8020 133. If You have
        any concerns about your gambling You can also find information and
        support at www.begambleaware.org.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        24. Complaints and Player Dispute Resolution
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        24.1. If a dispute arises in relation to the use of our Services or the
        results of any bets, wagers, pools, games or entries, our transactions
        database will be the ultimate authority in such dispute.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        24.2.If You have any cause to complain about anything that has happened
        as a consequence of Your dealings with us, you should notify us by
        contacting the Customer Support Team by email within 12 (twelve) months
        of the original transaction and they will oversee the management of your
        complaint. Contact details can be found at Clause 20 (Contact Us).
        <br />
        If you are unhappy with the decision of our Customer Experience team you
        can choose to escalate your complaint to our dedicated Complaints team.
        We will deal with your complaint as quickly as we reasonably can and
        shall, as necessary, request appropriate evidence from you for the
        purposes of settling your complaint. Our Complaints team will
        investigate any complaint or concern and issue a final response within 8
        weeks.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        24.2.1 &nbsp; If a complaint is not resolved to your satisfaction by the
        use of our internal complaints procedure, you may refer the matter to an
        independent adjudicator. We have nominated the Independent Betting
        Adjudication Service (IBAS) (www.ibas-uk.com) to handle Alternative
        Dispute Resolution (ADR) for disputes relating to your account. This ADR
        service is freely accessible to you. IBAS can be contacted via email at:
        adjudication@ibas-uk.co.uk, by phone at: 44 (0)20 7347 5883, or via post
        at: Independent Betting Adjudication, PO Box 62639, London, EC3P 3AS.
        For additional information about IBAS, including their Terms of Use,
        please visit www.ibas-uk.com.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        25. Indemnity
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        25.1. You hereby indemnify, defend and hold us harmless and our
        affiliates, officers, directors, owners, licensors, service providers,
        partners, contractors, employees, agents and licensees (collectively,
        the “Indemnified Parties”) from and against any and all any liabilities,
        claims, loss, damage, injury, costs and expenses (including professional
        fees) incurred or suffered by the Indemnified Parties in connection with
        any claim arising out of any breach by you of these Terms and Conditions
        or claims arising directly or indirectly from your use or misuse of the
        Services and / or any negligent or improper use of your password and
        username and / or any use otherwise than in accordance with the Terms
        and Conditions. Such indemnity shall be on a full indemnity basis.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        26. Appendix 1
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        a) The Games Rules
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        This section serves as the comprehensive guide to understanding and
        playing Champion’s Choice, outlining all the essential rules of the
        game.
        <br />
        Applicable to Football standard games
      </div>
      <ol>
        <li>
          Choose a game to join, the cost per entry is the same for all
          participants and for all entries within each game.
        </li>
        <li>
          For each entry, you must select a team from the relevant fixture list
          (‘game week’) to WIN their fixture.
        </li>
        <li>
          If your selection wins, you are through to the next round. If your
          selection draws or loses you are eliminated from the game. 
        </li>
        <li>
          If you are through to the next round, you must repeat the procedure
          using the next set of fixtures before the advertised deadline of the
          following week. However, a team that has already been selected may not
          be selected again within a game. 
        </li>
        <li>
          You may enter up to three separate entries at the beginning of any
          game: each entry is treated as an independent entity.
          <br />
          For example, If a participant has a username called Phil, they will
          have Phil 1 & Phil 2 & Phil 3 as three separate entries within the
          same game. In the first round of game 1, Phil 1 can choose Liverpool,
          Phil 2 can choose Manchester City, Phil 3 can choose Manchester City
          as well. If Liverpool wins and Manchester City lost, Phil 2 and Phil 3
          entries are out of the game, while Phil 1 can still choose Manchester
          city in round 2 of the game as the entries of Phil 1 & Phil 2 are
          treated as separate entities. 
        </li>
        <li>
          If you purchase one entry before the start of a game, you may choose
          to add 1 or 2 more entries (up to a maximum of 3 entries per game)
          before the advertised deadline.
        </li>
        <li>
          Selections must be made by the advertised deadline. If for any reason
          you have not made your team(s) selection by the deadline they will be
          allotted the LOWEST placed team in the league that is available to
          choose. This is known as the ‘default selection’.
        </li>
        <li>
          The deadline for team(s) selection(s) is defined as two (2) hours
          prior to the commencement of the first fixture of each round of each
          game. We reserve the right to modify the selection deadline under
          exceptional circumstances. This might be due to factors beyond our
          control, changes in fixture schedules, or other reasons and will be
          duly communicated.
          <br />
          Failure to make a selection within the adjusted deadline will be
          treated as per the existing rule 7 around missed selections. 
        </li>
        <li>
          In every game, the team selections made by each participant will
          remain confidential until after the selection deadline for each round.
          Only then will they become visible to all other participants. This
          measure is put in place to uphold fairness and transparency within
          each game round. 
        </li>
        <li>
          Once you have made your pick(s), you may change it (them) at any time
          up until the advertised deadline. If you submitted your choice before
          the advertised deadline, but the fixture is postponed and you did not
          change your selection then rule 7 applies.  
        </li>
        <li>
          If you reach the last 10% or less of total participants in a game, you
          will share an equal split of 10% of the prize pot with those still
          standing. The game continues and the ultimate winner(s) receive/s the
          totality of the remaining pot.
        </li>
        <li>
          The winner of the game is the player that remains in the game for the
          longest time and is the Last Player Standing. A situation may arise
          whereby the last players standing all go out at the same time. In this
          instance the prize pot will be split equally between those players.
        </li>
        <li>
          In the unlikely event where all remaining participants get to a round
          where they have no selections to choose from, having chosen all
          available teams already as per rule number 4, the game is considered
          over, and the pot is split between the remaining participants. 
        </li>
        <li>
          In the unlikely event that all participants lose in the first round of
          a game, then the round is considered void and all participants get to
          round 2, rule 4 applies.
        </li>
        <li>
          In case a game ends at a week where there were more than 10% of the
          participants left standing, then the prize pot is split between them
          all.
        </li>
        <li>
          During the season there may be weeks when there are less than 10
          League fixtures, usually due to cup fixtures or other reasons. A round
          will be valid If there are at least 7 fixtures of the competition,
          otherwise the round will be void and postponed until the following
          match week. Please note, in a voided round, any team selections made
          by participants will not be counted as "used" under Rule 4.
          Participants will regain the ability to select these teams in
          subsequent rounds.
        </li>
        <li>
          Any fixture that is played out of sequence, due to previous
          postponement or rescheduling will not be included in the fixtures list
          of a particular round. 
        </li>
        <li>
          If a listed game has not concluded by the end of the League Season,
          the remaining pot will be distributed equally among the last standing
          players.
        </li>
        <li>
          Champion’s Choice reserves the right to retain a percentage of the pot
          as commission fees. 
        </li>
      </ol>
      <div className="privacy-policy-description font-cairo-normal">
        b) Turbo Mode Games
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        Applicable to Football Turbo games & NBA games on Champion’s Choice.
        <br />
        In turbo mode, same rules as the standard games apply (see appendix 1 a)
        with a few exceptions listed below:
      </div>
      <br />
      <ol>
        <li>
          A round is defined as a game day with at least one fixture. In the
          context of the NBA, a round is likely to take place every day given
          the frequency of matches throughout the regular season.
          <br />
          For each entry, a player must select a team from the relevant fixture
          list (‘game day’) to WIN their fixture.
        </li>
        <li>
          A participant may enter up to one entry only at the beginning of any
          game.
        </li>
        <li>
          Once you purchase your entry and made your team selection, you may
          edit this selection before the advertised deadline.
        </li>
        <li>
          A round will be valid If there is at least 1 fixture of the
          competition, otherwise the round will be void and postponed until the
          following match day. In a voided round, any team selections made by
          participants will not be counted as “used” under Rule 4 in appendix 1
          a). Participants will regain the ability to select these teams in
          subsequent rounds.
        </li>
        <li>
          Champion’s Choice reserves the right to retain a percentage of the pot
          as commission fees.
        </li>
      </ol>
    </div>
  );
};

export default TermAndConditions;
