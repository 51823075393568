import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import ButtonShine from "../../../assets/images/shine3.svg";
import "./FilledButton.scss";

const FilledButton = ({
  id,
  name,
  link,
  btnHeight,
  btnClass,
  functionCall,
  type,
  btnType,
  disable,
}) => {
  
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(link);
  };
  const defaultId =
    id ||
    `filled-button-${
      typeof name === "string"
        ? name.toLowerCase().replace(/\s+/g, "-")
        : Date.now()
    }`;

  return (
    <div className={`position-relative filled-button-container${type} flex promo-btn`}>
      <div className={`filled-button-top-shine${type}`}>
        <img src={ButtonShine} alt="Button Shine" />
      </div>
      <Button
        id={defaultId}
        className={`filled-button tranform-skew20 font-cairo-bold ${btnClass} btntype${type}`}
        onClick={functionCall ? functionCall : handleNavigation}
        style={{ height: btnHeight ? btnHeight : null }}
        htmlType={btnType ? btnType : null}
        disabled={disable}
      >
        <div className="remove-transform font-cairo-bold">{name}</div>
      </Button>
      <div className={`filled-button-bottom-shine${type}`}>
        <img src={ButtonShine} alt="Button Shine" />
      </div>
    </div>
  );
};

export default FilledButton;
