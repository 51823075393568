import React, { useState } from "react";
import { BorderedButton } from "../../../commonComponents";
import { useCountdown } from "../../../../helpers/timer.js";
import PoundIcon from "../../../../assets/images/pound-icon.svg";
import JoinGameModal from "../../../modals/JoinGameModal/JoinGameModal";
import FootballBanner from "../../../../assets/images/football-banner.jpg";
import BasketballBanner from "../../../../assets/images/basketball-banner.jpg";
import PremierLeagueIcon from "../../../../assets/images/premier_league.svg";
import "./JoinGame.scss";

const JoinGame = ({ gameDetails }) => {
  const [days, hours, minutes] = useCountdown(gameDetails.start_date);
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);

  const openContactUsModal = () => {
    setIsContactUsModalOpen(true);
  };
  const closeContactUsModal = () => {
    setIsContactUsModalOpen(false);
  };

  return (
    <div className="join-game black-background">
      <JoinGameModal
        visible={isContactUsModalOpen}
        closeModal={closeContactUsModal}
        gameDetails={gameDetails}
      />
      <div className="join-game-image">
        <img
          src={
            gameDetails.sport_name === "Football"
              ? FootballBanner
              : BasketballBanner
          }
          alt="Sport Icon"
          className="image-cover-full"
        />
      </div>
      <div className="join-game-cont">
        <div className="join-game-cont-top">
          <div className="join-game-cont-top-title">
            <div className="join-game-cont-top-title-icon">
              <img
                src={
                  gameDetails.season_id == 23614
                    ? PremierLeagueIcon
                    : gameDetails.league_image
                }
                alt="title-icon"
                className="image-cover-full"
                style={{ width: gameDetails?.sport_id == 5 ? "17px" : "24px" }}
              />
            </div>
            <span className="join-game-cont-top-title-text font-cairo-medium">
              {gameDetails.league_name}
            </span>
          </div>
          <div className="join-game-timer">
            <div className="d-grid">
              <span className="join-game-timer-cont font-cairo-medium">
                {days}
              </span>
              <span className="join-game-timer-text font-cairo-normal">
                Days
              </span>
            </div>
            <div className="d-grid">
              <span className="join-game-timer-cont font-cairo-medium">
                {hours}
              </span>
              <span className="join-game-timer-text font-cairo-normal">
                Hours
              </span>
            </div>
            <div className="d-grid">
              <span className="join-game-timer-cont font-cairo-medium">
                {minutes}
              </span>
              <span className="join-game-timer-text font-cairo-normal">
                Minutes
              </span>
            </div>
          </div>
        </div>
        <div className="join-game-cont-bottom">
          <div className="join-game-cont-bottom-left font-cairo-normal">
            Stake:
            <div className="join-game-cont-bottom-left-icon">
              <img
                src={PoundIcon}
                alt=""
                className="join-game-cont-bottom-right-icon-image image-cover-full"
              />
            </div>
            <span className="font-cairo-bold">
              {Math.floor(gameDetails.stake)}
            </span>
          </div>
          <div className="join-game-cont-bottom-right font-cairo-normal">
            Pot:
            <div className="join-game-cont-bottom-right-icon">
              <img
                src={PoundIcon}
                alt=""
                className="join-game-cont-bottom-right-icon-image image-cover-full"
              />
            </div>
            <span className="font-cairo-bold">
              {Math.floor(gameDetails.pot)}
            </span>
          </div>
        </div>
      </div>
      <div className="join-game-button">
        <BorderedButton
          name={gameDetails.is_joined ? "Game Joined" : "Join Game"}
          link={"#"}
          btnClass="join-game-button-class"
          functionCall={gameDetails.is_joined ? null : openContactUsModal}
        />
      </div>
    </div>
  );
};

export default JoinGame;
