import React from "react";
import "./GameStep.scss";

const GameStep = ({ step, description, image }) => {
  return (
    <div className="game-steps position-relative">
      <div className="game-steps-text">
        <div className="font-cairo-extrabold game-steps-text-step">
          {step}
        </div>
        <div className="font-cairo-bold game-steps-text-join text-start">
          {description}
        </div>
        <div className="game-steps-image" 
        style={{height:step==="03"?"216px":"240px"}}>
          <img src={image} alt="Step Icon" className="image-cover-full" />
        </div>
      </div>
    </div>
  );
};

export default GameStep;
