import React from "react";
import RTLStep from "../../../../assets/images/ltr-step.png";
import LTRStep from "../../../../assets/images/rtl-step.png";
import MobileArrow from "../../../../assets/images/rules-mobile.png";
import { Collapse } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useScreenWidth } from "../../../../utils/useScreenWidth";

import "./GameRule.scss";

const { Panel } = Collapse;

const GameRule = ({
  stepNumber,
  stepDetails,
  stepDirection,
  isLast,
  openContactUsModal,
  setModalDetails,
  question,
}) => {
  const screenWidth = useScreenWidth();

  const onChange = (key) => {
  };
  const customExpandIcon = ({ isActive }) => {
    return isActive ? (
      <UpOutlined style={{ fontSize: "16px", color: "#1890ff" }} />
    ) : (
      <DownOutlined style={{ fontSize: "16px", color: "#1890ff" }} />
    );
  };
  return (
    <div
      className={`game-rules position-relative ${
        stepDirection === "rtl" ? "game-rules-rtl" : "game-rules-margin"
      }`}
      style={{ marginRight: isLast && screenWidth > 767 && "25rem" }}

    >
      <div className="game-rules-cont position-relative">
        <div className="game-rules-cont-number container-border font-cairo-extrabold">
          {stepNumber}
        </div>
        <div className="game-rules-cont-details container-border font-cairo-normal">
          <Collapse
            onChange={onChange}
            expandIconPosition="end"
            className="game-rules-collapse"
            expandIcon={customExpandIcon}
          >
            <Panel key={1} header={question}>
              {stepDetails}
            </Panel>
          </Collapse>
          {!isLast && (
            <img
              src={MobileArrow}
              alt="Mobile Arrow Icon"
              className="game-rules-mobilearrow"
            />
          )}
        </div>
      </div>
      <div className="position-relative game-rules-images">
        {isLast ? null : (
          <div className="game-rules-images-icon desktoponly">
            {stepDirection === "rtl" ? (
              <img
                src={LTRStep}
                alt="Game Rules Icon"
                className="image-cover-full"
              />
            ) : (
              <img
                src={RTLStep}
                alt="Game Rules Icon"
                className="image-cover-full"
              />
            )}
          </div>
        )}
        
      </div>
    </div>
  );
};

export default GameRule;
