import React, { useState } from "react";
import * as Yup from "yup";
import { Result, Spin } from "antd";
import DatePicker from "react-datepicker";
import PlacesSearch from "../Places/Places.jsx";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getApiWithoutAuth } from "../../../../utils/api.js";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { FilledButton } from "../../../commonComponents/index.js";
import {
  GET_Availability,
  GET_AvailabilityPromoCode,
} from "../../../../utils/apiEndPoints";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { useMyContext } from "../../../../helpers/Context/MyContext.js";
import { sendMetaEvents } from "../../../../helpers/genericFunctions.js";
import { signupWithEmail, getValidEmail } from "../../../../utils/service.js";
import { ReactComponent as ErrorIcon } from "../../../../assets/images/error-icon.svg";
import { passwordValidationSchema } from "../../ValidationsSchema/ValidationsSchema.js";
import { ReactComponent as CallendarIcon } from "../../../../assets/images/callendar-icon.svg";
import "react-datepicker/dist/react-datepicker.css";
import notify from "../../../../utils/notificationService.js";
import "../Signup.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const MobileSignUp = ({ setFormStep, setStepOneData, stepOneData }) => {
  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[A-Za-z]+$/, "First name should contain only alphabets")
      .required("First name is required"),
    lastName: Yup.string()
      .matches(/^[A-Za-z]+$/, "Last name should contain only alphabets")
      .required("Last name is required"),
    userName: Yup.string()
      .required("Username is required")
      .matches(
        /^[a-zA-Z0-9]+$/,
        "Username should contain only letters and numbers"
      )
      .max(10, "Username must be at most 10 characters")
      .test("username", "Username already taken", async (value) => {
        if (value) {
          const isAvailable = await getName(value);
          return isAvailable ? true : false;
        }
        return false; // Username is required
      }),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      )
      .test("email", "Email is already taken", async (value) => {
        if (Yup.string().email().isValidSync(value)) {
          const isAvailable = await getEmail(value);
          return isAvailable ? true : false;
        }
        return false;
      }),

    dateOfBirth: Yup.date()
      .min(new Date(1920, 0, 1), "Must be after 1900/01/01")
      .max(new Date(), "Cannot be a future date")
      .required("Date of Birth is required")
      .test("is-adult", "Must be 18 years or older", function (value) {
        const currentDate = new Date();
        const birthDate = new Date(value);
        const ageDifference =
          currentDate.getFullYear() - birthDate.getFullYear();
        return ageDifference >= 18;
      }),
    promoCode: Yup.string().test(
      "promoCode",
      "Invalid promocode",
      async (value) => {
        if (value) {
          const isAvailable = await getPromo(value);
          return isAvailable ? true : false;
        }
        return true; // No promo code provided
      }
    ),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .test("valid-phone", "Invalid phone number", (value) => {
        try {
          const phoneNumber = parsePhoneNumber(value);
          return phoneNumber && phoneNumber.isValid();
        } catch (error) {
          return false;
        }
      }),
    country: Yup.string().required("Please select your country"),
    city: Yup.string().required("Please add your city"),
    postcode: Yup.string().required("Please add your postcode"),
    address: Yup.string().required("Please add your address"),
    termsAndConditions: Yup.bool()
      .oneOf([true], "Please accept the terms and conditions")
      .required("Please confirm your agreement with our policies to continue"),
    password: passwordValidationSchema.fields.password,
    building_number: Yup.string(),
    building_name: Yup.string(),
    building: Yup.string(),
    street: Yup.string(),
  });
  const [loading, setLoading] = useState(false);
  const [emailValue, setEmailValue] = useState();
  const [emailError, setEmailError] = useState(null);
  const [countryCode, setCountryCode] = useState("GB");
  const [userNameValue, setUserNameValue] = useState();
  const [usernameError, setUsernameError] = useState(null);
  const [togglePassword, setTogglePassword] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [apiResponseStatus, setApiResponseStatus] = useState(null);
  const [isUsernameFocused, setIsUsernameFocused] = useState(false);
  const [emailResponseStatus, setEmailResponseStatus] = useState(null);
  const [addvertisementCheck, setAddvertisementCheck] = useState(false);
  const [isPasswordFieldFocused, setIsPasswordFieldFocused] = useState(false);
  const { contextData, setContextData } = useMyContext();
  const [promoCodeValue, setPromoCodeValue] = useState();
  const [promoCodeError, setPromoCodeError] = useState(null);
  const [isPromoCodeFocus, setIsPromoCodeFocus] = useState(false);
  const [promoResponseStatus, setPromoResponseStatus] = useState(null);

  const query = useQuery();
  const promo = query.get("promo");

  const handlePromoCodeFocus = (event) => {
    setIsPromoCodeFocus(true);
  };

  const handlePromoCodeBlur = (event) => {
    const value = event.target.value;
    if (!value) {
      setPromoCodeValue();
      setPromoCodeError(null);
    }
    setIsPromoCodeFocus(false);
  };

  const navigate = useNavigate();
  const showPassword = () => {
    setTogglePassword(!togglePassword);
  };

  const handleUsernameFocus = () => {
    setIsUsernameFocused(true);
  };
  const handleEmailFocus = () => {
    setIsEmailFocused(true);
  };
  const handleUsernameBlur = () => {
    setIsUsernameFocused(false);
  };
  const handleEmailBlur = () => {
    setIsEmailFocused(false);
  };
  const getName = async (username) => {
    setUserNameValue(username);
    try {
      const response = await getApiWithoutAuth(GET_Availability(username));
      setApiResponseStatus(response?.success);
      setUsernameError(response?.message);
      if (response.data.success) {
        return true; // Username is available
      }
      return false; // API call failed or other error
    } catch (error) {
      setApiResponseStatus(false);
      return false;
    }
  };

  const getPromo = async (promoCode) => {
    if (promoCode) {
      setPromoCodeError(null);
      setPromoCodeValue();
    }
    setPromoCodeValue(promoCode);
    try {
      const response = await getApiWithoutAuth(
        GET_AvailabilityPromoCode(promoCode)
      );
      setPromoResponseStatus(response?.message);
      setPromoCodeError(response?.message);
      if (response.data.success) {
        return true; // Username is available
      }
      return false; // API call failed or other error
    } catch (error) {
      setPromoResponseStatus(false);
      // setPromoCodeError(null)
      return false;
    }
  };

  const getEmail = async (email) => {
    setEmailValue(email);
    try {
      const response = await getValidEmail({ email: email });
      setEmailResponseStatus(response.success);
      setEmailError(response?.message);
      if (response.data.success) {
        setEmailResponseStatus(response.data.success);

        return true;
      }
      return false;
    } catch (error) {
      setEmailResponseStatus(false);
      return false;
    }
  };
  const handleFormSubmit = async (values) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Sign Ups", // You can name this anything
      conversionId: "11420071760",
      conversionLabel: "voBLCPi9gscZENDuwcUq",
      buttonId: "mobileSignupBtn",
    });
    // Handle form submission
    const offsetMinutes = values.dateOfBirth.getTimezoneOffset();
    const adjustedDate = new Date(
      values.dateOfBirth.getTime() - offsetMinutes * 60000
    );
    const formattedDate = adjustedDate.toISOString().split("T")[0];
    setLoading(true);
    let data = new FormData();
    data.append("email", values.email);
    data.append("password", values.password);
    data.append("password_confirmation", values.password);
    data.append("first_name", values.firstName);
    data.append("last_name", values.lastName);
    data.append("username", values.userName);
    data.append("address", values.address);
    data.append("date_of_birth", formattedDate);
    data.append("country", values.country);
    data.append("city", values.city);
    data.append("phone", values.phoneNumber);
    data.append("referal_code", values.promoCode);
    data.append("post_code", values.postcode);
    data.append("notification", addvertisementCheck);
    data.append("text_messages", addvertisementCheck);
    data.append("email_notification", addvertisementCheck);
    data.append("building_number", values.building_number);
    data.append("street", values.street);
    data.append("building_name", values.building_name);
    data.append("building", values.building);
    if (isUsernameFocused) {
      const isUsernameAvailable = await getName(values.userName);
      if (!isUsernameAvailable) {
        setUsernameError("Username already taken");
      } else {
        setUsernameError(null);
      }
    }

    // if (isPromoCodeFocus) {
    //   const isValidPromoCode = await getPromo(values.promoCode);
    //   if (!isValidPromoCode) {
    //     setPromoCodeError("Invalid Promo Code");
    //   } else {
    //     setPromoCodeError(null);
    //   }
    // }

    if (isEmailFocused) {
      const isEmailAvailable = await getEmail(values.email);
      if (!isEmailAvailable) {
        setEmailError("Email is already taken");
      } else {
        setEmailError(null);
      }
    }
    const res = await signupWithEmail(data);
    if (res.status === 200) {
      setLoading(false);
      sessionStorage.setItem("signedIn", true);
      if (
        res?.data?.data.is_excluded === false &&
        res?.data?.data.status === "pass"
      ) {
        notify(
          "Welcome to Champion's Choice! We're thrilled to have you,Champ! Good Luck 🙌🏻🍀",
          "success"
        );
      }
      setContextData({ ...contextData, signupData: res?.data?.data });
      setTimeout(() => {
        navigate("/");
      }, 4000);
      var dataEvent = new FormData();
      dataEvent.append("event_name", "SignUp");
      sendMetaEvents(dataEvent);
    } else {
      notify(res?.message, "error");
      setFormStep(0);
      setLoading(false);
    }
  };
  return (
    <div>
      <Formik
        initialValues={{
          firstName: stepOneData ? stepOneData.firstName : "",
          lastName: stepOneData ? stepOneData.lastName : "",
          userName: stepOneData ? stepOneData.userName : "",
          email: stepOneData ? stepOneData.email : "",
          password: stepOneData ? stepOneData.password : "",
          dateOfBirth: null,
          phoneNumber: "",
          promoCode: promo ? promo : "",
          city: "",
          country: "United Kindom",
          postcode: "",
          address: "",
          termsAndConditions: false,
          building_number: "",
          street: "",
          building_name: "",
          building: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={handleFormSubmit}
      >
        {({ errors, touched, values, handleBlur }) => (
          <Form>
            <div className="signup-fields-cont overflow-hidden">
              <Field
                type="text"
                id="firstName"
                name="firstName"
                className="signup-input-field"
                placeholder="First Name"
              />
              <ErrorMessage name="firstName" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div className="signup-fields-cont overflow-hidden">
              <Field
                type="text"
                id="lastName"
                name="lastName"
                className="signup-input-field"
                placeholder="Last Name"
              />
              <ErrorMessage name="lastName" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div className="signup-fields-cont position-relative overflow-hidden">
              <Field
                type="text"
                id="userName"
                name="userName"
                className="signup-input-field"
                placeholder="Username"
                onFocus={handleUsernameFocus}
                onBlur={handleUsernameBlur}
              />
              {userNameValue &&
                (apiResponseStatus !== false ? (
                  <Result status="success" className="signup-fname-result" />
                ) : (
                  <Result status="error" className="signup-fname-error" />
                ))}

              {usernameError ? (
                <div className="error-message">
                  <ErrorIcon className="error-icon-margin" />
                  {usernameError}
                </div>
              ) : (
                <ErrorMessage name="userName" component="div">
                  {(msg) => (
                    <div className="error-message">
                      <ErrorIcon className="error-icon-margin" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              )}
            </div>
            <div className="signup-fields-cont overflow-hidden position-relative">
              <Field
                type="email"
                id="email"
                name="email"
                className="signup-input-field"
                placeholder="Email"
                onFocus={handleEmailFocus}
                onBlur={handleEmailBlur}
              />
              {emailValue &&
                (emailResponseStatus == true ? (
                  <Result status="success" className="signup-fname-result" />
                ) : (
                  <Result status="error" className="signup-fname-error" />
                ))}

              {emailError ? (
                <div className="error-message">
                  <ErrorIcon className="error-icon-margin" />
                  {emailError}
                </div>
              ) : (
                <ErrorMessage name="email" component="div">
                  {(msg) => (
                    <div className="error-message">
                      <ErrorIcon className="error-icon-margin" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              )}
            </div>
            <div
              className="signup-fields-cont position-relative"
              style={{
                height: touched.password && isPasswordFieldFocused && "120px",
              }}
            >
              <Field
                type={togglePassword ? "text" : "password"}
                id="password"
                name="password"
                className={`signup-input-field no-outline ${
                  errors.password && touched.password ? "error" : ""
                }`}
                placeholder="Password"
                onFocus={(e) => {
                  setIsPasswordFieldFocused(true);
                  handleBlur(e);
                }}
                onBlur={(e) => {
                  setIsPasswordFieldFocused(false);
                  handleBlur(e);
                }}
              ></Field>
              {togglePassword ? (
                <EyeOutlined
                  className="signup-password-icon"
                  onClick={showPassword}
                />
              ) : (
                <EyeInvisibleOutlined
                  className="signup-password-icon"
                  onClick={showPassword}
                />
              )}
              {touched.password && isPasswordFieldFocused && (
                <div className="password-conditions font-cairo-medium">
                  <div
                    className={`condition ${
                      values.password.length >= 8 ? "valid" : "invalid"
                    }`}
                  >
                    {values.password.length >= 8 ? (
                      <CheckOutlined className="signup-correct" />
                    ) : (
                      <CloseOutlined className="signup-wrong" />
                    )}{" "}
                    At least 8 characters
                  </div>
                  <div
                    className={`condition ${
                      /[a-z]/.test(values.password) ? "valid" : "invalid"
                    }`}
                  >
                    {/[a-z]/.test(values.password) ? (
                      <CheckOutlined className="signup-correct" />
                    ) : (
                      <CloseOutlined className="signup-wrong" />
                    )}{" "}
                    At least 1 lowercase letter
                  </div>
                  <div
                    className={`condition ${
                      /\d/.test(values.password) ? "valid" : "invalid"
                    }`}
                  >
                    {/\d/.test(values.password) ? (
                      <CheckOutlined className="signup-correct" />
                    ) : (
                      <CloseOutlined className="signup-wrong" />
                    )}{" "}
                    At least 1 number
                  </div>
                  <div
                    className={`condition ${
                      /[A-Z]/.test(values.password) ? "valid" : "invalid"
                    }`}
                  >
                    {/[A-Z]/.test(values.password) ? (
                      <CheckOutlined className="signup-correct" />
                    ) : (
                      <CloseOutlined className="signup-wrong" />
                    )}{" "}
                    At least 1 uppercase letter
                  </div>
                  {/* ... (other password conditions) */}
                </div>
              )}
              {/* <ErrorMessage name="password" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage> */}
            </div>

            {/* <div className="signup-fields-cont position-relative overflow-hidden">
            <Field
              type={togglePassword ? "text" : "password"}
              id="password"
              name="password"
              className="signup-input-field"
              placeholder="Password"
            ></Field>
            {togglePassword ? (
              <EyeOutlined
                className="signup-password-icon"
                onClick={showPassword}
              />
            ) : (
              <EyeInvisibleOutlined
                className="signup-password-icon"
                onClick={showPassword}
              />
            )}
            <ErrorMessage
              name="password"
              component="div"
              className="error-message"
            />
          </div> */}
            <div className="signup-fields-cont">
              <div style={{ position: "relative" }}>
                <CallendarIcon className="signup-callender-icon" />
                <Field name="dateOfBirth">
                  {({ field, form }) => (
                    <DatePicker
                      {...field}
                      selected={field.value} // Add this line to ensure the selected date is reflected in the field
                      onChange={(date) => {
                        form.setFieldValue("dateOfBirth", date);
                      }}
                      className="signup-input-field"
                      placeholderText="Date of Birth" // Set the placeholder text
                      dateFormat="yyyy/MM/dd"
                    />
                  )}
                </Field>
              </div>
              <ErrorMessage name="dateOfBirth" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div className="signup-infomation">
              To set up your account successfully, please make sure your home
              address matches your official and personal documentation.
            </div>
            <Field name="address">
              {({ field, form }) => (
                <PlacesSearch setFormValues={form.setFieldValue} />
              )}
            </Field>
            <div className="signup-fields-cont " style={{ width: "100%" }}>
              <Field name="phoneNumber">
                {({ field, form }) => (
                  <PhoneInput
                    {...field}
                    id="phoneNumber"
                    defaultCountry={countryCode}
                    onChange={(value) =>
                      form.setFieldValue("phoneNumber", value)
                    }
                    className="signup-input-field phone-number-dropdown"
                    style={{ width: "100%" }}
                    placeholder={"Phone number"}
                  />
                )}
              </Field>
              <ErrorMessage
                name="phoneNumber"
                component="div"
                className="error-message"
              />
            </div>

            <div className="signup-fields-cont position-relative overflow-hidden">
              <Field
                type="text"
                id="promoCode"
                name="promoCode"
                className="signup-input-field"
                placeholder="Promo code (optional)"
                onFocus={handlePromoCodeFocus}
                onBlur={handlePromoCodeBlur}
              />
              {promoCodeValue &&
                (promoResponseStatus !== false ? (
                  <Result status="success" className="signup-fname-result" />
                ) : (
                  <Result status="error" className="signup-fname-error" />
                ))}

              {promoCodeError ? (
                <div className="error-message">
                  <ErrorIcon className="error-icon-margin" />
                  {promoCodeError}
                </div>
              ) : (
                <ErrorMessage name="promoCode" component="div">
                  {(msg) => (
                    <div className="error-message">
                      <ErrorIcon className="error-icon-margin" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              )}
            </div>

            {/* <div>
            <div className="signup-terms">
              <Field
                type="checkbox"
                name="termsAndConditions"
                className="signup-form-checkbox"
              />
              <label className="signup-form-terms">
                &nbsp;&nbsp;I agree the
                <Link to={"#"} className="white-color link-style-underline">
                  &nbsp;Terms & Conditions and Privacy Policy.
                </Link>
              </label>
            </div>
            <ErrorMessage
              name="termsAndConditions"
              component="div"
              className="error-message"
            />
          </div> */}

            <div>
              <div className="signup-terms" style={{ marginBottom: "0.6" }}>
                <Field
                  type="checkbox"
                  name="advertisementCheck"
                  className="signup-form-checkbox checkoxSignup"
                  onClick={() => {
                    setAddvertisementCheck(!addvertisementCheck);
                  }}
                />
                <label className="signup-form-terms">
                  I want to receive information about free games, bonuses and
                  other offers via email, SMS or telephone. (You can edit your
                  preferences at any time)
                </label>
              </div>

              <ErrorMessage name="advertisementCheck" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div>
              <div className="signup-terms">
                <Field
                  type="checkbox"
                  name="termsAndConditions"
                  className="signup-form-checkbox checkoxSignup"
                />
                <label className="signup-form-terms">
                  I confirm that I am 18 years of age or older. I have read,
                  understood, and agree to the{" "}
                  <Link
                    to={"/termsandconditions"}
                    className="primary-color link-style"
                  >
                    {" "}
                    Terms & Conditions{" "}
                  </Link>
                  and{" "}
                  <Link
                    to={"/privacypolicy"}
                    className="primary-color link-style"
                  >
                    Privacy Policy{" "}
                  </Link>
                </label>
              </div>

              <ErrorMessage name="termsAndConditions" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div className="signup-button-cont">
              <FilledButton
                id="mobileSignupBtn"
                name={
                  loading ? (
                    <div className="auth-loading">
                      <Spin className="loader" />
                    </div>
                  ) : (
                    "Create Account"
                  )
                }
                btnClass="primary-background gtm-signup-submit"  
                type={5}
                btnType={"submit"}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MobileSignUp;
