import React from "react";
import { Modal } from "antd";
import "./ReferralModal.scss";

const ReferralModal = ({ visible, closeModal }) => {
  return (
    <Modal
      visible={visible}
      title={"Referral Terms and Conditions"}
      onCancel={closeModal}
      wrapClassName="verification-warning-modal"
      className="verification-warning-modal-inner"
      footer={null}
      maskStyle={{
        backdropFilter: "blur(5px)",
      }}
    >
      <div className="terms font-cairo-normal">
        <ul>
          <li>
            You will get 50% off once the person you referred has successfully
            enrolled in their first game.
          </li>
          <li>Offer applies to new customers only.</li>
          <li>
            New customers must have their account verified on Champion’s Choice
            and have a valid UK bank account.
          </li>
          <li>
            The referred customer must not have previously held an account with
            Champion’s Choice.
          </li>
          <li>
            The 50% discount is valid only for the referrer’s next entry and
            cannot be exchanged for cash or other rewards.
          </li>
          <li>
            The referral offer cannot be combined with any other promotions or
            discounts.
          </li>
          <li>
            The referred friend must use the unique referral code during the
            sign-up process for the referrer to be eligible for the discount.
          </li>
          <li>
            Champion’s Choice reserves the right to modify or terminate the
            referral program at any time without prior notice.
          </li>
          <li>
            The offer is limited to one discount per new customer referral.
          </li>
          <li>
            The referral discount will be applied automatically to the
            referrer’s account once the referred friend has met all the
            qualifying criteria.
          </li>
          <li>
            This offer is available only to residents of the UK who are 18 years
            or older.
          </li>
          <li>
            Any decision made by Champion’s Choice regarding the referral
            program is final.
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default ReferralModal;
