import React, { useEffect, useState } from "react";
import { Select } from "antd";
import ContactUsModal from "../../../modals/ContactUs/ContactUsModal";
import "./TimeOut.scss";

const TimeOut = ({
  userProfileData,
  setUserProfileData,
  userUpdatedData,
  setUserUpdatedData,
  Ï,
}) => {
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
  const [timeoutValue, setTimeoutlValue] = useState();
  const [timePeriodOptions, setTimePeriodOptions] = useState();

  const handleWeeklyChange = (value) => {
    const updatedValues = {
      // ...userUpdatedData,
      time_out_period: value,
    };
    setUserUpdatedData(updatedValues);
    setTimeoutlValue(value);
  };
  const openContactUsModal = () => {
    setIsContactUsModalOpen(true);
  };

  const closeContactUsModal = () => {
    setIsContactUsModalOpen(false);
  };
  useEffect(() => {
    let daysArray = [];
    for (let i = 1; i <= 30; i++) {
      daysArray.push({
        value: i,
        label: `${i} Day${i !== 1 ? "s" : ""}`,
      });
    }
    setTimePeriodOptions(daysArray);
  }, []);
  useEffect(() => {
    if (userProfileData) {
      setTimeoutlValue(
        userProfileData?.time_out_period == null
          ? "Select Time Out Period"
          : `${userProfileData?.time_out_period} Day`
      );
    }
  }, [userProfileData]);
  return (
    <div className="timeout">
      <div className="timeout-title font-cairo-medium">Time Out</div>
      <div className="timeout-description">
        You can restrict access to your account for up to 30 days by updating
        your time out here. During this period, you won't be able to make new
        deposits, but you can still withdraw any remaining funds in your
        account. If you wish to extend the time-out period to more than 30 days
        please contact our support team
        <span className="timeout-description-link" onClick={openContactUsModal}>
          {" "}
          here
        </span>
        .
      </div>
      <div>
        <div className="timeout-label">Your Time Out Period</div>
        <Select
          value={timeoutValue}
          options={timePeriodOptions}
          onChange={handleWeeklyChange}
          className="timeout-dropdown-filter"
          popupClassName="dropdown-popup-style timeout-dropdown-filter-popupmenu"
          placement="bottomRight"
        />
      </div>
      <ContactUsModal
        visible={isContactUsModalOpen}
        closeModal={closeContactUsModal}
      />
    </div>
  );
};

export default TimeOut;
