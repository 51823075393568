import React from "react";
import { Outlet } from "react-router-dom";
import PublicLayout from "../components/layout/publicLayout";

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  );
};

export default PublicRoute;
