export const gameRulesData = [
  {
    number: "01",
    details:
      "Choose a game to join, the cost per entry is the same for all participants and for all entries within each game.",
    direction: "ltr",
    question: "Where do I start?",
  },
  {
    number: "02",
    details:
      "For each entry, you must select a team from the relevant fixture list (‘game week’) to WIN their fixture.",
    direction: "rtl",
    question: "What do I need to do for each entry? ",
  },
  {
    number: "03",
    details:
      "If your selection wins, you are through to the next round. If your selection draws or loses you are eliminated from the game.",
    direction: "ltr",
    question: "What happens if my team wins in a round? ",
  },
  {
    number: "04",
    details:
      "If you are through to the next round, you must repeat the procedure using the next set of fixtures before the advertised deadline of the following week. However, a team that has already been selected may not be selected again within a game.",
    direction: "rtl",
    question: "What happens in a new round?",
  },
  {
    number: "05",
    details:
      "In turbo games, every gameday with at least 1 fixture represents a round. In standard games, every game-week with at least 7 fixtures represents a round ",
    direction: "ltr",
    question: "What is the difference between Turbo & Standard Games?",
  },
  {
    number: "06",
    details:
      "You may enter one entry for turbo & nba games, and up to three separate entries at the beginning of any standard game: each entry is treated as an independent entity. For example, if a participant has a username called Phil, they will have Phil 1 & Phil 2 & Phil 3 as three separate entries within the same game. In the first round of game 1, Phil 1 can choose Liverpool, Phil 2 can choose Manchester City, Phil 3 can choose Manchester City as well. If Liverpool wins and Manchester City lost, Phil 2 and Phil 3 entries are out of the game, while Phil 1 can still choose Manchester city in round 2 of the game as the entries of Phil 2 & Phil 3 are treated as separate entities. ",
    direction: "rtl",
    question: "How many entries can I make? ",
  },
  {
    number: "07",
    details:
      "If you purchase one entry before the start of a standard game, you may choose to add 1 or 2 more entries (up to a maximum of 3 entries per game) before the advertised deadline.",
    direction: "ltr",
    question: "I already bought an entry, can I add more? ",
  },
  {
    number: "08",
    details:
      "Selections must be made by the advertised deadline. If for any reason you have not made your team(s) selection by the deadline they will be allotted the LOWEST placed team in the league that is available to choose. This is known as the ‘default selection’.",
    direction: "rtl",
    question: "What if I forget to make my selections? ",
  },
  {
    number: "09",
    details:
      "The deadline for team(s) selection(s) is defined as one (1) hour prior to the commencement of the first fixture of each round of each game. We reserve the right to modify the selection deadline under exceptional circumstances. Failure to make a selection within the adjusted deadline will be treated as per the existing rule 8 around missed selections.",
    direction: "ltr",
    question: "When does a round start? ",
  },
  {
    number: "10",
    details:
      "In every game, the team selections made by each participant will remain confidential until after the selection deadline for each round. Only then will they become visible to all other participants. This measure is put in place to uphold fairness and transparency within each game round.",
    direction: "rtl",
    question: "Why can't I see other players selections?",
  },
  {
    number: "11",
    details:
      "Once you have made your pick(s), you may change it (them) at any time up until the advertised deadline. If you submitted your choice before the advertised deadline, but the fixture is postponed and you did not change your selection then rule 8 applies.",
    direction: "ltr",
    question: "Can I change my entry?",
  },
  {
    number: "12",
    details:
      "If you reach the last 10% or less of total participants in a game, you will share an equal split of 10% of the prize pot with those still standing. The game continues and the ultimate winner(s) receive/s the totality of the remaining pot.",
    direction: "rtl",
    question: "How do I win a slice of the pot?",
  },
  {
    number: "13",
    details:
      "The winner of the game is the player that remains in the game for the longest time and is the Last Player Standing. A situation may arise whereby the last players standing all go out at the same time. In this instance the prize pot will be split equally between those players.",
    direction: "ltr",
    question: "How do I win the entire pot?",
  },
  {
    number: "14",
    details:
      "In the unlikely event where all remaining participants get to a round where they have no selections to choose from, having chosen all available teams already as per rule number 4, the game is considered over, and the pot is split between the remaining participants.",
    direction: "rtl",
    question: "What if I’ve already chosen every team in a single game?",
  },
  {
    number: "15",
    details:
      "In the unlikely event that all participants lose in the first round of a game, then the round is considered void and all participants get to round 2, rule 4 applies.",
    direction: "ltr",
    question: "What happens if everyone loses?",
  },
  {
    number: "16",
    details:
      "In case a game ends at a week where there were more than 10% of the participants left standing, then the prize pot is split between them all.",
    direction: "rtl",
    question: "When is a pot shared/split?",
  },
  {
    number: "17",
    details:
      "A standard game round will be valid If there are at least 7 fixtures of the competition, otherwise the round will be void and postponed until the following match week. In a voided round, any team selections made by participants will not be counted as “used” under Rule 4. Participants will regain the ability to select these teams in subsequent rounds.",
    direction: "ltr",
    question:
      "What happens if there's not enough fixtures in a standard game round?",
  },
  {
    number: "18",
    details:
      "Any fixture that is played out of sequence, due to previous postponement or rescheduling will not be included in the fixtures list of a particular round.",
    direction: "rtl",
    question: "What happens if a fixture is postponed or rescheduled?",
  },
  {
    number: "19",
    details:
      "If a listed game has not concluded by the end of the League Season, the remaining pot will be distributed equally among the last standing players.",
    direction: "ltr",
    question: "What happens at the end of the season?",
  },
  // {
  //   number: "20",
  //   details:
  //     "For Euros 2024, our game adopts the turbo format and aligns with the tournament’s knock-out structure. During the group stage, from 14th June to 26th June 2024, you can select one team per match day. Subsequently, before the round of 16 begins, all selections are reset for players still in contention. A similar reset is implemented before the semi-finals. These adjustments ensure fairness and suit the distinctive knockout nature of the Euros.",
  //   direction: "rtl",
  //   question: "How Do Special Rules Apply for the Euros 2024 Tournament?",
  // },
  {
    number: "20",
    details:
      "In Turbo Mode, if you find yourself having selected all teams available in a round, we’ll offer a one-time reset for that round, allowing you to choose again. Following this exception, the stipulations of Rule 4 — that a team cannot be selected more than once within the same game — will resume. This policy ensures that fair play and engagement continue, even in exceptional circumstances.",
    direction: "rtl",
    question:
      "What happens if I’ve already selected all teams in a turbo mode round?",
  },
  {
    number: "21",
    details:
      "Champion’s Choice reserves the right to retain a percentage of the pot as commission fees.",
    direction: "ltr",
    question: "How does Champions Choice earn revenue?",
  },
];
