import React, { useState } from "react";
import { Table } from "antd";
import { useScreenWidth } from "../../../../utils/useScreenWidth";
import PremierLeagueIcon from "../../../../assets/images/premier_league.svg";
import { BorderedButton, CountTimer } from "../../../commonComponents";
import ExpandedDetails from "./ExpandedDetails/ExpandedDetails";
import "./PrivateGames.scss";

const PrivateGamesTable = ({ privateGameTableData }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandOnMobile, setExpandOnMobile] = useState({
    record: null,
    expand: false,
  });
  const [buttonType, setButtonType] = useState(0);
  const screenWidth = useScreenWidth();

  const expandedRowRender = (record) => {
    return <ExpandedDetails expandedRowsKey={record} />;
  };

  const handleExpandToggle = (key) => {
    const isRowExpanded = expandedRowKeys.includes(key);
    if (isRowExpanded) {
      setExpandedRowKeys(expandedRowKeys.filter(k => k !== key));
    } else {
      setExpandedRowKeys([...expandedRowKeys, key]);
    }
  };

  const handleMobileExpand = (record, value) => {
    setExpandOnMobile({
      record: record,
      expand: value,
    });
  };

  const columns = [
    {
      title: "League",
      dataIndex: "league_name",
      render: (_, record) => (
        <div className="ongoing-table-competitions">
          {record.is_turbo && (
            <div className="game-table-turbotag font-cairo-bold">Turbo</div>
          )}
          <img
            src={
              record.season_id === 23614
                ? PremierLeagueIcon
                : record.league_image
            }
            alt="League Icon"
            className="ongoing-table-competitions-image"
            style={{
              width: record?.sport_id === 5 ? "14px" : "20px",
              height: record?.sport_id === 5 ? "25px" : "36px",
            }}
          />
          <div>{record.league_name}</div>
        </div>
      ),
    },
    {
      title: "Entries Remaining",
      dataIndex: "joined_players",
      render: (_, record) => (
        <div className="ongoing-table-entries-cont">
          {record?.joined_players
            ?.toString()
            ?.split("")
            .map((item, index) => (
              <div
                key={index}
                className="ongoing-table-entries-cont-number container-border font-cairo-bold"
              >
                {item}
              </div>
            ))}
          <div className="ongoing-table-entries-centerline"></div>{" "}
          {record.round_number > 1
            ? record?.max_players
                ?.toString()
                ?.split("")
                .map((item, index) => (
                  <div
                    key={index}
                    className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                  >
                    {item}
                  </div>
                ))
            : record?.joined_players
                ?.toString()
                ?.split("")
                .map((item, index) => (
                  <div
                    key={index}
                    className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                  >
                    {item}
                  </div>
                ))}
        </div>
      ),
    },
    {
      title: "Pot",
      dataIndex: "pot",
      render: (_, record) => <div>£{Math.floor(record.pot)}</div>,
    },
    {
      title: "Round",
      dataIndex: "round_number",
    },
    {
      title: "My Selections",
      dataIndex: "teams",
      render: (_, record) => {
        return record?.teams && record.teams.length > 0 ? (
          record.teams.map((item) => (
            <div className="ongoing-table-selections" key={item.team_id}>
              <img
                src={item.team_image}
                alt="Team Icon"
                className="ongoing-table-selections-image"
              />
              <div>{item.team_name}</div>
            </div>
          ))
        ) : (
          <div>No Team Selected</div>
        );
      },
    },
    {
      title: "Timeline",
      dataIndex: "action",
      render: (_, record) => (
        <div
          className={`${
            screenWidth >= 767
              ? "ongoing-table-actions"
              : "ongoing-table-mobile-actions"
          } `}
        >
          <CountTimer
            record={record.start_date}
            type={2}
            setButtonType={setButtonType}
          />
          <BorderedButton
            name={"Join Game"}
            link={"#"}
            btnClass={"ongoing-table-actions-button"}
            functionCall={() => handleExpandToggle(record.key)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="ongoing-table position-relative">
      {screenWidth >= 767 ? (
        <Table
          columns={columns}
          dataSource={privateGameTableData}
          className="ongoing-table-cont font-cairo-normal"
          rowClassName="ongoing-table-row"
          rowKey={(record) => record.key}
          expandable={{
            expandedRowRender,
            expandedRowKeys: expandedRowKeys,
          }}
          pagination={false}
          loading={privateGameTableData ? false : true}
        />
      ) : (
        <div className="ongoing-mobile">
          {privateGameTableData?.map((item, index) => (
            <div className="ongoing-mobile-cont" key={index}>
              <div className="ongoing-mobile-cont-items">
                <div className="ongoing-mobile-cont-items-title">League</div>
                <div>{item.league_name}</div>
              </div>
              <div className="ongoing-mobile-cont-items">
                <div className="ongoing-mobile-cont-items-title">
                  Entries Remaining
                </div>
                <div className="ongoing-table-entries-cont">
                  {item?.joined_players
                    ?.toString()
                    ?.split("")
                    .map((item, index) => (
                      <div
                        key={index}
                        className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                      >
                        {item}
                      </div>
                    ))}
                  <div className="ongoing-table-entries-centerline"></div>{" "}
                  {item.round_number > 1
                    ? item?.max_players
                        ?.toString()
                        ?.split("")
                        .map((item, index) => (
                          <div
                            key={index}
                            className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                          >
                            {item}
                          </div>
                        ))
                    : item?.joined_players
                        ?.toString()
                        ?.split("")
                        .map((item, index) => (
                          <div
                            key={index}
                            className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                          >
                            {item}
                          </div>
                        ))}
                </div>
              </div>
              <div className="ongoing-mobile-cont-items">
                <div className="ongoing-mobile-cont-items-title">Pot</div>
                <div>£{Math.floor(item.pot)}</div>
              </div>
              <div className="ongoing-mobile-cont-items">
                <div className="ongoing-mobile-cont-items-title">Round</div>
                <div>{item.round_number}</div>
              </div>
              <div className="ongoing-mobile-cont-items">
                <div className="ongoing-mobile-cont-items-title alignself-baseline">
                  My Selection
                </div>
                <div className="ongoing-mobile-cont-items-title-teams">
                  {item?.teams?.map((team) => (
                    <div className="ongoing-table-selections" key={team.team_id}>
                      <img
                        src={team.team_image}
                        alt="Team Icon"
                        className="ongoing-table-selections-image"
                      />
                      <div>{team.team_name}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="ongoing-mobile-cont-items alignself-baseline">
                <div className="ongoing-mobile-cont-items-title">Timeline</div>
                <div>
                  <CountTimer
                    record={item.start_date}
                    type={2}
                    setButtonType={setButtonType}
                  />
                  {item.is_public === false && item.is_joined === false ? (
                    <BorderedButton
                      name={"Join Game"}
                      link={"#"}
                      btnHeight={"32px"}
                      btnClass={"ongoing-table-actions-button"}
                      functionCall={() =>
                        handleMobileExpand(item, !expandOnMobile.expand)
                      }
                    />
                  ) : null}
                </div>
              </div>
              {expandOnMobile.record === item && expandOnMobile.expand && (
                <ExpandedDetails expandedRowsKey={expandOnMobile.record} />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PrivateGamesTable;
