import React, { useEffect } from "react";
import { userData } from "../../../../utils/service";
import { useMyContext } from "../../../../helpers/Context/MyContext";
import "./Verification.scss";

const Verification = ({ userProfileData, setUserProfileData, activeTab }) => {
  const { contextData, setContextData } = useMyContext();

  const getUserData = async () => {
    const res = await userData();
    setUserProfileData(res);
    setContextData({
      ...contextData,
      userData: res,
    });
  };

  useEffect(() => {
    if (
      !(
        userProfileData?.verification === null &&
        userProfileData?.status === "refer" &&
        activeTab === 4
      )
    ) {
      return;
    }
    const id = setInterval(() => {
      getUserData();
    }, 3000);

    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [userProfileData, activeTab]);

  return (
    <div className="verification">
      <div className="verification-title font-cairo-medium">Verification</div>
      {userProfileData?.status === "refer" &&
      userProfileData?.verification === null ? (
        <>
          <div className="verification-details font-cairo-normal">
            Please upload the required documents below to complete your
            verification process. This secure form is straightforward and should
            only take a moment.
          </div>
          <iframe
            title="ID Scan Module"
            src={`https://main.d1uih66nw196vl.amplifyapp.com/?user_id=${userProfileData.id}`}
            allow="camera *"
            width="800px"
            height="600px"
            style={{ background: "#fff" }}
          ></iframe>
        </>
      ) : userProfileData?.verification === true &&
        userProfileData?.status === "refer" ? (
        <div className="verification-details font-cairo-normal">
          Your documents have been received. Your account is currently under
          review and we aim to complete this process soon. Thank you for your
          patience.
        </div>
      ) : (
        <>
          <div className="verification-account font-cairo-bold">
            Your account is verified
          </div>
          <div className="verification-details font-cairo-normal">
            Please note, in certain circumstances we may require further
            information from you in accordance with our license conditions. We
            will contact you should this be necessary
          </div>
        </>
      )}
    </div>
  );
};

export default Verification;
