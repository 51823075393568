import React, { useState, useEffect } from "react";
import { Dropdown, Drawer } from "antd";
import { userData } from "../../../utils/service";
import Logo from "../../../assets/images/auths/logo.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import HamburgIcon from "../../../assets/images/hamburg-icon.svg";
import DepositIcon from "../../../assets/images/deposit-icon.svg";
import { useMyContext } from "../../../helpers/Context/MyContext";
import { setExCluded,setPromoApplied } from "../../../utils/localStorage";
import { ReactComponent as UserIcon } from "../../../assets/images/auths/user.svg";
import {
  setNavItem,
  getNavItem,
  removeToken,
  removeNavItem,
  removeURL,
  removeExcluded,
  removePromoApplied
} from "../../../utils/localStorage";
import Cookies from "js-cookie";
import "./InternalNavbar.scss";

const navData = [
  {
    key: 0,
    name: "Home",
    link: "/",
  },
  {
    key: 1,
    name: "Games",
    link: "/game",
  },
  {
    key: 2,
    name: "Insights",
    link: "/insights",
  },
  {
    key: 3,
    name: "Dashboard",
    link: "/dashboard",
  },
  {
    key: 4,
    name: "Rules",
    link: "/rules",
  },
];

const InternalNavbar = () => {
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const [profileData, setProfileData] = useState();
  const [placement, setPlacement] = useState("top");
  const { contextData, setContextData } = useMyContext();
  const navigate = useNavigate();
  const location = useLocation();
  const navItem = getNavItem();

  const items = [
    {
      key: 0,
      label: "Personal Information",
    },
    {
      key: 1,
      label: "Payments",
    },
    {
      key: 2,
      label: (
        <div className="internal-main-notifications">
          Notifications
          {contextData?.userData?.notification_count > 0 && (
            <div className="internal-main-notifications-count font-cairo-bold">
              {contextData?.userData?.notification_count}
            </div>
          )}
        </div>
      ),
    },
    {
      key: 3,
      label: "Activity",
    },
    {
      key: 4,
      label: "Refer a Friend",
    },
    {
      key: 5,
      label: "Safer Gambling",
    },
    {
      key: 6,
      label: "Logout",
    },
  ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    removeToken();
    Cookies.remove("user_token", {
      domain:
        process.env.NODE_ENV !== "development"
          ? ".championschoice.co.uk"
          : "localhost",
    });
    removeNavItem();
    removeURL();
    removeExcluded();
    removePromoApplied()
    navigate("/login");
    setContextData("");
  };

  const handleMenuClick = (item) => {
    setNavItem(null);
    if (item.key == 0) {
      navigate("/personalinformation");
    } else if (item.key == 1) {
      navigate("/payment");
    } else if (item.key == 2) {
      navigate("/notifications");
    } else if (item.key == 3) {
      navigate("/activity");
    } else if (item.key == 4) {
      navigate("/referafriend");
    } else if (item.key == 5) {
      // setHideDepositButton(5);
      navigate("/safergambling");
    } else if (item.key == 6) {
      handleLogout();
    }
    setOpen(false);
  };

  const getUserData = async () => {
    removeExcluded();
    removePromoApplied();
    const res = await userData();
    setProfileData(res);
    setContextData({
      ...contextData,
      onGoingGames: res?.games_count,
      balance: res?.balance,
      userData: res,
      isExcluded: res?.is_excluded,
      promoCodeApplied: res?.promo_code_applied
    });
    if (res?.is_excluded) {
      setExCluded(true);
    }
    if(res?.promo_code_applied){
      setPromoApplied(res?.promo_code_applied)
    }
  };

  function formatNumber(num) {
    if (typeof num !== 'number' || isNaN(num)) {
      return ''; // Return an empty string or handle it as you prefer.
    }
  
    let formattedNum;
    if (num >= 1000) {
      formattedNum = Math.floor((num / 1000) * 100) / 100;
      return formattedNum.toFixed(2) + "k";
    } else {
      return num.toFixed(2);
    }
  }
  

  const handleDeposit = () => {
    navigate("/payment");
  };
  useEffect(() => {
    if (!navItem) {
      setNavItem(0);
    }
  }, []);

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="internal-main">
      <nav className="internal-navigation-bar">
        <div
          className="logo cursor-pointer"
          onClick={() => {
            navigate("/");
            setNavItem(0);
          }}
        >
          <img src={Logo} alt="Logo" />
        </div>
        <div className="internalnav-main">
          <ul className="nav-links">
            {navData?.map((item, index) => {
              return (
                <li key={item.key} className="position-relative">
                  <Link
                    to={item.link}
                    className="nav-links-list"
                    onClick={() => {
                      setActiveItem(item.key);
                      setNavItem(item.key);
                    }}
                  >
                    {item.name}
                  </Link>
                  {item.key === 3 && contextData?.onGoingGames > 0 && (
                    <div className="dasboard-notification font-cairo-bold">
                      {contextData?.onGoingGames}
                    </div>
                  )}
                  {navItem == item.key && (
                    <div className="nav-links-below-line"></div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div
          className="internal-navbar-right"
          style={{
            justifyContent:
              location?.pathname === "/safergambling" ||
              location?.pathname === "/payment"
                ? "end"
                : "space-between",
          }}
        >
          {location?.pathname == "/safergambling" ||
          location?.pathname == "/payment" ? null : (
            <button
              className="navbar-signup-button black-background"
              onClick={handleDeposit}
            >
              <div className="button-text">Deposit</div>
            </button>
          )}
          <div className="profile-details">
            <div className="username">
              <span>{profileData?.username}</span>
              <span>£{formatNumber(profileData?.balance)}</span>
            </div>
            <Dropdown
              menu={{ onClick: handleMenuClick, items }}
              placement="bottomRight"
              overlayClassName="internal-nav-dropdown"
            >
              <div className="internal-profile-icon">
                <div className="profile-image border-50">
                  <UserIcon className="profile-image-icon" />
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
        <div className="external-hamburg">
          <div onClick={showDrawer}>
            <img src={HamburgIcon} alt="Hamburg Icon" />
          </div>
          <Drawer
            placement={placement}
            width={500}
            height={505}
            onClose={onClose}
            className="internal-mobile-drawer container-border"
            open={open}
            extra={
              <div
                className="mobile-navbar-right"
                style={{
                  justifyContent:
                    (location?.pathname === "/safergambling" ||
                      location?.pathname === "/payment") &&
                    "flex-end",
                }}
              >
                {location?.pathname === "/safergambling" ||
                location?.pathname == "/payment" ? null : (
                  <button
                    className="navbar-signup-button transparent-background"
                    onClick={handleDeposit}
                  >
                    <div className="button-text">
                      Deposit
                      <img src={DepositIcon} alt="Deposit Icon" />
                    </div>
                  </button>
                )}
                <div className="profile-details">
                  <div className="username">
                    <span>{profileData?.username}</span>
                    <span>£{formatNumber(profileData?.balance)}</span>
                  </div>
                  <Dropdown
                    menu={{ onClick: handleMenuClick, items }}
                    placement="bottomRight"
                    overlayClassName="internal-nav-dropdown"
                  >
                    <div className="internal-profile-icon">
                      <div className="profile-image border-50">
                        {/* <img src={UserIcon} alt="User Icon" /> */}
                        <UserIcon className="profile-image-icon" />
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
            }
          >
            <div className="externalnav-mobile">
              <ul className="mobile-nav-links">
                {navData?.map((item, index) => {
                  return (
                    <li
                      key={item.key}
                      className="mobile-links-list position-relative"
                    >
                      <Link
                        to={item.link}
                        className={`link-style externalnav-links-list-item ${
                          index == activeItem ? "primary-color" : null
                        }`}
                        onClick={() => {
                          setActiveItem(item.key);
                          setOpen(false);
                        }}
                      >
                        {item.name}
                      </Link>
                      {item.key === 3 && contextData?.onGoingGames > 0 && (
                        <div className="dasboard-notification font-cairo-bold">
                          {contextData?.onGoingGames}
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </Drawer>
        </div>
      </nav>
    </div>
  );
};

export default React.memo(InternalNavbar);
