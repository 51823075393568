import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import MainLayout from "../components/layout/mainLayout";
import { getToken, setURL } from "../utils/localStorage";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // Replace the isAuthenticated check with your own authentication logic
  const user_token = getToken();
  const isAuthenticated = Cookies.get("user_token") ? true : false;
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname == "/dashboard" && location?.search) {
      setURL(`${location?.pathname}/${location?.search}`);
    }
  }, []);

  return isAuthenticated ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;
