import React, { useEffect, useState } from "react";
import axios from "axios";
import { ErrorMessage } from "formik";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { ReactComponent as ErrorIcon } from "../../../../assets/images/error-icon.svg";
import "./Places.scss";

const PlacesSearch = ({
  setPropsData,
  setPropsAddressError,
  setFormValues,
  setUserUpdatedData,
  userUpdatedData,
  userProfileData,
}) => {
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState();
  const [isContainer, setIsContainer] = useState(false);
  const [enterManually, setEnterManually] = useState(false);
  const [checkManual, setCheckManual] = useState(false);
  const [place, setPlace] = useState();
  const [addressData, setAddressData] = useState({
    city: "",
    postcode: "",
    address: "",
  });
  const [addressError, setAddressError] = useState({
    city: "",
    postcode: "",
    address: "",
  });

  const hasNumericValue = (arr) => {
    if (arr) {
      if (arr.length < 2) {
        return false;
      }
      const secondLastElement = arr[arr?.length - 2];
      for (let char of secondLastElement) {
        if (!isNaN(Number(char))) {
          return true;
        }
      }
      return false;
    }
  };
  const getSentenceBasedOnNumericValue = (arr) => {
    if (arr) {
      if (arr?.length < 2) {
        return "";
      }
      const secondLastElement = arr[arr?.length - 2];
      if (!isNaN(Number(secondLastElement))) {
        return arr.slice(0, arr?.length - 4).join(" ");
      } else {
        return arr.slice(0, arr?.length - 3).join(" ");
      }
    }
  };
  const searchPlaces = async (newValue, isContainer = false) => {
    if (newValue) {
      setIsContainer(isContainer);
      const postcode = newValue;
      const apiKey = "YA92-TD69-BF74-FX62";
      const url = `https://api.addressy.com/Capture/Interactive/Find/v1.00/json3.ws?Countries=GB&Language=en&Limit=10&Key=${apiKey}&${
        isContainer ? "Container" : "Text"
      }=${postcode}`;
      try {
        const response = await axios.get(url);
        const places = response.data.Items;
        setData(places);
        // Do something with the places data
      } catch (error) {
        // Handle any errors
      }
    } else {
      setData([]);
    }
  };
  const handleSearch = (newValue) => {
    searchPlaces(newValue, false);
  };
  const handleAddressDetail = async (Id) => {
    const apiKey = "YA92-TD69-BF74-FX62";
    const url = `https://api.addressy.com/Capture/Interactive/Retrieve/v1.2/json3.ws?Countries=GB&Language=en&Limit=10&Key=${apiKey}&Id=${Id}`;
    try {
      const response = await axios.post(url);
      const places = response.data.Items;
      setAddressData({
        city: places[0].City,
        postcode: places[0].PostalCode,
        address: places[0].Label.replace(/\n/g, " "),
        buildingNumber: places[0].BuildingNumber,
        street: places[0].Street,
        building_name: places[0].BuildingName,
      });
      setFormValues("postcode", places[0].PostalCode);
      setFormValues("city", places[0].City);
      setFormValues("address", places[0].Label.replace(/\n/g, " "));
      setFormValues("building_number", places[0].BuildingNumber);
      setFormValues("street", places[0].Street);
      setFormValues("building_name", places[0].BuildingName);
      setPlace(places);
      // setData(places);
    } catch (error) {
      // Handle any errors
    }
  };

  useEffect(() => {
    if (inputValue === "") {
      setData([]);
    }
  }, [inputValue]);
  
  useEffect(() => {
    if (enterManually) {
      setInputValue("");
    } else {
      setAddressData({
        city: "",
        postcode: "",
        address: "",
      });
      setAddressError({
        city: "",
        postcode: "",
        address: "",
      });
    }
  }, [enterManually]);

  useEffect(() => {
    setFormValues("address", addressData.address);
    setFormValues("city", addressData.city);
    setFormValues("postcode", addressData.postcode);
    if (setUserUpdatedData) {
      setUserUpdatedData({
        ...userUpdatedData,
        address: addressData.address,
        city: addressData.city,
        post_code: addressData.postcode,
      });
    }
  }, [addressData]);

  useEffect(() => {
    if (userProfileData) {
      setEnterManually(true);
      setAddressData({
        city: userProfileData?.city,
        postcode: userProfileData?.post_code,
        address: userProfileData?.address,
      });
    }
  }, [userProfileData]);

  return (
    <div>
      {enterManually ? (
        <>
          {checkManual && (
            <div className="signup-fields-cont">
              <input
                placeholder={"Building Number or Building Name"}
                value={addressData.building}
                className="signup-input-field places-field"
                onChange={(e) => {
                  setAddressData((prevState) => ({
                    ...prevState,
                    building: e.target.value,
                  }));
                  setFormValues("building", e.target.value);
                }}
              />
              <ErrorMessage name="building" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
          )}
          <div className="signup-fields-cont">
            <input
              placeholder={"Street Name"}
              value={addressData.address}
              className="signup-input-field places-field"
              onChange={(e) => {
                setAddressData((prevState) => ({
                  ...prevState,
                  address: e.target.value,
                }));
                setFormValues("address", e.target.value);
              }}
            />
            <ErrorMessage name="address" component="div">
              {(msg) => (
                <div className="error-message">
                  <ErrorIcon className="error-icon-margin" />
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div>

          <div className="places-manual-fields">
            <div className="places-manual-fields-input">
              <input
                placeholder={"Town / City"}
                className="signup-input-field field100"
                value={addressData.city}
                onChange={(e) => {
                  setAddressData((prevState) => ({
                    ...prevState,
                    city: e.target.value,
                  }));
                  setFormValues("city", e.target.value);
                }}
              />
              <ErrorMessage name="city" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div className="places-manual-fields-input">
              <input
                placeholder={"Postcode"}
                className="signup-input-field field100"
                value={addressData.postcode}
                onChange={(e) => {
                  setAddressData((prevState) => ({
                    ...prevState,
                    postcode: e.target.value,
                  }));
                  setFormValues("postcode", e.target.value);
                }}
              />
              <ErrorMessage name="postcode" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="signup-fields-cont position-relative">
            <input
              type="text"
              value={inputValue}
              className="signup-input-field"
              placeholder="Enter postcode or first line of address"
              onChange={(e) => {
                handleSearch(e.target.value);
                setInputValue(e.target.value);
              }}
            />
            <ErrorMessage name="address" component="div">
              {(msg) => (
                <div className="error-message">
                  <ErrorIcon className="error-icon-margin" />
                  {msg}
                </div>
              )}
            </ErrorMessage>
            {data.length > 0 && inputValue && (
              <div className="places-dropdown">
                <div>
                  {isContainer && (
                    <div
                      onClick={() => {
                        handleSearch(inputValue);
                      }}
                    >
                      <LeftOutlined />
                    </div>
                  )}
                  {data?.map((item) => (
                    <div
                      className="places-dropdown-item"
                      onClick={() => {
                        handleAddressDetail(item.Id);

                        if (item.Description) {
                          searchPlaces(item.Id, true);
                        } else {
                          const arr = item?.Text?.split(" ");
                          const hasNumeric = hasNumericValue(arr);
                          const address = getSentenceBasedOnNumericValue(arr);
                          // if (hasNumeric) {
                          //   setAddressData({
                          //     city: arr[arr?.length - 3],
                          //     postcode: `${arr[arr?.length - 2]} ${
                          //       arr[arr?.length - 1]
                          //     }`,
                          //     address: address,
                          //   });
                          //   setFormValues(
                          //     "postcode",
                          //     `${arr[arr?.length - 2]} ${arr[arr?.length - 1]}`
                          //   );
                          //   setFormValues("city", arr[arr?.length - 3]);
                          //   setFormValues("address", address);
                          // } else {
                          //   setAddressData({
                          //     city: arr[arr?.length - 2],
                          //     postcode: arr[arr?.length - 1],
                          //     address: address,
                          //   });

                          //   setFormValues("postcode", arr[arr?.length - 1]);
                          //   setFormValues("city", arr[arr?.length - 2]);
                          //   setFormValues("address", address);
                          // }
                          // setAddressData({
                          //     city: place[0].City,
                          //     postcode: place[0].PostalCode,
                          //     address: place[0].Label,
                          //     buildingNumber:place[0].BuildingNumber,
                          //     street:place[0].Street
                          //   });
                          setEnterManually(true);
                        }
                      }}
                    >
                      <div className="places-dropdown-item-cont">
                        <div className="places-dropdown-item-text">
                          <div className="places-dropdown-item-postcode font-cairo-bold">
                            {item.Text ? (
                              hasNumericValue(item?.Text?.split(" ")) ? (
                                `${
                                  item?.Text?.split(" ")[
                                    item?.Text?.split(" ")?.length - 2
                                  ]
                                } ${
                                  item?.Text?.split(" ")[
                                    item?.Text?.split(" ")?.length - 1
                                  ]
                                }`
                              ) : (
                                `${
                                  item?.Text?.split(" ")[
                                    item?.Text?.split(" ")?.length - 1
                                  ]
                                }`
                              )
                            ) : (
                              <div>Please type your address</div>
                            )}
                          </div>
                          <div className="places-dropdown-item-details font-cairo-normal">
                            {getSentenceBasedOnNumericValue(
                              item?.Text?.split(" ")
                            )}
                            {item.Description === "Text or Container Required"
                              ? "Please type your postcode or first line of address"
                              : item.Description}{" "}
                          </div>
                        </div>
                        <RightOutlined />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <div className="places-text font-cairo-normal">
        <span
          onClick={() => {
            setEnterManually(!enterManually);
            setCheckManual(!enterManually ? true : false);
          }}
          className="places-text-div"
        >
          {enterManually
            ? userProfileData
              ? "Change Address"
              : "Start Over"
            : "Enter Address Manually"}
        </span>
      </div>
    </div>
  );
};

export default PlacesSearch;
