import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./BorderedButton.scss";

const BorderedButton = ({
  name,
  link,
  btnClass,
  btnHeight,
  functionCall,
  dataToSend,
  btnType,
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    if (dataToSend) {
      navigate(link, { state: { data: dataToSend } });
    } else {
      navigate(link);
    }
  };
  return (
    <Button
      className={`bordered-button tranform-skew20 font-cairo-bold black-background ${btnClass}`}
      onClick={functionCall ? functionCall : handleNavigation}
      style={{
        height: btnHeight,
      }}
      htmlType={btnType ? btnType : null}
    >
      <div className="remove-transform">{name}</div>
    </Button>
  );
};

export default BorderedButton;
