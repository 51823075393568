import React, { useState, useEffect } from "react";
import { Select, Radio, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { SimpleFilledButton } from "../../../../commonComponents";
import PremierLeagueIcon from "../../../../../assets/images/premier_league.svg";
import {
  getRemainingTeams,
  getFixturesData,
  editGame,
  joinGame,
} from "../../../../../utils/service";
import { getApiWithAuth } from "../../../../../utils/api";
import { GET_AMOUNT_TO_BE_PAID,CHECK_CODE_APPLICABILITY } from "../../../../../utils/apiEndPoints";
import {
  getToken,
  getExcluded,
  getPromoApplied,
} from "../../../../../utils/localStorage";
import { sendMetaEvents } from "../../../../../helpers/genericFunctions";
import PromoCodeInput from "../../../../commonComponents/PromoCodeInput/PromoCodeInput";
import { useMyContext } from "../../../../../helpers/Context/MyContext";
import DiscountButton from "../../../../commonComponents/DiscountButton/DiscountButton";
import notify from "../../../../../utils/notificationService";
import VerificationProgress from "../../../../modals/VerificationProgress/VerificationProgress";
import VerificationWarning from "../../../../modals/VerificationWarning/VerificationWarning";
import InsufficientBalance from "../../../../modals/InsufficientBalance/InsufficientBalance";
import DepositAlert from "../../../../modals/DepositAlert/DepositAlert";
import "./ExpandedDetails.scss";

const ExpandedDetails = ({ expandedRowsKey }) => {
  const { contextData, setContextData } = useMyContext();
  const [teamOne, setTeamOne] = useState(
    expandedRowsKey?.sport_id == 3 && expandedRowsKey?.is_turbo == false
      ? "Entry 1 - Select Team"
      : "Entry - Select Team"
  );
  const [teamTwo, setTeamTwo] = useState("Entry 2 - Select Team");
  const [teamThree, setTeamThree] = useState("Entry 3 - Select Team");
  const [entryOne, setEntryOne] = useState();
  const [entryTwo, setEntryTwo] = useState();
  const [entryThree, setEntryThree] = useState();
  const [fixturesResponse, setFixturesResponse] = useState([]);
  const [fixtureWeek, setFixtureWeek] = useState();
  const [entriesValue, setEntriesValue] = useState(
    expandedRowsKey?.sport_id == 3 && expandedRowsKey?.is_turbo == false ? 3 : 1
  );
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [amountToPay, setAmountToPay] = useState();
  const excluded = getExcluded();
  const promoApplied = getPromoApplied();
  const [turboFormattedDate, setTurboFormattedDate] = useState();
  const [isVerificationProgressOpen, setIsVerificationProgressOpen] =
    useState(false);
  const [isVerificationWarningOpen, setIsVerificationWarningOpen] =
    useState(false);
    const [isInsufficientBalanceOpen, setIsInsufficientBalanceOpen] =
    useState(false);
    const [depositAlertText, setDepositAlertText] = useState("");
    const [isDepositAlertOpen, setDepositAlertOpen] = useState(false);
    const [depositAlertTitle, setDepositAlertTitle] = useState("");
    const [newUser,setNewUser] = useState(false)

  const navigate = useNavigate();
  const userToken = getToken();
  const userData = contextData?.userData;

  const check_code_applicability= async()=>{
    try {
      const response = await getApiWithAuth(
        CHECK_CODE_APPLICABILITY()
      );
      if(response.success){
        setNewUser(true)
      }

    } catch (error) {
      console.log(error);
    } 
  }

  useEffect(()=>{
    if((!promoApplied || promoApplied === false) && userToken){
      check_code_applicability()
    }
   },[])

  const handleTurboFormattedDate = () => {
    let newDate = new Date(expandedRowsKey.new_date);
    let weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let weekdayIndex = newDate.getDay();
    let weekdayName = weekdays[weekdayIndex];
    let monthName = newDate.toLocaleString("default", {
      month: "long",
    });
    let day = newDate.getDate();
    let year = newDate.getFullYear();
    let formattedDate = weekdayName + " " + monthName + " " + day + " " + year;

    setTurboFormattedDate(formattedDate);
  };

  const getAmount = async () => {
    try {
      setLoading(true); // Set loading to true before fetching data
      const numberofEntries = expandedRowsKey.is_turbo ? 1 : entriesValue;
      const response = await getApiWithAuth(
        GET_AMOUNT_TO_BE_PAID(expandedRowsKey?.id, numberofEntries)
      );

      const validEntries =
        (entriesValue === 3 &&
          teamOne !== "Entry 1 - Select Team" &&
          teamTwo !== "Entry 2 - Select Team" &&
          teamThree !== "Entry 3 - Select Team") ||
        (entriesValue === 2 &&
          teamOne !== "Entry 1 - Select Team" &&
          teamTwo !== "Entry 2 - Select Team") ||
        (entriesValue === 1 &&
          teamOne !== "Entry 1 - Select Team" &&
          teamOne !== "Entry - Select Team");

      if (validEntries) {
        setAmountToPay(response.amount);
      } else {
        setAmountToPay(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  useEffect(() => {
    setButtonLoading(true); // Set button loading to true when any dependency changes
    getAmount().finally(() => setButtonLoading(false)); // Reset button loading state when getAmount completes
  }, [entriesValue, teamOne, teamTwo, teamThree]);

  const handleTeamOne = (value) => {
    if (loading) setLoading(false);
    setTeamOne(value);
  };
  const handleTeamTwo = (value) => {
    if (loading) setLoading(false);
    setTeamTwo(value);
  };
  const handleTeamThree = (value) => {
    if (loading) setLoading(false);
    setTeamThree(value);
  };

  const handleStandingData = async () => {
    const res = await getRemainingTeams(expandedRowsKey?.id);
    const newArray1 = res?.data?.entry_1?.map((item) => ({
      key: item?.team_id,
      label: (
        <div
          className="expanded-details-dropdown-main font-cairo-normal"
          style={{ opacity: item?.selected && "0.5" }}
        >
          <div className="iconLeague">
            <img
              src={item?.team_picture}
              alt="Team Icon"
              className="image-cover-full"
            />
          </div>
          {item?.team_name}
        </div>
      ),
      value: item?.team_id,
      logo: item?.team_picture,
      disabled: item?.selected,
    }));
    const newArray2 = res?.data?.entry_2?.map((item) => ({
      key: item?.team_id,
      label: (
        <div
          className="expanded-details-dropdown-main font-cairo-normal"
          style={{ opacity: item?.selected && "0.5" }}
        >
          <div className="iconLeague">
            <img
              src={item?.team_picture}
              alt="Team Icon"
              className="image-cover-full"
            />
          </div>
          {item?.team_name}
        </div>
      ),
      value: item?.team_id,
      logo: item?.team_picture,
      disabled: item?.selected,
    }));
    const newArray3 = res?.data?.entry_3?.map((item) => ({
      key: item?.team_id,
      label: (
        <div
          className="expanded-details-dropdown-main font-cairo-normal"
          style={{ opacity: item?.selected && "0.5" }}
        >
          <div className="iconLeague">
            <img
              src={item?.team_picture}
              alt="Team Icon"
              className="image-cover-full"
            />
          </div>
          {item?.team_name}
        </div>
      ),
      value: item?.team_id,
      logo: item?.team_picture,
      disabled: item?.selected,
    }));
    setEntryOne(newArray1);
    setEntryTwo(newArray2);
    setEntryThree(newArray3);
  };

  const handleFixturesData = async () => {
    const res = await getFixturesData(expandedRowsKey?.season_id);
    setFixturesResponse(res?.data?.data);
    const week = res?.data?.data?.findIndex(
      (item) => item?.round == expandedRowsKey?.in_game_round_number
    );
    setFixtureWeek(week);
  };

  const handleEditGame = async () => {
    const combinedArray = [teamOne, teamTwo, teamThree].filter(
      (state) => state !== undefined && state !== null
    );
    let dataObject = {
      teams: combinedArray,
      round: expandedRowsKey?.round_number,
    };

    const response = await editGame(expandedRowsKey?.id, dataObject);
    if (response) {
      notify(response?.message, "success");
      window.location.reload();
    } else {
      notify("Something went wrong", "error");
    }
  };

  const openVerificationModal = () => {
    setIsVerificationWarningOpen(true);
  };
  const closeVerificationModal = () => {
    setIsVerificationWarningOpen(false);
  };
  const openVerificationProgressModal = () => {
    setIsVerificationProgressOpen(true);
  };
  const closeVerificationProgressModal = () => {
    setIsVerificationProgressOpen(false);
  };
  
  const openInsufficientBalanceModal = () => {
    setIsInsufficientBalanceOpen(true);
  };
  const closeInsufficientBalanceModal = () => {
    setIsInsufficientBalanceOpen(false);
  };

  const openDepositAlertModal = () => {
    setDepositAlertOpen(true);
  };
  const closeDepositAlertModal = () => {
    setDepositAlertOpen(false);
  };
  const handleJoinGame = async () => {
    if (userToken) {
      if (userData?.is_excluded || excluded) {
        notify("Action restricted due to self-exclusion.", "error");
        return;
      }
      if (userData.status === "refer" && userData.verification !== null) {
        openVerificationProgressModal();
      } else if (
        userData.status === "refer" &&
        userData.verification === null
      ) {
        openVerificationModal();
      } else {
        setLoading(true);
        const combinedArray = [teamOne, teamTwo, teamThree].filter(
          (state) =>
            state !== undefined &&
            state !== null &&
            state !== "Entry 1 - Select Team" &&
            state !== "Entry 2 - Select Team" &&
            state !== "Entry 3 - Select Team"
        );
        let dataObject = {
          teams: combinedArray,
          round: expandedRowsKey?.round_number,
        };
        if (combinedArray?.length == entriesValue) {
          const response = await joinGame(expandedRowsKey?.id, dataObject);
          if (response.success) {
            notify(response?.message, "success");
            setLoading(false);
            var data = new FormData();
            data.append("event_name", "Private Game Joined");
            sendMetaEvents(data);
            navigate("/dashboard");
          } else {
            if (response.not_first) {
              openDepositAlertModal();
              setDepositAlertText(response.message);
              setDepositAlertTitle("Deposit Limit Increase Notice");
            } else if (response?.message == "Insufficient Funds!") {
              openInsufficientBalanceModal();
            } else if (response?.errors) {
              setDepositAlertTitle("Time-Out Period Active");
              setDepositAlertText(response.message);
              openDepositAlertModal();
            }
            setLoading(false);
          }
        } else {
          notify(`Please select your team${expandedRowsKey?.is_turbo ? "" : "s"}`, "warning");
          setLoading(false);
        }
      }
    }
  };

  const onChange = (e) => {
    if (e.target.value == 1) {
      setTeamTwo("Entry 2 - Select Team");
      setTeamThree("Entry 3 - Select Team");
    } else if (e.target.value == 2) {
      setTeamThree("Entry 3 - Select Team");
    }
    setEntriesValue(e.target.value);
  };
  useEffect(() => {
    handleStandingData();
    handleFixturesData();
    if (expandedRowsKey?.teams) {
      setTeamOne(
        expandedRowsKey?.teams[0]?.team_id
          ? expandedRowsKey?.teams[0]?.team_id
          : "Entry 1 - Select Team"
      );
      setTeamTwo(
        expandedRowsKey?.teams[1]?.team_id
          ? expandedRowsKey?.teams[1]?.team_id
          : "Entry 2 - Select Team"
      );
      setTeamThree(
        expandedRowsKey?.teams[2]?.team_id
          ? expandedRowsKey?.teams[2]?.team_id
          : "Entry 3 - Select Team"
      );
    }
  }, [expandedRowsKey]);
  useEffect(() => {
    if (expandedRowsKey?.sport_id == 5 || expandedRowsKey.is_turbo == true) {
      handleTurboFormattedDate();
    }
  }, []);

  useEffect(() => {
    handleStandingData();
    handleFixturesData();
  }, []);
  return (
    <div className="expanded-details lightblack-background">
      <VerificationProgress
        visible={isVerificationProgressOpen}
        closeModal={closeVerificationProgressModal}
      />
      <VerificationWarning
        visible={isVerificationWarningOpen}
        closeModal={closeVerificationModal}
      />
       <DepositAlert
        visible={isDepositAlertOpen}
        closeModal={closeDepositAlertModal}
        description={depositAlertText}
        title={depositAlertTitle}
      />
       <InsufficientBalance
        visible={isInsufficientBalanceOpen}
        closeModal={closeInsufficientBalanceModal}
      />
      <div className="expanded-details-top">
        <div className="expanded-details-top-left">
          <img
            src={
              expandedRowsKey.season_id == 23614
                ? PremierLeagueIcon
                : expandedRowsKey.league_image
            }
            alt="Profile Pic"
            className="expanded-details-top-left-image"
          />
          <div className="expanded-details-top-left-text font-cairo-normal">
            <span className="font18">{expandedRowsKey?.league_name}</span>
            <span className="font14 grey-color">
              Round {expandedRowsKey?.round_number}
            </span>
          </div>
        </div>
        {expandedRowsKey.sport_id == 3 &&
          expandedRowsKey.is_turbo === false && (
            <div className="expanded-details-top-right">
              <div>Choose your number of entries</div>
              <Radio.Group
                onChange={onChange}
                value={entriesValue}
                className="expanded-details-top-right-radio"
              >
                <Radio value={1}>1</Radio>
                <Radio value={2}>2</Radio>
                <Radio value={3}>3</Radio>
              </Radio.Group>
            </div>
          )}
      </div>
      <div className="expanded-details-bottom">
        <div className="expanded-details-bottom-fixtures">
          <div className="font18 font-cairo-medium expanded-details-bottom-fixtures-title">
            Fixtures
          </div>
          {fixturesResponse?.length > 0 ? (
            expandedRowsKey?.sport_id == 5 ||
            expandedRowsKey.is_turbo == true ? (
              <>
                <div key={turboFormattedDate}>
                  <div className="expand-details-cont-date font-cairo-medium">
                    {turboFormattedDate}
                  </div>
                  {fixturesResponse[fixtureWeek]?.fixtures[
                    expandedRowsKey?.new_date
                  ]?.map((fixture, index) => {
                    const {
                      team1_name,
                      team2_name,
                      time,
                      team1_img,
                      team2_img,
                      is_postponed,
                    } = fixture;
                    return (
                      <div
                        className="expand-details-cont-teams font-cairo-normal"
                        key={index}
                      >
                        <div className="expand-details-cont-teams-one">
                          <div className="expand-details-cont-teams-one-name">
                            {team1_name}
                          </div>
                          <div className="expand-details-cont-teams-one-image">
                            <img
                              src={team1_img}
                              alt="Team Icon"
                              className="image-cover-full"
                            />
                          </div>
                        </div>
                        <div className="expand-details-cont-teams-time">
                          {is_postponed
                            ? "Postp."
                            : time
                                .split(" ")[1]
                                .split(":")
                                .slice(0, 2)
                                .join(":")}
                        </div>
                        <div className="expand-details-cont-teams-two">
                          <div className="expand-details-cont-teams-two-image">
                            <img
                              src={team2_img}
                              alt="Team Icon"
                              className="image-cover-full"
                            />
                          </div>
                          <div className="expand-details-cont-teams-two-name">
                            {team2_name}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="expand-details-cont-line"></div>
                </div>
              </>
            ) : (
              Object?.keys(fixturesResponse[fixtureWeek]?.fixtures).map(
                (date) => {
                  const fixturesArray =
                    fixturesResponse[fixtureWeek]?.fixtures[date];
                  let newDate = new Date(date);
                  let weekdays = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ];
                  let weekdayIndex = newDate.getDay();
                  let weekdayName = weekdays[weekdayIndex];
                  let monthName = newDate.toLocaleString("default", {
                    month: "long",
                  });
                  let day = newDate.getDate();
                  let year = newDate.getFullYear();
                  let formattedDate =
                    weekdayName + " " + monthName + " " + day + " " + year;
                  return (
                    <div key={date}>
                      <div className="expand-details-cont-date font-cairo-medium">
                        {formattedDate}
                      </div>
                      {fixturesArray?.map((fixture, index) => {
                        const {
                          team1_name,
                          team2_name,
                          time,
                          team1_img,
                          team2_img,
                        } = fixture;
                        return (
                          <div
                            className="expand-details-cont-teams font-cairo-normal"
                            key={index}
                          >
                            <div className="expand-details-cont-teams-one">
                              <div className="expand-details-cont-teams-one-name">
                                {team1_name}
                              </div>
                              <div className="expand-details-cont-teams-one-image">
                                <img
                                  src={team1_img}
                                  alt="Team Icon"
                                  className="image-cover-full"
                                />
                              </div>
                            </div>
                            <div className="expand-details-cont-teams-time">
                              {time
                                .split(" ")[1]
                                .split(":")
                                .slice(0, 2)
                                .join(":")}
                            </div>
                            <div className="expand-details-cont-teams-two">
                              <div className="expand-details-cont-teams-two-image">
                                <img
                                  src={team2_img}
                                  alt="Team Icon"
                                  className="image-cover-full"
                                />
                              </div>
                              <div className="expand-details-cont-teams-two-name">
                                {team2_name}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="expand-details-cont-line"></div>
                    </div>
                  );
                }
              )
            )
          ) : (
            <div className="spinner-loading">
              <Spin className="loader" />
            </div>
          )}
        </div>
        <div className="expanded-details-bottom-selections">
          <div className="expanded-details-bottom-selections-title font18 font-cairo-medium">
            My Selections
          </div>
          {expandedRowsKey.is_turbo === false && (
            <div className="selections-teams-list">
              {(entriesValue == 1 ||
                entriesValue == 2 ||
                entriesValue == 3) && (
                <Select
                  defaultValue="Entry 1 - Select Team"
                  onChange={handleTeamOne}
                  options={entryOne}
                  className="expanded-details-dropdown"
                  popupClassName="dropdown-popup-style expanded-details-dropdown-popup"
                />
              )}
              {(entriesValue == 3 || entriesValue == 2) && (
                <Select
                  defaultValue="Entry 2 - Select Team"
                  onChange={handleTeamTwo}
                  options={entryTwo}
                  className="expanded-details-dropdown"
                  popupClassName="dropdown-popup-style expanded-details-dropdown-popup"
                />
              )}
              {entriesValue == 3 && (
                <Select
                  defaultValue="Entry 3 - Select Team"
                  onChange={handleTeamThree}
                  options={entryThree}
                  className="expanded-details-dropdown"
                  popupClassName="dropdown-popup-style expanded-details-dropdown-popup"
                />
              )}
            </div>
          )}
          {expandedRowsKey.is_turbo === true && (
            <div className="selections-teams-list">
              <Select
                defaultValue="Entry - Select Team"
                onChange={handleTeamOne}
                options={entryOne}
                className="expanded-details-dropdown"
                popupClassName="dropdown-popup-style expanded-details-dropdown-popup"
              />
            </div>
          )}
          {newUser && <PromoCodeInput getAmount={getAmount} />} 
          <div className="text-center padding-top2">
            {amountToPay === entriesValue * expandedRowsKey?.stake ? (
              <SimpleFilledButton
                id="submit-private-game-button"
                name={
                  buttonLoading ? (
                    <div className="auth-loading">
                      <Spin className="loader" />
                    </div>
                  ) : (entriesValue === 3 &&
                      teamThree !== "Entry 3 - Select Team") ||
                    (entriesValue === 2 &&
                      teamTwo !== "Entry 2 - Select Team") ||
                    ((entriesValue === 1 &&
                      teamOne !== "Entry 1 - Select Team" &&
                      teamOne !== "Entry - Select Team")) ? (
                    expandedRowsKey.stake == "0.0" ? (
                      "Submit"
                    ) : (
                      `Submit & Pay £${entriesValue * expandedRowsKey?.stake}`
                    )
                  ) : (
                    "Submit"
                  )
                }
                link={"#"}
                btnClass={"expand-details-bottom-selections-button"}
                functionCall={handleJoinGame}
              />
            ) : (
              <DiscountButton
                name={
                  buttonLoading ? (
                    <div className="auth-loading">
                      <Spin className="loader" />
                    </div>
                  ) : (entriesValue === 3 &&
                      teamOne !== "Entry 1 - Select Team" &&
                      teamTwo !== "Entry 2 - Select Team" &&
                      teamThree !== "Entry 3 - Select Team") ||
                    (entriesValue === 2 &&
                      teamOne !== "Entry 1 - Select Team" &&
                      teamTwo !== "Entry 2 - Select Team") ||
                    ((entriesValue === 1 &&
                      teamOne !== "Entry 1 - Select Team" &&
                      teamOne !== "Entry - Select Team")) ? (
                    expandedRowsKey.stake == "0.0" ? (
                      "Submit"
                    ) : (
                      `Submit & Pay`
                    )
                  ) : (
                    "Submit"
                  )
                }
                link={"#"}
                btnClass={"expand-details-bottom-selections-button"}
                functionCall={handleJoinGame}
                actualAmount={amountToPay}
                strikeAmount={entriesValue * expandedRowsKey?.stake}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpandedDetails;
