import React, { useEffect, useState } from "react";
import { Tabs, Spin } from "antd";
import ContactDetails from "./ContactDetails";
import Verification from "./Verification/Verification";
import MyPreferences from "./MyPreferences/MyPreferences";
import { useScreenWidth } from "../../../utils/useScreenWidth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userData, updateUserData } from "../../../utils/service";
import { BorderedButton, FilledButton } from "../../commonComponents";
import ResidentialAddress from "./ResidentialAddress/ResidentialAddress";
import notify from "../../../utils/notificationService"
import "./PersonalInformation.scss";

const PersonalInformation = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [userProfileData, setUserProfileData] = useState();
  const [userUpdatedData, setUserUpdatedData] = useState();
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    email: "",
    password: "",
    phone: "",
    confirmPassword: "",
  });
  const screenWidth = useScreenWidth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const verfication_needed = searchParams.get("verification_required");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const tabItems = [
    {
      label: "Contact Details",
      key: 1,
      children: (
        <ContactDetails
          userProfileData={userProfileData}
          setUserProfileData={setUserProfileData}
          userUpdatedData={userUpdatedData}
          setUserUpdatedData={setUserUpdatedData}
          setValidationErrors={setValidationErrors}
          validationErrors={validationErrors}
        />
      ),
    },
    {
      label: "Address",
      key: 2,
      children: (
        <ResidentialAddress
          userProfileData={userProfileData}
          setUserProfileData={setUserProfileData}
          userUpdatedData={userUpdatedData}
          setUserUpdatedData={setUserUpdatedData}
        />
      ),
    },
    {
      label: "Preferences ",
      key: 3,
      children: (
        <MyPreferences
          userProfileData={userProfileData}
          setUserProfileData={setUserProfileData}
          userUpdatedData={userUpdatedData}
          setUserUpdatedData={setUserUpdatedData}
          setValidationErrors={setValidationErrors}
          validationErrors={validationErrors}
        />
      ),
    },
    {
      label: "Verification",
      key: 4,
      children: (
        <Verification
          userProfileData={userProfileData}
          setUserProfileData={setUserProfileData}
          activeTab={activeTab}
        />
      ),
    },
  ];

  const getUserData = async () => {
    const res = await userData();
    setUserProfileData(res);
  };
  const handleErrorChecks = async () => {
    const update = {
      user: { ...userUpdatedData },
    };
    if (
      validationErrors.email === "" &&
      validationErrors.phone === "" &&
      validationErrors.password === "" &&
      (validationErrors.confirmPassword === "" ||
        validationErrors.confirmPassword === "Matched")
    ) {
      const response = await updateUserData(update);
      if (response) {
        if (response.log_out) {
          notify("You have updated your email/password, Your session is expired. Please Signin again","success")
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else if (!response.success) {
          notify(response.message,"error")
          setLoading(false);
        } else {
          notify("You have updated your data","success")
          setLoading(false);
        }
        getUserData();
      } else {
        notify("Something went wrong try again","error")
        setLoading(false);
      }
    } else {
      if (validationErrors.password !== "") {
        notify(validationErrors.password,"error")
      } else {
        notify("Please remove the validation errors first","error")
      }
      setLoading(false);
    }
  };
  const handleDataUpdate = async () => {
    setLoading(true);
    if (!userUpdatedData) {
      notify("You have nothing to update","warning")
      setLoading(false);
    } else {
      handleErrorChecks();
    }
  };

  useEffect(() => {
    getUserData();
    if (verfication_needed) {
      handleTabChange(4);
    }
  }, []);

  const heightStylesMobile = {
    height:
      activeTab === 1
        ? "730px"
        : activeTab === 2
        ? "510px"
        : activeTab === 3
        ? "595px"
        : "370px",
  };
  const heightStyles = {
    height:
      activeTab === 1
        ? "675px"
        : activeTab === 2
        ? "480px"
        : activeTab === 3
        ? "415px"
        : activeTab === 4
        ? userProfileData?.status === "pass" ||
          (userProfileData?.status === "refer" && userProfileData.verification)
          ? "400px"
          : "870px"
        : null,
  };

  useEffect(() => {
    setUserUpdatedData();
  }, [activeTab]);

  return (
    <div className="personal-info">
      <div className="personal-info-title font-cairo-extrabold">
        Personal Information
      </div>
      <div
        className="personal-info-cont background-blur"
        style={screenWidth <= 767 ? heightStylesMobile : heightStyles}
      >
        <Tabs
          activeKey={activeTab}
          onChange={handleTabChange}
          items={tabItems}
          className="personal-info-cont-tab"
        />
      </div>
      {activeTab !== 4 && (
        <div className="personal-info-buttons">
          <BorderedButton
            name={"Cancel"}
            btnClass="padding-x6 personal-info-buttons-btnCancel"
            functionCall={getUserData}
          />
          <FilledButton
            name={
              loading ? (
                <div className="auth-loading">
                  <Spin className="loader" />
                </div>
              ) : (
                "Update"
              )
            }
            btnClass="primary-background personal-info-buttons-btnUpdate"
            functionCall={handleDataUpdate}
            type={3}
          />
        </div>
      )}
    </div>
  );
};

export default PersonalInformation;
