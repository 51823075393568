import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
import CookieConsent from "react-cookie-consent";
import { PrivateRoute, PublicRoute } from "./routes";
import MobileRedirect from "./components/mainComponents/MobileRedirect";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import {
  Signup,
  Login,
  ForgetPassword,
  ResetPassword,
} from "./components/authComponents";
import {
  Dashboard,
  Game,
  Home,
  Insights,
  Rules,
  PersonalInformation,
  Notifications,
  AllSelections,
  Referral,
  SaferGambling,
  PrivateGame,
  Activity,
  Payment,
  PrivacyPolicy,
  TermAndConditions,
} from "./components/mainComponents";
import CookiesPreferences from "./components/modals/CookiesPreferences/CookiesPreferences";
import "./App.css";

const loadGTM = (gtmId) => {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "gtm.js",
    "gtm.start": new Date().getTime(),
  });
};

function App() {
  const [cookies, setCookie] = useCookies();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cookiesBannerVisible, setCookiesBannerVisible] = useState(
    cookies.myCookieConsent ? "hidden" : "show"
  );

  const handleManagePreferences = useCallback(() => {
    showModal();
  }, []);

  const gtmId = "GTM-K6M48QZR";

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    Cookies.set("myCookieConsent", true);
    setCookiesBannerVisible("hidden");
  }, []);

  const handleAccept = useCallback(() => {
    Cookies.set("allow_functional_cookies", true);
    Cookies.set("allow_performance_cookies", true);
    setCookiesBannerVisible("hidden");
  }, []);

  useEffect(() => {
    loadGTM(gtmId);
    ReactGA.initialize("G-6LNP2MMPJD");
    ReactGA.send({
      hitType: "pageview",
      page: "/landingpage",
      title: "Landing Page",
    });
  }, []);

  return (
    <Router>
      <ToastContainer />
      <CookiesPreferences
        visible={isModalVisible}
        closeModal={closeModal}
        setCookiesBannerVisible={setCookiesBannerVisible}
      />
      <CookieConsent
        location="bottom"
        buttonText="Accept All Cookies"
        hideOnDecline={true}
        hideOnAccept={true}
        cookieName="myCookieConsent"
        style={{ background: "#000", display: "flex" }}
        buttonStyle={{
          color: "#000",
          fontSize: "13px",
          background: "#fff017",
          fontFamily: "Cairo Medium",
          height: "32px",
        }}
        onAccept={() => {
          handleAccept();
        }}
        contentClasses="cookie-consent-body"
        visible={cookiesBannerVisible}
      >
        Your privacy matters to us. We use cookies to optimize your experience
        on our site. For more details, please review our privacy policy.
        <div>
          <button
            className="cookies-preferences-button"
            onClick={handleManagePreferences}
          >
            Manage Preferences
          </button>
        </div>
      </CookieConsent>
      <Routes>
        <Route path="/signup/:promo?" element={<Signup />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/" element={<PublicRoute />}>
          <Route exact path="/" element={<Home bg={1} />} bg={1} />
        </Route>
        <Route path="/" element={<PublicRoute />}>
          <Route exact path="/insights" element={<Insights />} />
        </Route>
        <Route path="/" element={<PublicRoute />}>
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
        </Route>
        <Route path="/" element={<PublicRoute />}>
          <Route
            exact
            path="/termsandconditions"
            element={<TermAndConditions />}
          />
           <Route
            exact
            path="/allselections/:id"
            element={<AllSelections />}
          />
        </Route>
        <Route exact path="/" element={<PrivateRoute />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route
            exact
            path="/dashboard/allselections"
            element={<AllSelections />}
          />
          <Route exact path="/activity" element={<Activity />} />
          <Route
            exact
            path="/personalinformation"
            element={<PersonalInformation />}
          />
          <Route exact path="/notifications" element={<Notifications />} />
          <Route exact path="/referafriend" element={<Referral />} />
          <Route exact path="/safergambling" element={<SaferGambling />} />
          <Route exact path="/privategame" element={<PrivateGame />} />
          <Route exact path="/payment" element={<Payment />} />
        </Route>
        <Route exact path="/" element={<PublicRoute />}>
          <Route exact path="/game" element={<Game />} />
          <Route exact path="/rules" element={<Rules />} />
        </Route>
        <Route exact path="/MobileRedirect/:id" element={<MobileRedirect />} />
      </Routes>
    </Router>
  );
}

export default App;
