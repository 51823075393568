import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import AllSelectionsTable from "./AllSelectionsTable";
import { FilledButton } from "../../commonComponents";
import { setNavItem } from "../../../utils/localStorage";
import { getToken } from "../../../utils/localStorage";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  getAllSelections,
  getPreviousSelections,
  getAllSelectionsForPublic,
} from "../../../utils/service";
import "./AllSelections.scss";

const { Option } = Select;

const AllSelections = () => {
  const [roundValue, setRoundValue] = useState(0);
  const [roundValues, setRoundValues] = useState();
  const [selectionData, setSelectionsData] = useState();
  const [breakDownData, setBreakDownData] = useState();
  const [showTeam, setShowTeam] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const title = searchParams.get("title"); // G
  const data = { id: id, title: title };
  const userToken = getToken();
  const gameId = location?.state?.data ? location?.state?.data : data;

  const handleRoundChange = (value) => {
    setBreakDownData(
      selectionData?.entries_breakdown[value]?.team_entries[0]?.teams.slice(
        0,
        5
      )
    );
    setRoundValue(value);
  };
  const handleAllSelectionResponse = async () => {
    if (userToken) {
      let response;
      if (gameId?.previousSelection) {
        response = await getPreviousSelections(gameId.id);
      } else {
        response = await getAllSelections(gameId.id);
      }
      if (response?.data) {
        setSelectionsData(response);
        const roundArray = response?.entries_breakdown?.map((item, index) => {
          return {
            value: index,
            label: `Round ${index + 1}`,
          };
        });
        setRoundValues(roundArray);
        if (gameId?.previousSelection) {
          if (gameId.round) {
            setBreakDownData(
              response?.entries_breakdown[
                gameId.round - 2
              ]?.team_entries[0]?.teams.slice(0, 5)
            );
            setRoundValue(gameId.round - 2);
          } else {
            setBreakDownData(
              response?.entries_breakdown[
                roundValue
              ]?.team_entries[0]?.teams.slice(0, 5)
            );
          }
        } else {
          if (gameId.round) {
            setBreakDownData(
              response?.entries_breakdown[
                gameId.round - 1
              ]?.team_entries[0]?.teams.slice(0, 5)
            );
            setRoundValue(gameId.round - 1);
          } else {
            setBreakDownData(
              response?.entries_breakdown[
                roundValue
              ]?.team_entries[0]?.teams.slice(0, 5)
            );
          }
        }
      }
      if (!response?.data) {
        let response;
        response = await getAllSelectionsForPublic(gameId.id);
        if (response?.data) {
          setSelectionsData(response?.data);
          const roundArray = response?.data?.entries_breakdown?.map(
            (item, index) => {
              return {
                value: index,
                label: `Round ${index + 1}`,
              };
            }
          );
          setRoundValues(roundArray);
          setBreakDownData(
            response?.data?.entries_breakdown[
              roundValue
            ]?.team_entries[0]?.teams.slice(0, 5)
          );
        }
      }
    } else {
      let response;
      response = await getAllSelectionsForPublic(gameId.id);
      if (response?.data) {
        setSelectionsData(response?.data);
        const roundArray = response?.data?.entries_breakdown?.map(
          (item, index) => {
            return {
              value: index,
              label: `Round ${index + 1}`,
            };
          }
        );
        setRoundValues(roundArray);
        setBreakDownData(
          response?.data?.entries_breakdown[
            roundValue
          ]?.team_entries[0]?.teams.slice(0, 5)
        );
      }
    }
  };

  const handleGameNavigation = () => {
    setNavItem(1);
    navigate("/game");
  };
  useEffect(() => {
    handleAllSelectionResponse(gameId);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="all-selections">
      <div className="all-selections-title font-cairo-extrabold">
        Game Stats
      </div>
      <div className="all-selections-main">
        <div className="all-selections-main-top">
          <div className="all-selections-main-title font-cairo-extrabold">
            {gameId?.title}
          </div>
          <div className="all-selections-main-top-dropdown">
            <Select
              value={roundValue}
              onChange={handleRoundChange}
              className="all-selections-dropdown-filter"
              popupClassName="all-selections-dropdown-filter-popupmenu"
            >
              {roundValues?.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="all-selections-main-tables">
          <div className="all-selections-tables-percents">
            <div className="all-selections-tables-percents-title font-cairo-medium">
              Percentage Of Entries Remaining
            </div>
            <div className="all-selections-tables-percents-trophy">
              <div className="all-selections-tables-percents-trophy-numbers font-cairo-bold">
                {selectionData?.entries_breakdown[
                  roundValue
                ]?.percentage.toFixed()}
                %
              </div>
            </div>
          </div>
          <div className="all-selections-tables-down">
            <div className="all-selections-tables-breakdown">
              <div className="all-selections-tables-breakdown-title font-cairo-medium">
                Entries Breakdown by Teams
              </div>
              <div className="all-selections-tables-breakdown-cont background-blur">
                {selectionData?.entries_breakdown[
                  roundValue
                ]?.team_entries[0]?.teams?.map((team, index) => {
                  return (
                    <div
                      key={index}
                      className="all-selections-tables-breakdown-cont-item"
                    >
                      <div className="all-selections-tables-breakdown-cont-item-name font-cairo-normal">
                        <img
                          src={team.image}
                          alt="Team Icon"
                          className="all-selections-tables-breakdown-cont-item-image"
                        />
                        <div>{team.name}</div>
                      </div>
                      <div className="all-selections-tables-breakdown-cont-div">
                        <div
                          className="all-selections-tables-breakdown-cont-bar"
                          style={{ width: `${team.percentage}%` }}
                        ></div>
                        <div
                          className={`all-selections-tables-breakdown-cont-line ${
                            index ===
                            selectionData?.entries_breakdown[roundValue]
                              ?.team_entries[0]?.teams.length -
                              1
                              ? "no-border-right"
                              : ""
                          }`}
                          style={{
                            width: `${100 - team.percentage}%`,
                            left: `${team.percentage}%`,
                          }}
                        ></div>
                      </div>
                      <div className="all-selections-tables-breakdown-cont-item-figure font-cairo-normal count-width">
                        {team.count}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="all-selections-tables-distribution">
              <div className="all-selections-tables-breakdown-title font-cairo-medium">
                Entries Distribution by Teams
              </div>
              <div className="all-selections-tables-distribution-cont background-blur">
                {breakDownData ? (
                  <>
                    {breakDownData.slice(0, 5).map((item, index) => {
                      return (
                        <div
                          key={index + 1}
                          className={`all-selections-tables-distribution-divs ${
                            item.percentage < 20
                              ? "size5"
                              : item.percentage < 40
                              ? "size4"
                              : item.percentage < 60
                              ? "size3"
                              : item.percentage < 80
                              ? "size2"
                              : item.percentage < 100
                              ? "size5"
                              : null
                          } div${index + 1}`}
                          onMouseEnter={() => setShowTeam(index)}
                          onMouseLeave={() => setShowTeam(null)}
                        >
                          <img
                            alt="League Icon"
                            className="all-selections-tables-distribution-divs-icon"
                            src={item.image}
                          />
                          <div className="font-cairo-bold mobile-percentage">
                            {item.percentage.toFixed(1)}%
                          </div>

                          {showTeam == index && (
                            <div className="all-selections-tables-distribution-showteam">
                              <img
                                alt="League Icon"
                                className="all-selections-tables-distribution-showteam-icon"
                                src={item.image}
                              />
                              <div className="font-cairo-bold">{item.name}</div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="all-selections-tables-distribution-cont-loading">
                    <Spin className="probability-loading-spinner" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="all-selections-tables-breakdown-title font-cairo-medium">
          Entries by Participants
        </div>
        {selectionData?.data ? (
          <AllSelectionsTable
            tableData={selectionData.data}
            roundCount={selectionData.rounds_count}
          />
        ) : (
          <div className="all-selections-tables-distribution-cont-loading">
            <Spin className="probability-loading-spinner" />
          </div>
        )}
        <div className="all-selections-button">
          <FilledButton
            name={"Join a Game"}
            btnClass="primary-background home-buttons-button"
            type={3}
            functionCall={handleGameNavigation}
          />
        </div>
      </div>
    </div>
  );
};

export default AllSelections;
