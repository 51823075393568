import React, { useEffect, useState } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import SignupStepOneForm from "./SignupStepOneForm";
import SignupStepTwoForm from "./SignupStepTwoForm";
import MobileSignUp from "../MobileSignUp/MobileSignUp";
import { ExternalNavbar } from "../../../commonComponents";
import { useScreenWidth } from "../../../../utils/useScreenWidth";
import { getToken, setNavItem } from "../../../../utils/localStorage";
import "../Signup.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function SignupStepOne() {
  const [formStep, setFormStep] = useState(0);
  const [stepOneData, setStepOneData] = useState();
  // const [isDirty, setIsDirty] = useState(true);
  const screenWidth = useScreenWidth();
  const user_token = getToken();
  const navigate = useNavigate();
  const isAuthenticated = user_token ? true : false;
  const[promoCode, setPromoCode] = useState()

  const query = useQuery();
  const promo = query.get('promo');
  useEffect(()=>{
   setPromoCode(promo)
  },[])

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/");
  //   }
  // }, []);
  // useEffect(() => {
  //   const handlePopstate = () => {
  //     if (isDirty) {
  //       if (
  //         window.confirm(
  //           "As you are going back from current page. Your unsaved changes will be lost."
  //         )
  //       ) {
  //         // setIsDirty(false);
  //         window.history.back();
  //       } else {
  //         window.history.pushState(null, "", window.location.href);
  //       }
  //     }
  //   };

  //   // Add the event listener when the component mounts
  //   window.addEventListener("popstate", handlePopstate);

  //   return () => {

  //     window.onpopstate=null
  //   };
  // }, [isDirty]);


  return (
    <div className="black-background white-color signup-background">
      <ExternalNavbar />
      <div>
        {/* <SearchPlaces /> */}
        <div className="signup-page-title">
          {screenWidth >= 767 ? "Sign Up" : "Create an Account"}
        </div>
      </div>
      <div className="signup-cont background-blur">
        <div className="signup-steps-cont d-flex">
          <div className="signup-steps-main">
            <div
              className={`signup-steps-stepone ${
                formStep === 0 && "signup-steps-selected-step"
              }`}
            >
              01
            </div>
            <div className="signup-steps-stepline"></div>
            <div
              className={`signup-steps-stepone ${
                formStep === 1 && "signup-steps-selected-step"
              }`}
            >
              02
            </div>
          </div>
          <div className="signup-steps-details">
            <div className="signup-steps-details-stepone">
              <div
                className="signup-steps-details-stepone-title"
                onClick={() => setFormStep(0)}
              >
                Sign up details
              </div>
              <div className="signup-steps-details-stepone-description">
                Make sure you provide us with accurate information in order for
                your account to be successfully created.
              </div>
            </div>
            <div className="signup-steps-details-steptwo">
              <div className="signup-steps-details-steptwo-title">
                Personal details
              </div>
              <div className="signup-steps-details-steptwo-description">
                Refer to our{" "}
                <Link to={"/privacypolicy"} className="white-color link-style underline">
                  Privacy Policy
                </Link>{" "}
                to understand how your Personal data will be used.
              </div>
            </div>
          </div>
        </div>
        <div className="signup-form-cont">
          {formStep == 0 ? (
            <div className="signup-form-title">Create an Account</div>
          ) : (
            <div className="signup-field-label">Date of Birth</div>
          )}
          {screenWidth > 992 ? (
            formStep === 0 ? (
              <SignupStepOneForm
                setFormStep={setFormStep}
                setStepOneData={setStepOneData}
                stepOneData={stepOneData}
                promoCode={promoCode}
              />
            ) : formStep === 1 ? (
              <SignupStepTwoForm
                stepOneData={stepOneData}
                setFormStep={setFormStep}
                promo={promoCode}
              />
            ) : null
          ) : (
            <MobileSignUp />
          )}

          <div className="already-member">
            Already a member?&nbsp;
            <Link
              to={"/login"}
              className="grey-color link-style-underline"
              onClick={() => setNavItem(null)}
            >
              Login here
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupStepOne;
