import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getToken } from "../../utils/localStorage";
import BackgroundImageTest from "../../assets/images/bg-main.jpg";
import { InternalNavbar, ExternalNavbar, Footer } from "../commonComponents";
import Cookies from "js-cookie";
import "./Layout.scss";

const PublicLayout = ({ children }) => {
  const [backgroundImageUrl, setBackgroundImageUrl] =
    useState("main-background1");
  const location = useLocation();
  const userAuthenticated = Cookies.get("user_token");
  const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setBackgroundImageLoaded(true);
  };
  useEffect(() => {
    setBackgroundImageLoaded(false); // Reset to false when route changes
    if (location.pathname == "/") {
      setBackgroundImageUrl("");
    } else if (location.pathname == "/insights") {
      setBackgroundImageUrl("main-background1");
    } else if (
      location.pathname == "/game" ||
      location.pathname == "/dashboard"
    ) {
      setBackgroundImageUrl("main-background2");
    } else {
      setBackgroundImageUrl("main-background3");
    }
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={`white-color ${backgroundImageUrl}`} id="scrollToTop">
      {userAuthenticated ? <InternalNavbar /> : <ExternalNavbar />}
      {children}
      <Footer backgroundImageUrl={backgroundImageUrl} />
      <img
        src={BackgroundImageTest}
        alt="Background"
        style={{ display: "none" }}
        onLoad={handleImageLoad}
      />
    </div>
  );
};

export default PublicLayout;
