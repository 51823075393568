import axios from "axios";
import Cookies from "js-cookie";

const token = Cookies.get("user_token");
const backEndURLWithAuth = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `${token}`,
  },
});

const backEndURLWithoutAuth = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
export const postApiWithoutAuth = async (url, body) => {
  try {
    const result = await backEndURLWithoutAuth.post(url, body);
    return result;
  } catch (error) {
    return error.response?.data;
  }
};
export const getApiWithoutAuth = async (url) => {
  try {
    const result = await backEndURLWithoutAuth.get(url);
    return result;
  } catch (error) {
    return error.response?.data;
  }
};
export const putApiWithoutAuth = async (url, body) => {
  try {
    const result = await backEndURLWithoutAuth.put(url, body);
    return result;
  } catch (error) {
    return error.response?.data;
  }
};
export const putApiWithAuth = async (url, body) => {
  backEndURLWithAuth.interceptors.request.use((config) => {
    // let token = getToken("access_token");
    let token = Cookies.get("user_token");
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  });
  try {
    const result = await backEndURLWithAuth.put(url, body);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateApiWithAuth = async (url, body) => {
  backEndURLWithAuth.interceptors.request.use((config) => {
    return config;
  });
  try {
    const result = await backEndURLWithAuth.patch(url, body);
    return result;
  } catch (error) {
    return error.response.data;
  }
};
export const deleteApiWithAuth = async (url) => {
  backEndURLWithAuth.interceptors.request.use((config) => {
    // let token = getToken("access_token");
    let token = Cookies.get("user_token");
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  });
  try {
    const result = await backEndURLWithAuth.delete(url);
    return result;
  } catch (error) {
    return error.response.data;
  }
};

export const getApiWithAuth = async (url) => {
  backEndURLWithAuth.interceptors.request.use((config) => {
    // let token = getToken("loginToken");
    let token = Cookies.get("user_token");
    config.headers["Authorization"] = token;
    return config;
  });
  try {
    const result = await backEndURLWithAuth.get(url);
    return result.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      window.location.href = "/login";
    }
    return error.response?.data;
  }
};

export const postApiWithAuth = async (url, body) => {
  backEndURLWithAuth.interceptors.request.use((config) => {
    // let token = getToken("access_token");
    let token = Cookies.get("user_token");
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  });
  try {
    const result = await backEndURLWithAuth.post(url, body);
    return result.data;
  } catch (error) {
    return error.response?.data;
  }
};

export const patchApiWithAuth = async (url, body) => {
  backEndURLWithAuth.interceptors.request.use((config) => {
    // let token = getToken("access_token");
    let token = Cookies.get("user_token");
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  });
  try {
    const result = await backEndURLWithAuth.patch(url, body);
    return result.data;
  } catch (error) {
    return error.response?.data;
  }
};
