import React, { useState, useRef } from "react";
import { PlayCircleFilled } from "@ant-design/icons";
import Thumbnail from "../../../../assets/images/homeImage.jpg";
import "./VideoPlayer.scss";

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    const video = videoRef.current;
    if (!isPlaying) {
      video
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error("Error playing video:", error);
        });
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };
  const handleVideoClick = (e) => {
    e.preventDefault(); // Prevent the default behavior of the click event
    togglePlay();
  };

  return (
    <div className="position-relative">
      <button className="video-toggle-btn" onClick={togglePlay}>
      {/* <img src={Thumbnail}/> */}
        <div className="video-ant-icon">
          {!isPlaying && <PlayCircleFilled />}
        </div>
      </button>
      <div onClick={handleVideoClick} style={{width:"100%",height:"100%"}}>
        <video
          controls
          poster={Thumbnail}
          ref={videoRef}
          src={
            "https://champions-choice.s3.amazonaws.com/Champion's+-+Choice_bgm.mp4"
          }
          className="video-cont"
          onPlay={handlePlay}
          onPause={handlePause}
        ></video>
      </div>
    </div>
  );
};

export default VideoPlayer;