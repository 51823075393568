import React, { useEffect, useState } from "react";
import { Select, Radio, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import {
  getToken,
  getExcluded,
  getPromoApplied,
} from "../../../../utils/localStorage";
import { setNavItem } from "../../../../utils/localStorage";
import { SimpleFilledButton } from "../../../commonComponents";

import {
  sendMetaEvents,
  sendGoogleAnalyticsEvents,
} from "../../../../helpers/genericFunctions";
import { useMyContext } from "../../../../helpers/Context/MyContext";
import DepositAlert from "../../../modals/DepositAlert/DepositAlert";
import PremierLeagueIcon from "../../../../assets/images/premier_league.svg";
import {
  joinGame,
  getFixturesData,
  getRemainingTeams,
} from "../../../../utils/service";
import { getApiWithAuth } from "../../../../utils/api";
import {
  GET_AMOUNT_TO_BE_PAID,
  CHECK_CODE_APPLICABILITY,
} from "../../../../utils/apiEndPoints";
import VerificationWarning from "../../../modals/VerificationWarning/VerificationWarning";
import VerificationProgress from "../../../modals/VerificationProgress/VerificationProgress";
import InsufficientBalance from "../../../modals/InsufficientBalance/InsufficientBalance";
import DiscountButton from "../../../commonComponents/DiscountButton/DiscountButton";
import PromoCodeInput from "../../../commonComponents/PromoCodeInput";
import notify from "../../../../utils/notificationService";
import "./ExpandedDetails.scss";

const ExpandedDetails = ({ expandedRowsKey, privateGame, showLinkModal }) => {
  const { contextData, setContextData } = useMyContext();
  const [entriesValue, setEntriesValue] = useState(
    expandedRowsKey?.sport_id == 3 && expandedRowsKey?.is_turbo == false ? 3 : 1
  );
  const [teamOne, setTeamOne] = useState(
    expandedRowsKey?.sport_id == 3 && expandedRowsKey?.is_turbo == false
      ? "Entry 1 - Select Team"
      : "Entry - Select Team"
  );
  const [teamTwo, setTeamTwo] = useState("Entry 2 - Select Team");
  const [teamThree, setTeamThree] = useState("Entry 3 - Select Team");
  const [entryOne, setEntryOne] = useState();
  const [entryTwo, setEntryTwo] = useState();
  const [entryThree, setEntryThree] = useState();
  const [fixturesResponse, setFixturesResponse] = useState([]);
  const [fixtureWeek, setFixtureWeek] = useState();
  const [loading, setLoading] = useState(false);
  const [turboFormattedDate, setTurboFormattedDate] = useState();
  const [depositAlertText, setDepositAlertText] = useState("");
  const [isDepositAlertOpen, setDepositAlertOpen] = useState(false);
  const [depositAlertTitle, setDepositAlertTitle] = useState("");
  const [isVerificationProgressOpen, setIsVerificationProgressOpen] =
    useState(false);
  const [isVerificationWarningOpen, setIsVerificationWarningOpen] =
    useState(false);
  const [isInsufficientBalanceOpen, setIsInsufficientBalanceOpen] =
    useState(false);
  const [newUser, setNewUser] = useState();
  const [amountToPay, setAmountToPay] = useState();
  const excluded = getExcluded();
  const userToken = getToken();
  const promoApplied = getPromoApplied();
  const navigate = useNavigate();
  const userData = contextData?.userData;

  const check_code_applicability = async () => {
    try {
      const response = await getApiWithAuth(CHECK_CODE_APPLICABILITY());
      if (response.success) {
        setNewUser(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (promoApplied === false) {
      check_code_applicability();
    }
  }, []);

  useEffect(() => {
    if ((!promoApplied || promoApplied === false) && userToken) {
      check_code_applicability();
    }
  }, []);

  const getAmount = async () => {
    setLoading(true); // Start loading
    try {
      const response = await getApiWithAuth(
        GET_AMOUNT_TO_BE_PAID(expandedRowsKey?.id, entriesValue)
      );

      const validEntries =
        (entriesValue === 3 &&
          teamOne !== "Entry 1 - Select Team" &&
          teamTwo !== "Entry 2 - Select Team" &&
          teamThree !== "Entry 3 - Select Team") ||
        (entriesValue === 2 &&
          teamOne !== "Entry 1 - Select Team" &&
          teamTwo !== "Entry 2 - Select Team") ||
        (entriesValue === 1 &&
          teamOne !== "Entry 1 - Select Team" &&
          teamOne !== "Entry - Select Team");

      if (validEntries) {
        setAmountToPay(response.amount);
      } else {
        setAmountToPay(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    getAmount();
  }, [entriesValue, teamOne, teamTwo, teamThree]);

  const openInsufficientBalanceModal = () => {
    setIsInsufficientBalanceOpen(true);
  };
  const closeInsufficientBalanceModal = () => {
    setIsInsufficientBalanceOpen(false);
  };

  const handleTeamOne = (value) => {
    setTeamOne(value);
  };
  const handleTeamTwo = (value) => {
    setTeamTwo(value);
  };
  const handleTeamThree = (value) => {
    setTeamThree(value);
  };
  const openVerificationProgressModal = () => {
    setIsVerificationProgressOpen(true);
  };
  const closeVerificationProgressModal = () => {
    setIsVerificationProgressOpen(false);
  };
  const openVerificationModal = () => {
    setIsVerificationWarningOpen(true);
  };
  const closeVerificationModal = () => {
    setIsVerificationWarningOpen(false);
  };

  const openDepositAlertModal = () => {
    setDepositAlertOpen(true);
  };
  const closeDepositAlertModal = () => {
    setDepositAlertOpen(false);
  };
  const onChange = (e) => {
    if (e.target.value == 1) {
      setTeamTwo("Entry 2 - Select Team");
      setTeamThree("Entry 3 - Select Team");
    } else if (e.target.value == 2) {
      setTeamThree("Entry 3 - Select Team");
    }
    setEntriesValue(e.target.value);
    // getAmount();
  };
  const handleStandingData = async (value) => {
    const res = await getRemainingTeams(expandedRowsKey?.id);
    const newArray1 = res?.data?.entry_1?.map((item) => ({
      key: item?.team_id,
      label: (
        <div className="expanded-details-dropdown-main font-cairo-normal">
          <div className="iconLeague">
            <img
              src={item?.team_picture}
              alt="Team Icon"
              className="image-cover-full"
            />
          </div>
          {item?.team_name}
        </div>
      ),
      value: item?.team_id,
      logo: item?.team_picture,
    }));
    const newArray2 = res?.data?.entry_2?.map((item) => ({
      key: item?.team_id,
      label: (
        <div className="expanded-details-dropdown-main font-cairo-normal">
          <div className="iconLeague">
            <img
              src={item?.team_picture}
              alt="Team Icon"
              className="image-cover-full"
            />
          </div>
          {item?.team_name}
        </div>
      ),
      value: item?.team_id,
      logo: item?.team_picture,
    }));
    const newArray3 = res?.data?.entry_3?.map((item) => ({
      key: item?.team_id,
      label: (
        <div className="expanded-details-dropdown-main font-cairo-normal">
          <div className="iconLeague">
            <img
              src={item?.team_picture}
              alt="Team Icon"
              className="image-cover-full"
            />
          </div>
          {item?.team_name}
        </div>
      ),
      value: item?.team_id,
      logo: item?.team_picture,
    }));
    setEntryOne(newArray1);
    setEntryTwo(newArray2);
    setEntryThree(newArray3);
  };
  const handleFixturesData = async () => {
    const res = await getFixturesData(expandedRowsKey?.season_id);
    setFixturesResponse(res?.data?.data);
    const week = res?.data?.data?.findIndex(
      (item) => item?.round == expandedRowsKey?.in_game_round_number
    );
    setFixtureWeek(week);
  };
  const handleJoinGame = async () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Join Game", // You can name this anything
      conversionId: "11420071760",
      conversionLabel: "CEI1CPyb58gZENDuwcUq",
    });
    if (userToken) {
      if (userData?.is_excluded || excluded) {
        notify("Action restricted due to self-exclusion.", "error");
        return;
      }
      if (userData.status === "refer" && userData.verification !== null) {
        openVerificationProgressModal();
      } else if (
        userData.status === "refer" &&
        userData.verification === null
      ) {
        openVerificationModal();
      } else {
        setLoading(true);
        const combinedArray = [teamOne, teamTwo, teamThree].filter(
          (state) =>
            state !== undefined &&
            state !== null &&
            state !== "Entry 1 - Select Team" &&
            state !== "Entry 2 - Select Team" &&
            state !== "Entry 3 - Select Team" &&
            state !== "Entry - Select Team"
        );
        let dataObject = {
          teams: combinedArray,
          round: expandedRowsKey?.round_number,
        };
        if (combinedArray.length == entriesValue) {
          const response = await joinGame(expandedRowsKey?.id, dataObject);
          if (response.success) {
            notify(response?.message, "success");
            if (privateGame) {
              showLinkModal();
            } else {
              navigate("/dashboard");
            }
            setNavItem(3);
            setLoading(false);
            var data = new FormData();
            data.append("event_name", "Game Joined");
            sendMetaEvents(data);
            const eventDetails = {
              category: "entered_game",
              action: "button_click",
              label: "Join Game",
              email: contextData?.userData?.email,
              username: contextData?.userData?.username,
              gameId: expandedRowsKey?.id,
            };
            sendGoogleAnalyticsEvents(eventDetails);
          } else {
            if (response.not_first) {
              openDepositAlertModal();
              setDepositAlertText(response.message);
              setDepositAlertTitle("Deposit Limit Increase Notice");
            } else if (response?.message == "Insufficient Funds!") {
              openInsufficientBalanceModal();
            } else if (response?.errors) {
              setDepositAlertTitle("Time-Out Period Active");
              setDepositAlertText(response.message);
              openDepositAlertModal();
            }
            setLoading(false);
          }
        } else {
          notify(
            `Please select your team${expandedRowsKey?.is_turbo ? "" : "s"}`,
            "warning"
          );
          setLoading(false);
        }
      }
    } else {
      navigate("/login");
    }
  };

  const handleTurboFormattedDate = () => {
    let newDate = new Date(expandedRowsKey.new_date);
    let weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let weekdayIndex = newDate.getDay();
    let weekdayName = weekdays[weekdayIndex];
    let monthName = newDate.toLocaleString("default", {
      month: "long",
    });
    let day = newDate.getDate();
    let year = newDate.getFullYear();
    let formattedDate = weekdayName + " " + monthName + " " + day + " " + year;
    // Do something with formattedDate if needed
    // return formattedDate;
    setTurboFormattedDate(formattedDate);
  };

  useEffect(() => {
    handleStandingData();
    handleFixturesData();
    if (expandedRowsKey?.teams) {
      setTeamOne(
        expandedRowsKey?.teams[0]?.team_id
          ? expandedRowsKey?.teams[0]?.team_id
          : "Entry 1 - Select Team"
      );
      setTeamTwo(
        expandedRowsKey?.teams[1]?.team_id
          ? expandedRowsKey?.teams[1]?.team_id
          : "Entry 2 - Select Team"
      );
      setTeamThree(
        expandedRowsKey?.teams[2]?.team_id
          ? expandedRowsKey?.teams[2]?.team_id
          : "Entry 3 - Select Team"
      );
    }
  }, []);

  return (
    <>
      <VerificationProgress
        visible={isVerificationProgressOpen}
        closeModal={closeVerificationProgressModal}
      />
      <VerificationWarning
        visible={isVerificationWarningOpen}
        closeModal={closeVerificationModal}
      />
      <DepositAlert
        visible={isDepositAlertOpen}
        closeModal={closeDepositAlertModal}
        description={depositAlertText}
        title={depositAlertTitle}
      />
      <InsufficientBalance
        visible={isInsufficientBalanceOpen}
        closeModal={closeInsufficientBalanceModal}
      />
      <div className="expand-details lightblack-background">
        <div className="expand-details-top">
          <div className="expand-details-top-left">
            <img
              src={
                expandedRowsKey?.season_id == 23614
                  ? PremierLeagueIcon
                  : expandedRowsKey?.league_image
              }
              alt="Profile Pic"
              className="expand-details-top-left-image"
              style={{
                width: expandedRowsKey?.sport_id == 5 ? "25px" : "47px",
              }}
            />
            <div className="expand-details-top-left-text font-cairo-normal">
              <span className="font18">{expandedRowsKey?.league_name}</span>
              <span className="font14 grey-color">
                Round {expandedRowsKey?.round_number}
              </span>
            </div>
          </div>
          {!privateGame && (
            <div className="expand-details-bottom-participant mobileonly expand-details-sidebyside">
              <div className="expand-details-bottom-participant-title font-cairo-bold">
                Entries
              </div>
              <div className="expand-details-bottom-participant-cont">
                {expandedRowsKey?.joined_players
                  ?.toString()
                  ?.split("")
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="expand-details-bottom-participant-cont-number container-border font-cairo-bold"
                      >
                        {item}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {expandedRowsKey?.sport_id == 3 &&
            expandedRowsKey.is_turbo == false && (
              <div className="expand-details-top-right">
                <div className="expand-details-top-right-text">
                  Choose your numbers of entries
                </div>
                <Radio.Group
                  onChange={onChange}
                  value={entriesValue}
                  className="expand-details-top-right-radio"
                >
                  <Radio value={1}>1</Radio>
                  <Radio value={2}>2</Radio>
                  <Radio value={3}>3</Radio>
                </Radio.Group>
              </div>
            )}
        </div>
        <div className="expand-details-bottom">
          <div
            className="expand-details-bottom-fixtures"
            style={{
              height: expandedRowsKey?.league_name?.includes("Euro")
                ? "230px"
                : "360px",
            }}
          >
            <div className="font18 font-cairo-bold expand-details-bottom-fixtures-title">
              Fixtures
            </div>
            {fixturesResponse?.length > 0 ? (
              expandedRowsKey?.sport_id == 5 ||
              expandedRowsKey.is_turbo == true ? (
                <>
                  <div>
                    <div className="expand-details-cont-date font-cairo-medium">
                      {turboFormattedDate}
                    </div>
                    {fixturesResponse[fixtureWeek]?.fixtures[
                      expandedRowsKey?.new_date
                    ]?.map((fixture, index) => {
                      const {
                        team1_name,
                        team2_name,
                        time,
                        team1_img,
                        team2_img,
                        is_postponed,
                      } = fixture;
                      return (
                        <div
                          className="expand-details-cont-teams font-cairo-normal"
                          key={index}
                        >
                          <div className="expand-details-cont-teams-one">
                            <div className="expand-details-cont-teams-one-name">
                              {team1_name}
                            </div>
                            <div className="expand-details-cont-teams-one-image">
                              <img
                                src={team1_img}
                                alt="Team Icon"
                                className="image-cover-full"
                              />
                            </div>
                          </div>
                          <div className="expand-details-cont-teams-time">
                            {is_postponed
                              ? "Postp."
                              : time
                                  .split(" ")[1]
                                  .split(":")
                                  .slice(0, 2)
                                  .join(":")}
                          </div>
                          <div className="expand-details-cont-teams-two">
                            <div className="expand-details-cont-teams-two-image">
                              <img
                                src={team2_img}
                                alt="Team Icon"
                                className="image-cover-full"
                              />
                            </div>
                            <div className="expand-details-cont-teams-two-name">
                              {team2_name}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="expand-details-cont-line"></div>
                  </div>
                </>
              ) : (
                Object?.keys(fixturesResponse[fixtureWeek]?.fixtures).map(
                  (date) => {
                    const fixturesArray =
                      fixturesResponse[fixtureWeek]?.fixtures[date];
                    let newDate = new Date(date);
                    let weekdays = [
                      "Sunday",
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                    ];
                    let weekdayIndex = newDate.getDay();
                    let weekdayName = weekdays[weekdayIndex];
                    let monthName = newDate.toLocaleString("default", {
                      month: "long",
                    });
                    let day = newDate.getDate();
                    let year = newDate.getFullYear();
                    let formattedDate =
                      weekdayName + " " + monthName + " " + day + " " + year;
                    return (
                      <div key={date}>
                        <div className="expand-details-cont-date font-cairo-medium">
                          {formattedDate}
                        </div>
                        {fixturesArray?.map((fixture, index) => {
                          const {
                            team1_name,
                            team2_name,
                            time,
                            team1_img,
                            team2_img,
                            is_postponed,
                          } = fixture;
                          return (
                            <div
                              className="expand-details-cont-teams font-cairo-normal"
                              key={index}
                            >
                              <div className="expand-details-cont-teams-one">
                                <div className="expand-details-cont-teams-one-name">
                                  {team1_name}
                                </div>
                                <div className="expand-details-cont-teams-one-image">
                                  <img
                                    src={team1_img}
                                    alt="Team Icon"
                                    className="image-cover-full"
                                  />
                                </div>
                              </div>
                              <div className="expand-details-cont-teams-time">
                                {is_postponed
                                  ? "Postp."
                                  : time
                                      .split(" ")[1]
                                      .split(":")
                                      .slice(0, 2)
                                      .join(":")}
                              </div>
                              <div className="expand-details-cont-teams-two">
                                <div className="expand-details-cont-teams-two-image">
                                  <img
                                    src={team2_img}
                                    alt="Team Icon"
                                    className="image-cover-full"
                                  />
                                </div>
                                <div className="expand-details-cont-teams-two-name">
                                  {team2_name}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className="expand-details-cont-line"></div>
                      </div>
                    );
                  }
                )
              )
            ) : (
              <div className="spinner-loading">
                <Spin className="loader" />
              </div>
            )}
          </div>
          <div className="expand-details-bottom-selections">
            <div className="expand-details-bottom-selections-title font-cairo-bold">
              My Selections
            </div>
            <div className="selections-teams-list text">
              {(entriesValue == 1 ||
                entriesValue == 2 ||
                entriesValue == 3) && (
                <Select
                  value={teamOne}
                  onChange={handleTeamOne}
                  options={entryOne}
                  className="expand-details-dropdown"
                  popupClassName="dropdown-popup-style expand-details-dropdown-popup"
                />
              )}
              {(entriesValue == 3 || entriesValue == 2) && (
                <Select
                  onChange={handleTeamTwo}
                  value={teamTwo}
                  options={entryTwo}
                  className="expand-details-dropdown"
                  popupClassName="dropdown-popup-style expand-details-dropdown-popup"
                />
              )}
              {entriesValue == 3 && (
                <Select
                  onChange={handleTeamThree}
                  options={entryThree}
                  value={teamThree}
                  className="expand-details-dropdown"
                  popupClassName="dropdown-popup-style expand-details-dropdown-popup"
                />
              )}
            </div>
            <br />
            {newUser && <PromoCodeInput getAmount={getAmount} />}
            <div className="text-center padding-top2">
              {!amountToPay ||
              amountToPay === entriesValue * expandedRowsKey?.stake ? (
                <SimpleFilledButton
                  id="submit-game-button"
                  name={
                    loading ? (
                      <div className="auth-loading">
                        <Spin className="loader" />
                      </div>
                    ) : (entriesValue === 3 &&
                        teamOne !== "Entry 1 - Select Team" &&
                        teamTwo !== "Entry 2 - Select Team" &&
                        teamThree !== "Entry 3 - Select Team") ||
                      (entriesValue === 2 &&
                        teamOne !== "Entry 1 - Select Team" &&
                        teamTwo !== "Entry 2 - Select Team") ||
                      (entriesValue === 1 &&
                        teamOne !== "Entry 1 - Select Team" &&
                        teamOne !== "Entry - Select Team") ? (
                      expandedRowsKey.stake == "0.0" ? (
                        "Submit"
                      ) : (
                        `Submit & Pay £${entriesValue * expandedRowsKey?.stake}`
                      )
                    ) : (
                      "Submit"
                    )
                  }
                  link={"#"}
                  btnClass={"expand-details-bottom-selections-button gtm-game-join"}
                  functionCall={handleJoinGame}
                />
              ) : (
                <DiscountButton
                  name={
                    loading ? (
                      <div className="auth-loading">
                        <Spin className="loader" />
                      </div>
                    ) : (entriesValue === 3 &&
                        teamOne !== "Entry 1 - Select Team" &&
                        teamTwo !== "Entry 2 - Select Team" &&
                        teamThree !== "Entry 3 - Select Team") ||
                      (entriesValue === 2 &&
                        teamOne !== "Entry 1 - Select Team" &&
                        teamTwo !== "Entry 2 - Select Team") ||
                      (entriesValue === 1 &&
                        teamOne !== "Entry 1 - Select Team") ? (
                      expandedRowsKey.stake == "0.0" ? (
                        "Submit"
                      ) : (
                        `Submit & Pay`
                      )
                    ) : (
                      "Submit"
                    )
                  }
                  link={"#"}
                  btnClass={"expand-details-bottom-selections-button gtm-game-join"}
                  functionCall={handleJoinGame}
                  actualAmount={amountToPay}
                  strikeAmount={entriesValue * expandedRowsKey?.stake}
                />
              )}
            </div>
          </div>
          {!privateGame && (
            <div className="expand-details-bottom-participant desktoponly">
              <div className="expand-details-bottom-participant-title font-cairo-bold">
                Entries
              </div>
              <div className="expand-details-bottom-participant-cont">
                {expandedRowsKey?.joined_players
                  ?.toString()
                  ?.split("")
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="expand-details-bottom-participant-cont-number container-border font-cairo-bold"
                      >
                        {item}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ExpandedDetails;
