import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Result, Spin } from "antd";
import PlacesSearch from "../Places/Places";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { setToken } from "../../../../utils/localStorage.js";
import { signupWithEmail } from "../../../../utils/service.js";
import { FilledButton } from "../../../commonComponents/index.js";
import { useMyContext } from "../../../../helpers/Context/MyContext.js";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { ReactComponent as ErrorIcon } from "../../../../assets/images/error-icon.svg";
import { sendGoogleAnalyticsEvents } from "../../../../helpers/genericFunctions.js";
import { getApiWithAuth, getApiWithoutAuth } from "../../../../utils/api.js";
import { GET_AvailabilityPromoCode } from "../../../../utils/apiEndPoints.js";
import { sendMetaEvents } from "../../../../helpers/genericFunctions.js";
import notify from "../../../../utils/notificationService.js";
import "react-phone-number-input/style.css";

const SignupStepTwoForm = ({ stepOneData, setFormStep, promo }) => {
  const SignupSchema = Yup.object().shape({
    dateOfBirth: Yup.date()
      .min(new Date(1920, 0, 1), "Must be after 1900/01/01")
      .max(new Date(), "Cannot be a future date")
      .required("Date of Birth is required")
      .test("is-adult", "Must be 18 years or older", function (value) {
        const currentDate = new Date();
        const birthDate = new Date(value);
        const ageDifference =
          currentDate.getFullYear() - birthDate.getFullYear();
        return ageDifference >= 18;
      }),
    promoCode: Yup.string().test(
      "promoCode",
      "Invalid promocode",
      async (value) => {
        if (value) {
          const isAvailable = await getPromo(value);
          return isAvailable ? true : false;
        }
        return true; // No promo code provided
      }
    ),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .test("valid-phone", "Invalid phone number", (value) => {
        try {
          const phoneNumber = parsePhoneNumber(value);
          return phoneNumber && phoneNumber.isValid();
        } catch (error) {
          return false;
        }
      }),
    country: Yup.string().required("Please select your country"),
    city: Yup.string()
      .trim()
      .required("City name is required")
      .matches(/^[^\s].*\S$/, "Please add valid city name"),
    postcode: Yup.string()
      .required("Please add your postcode")
      .matches(
        /^[A-Z]{1,2}\d{1,2}[A-Z]?\s\d[A-Z]{2}$/i,
        "Add a valid postcode"
      ),
    address: Yup.string().trim().required("Address is required"),
    building_number: Yup.string(),
    building_name: Yup.string(),
    building: Yup.string(),
    street: Yup.string(),
    termsAndConditions: Yup.bool()
      .oneOf([true], "Please accept the terms and conditions")
      .required("Please confirm your agreement with our policies to continue"),
  });
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("GB");
  const [isDirty, setIsDirty] = useState(true);
  const [addvertisementCheck, setAddvertisementCheck] = useState(false);
  const navigate = useNavigate();
  const minimumDate = new Date(1920, 0, 1); // Define your desired minimum date here
  const { contextData, setContextData } = useMyContext();
  const [promoCodeValue, setPromoCodeValue] = useState();
  const [promoCodeError, setPromoCodeError] = useState(null);
  const [isPromoCodeFocus, setIsPromoCodeFocus] = useState(false);
  const [promoResponseStatus, setPromoResponseStatus] = useState(null);
  const handlePromoCodeFocus = () => {
    setIsPromoCodeFocus(true);
  };

  const handlePromoCodeBlur = (event) => {
    const value = event.target.value;
    if (!value) {
      setPromoCodeValue();
      setPromoCodeError(null);
    }
    setIsPromoCodeFocus(false);
  };

  const getPromo = async (promoCode) => {
    if (promoCode) {
      setPromoCodeError(null);
      setPromoCodeValue();
    }
    setPromoCodeValue(promoCode);
    try {
      const response = await getApiWithoutAuth(
        GET_AvailabilityPromoCode(promoCode)
      );
      setPromoResponseStatus(response?.message);
      setPromoCodeError(response?.message);
      if (response.data.success) {
        return true; // Username is available
      }
      return false; // API call failed or other error
    } catch (error) {
      setPromoResponseStatus(false);
      // setPromoCodeError(null)
      return false;
    }
  };

  const handleFormSubmit = async (values) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Sign Ups", // You can name this anything
      conversionId: "11420071760",
      conversionLabel: "voBLCPi9gscZENDuwcUq",
      buttonId: "signupBtn",
    });
    // Handle form submission
    const eventDetails = {
      category: "engagement",
      action: "form_submission",
      label: "Sign up",
      email: values.email,
      username: values.userName,
      firstName: values.firstName,
      lastName: values.lastName,
    };
    sendGoogleAnalyticsEvents(eventDetails);

    setLoading(true);
    let data = new FormData();
    data.append("email", stepOneData?.email);
    data.append("password", stepOneData?.password);
    data.append("password_confirmation", stepOneData?.password);
    data.append("first_name", stepOneData?.firstName);
    data.append("last_name", stepOneData?.lastName);
    data.append("username", stepOneData?.userName);
    data.append("address", values.address);
    data.append("date_of_birth", values.dateOfBirth);
    data.append("country", values.country);
    data.append("city", values.city);
    data.append("phone", values.phoneNumber);
    data.append("referal_code", values.promoCode);
    data.append("post_code", values.postcode);
    data.append("notification", addvertisementCheck);
    data.append("text_messages", addvertisementCheck);
    data.append("email_notification", addvertisementCheck);
    data.append("building_number", values.building_number);
    data.append("street", values.street);
    data.append("building_name", values.building_name);
    data.append("building", values.building);

    if (isPromoCodeFocus) {
      const isValidPromoCode = await getPromo(values.promoCode);
      if (!isValidPromoCode) {
        setPromoCodeError("Invalid Promo Code");
      } else {
        setPromoCodeError(null);
      }
    }

    const res = await signupWithEmail(data);

    if (res.status === 200) {
      setLoading(false);
      setToken(res?.headers["authorization"]);
      sessionStorage.setItem("signedIn", true);
      if (
        res?.data?.data.is_excluded === false &&
        res?.data?.data.status === "pass"
      ) {
        notify(
          "Welcome to Champion's Choice! We're thrilled to have you,Champ! Good Luck 🙌🏻🍀",
          "success"
        );
      }
      setContextData({ ...contextData, signupData: res?.data?.data });
      setTimeout(() => {
        navigate("/");
      }, 2000);
      var dataEvent = new FormData();
      dataEvent.append("event_name", "SignUp");
      sendMetaEvents(dataEvent);
    } else {
      notify(res?.message, "error");
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    setIsDirty(!isDirty);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue =
          "Are you sure you want to leave? Your unsaved changes will be lost.";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);

  return (
    <div>
      <Formik
        initialValues={{
          dateOfBirth: "",
          phoneNumber: "",
          promoCode: promo ? promo : "",
          city: "",
          country: "United Kindom",
          postcode: "",
          address: "",
          termsAndConditions: "",
          building_number: "",
          street: "",
          building_name: "",
          building: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={handleFormSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="signup-fields-cont">
              <Field
                name="dateOfBirth"
                type="date"
                className={`signup-input-field ${
                  errors.dateOfBirth && touched.dateOfBirth ? "error" : ""
                }`}
                placeholder="Date of Birth"
                min={minimumDate.toISOString().split("T")[0]}
              />
              <ErrorMessage name="dateOfBirth" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div className="signup-infomation">
              To set up your account successfully, please make sure your home
              address matches your official and personal documentation.
            </div>
            <Field name="address">
              {({ field, form }) => (
                <PlacesSearch setFormValues={form.setFieldValue} />
              )}
            </Field>
            <div className="signup-fields-cont">
              <Field name="phoneNumber">
                {({ field, form }) => (
                  <PhoneInput
                    style={{ backgroundColor: "#1b1b1b" }}
                    placeholder={"Phone number"}
                    containerStyle={{ backgroundColor: "#000" }}
                    textInputStyle={{ color: "#fff" }}
                    {...field}
                    id="phoneNumber"
                    defaultCountry={countryCode}
                    onChange={(value) => {
                      form.setFieldValue("phoneNumber", value);
                    }}
                    className={`signup-input-field phone-number-dropdown ${
                      errors.phoneNumber && touched.phoneNumber ? "error" : ""
                    }`}
                  />
                )}
              </Field>
              <ErrorMessage name="phoneNumber" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>

            <div className="signup-fields-cont position-relative">
              <Field
                type="text"
                id="promoCode"
                name="promoCode"
                className={`signup-input-field no-outline ${
                  errors.userName && touched.userName ? "error" : ""
                }`}
                placeholder="Promo code (optional)"
                onFocus={handlePromoCodeFocus}
                onBlur={handlePromoCodeBlur}
                style={{ paddingRight: "3.5rem" }}
              />
              {promoCodeValue &&
                (promoResponseStatus !== false ? (
                  <Result status="success" className="signup-fname-result" />
                ) : (
                  <Result status="error" className="signup-fname-error" />
                ))}
              {promoCodeError ? (
                <div className="error-message">
                  <ErrorIcon className="error-icon-margin" />
                  {promoCodeError}
                </div>
              ) : (
                <ErrorMessage name="userName" component="div">
                  {(msg) => (
                    <div className="error-message">
                      <ErrorIcon className="error-icon-margin" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              )}
            </div>

            <div>
              <div className="signup-terms" style={{ marginBottom: "0.6" }}>
                <Field
                  type="checkbox"
                  name="advertisementCheck"
                  className="signup-form-checkbox checkoxSignup"
                  onClick={() => {
                    setAddvertisementCheck(!addvertisementCheck);
                  }}
                />
                <label className="signup-form-terms">
                  I want to receive information about free games, bonuses and
                  other offers via email, SMS or telephone. (You can edit your
                  preferences at any time)
                </label>
              </div>

              <ErrorMessage name="advertisementCheck" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div>
              <div className="signup-terms">
                <Field
                  type="checkbox"
                  name="termsAndConditions"
                  className="signup-form-checkbox checkoxSignup"
                />
                <label className="signup-form-terms">
                  I confirm that I am 18 years of age or older. I have read,
                  understood, and agree to the
                  <Link
                    to={"/termsandconditions"}
                    className="white-color link-style"
                  >
                    {" "}
                    Terms & Conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    to={"/privacypolicy"}
                    className="white-color link-style"
                  >
                    Privacy Policy{" "}
                  </Link>
                </label>
              </div>

              <ErrorMessage name="termsAndConditions" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div className="signup-button-cont signupCreate">
              <FilledButton
                id="signupBtn"
                name={
                  loading ? (
                    <div className="auth-loading">
                      <Spin className="loader" />
                    </div>
                  ) : (
                    "Create Account"
                  )
                }
                btnClass="primary-background gtm-signup-submit"
                type={5}
                btnType={"submit"}
              ></FilledButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignupStepTwoForm;
