import React, { useState } from "react";
import { Modal, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { setNavItem } from "../../../utils/localStorage";
import { useMyContext } from "../../../helpers/Context/MyContext";
import { SimpleFilledButton, BorderedButton } from "../../commonComponents";
import "./DepositAlert.scss";

const DepositAlert = ({ visible, closeModal, description, title }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { contextData, setContextData } = useMyContext();

  const handleUpdateSaferGambling = async () => {
    setContextData((prevState) => {
      const newState = { ...prevState };
      delete newState.signupData;
      return newState;
    });
    navigate("/personalinformation?verification_required=true");
    setNavItem(null);
  };
  const handleRemindLater = async () => {
    setContextData((prevState) => {
      const newState = { ...prevState };
      delete newState.signupData;
      return newState;
    });
    closeModal();
  };
  return (
    <>
      <Modal
        open={visible}
        title={title}
        onCancel={closeModal}
        wrapClassName="verification-warning-modal"
        className="verification-warning-modal-inner"
        footer={null}
        maskStyle={{
          backdropFilter: "blur(5px)",
        }}
      >
        <div className="verification-warning-modal-details font-cairo-medium">
          {description}
        </div>
        {/* <div className="verification-warning-modal-buttons">
          <BorderedButton
            name={"Remind me Later"}
            btnClass="verification-warning-modal-buttons-confirm font-cairo-medium"
            functionCall={handleRemindLater}
          />
          <SimpleFilledButton
            name={
              loading ? (
                <div className="auth-loading">
                  <Spin className="loader" />
                </div>
              ) : (
                "Proceed to Verification"
              )
            }
            btnClass="verification-warning-modal-buttons-cancel font-cairo-medium"
            type={7}
            functionCall={handleUpdateSaferGambling}
          />
        </div> */}
      </Modal>
    </>
  );
};
export default DepositAlert;
