import React, { useState, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Result } from "antd";
import { ReactComponent as ErrorIcon } from "../../../assets/images/error-icon.svg";
import { getApiWithoutAuth } from "../../../utils/api.js";
import { GET_AvailabilityPromoCode } from "../../../utils/apiEndPoints.js";
import { applyPromoCode } from "../../../utils/service.js";
import { setPromoApplied } from "../../../utils/localStorage.js";

import "./PromoCodeInput.scss";

const PromoCodeInput = ({ getAmount }) => {
  const PromoCodeSchema = Yup.object().shape({
    promoCode: Yup.string(),
  });

  const [promoCodeError, setPromoCodeError] = useState(null);
  const [promoResponseStatus, setPromoResponseStatus] = useState(null);
  const formikRef = useRef();
  const [cursor, setCursor] = useState("pointer");
  const [showResult, setShowResult] = useState(false);

  const handlePromoCodeFocus = () => {
    setPromoCodeError(null);
  };

  const handlePromoCodeBlur = (event) => {
    const value = event.target.value;
    if (!value) {
      setPromoCodeError(null);
    }
  };

  const handlePromoCodeChange = (setFieldValue, event) => {
    const value = event.target.value;
    setFieldValue("promoCode", value);
    setPromoCodeError(null);
    setShowResult(false);
  };

  const getPromo = async (promoCode) => {
    try {
      const response = await getApiWithoutAuth(
        GET_AvailabilityPromoCode(promoCode)
      );
      if (response.data.success) {
        return true;
      }
      // setCursor("not-allowed");
      // return false;
    } catch (error) {
      setPromoCodeError(null);
      return false;
    }
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setShowResult(false);
    const check = await getPromo(values.promoCode);
    // console.log("check", check)
    // if (check) {
    const response = await applyPromoCode({ referal_code: values.promoCode });
    setShowResult(true);
    if (response.success) {
      setPromoApplied(true);
      setPromoResponseStatus("success");
      setPromoCodeError(null);
      getAmount();
      setCursor("not-allowed");
    } else {
      setPromoResponseStatus("error");
      setPromoCodeError(response?.message);
    }
    // }
    setSubmitting(false);
  };
  return (
    <div className="game-promo-div">
      <Formik
        initialValues={{
          promoCode: "",
        }}
        validationSchema={PromoCodeSchema}
        onSubmit={handleFormSubmit}
        innerRef={formikRef}
      >
        {({
          errors,
          touched,
          setFieldValue,
          values,
          isSubmitting,
          submitForm,
        }) => (
          <Form>
            <div className="promoCode-fields-cont position-relative">
              <div className="in-line-input-btn">
                <Field
                  type="text"
                  id="promoCode"
                  name="promoCode"
                  value={values.promoCode}
                  className={`promo-input-field no-outline ${
                    errors.promoCode && touched.promoCode ? "error" : ""
                  }`}
                  placeholder="Promo code (optional)"
                  onFocus={handlePromoCodeFocus}
                  onBlur={handlePromoCodeBlur}
                  onChange={(event) =>
                    handlePromoCodeChange(setFieldValue, event)
                  }
                  style={{ paddingRight: "3.5rem" }}
                  autoComplete="off"
                />
                <p
                  className="promo-apply-text"
                  style={{ cursor: cursor }}
                  onClick={cursor === "pointer" ? submitForm : () => {}}
                  disabled={isSubmitting}
                >
                  Apply
                </p>
              </div>
              {showResult && promoResponseStatus === "success" && (
                <Result status="success" className="promo-fname-result" />
              )}
              {showResult && promoResponseStatus === "error" && (
                <Result status="error" className="promo-fname-error" />
              )}
              {promoCodeError ? (
                <div className="error-message">
                  <ErrorIcon className="error-icon-margin" />
                  {promoCodeError}
                </div>
              ) : (
                <ErrorMessage name="promoCode" component="div">
                  {(msg) => (
                    <div className="error-message">
                      <ErrorIcon className="error-icon-margin" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PromoCodeInput;
