import React, { useState } from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import PrivateAlert from "../PrivateAlert/PrivateAlert";
import { deletePrivateGame } from "../../../utils/service";
import ExpandedDetails from "../../mainComponents/Game/ExpandedDetails";
import "./JoinGameModal.scss";

const JoinGameModal = ({
  visible,
  closeModal,
  gameDetails,
  privateGame,
  showLinkModal,
  openContactUsModal,
}) => {
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const navigate = useNavigate();

  const openAlertUsModal = () => {
    setIsAlertModalOpen(true);
  };
  const closeAlertUsModal = () => {
    setIsAlertModalOpen(false);
  };
  const handleModalClose = () => {
    openAlertUsModal();
  };
  const handleGameDeletion = async () => {
    const response = await deletePrivateGame(gameDetails?.id);
    if (response?.data?.success) {
      navigate("/");
      closeAlertUsModal();
    }
  };

  return (
    <>
      <PrivateAlert
        visible={isAlertModalOpen}
        closeModal={closeAlertUsModal}
        handleGameDeletion={handleGameDeletion}
        openContactUsModal={openContactUsModal}
      />
      <Modal
        open={visible}
        title={
          privateGame
            ? `Finalize Game: ${
                gameDetails?.is_turbo
                  ? "Pick Your Entry"
                  : "Pick Your Entries & Teams"
              } `
            : "Join Game"
        }
        onCancel={closeModal}
        wrapClassName="game-modal"
        className="game-modal-inner"
        footer={null}
        maskStyle={{
          backdropFilter: "blur(5px)",
        }}
        afterClose={privateGame ? handleModalClose : null}
      >
        <div className="game-modal-details font-cairo-medium">
          <ExpandedDetails
            expandedRowsKey={gameDetails}
            privateGame={privateGame}
            showLinkModal={showLinkModal}
          />
        </div>
      </Modal>
    </>
  );
};
export default JoinGameModal;
