import React from "react";
import "./PrivacyPolicy.scss";

const data = [
  {
    title: "Data",
    description:
      "Collectively all information that you submit to us via our website or App. This definition incorporates, where applicable, the definitions provided in the EU General Data Protection Regulation 2018 (GDPR) and any further definitions provided for in the Data Protection Act 2018;",
  },
  {
    title: "DPA 2018",
    description: "The Data Protection Act 2018 as amended from time to time;",
  },
  {
    title: "We, us or our",
    description:
      "Refers to Champion’s Choice Ltd being a company incorporated in England & Wales with registered number 14788870 whose registered office is at 3rd Floor, 86-90 Paul Street, London, EC2A 4NE, England",
  },
  {
    title: "User, you or your",
    description:
      "Any third party that accesses / uses the App and or Website and is not either (i) employed by us and acting in the course of their employment or (ii) engaged as a consultant or otherwise providing services to us and accessing the App in connection with the provision of such services;",
  },
  {
    title: "App",
    description:
      "the application provided and used to access services and/or account platforms provided by us, together with any additional or associated apps.",
  },
  { title: "Website", description: "The website at www.championschoice.co.uk" },
];
const data6 = [
  {
    title: "Contact Data, Functional Data, Financial Data Account Data",
    description:
      "To enable us to set up your account, to provide services to you and allow you to use the App, and allow you to enroll in games through our App or Website",
    legalBasis: "As part of our contract with you",
  },
  {
    title: "Contact Data",
    description:
      "To communicate with you about your account, deal with questions or concerns you may have",
    legalBasis: "As part of our contract with you",
  },
  {
    title: "Contact Data",
    description:
      "Refers to Champion’s Choice Ltd being a company incorporated in England & Wales with registered number 14788870 whose registered office is at 3rd Floor, 86-90 Paul Street, London, EC2A 4NE, England",
    legalBasis: "As part of our contract with you",
  },
  {
    title: "Contact Data",
    description:
      "To record communications with you for the purposes of training and improvement of our services",
    legalBasis: "Legitimate interests",
  },
  {
    title: "AContact Data",
    description:
      "To send you offers and promotions that may be of interest to you",
    legalBasis: "Consent",
  },
  {
    title: "Contact Data, Functional Data",
    description:
      "To send you details of competitions or other aspects of our services that may be of interest to you",
    legalBasis: "Legitimate Interests",
  },
  {
    title: "Contact Data, Financial Data Account Data",
    description: "Internal record keeping",
    legalBasis: "Legitimate Interests",
  },
  {
    title: "All types of Data, particularly Usage Data",
    description:
      "Improvement of our products / services, including using your Data for research purposes so that we can better understand our services and how they are used and perceived for the purposes of making improvements",
    legalBasis: "Legitimate Interests",
  },
  {
    title: "Contact Data",
    description:
      "Contact for market research purposes which may be done using email, telephone, or mail. Such information may be used to customise or update the App",
    legalBasis: "Consent",
  },
  {
    title: "All types of Data",
    description:
      "To deter, prevent, or detect any activities conducted in breach of the our General Terms and Conditions",
    legalBasis: "Legitimate interests",
  },
  {
    title: "Contact Data, Usage Data",
    description: "To make sure we offer our services to eligible persons",
    legalBasis: "Legal obligation",
  },
  {
    title: "All types of Data",
    description: "Crime detection, prevention, and prosecution",
    legalBasis: "Legitimate interests",
  },
  {
    title: "Contact Data, Account Data",
    description:
      "To verify your identity and establish the source of funding in any transaction",
    legalBasis: "As part of our contract with you",
  },
  {
    title: "All types of Data",
    description:
      "To assess and manage any potential risks and prevent inappropriate use of the App",
    legalBasis: "As part of our contract with you",
  },
  {
    title: "Contact Data, Functional Data, Account Data",
    description:
      "To communicate with you about your use of the App, Website and our services.",
    legalBasis: "As part of our contract with you",
  },
  {
    title: "Financial Data",
    description:
      "To receive payments from you for tokens and to manage these in accordance with your choices",
    legalBasis: "As part of our contract with you",
  },
  {
    title: "Account Data",
    description:
      "To manage, monitor, assess risks in connection with and regulate our contractual relationship with you (for example, by limiting or prohibiting access where you breach our terms of use)",
    legalBasis: "Legitimate interest",
  },
  {
    title: "Contact Data",
    description:
      "To enable us to receive and respond to questions and comments from you",
    legalBasis: "As part of our contract with you",
  },
  {
    title: "All types of Data",
    description:
      "To identify, investigate and expose suspected unlawful, fraudulent or improper activity connected with the App or our Website. This may involve disclosure of information, which may include personal Data, to the appropriate authorities",
    legalBasis: "Compliance with the law; legitimate interests",
  },
  {
    title: "Contact Data",
    description:
      "To inform third party marketing or advertising agencies, or third parties who want to market or advertise their products and services",
    legalBasis: "Consent",
  },
];
const cellStyle = {
  border: "1px solid #ddd", // Border color and width
  padding: "8px", // Cell padding
  textAlign: "left", // Text alignment
};
const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="font-cairo-extrabold privacy-policy-heading">
        Champion’s Choice Ltd Privacy Policy
      </div>
      <div>
        This Privacy Policy applies between you, the user of our Website, mobile
        or tablet App, (‘user’, ‘you’ or ‘your’), and Champion’s Choice Ltd
        (‘we’ or ‘us’) as the owner and provider of the Website and App. We take
        the privacy of your information very seriously. This Privacy Policy
        applies to our use of any and all Data (defined below) collected by us
        or provided by you in relation to your use of our website and App.
        <br />
        <br />
        Please read this Privacy policy carefully and contact us if you have any
        questions using the contact details set out below.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        1. Definitions and interpretation
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        1.1. In this Privacy Policy, the following definitions are used:
      </div>
      <div>
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td style={cellStyle} className="font-cairo-bold">
                  {item.title}
                </td>
                <td style={cellStyle}>{item.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        1.2. In this Privacy Policy, unless the context requires a different
        interpretation:
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        1.2.1 &nbsp; the singular includes the plural and vice versa;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        1.2.2 &nbsp; references to sub-clauses, clauses, schedules or appendices
        are to sub-clauses, clauses, schedules or appendices of this privacy
        policy;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        1.2.3 &nbsp; a reference to a person includes, insofar as the context
        permits, firms, companies, government entities, trusts and partnerships;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        1.2.4 &nbsp; "including" is understood to mean "including without
        limitation";
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        1.2.5 &nbsp; reference to any statutory provision includes any
        modification or amendment of it;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        1.2.6 &nbsp; the headings and sub-headings do not form part of this
        Privacy Policy.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">2. Scope</div>
      <div className="privacy-policy-description font-cairo-normal">
        2.1. This Privacy Policy applies only to the actions of you and us with
        respect to the use of our App and or Website, or otherwise when you
        register to use our services. It does not extend to any websites,
        forums, apps or other online resources that can be accessed from the App
        or the Website including, but not limited to, any links we may provide
        to social media websites.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        3. Data Collected
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        3.1. We collect personal Data directly from you via the App or website
        or via other communications between us when you make enquiries with us
        or engage with our services. We may collect the following Data, from
        you:
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        3.1.1 &nbsp; Your personal details, such as your name, date of birth,
        email address, telephone number (so that we can contact you where we are
        lawfully able to do so in accordance with this policy and identify you
        when you set up an account with us)
        <span className="font-cairo-bold"> (Contact Data);</span>
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        3.1.2 &nbsp; Information relating to your use of the App or Website to
        help it function correctly, details of games you enter, your usage
        preferences, the mobile platform or service provider you use and your
        browser
        <span className="font-cairo-bold"> (Functional Data);</span>
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        3.1.3 &nbsp; Usage information to enable us to tailor our services to
        you and improve our services generally, including information about how
        you interact with the App, the Website and our services, your IP address
        and unique devices identifiers and the time and date of your usage of
        the App and or Website
        <span className="font-cairo-bold"> (Usage Data);</span>
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        3.1.4 &nbsp; Banking and financial details to enable you to use our
        services and buy tokens, which may include your bank account and card
        details and other information via Paypal, and other similar platforms
        <span className="font-cairo-bold"> (Financial Data);</span>
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        3.1.5 &nbsp; Your account login details, such as your username and
        password (it is your responsibility to keep these safe)
        <span className="font-cairo-bold"> (Account Data)</span>
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        3.1.6 &nbsp; In addition, we may monitor your use of the Website through
        the use of cookies and similar tracking devices
        <span className="font-cairo-bold"> (Cookie Data)</span>. For example, we
        may monitor how many times you visit, which pages you go to, traffic
        data, and the originating domain name of a user's internet service
        provider. This information helps us to build a profile of our users.
        Some (but not all) of this data will be aggregated or statistical, which
        means that we will not be able to identify you individually. Please see
        further the section on
        <span className="font-cairo-bold"> 'Use of cookies'</span>
        below.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        4. Our use of data
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        4.1.For purposes of the GDPR and the DPA 2018, we are the "controller"
        of Data. This means that, under the DPA 2018 and the GDPR, we may
        control and process your personal data in accordance with this policy
        and the law.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        4.2.We will only retain your Data for only so long as is needed in
        accordance with our retention policy. Ordinarily, this is five years
        from the point at which you deactivate your account, unless you consent
        to receive marketing materials, in which case we shall retain your
        Personal Data until such a time as the business ceases to operate, or
        you withdraw your consent to be contacted.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        4.3.Unless we are obliged or permitted by law to do so, and subject to
        any third party disclosures specifically set out in this policy, your
        Data will not be disclosed to third parties, save for the purposes and
        on the occasions identified this policy.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        4.4.All personal Data is stored securely in accordance with the
        principles of the GDPR. For more details on security see the clause
        below (Security).
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        5. Our obligations to you
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        5.1.As the ‘controller’ of your personal information, we will ensure
        that the personal information we hold about you is:
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        5.1.1 &nbsp; used lawfully, fairly and in a transparent way.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        5.1.2 &nbsp; collected only for valid purposes that we have clearly
        explained to you and not used in any way that is incompatible with those
        purposes.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        5.1.3 &nbsp; relevant to the purposes we have told you about and limited
        only to those purposes. accurate and kept up to date.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        5.1.4 &nbsp; accurate and kept up to date.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        5.1.5 &nbsp; kept only as long as necessary for the purposes we have
        told you about.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        5.1.6 &nbsp; kept securely.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        6. Legal basis for use of your Data
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        6.1.We can only process your personal information where we have a legal
        right to do so under the DPA 2018 or the GDPR. Generally, we rely on the
        following grounds:
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        6.1.1 &nbsp; Article 6(1)(a) – processing is conducted with your 
        <span className="font-cairo-bold">consent</span>
         to process personal data for specified purposes
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        6.1.2 &nbsp; Article 6(1)(b) – processing is necessary for the
        performance of our
        <span className="font-cairo-bold"> contract</span>
         to provide services to you, or for preparing to enter into such a
        contract with you
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        6.1.3 &nbsp; Article 6(1)(c) – processing is necessary for us to
        demonstrate
        <span className="font-cairo-bold"> compliance with the law</span>
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        6.1.4 &nbsp; Article 6(1)(f) – to process your personal data in pursuit
        of our 
        <span className="font-cairo-bold">legitimate interests</span>
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        6.2.Below, we have set out the particular types of Data that we collect,
        the reasons for this and the legal basis for doing so:
      </div>
      <div>
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr>
              <th style={cellStyle}>Type of Data</th>
              <th style={cellStyle}>Reasons for Processing</th>
              <th style={cellStyle}>Legal Basis</th>
            </tr>
          </thead>
          <tbody>
            {data6.map((item, index) => (
              <tr key={index}>
                <td style={cellStyle}>{item.title}</td>
                <td style={cellStyle}>{item.description}</td>
                <td style={cellStyle}>{item.legalBasis}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        7. Third party websites and services
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        7.1. We may, from time to time, employ the services of other parties for
        dealing with certain processes necessary for the operation of the App
        and the Website. The providers of such services do not have access to
        certain personal Data provided by Users of the App or Website.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        8. Links to other websites and apps
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        8.1. The App / Website may, from time to time, provide links to websites
        and other apps. We have no control over such websites and apps; we are
        not responsible for the content of these websites or apps. This policy
        does not extend to your use of such websites or apps. You are advised to
        read the Privacy Policy or statement of other websites and apps prior to
        using them. Your use of third party websites and apps is at your own
        risk.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        9. Change of business ownership and control
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        9.1. We may, from time to time, expand or reduce our business and this
        may involve the sale and/or the transfer of control of all or part of
        Champion’s Choice Ltd. Data provided by users will, where it is relevant
        to any part of our business so transferred, be transferred along with
        that part and the new owner or newly controlling party will, under the
        terms of this Privacy Policy, be permitted to use the Data for the
        purposes for which it was originally supplied to us.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        9.2.We may also disclose Data to a prospective purchaser of our business
        or any part of it. This may include where we sell part of our business,
        or our assets, including personal data.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        9.3. In the above instances, we will take reasonable steps with the aim
        of ensuring your privacy is protected.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        10. Direct Marketing
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        10.1. We have a policy of ‘privacy by design’, and your Data will not be
        used for the purposes of direct marketing, unless you specifically
        request to receive such materials. Champion’s Choice Ltd will not share
        your data with third parties without specific consent from you for
        marketing purposes. We will not contact you for the purposes of direct
        marketing unless you have asked us to do so. However, if you have asked
        us to do so and later your change your mind, you can opt-out at any time
        with no hassle. To do this, just let us know. See further 'Your rights'
        below for details about how to contact us.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        11. Transferring Your Information Outside of Europe
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        11.1. We may transfer your information outside of Europe, specifically
        to our developers based in Asia. These transfers are necessary for the
        purposes of maintaining and developing our services.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        11.2. When we transfer your information outside of Europe, we ensure
        that appropriate safeguards are in place to protect your data and comply
        with applicable data protection laws, including the UK GDPR. These
        safeguards include:
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        11.2.1 &nbsp; Utilizing secure data transfer methods, such as
        encryption.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        11.2.2 &nbsp; Implementing contractual clauses that comply with the
        International Data Transfer Agreement (IDTA) , as recognized by the UK
        Information Commissioner’s Office (ICO).
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        11.2.3 &nbsp; Ensuring that our partners and developers adhere to strict
        data protection and security standards, such as ISO 27001 certification.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        11.3. If you would like more information about the specific safeguards
        we apply to the transfer of your personal data outside Europe, please
        contact us at{" "}
        <a href="mailto:support@championschoice.co.uk" className="link-style">
          support@championschoice.co.uk
        </a>
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        12. Sharing Data with third parties
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        12.1. We will only share your Data with third parties if you have given
        express consent for us to do so or there is a legal basis for doing so.
        In certain circumstances, it may be in our legitimate interests to pass
        your Data to a third party for certain limited reasons. These third
        parties will only process your personal Data on our instructions and we
        will ensure that adequate measures are in place to ensure that your
        personal Data is only processed where it is lawful and secure to do so,
        although we are not directly responsible for the actions and inactions
        of such third parties. This may include:
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        12.1.1 &nbsp; Our website and app provider to enable us and them to
        deliver their service to us, carry out analysis and research on
        demographics, interests and behaviour of our users and supporters to
        help us gain a better understanding of them to enable us to improve our
        services. This may include connecting Data we receive from you to Data
        available from other sources. Your personally identifiable Data will
        only be used where it is necessary for the analysis required, and where
        your interests for privacy are not deemed to outweigh their legitimate
        interests in developing new services for us.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        12.1.2 &nbsp; Our professional advisors and agents for the purposes of
        advising and representing us in any matter connected with your account
        or your use of the App or our Website upon which we legitimately
        consider that advice or representation is needed.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        12.1.3 &nbsp; We share transaction data with our payment provider
        <span className="font-cairo-bold"> TrueLayer </span>
        and other similar platforms (including Financial Data), but only to the
        extent necessary for the purposes of processing your payments, refunding
        such payments and dealing with complaints and queries relating to such
        payments and refunds.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        12.1.4 &nbsp; Law enforcement agencies including government or
        regulatory bodies, lawful authorities, or other authorised third parties
        in order to comply with laws, regulations, court orders, or other legal
        obligations or to assist in an investigation, to protect and defend our
        rights and property, or the rights or safety of third parties, to
        enforce our Terms of Use, this Privacy Policy, or agreements with third
        parties, or for crime-prevention purposes.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        12.1.5 &nbsp; Affiliates and Partners. This includes companies within
        our group in order to improve services and for advertising and marketing
        purposes but also includes our business partners for the purposes of
        improving, promoting or personalising our services
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        12.1.6 &nbsp; Advertising agencies. Where you have consented to us doing
        so, we may use your personal Data for the purposes of providing targeted
        advertising to you. This may include the sale of your Data and
        disclosing your Data to companies involved in interest-based
        advertising, including web, mobile app and other advertising. This
        advertising consists of third party ads that are personalised and
        displayed on our Website and Apps and through other channels.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        12.1.7 &nbsp; Prize Providers. Where we offer a prize, being a product,
        experience or service, provided by a third party, we any need to provide
        that third party with your contact details so that they can provide the
        prize to you and communicate with you about any matter concerning the
        prize. This is necessary to enable us to perform our contract with you.
        If you win a prize, and there is a reason why you do not want us to pass
        your details to the third party prize provider, you have the right to
        object to us providing such details to the prize provider, but where you
        do so this may lead to you waiving your right to receive the prize. For
        more information, please see the section below headed
        <span className="font-cairo-bold"> Your rights.</span>
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        13. Functionality of the App and Website
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        13.1. It is important that the information we hold about you is accurate
        in order for us to comply with our legal obligations and to provide you
        with the best possible service. We therefore ask that your personal Data
        is up to date at all times. You may update your personal Data from
        within your account in the App, or on our Website, or by using the
        contact details set out in this Privacy Policy. <br />
         To use all features and functions available on the Website, you may be
        required to submit certain Data, particularly Cookie Data. You may
        restrict your internet browser's use of Cookies. For more information
        see the clause below
        <span className="font-cairo-bold"> (Cookies).</span>
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        14. Your rights
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        14.1. You have the right to ask for a copy of any of your personal Data
        held by us (where such Data is held), as is your right to access under
        GDPR and the DPA 2018. There will be no charge for this provision
        (except where requests are repetitious or unreasonable), and we will
        provide the data within 30 days of receipt of your request.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        14.2. You have various rights under the GDPR and the DPA 2018, including
        the following rights:
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.1 &nbsp;
        <span className="font-cairo-bold">Right to object: </span>
        If we are using your data because we deem it necessary for our
        legitimate interests to do so, and you do not agree, you have the right
        to object. We will respond to your request within 30 days (although we
        may be allowed to extend this period in certain cases). Generally, we
        will only disagree with you if certain limited conditions apply.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.2 &nbsp;
        <span className="font-cairo-bold">Right to withdraw consent: </span>
        Where we have obtained your consent to process your personal data for
        certain activities (for example, providing you with services), or
        consent to market to you, you may withdraw your consent at any time.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.3 &nbsp;
        <span className="font-cairo-bold">
          Data Subject Access Requests (DSAR): 
        </span>
        Just so it's clear, you have the right to ask us to confirm what
        information we hold about you at any time, and you may ask us to modify,
        update or delete such information. At this point we may comply with your
        request or, additionally do one of the following:
      </div>
      <div className="privacy-policy-subdescription1 font-cairo-normal">
        14.2.3.1 &nbsp; we may ask you to verify your identity, or ask for more
        information about your request; and
      </div>
      <div className="privacy-policy-subdescription1 font-cairo-normal">
        14.2.3.2 &nbsp; where we are legally permitted to do so, we may decline
        your request, but we will explain why if we do so.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.4 &nbsp;
        <span className="font-cairo-bold">Right to erasure: </span>
        In certain situations (for example, where we have processed your data
        unlawfully), you have the right to request us to "erase" your personal
        data. We will respond to your request within 30 days (although we may be
        allowed to extend this period in certain cases) and will only disagree
        with you if certain limited conditions apply.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.5 &nbsp;
        <span className="font-cairo-bold">Right of data portability:</span>
         If you wish, you have the right to transfer your data from us to
        another data controller. We will help with this – either by directly
        transferring your data for you, or by providing you with a copy in a
        commonly used machine-readable format.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        14.2.6 &nbsp;
        <span className="font-cairo-bold">
          Right to lodge a complaint with a supervisory authority:
        </span>
         You also have the right to lodge a complaint with your local
        supervisory authority, details of which can be found below.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        14.3. Any requests to exercise any rights in connection with your Data,
        must be made in writing to the Data Protection Officer through our
        postal address, or by email to support@championschoice.co.uk
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        14.4. We may ask you to provide us with proof of your identity. Please
        do not be offended; this may occur even if we know you. It is a
        requirement of the GDPR or DPA 2018 in some cases.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">15. Security</div>
      <div className="privacy-policy-description font-cairo-normal">
        15.1. Data security is of great importance to us, and to protect your
        Data we have put in place suitable physical, electronic and managerial
        procedures to safeguard and secure Data collected via our App or
        Website. To use all features and functions available on the App and
        Website, you may be required to submit certain Data.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        15.2.If password access is required for certain parts of the App or
        Website, you are responsible for keeping your password and other Account
        Data confidential.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        15.3. We endeavour to do our best to protect your personal Data.
        However, transmission of information over the internet, or via wifi, is
        not entirely secure and is done at your own risk. We cannot ensure the
        security of your Data transmitted to the App and your use of the App and
        Website is generally at your own risk.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">16. Cookies</div>
      <div className="privacy-policy-description font-cairo-normal">
        16.1. Our Website may place and access certain Cookies on your computer.
        We use Cookies to improve your experience of using the Website and to
        improve our range of services. We have carefully chosen these Cookies
        and has taken steps to ensure that your privacy is protected and
        respected at all times.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        16.2. All Cookies used by the Website are used in accordance with
        current and applicable UK and EU laws.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        16.3. Before the Website places Cookies on your computer, you will be
        presented with a pop-up requesting your consent to set those Cookies. By
        giving your consent to the placing of Cookies, you are enabling us to
        provide a better experience and service to you. You may, if you wish,
        deny consent to the placing of Cookies; however certain features of the
        Website may not function fully or as intended.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        16.4. This Website may place the following Cookies:
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        16.4.1 &nbsp;
        <span className="font-cairo-bold">Strictly necessary cookies:</span>
        These are Cookies that are required for the operation of the Website.
        They include, for example, Cookies that enable you to log into secure
        areas of the Website, if any.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        16.4.2 &nbsp;
        <span className="font-cairo-bold">Analytical/performance cookies:</span>
        They allow us to recognise and count the number of visitors and to see
        how visitors move around our website when they are using it. This helps
        us to improve the way our Website works, for example, by ensuring that
        users are finding what they are looking for easily.
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        16.4.3 &nbsp;
        <span className="font-cairo-bold">Functionality cookies:</span>
        These are used to recognise you when you return to our Website. This
        enables us to personalise our content for you, greet you by name and
        remember your preferences (for example, your choice of language or
        region), although these features may not be available all the time.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        16.5. You can choose to enable or disable Cookies in your internet
        browser. By default, most internet browsers accept Cookies, but this can
        be changed. For further details, please consult the help menu in your
        internet browser.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        16.6. You can choose to delete Cookies at any time; however, you may
        lose any information that enables you to access the Website more quickly
        and efficiently including, but not limited to, personalisation settings.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        16.7. It is recommended that you ensure that your internet browser is
        up-to-date and that you consult the help and guidance provided by the
        developer of your internet browser if you are unsure about adjusting
        your privacy settings.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">17. Children</div>
      <div className="privacy-policy-description font-cairo-normal">
        17.1. We do not knowingly collect personal Data online or through the
        App from persons under 18. If you become aware that a person under the
        age of 18 has provided us with Personal Data without parental consent,
        please contact us using the details below. If we become aware that a
        child under 18 has provided us with personal Data without parental
        consent, we will take steps to remove the Data and cancel any relevant
        account.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">18. General</div>
      <div className="privacy-policy-description font-cairo-normal">
        18.1. You may not transfer any of your rights under this Privacy Policy
        to any other person. We may transfer our rights under this Privacy
        Policy where we reasonably believe your rights will not be affected.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        18.2. If any court or competent authority finds that any provision of
        this Privacy Policy (or part of any provision) is invalid, illegal or
        unenforceable, that provision or part-provision will, to the extent
        required, be deemed to be deleted, and the validity and enforceability
        of the other provisions of this privacy policy will not be affected.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        18.3. Unless otherwise agreed, no delay, act or omission by a party in
        exercising any right or remedy will be deemed a waiver of that, or any
        other, right or remedy.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        18.4. This Privacy Policy will be governed by and interpreted according
        to the law of England. All disputes arising under the Agreement will be
        subject to the exclusive jurisdiction of the English courts.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        19. Changes to this Privacy Policy
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        19.1. We reserve the right to change this Privacy Policy as we may deem
        necessary from time to time or as may be required by law. Any changes
        will be immediately posted on the Website, and updated on the App, and
        you are deemed to have accepted the terms of the privacy policy on your
        first use of the Website or App following the alterations.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        20. Automated decision making
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        20.1.We may, from time to time, use systems to make automated decisions
        based on your personal Data where we are operating a live prize draw or
        a similar event that supports automated decision making. This helps us
        to make sure our decisions are quick and fair.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        20.2.We use automated decision making in the following situations:
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        20.2.1 &nbsp;
        <span className="font-cairo-bold">Detecting fraud:</span>
        we use your personal information to help decide and detect if your
        account may be being used for fraud or money-laundering. If we think
        there is a risk of fraud, we may block or suspend the account;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        20.2.2 &nbsp;
        <span className="font-cairo-bold">Opening an account:</span>
        when you open an account with us, we check that the product or service
        is relevant for you, based on what we know. We also check that you meet
        the conditions needed to open the account.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        20.3.Data protection law seeks to safeguard individuals against harm
        that may arise from decision-making - including profiling - that takes
        place without human intervention. You have the right not to be subject
        to a decision when it is based on the automated processing of your
        personal information and it has a legal effect or a similarly
        significant effect on you.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        20.4.Please note that the right does not apply when the processing is:
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        20.4.1 &nbsp; necessary for entering into or for the performance of a
        contract with you;
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        20.4.2 &nbsp; when it is authorised by law; or
      </div>
      <div className="privacy-policy-subdescription font-cairo-normal">
        20.4.3 &nbsp; when it is based on your explicit consent.
      </div>
      <div className="privacy-policy-subhead font-cairo-bold">
        21. Contact Details
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        21.1.If you are not satisfied with our response or believe we are
        processing your personal data not in accordance with the law you can
        complain to the Information Commissioner’s Office (ICO) by contacting
        them on 0303 123 1113 or visiting https://ico.org.uk/.
      </div>
      <div className="privacy-policy-description font-cairo-normal">
        21.2.Our Data Protection Officer is Philippe Paoli and you can contact
        them at philippepaoli@championschoice.co.uk or by writing to: 3rd floor
        – 86-89 Paul Street, London EC2A 4NE, London, England
      </div>
    </div>
  );
};

export default PrivacyPolicy;
