import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import PastTable from "./PastTable/PastTable";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getURL, setNavItem } from "../../../utils/localStorage";
import { FilledButton } from "../../commonComponents";
import OngoingTable from "./OngoingTable/OngoingTable";
import PrivateGamesTable from "./PrivateGames/PrivateGames";
import { useScreenWidth } from "../../../utils/useScreenWidth";
import { useMyContext } from "../../../helpers/Context/MyContext";
import {
  getPrivateGameByLink,
  getPrivateGames,
  getPastGames,
  getOngoingGames,
} from "../../../utils/service";

import "./Dashboard.scss";

const Dashboard = () => {
  const [privateGameTableData, setPrivateGameTableData] = useState();
  const [pastTableData, setPastTableData] = useState([]);
  const [ongoingTableData, setOngoingTableData] = useState();
  const [loading, setLoading] = useState(true);
  const screenWidth = useScreenWidth(true);
  const privatelink = getURL();
  const [searchParams] = useSearchParams();
  const private_game_token = searchParams.get("private_token");
  const { contextData, setContextData } = useMyContext();
  const navigate = useNavigate();
  const userData = contextData?.userData;
  const handlePrivateGames = async () => {
    const response = await getPrivateGames();
    if (private_game_token) {
      const resp = await getPrivateGameByLink(private_game_token);
      const combinedArray = [...response?.game, ...resp?.game];
      const modifiedGameData = combinedArray?.map((game) => ({
        ...game,
        key: game.id,
      }));
      setPrivateGameTableData(modifiedGameData);
    } else {
      if (response) {
        const modifiedGameData = response?.game?.map((game) => ({
          ...game,
          key: game.id,
        }));
        setPrivateGameTableData(modifiedGameData);
      }
    }
  };
  const handlePastGamesResponse = async () => {
    const response = await getPastGames();
    if (response?.success) {
      setPastTableData(response?.past_games);
    }
  };
  const handleOngoingResponse = async () => {
    setLoading(true);
    setOngoingTableData([]);
    const response = await getOngoingGames();
    if (response) {
      const modifiedGameData = response?.data?.map((game) => ({
        ...game,
        key: game?.id,
      }));
      setOngoingTableData(modifiedGameData);
      setContextData((prevState) => ({
        ...prevState,
        onGoingGames: response?.data?.length,
      }));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (privatelink) {
      const sectionElement = document.getElementById("targetSectionId");
      window.scrollTo({
        top: sectionElement?.offsetTop,
        behavior: "smooth",
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [privatelink]);
  useEffect(() => {
    handlePrivateGames();
    handlePastGamesResponse();
    handleOngoingResponse();
    setNavItem(3);
  }, []);

  return (
    <div className="white-color">
      <div className="dashboard">
        <div className="dashboard-title font-cairo-extrabold">
          Champion's Dashboard
        </div>
        <div className="dashboard-ongoing">
          <div className="dashboard-ongoing-title font-cairo-extrabold">
            Ongoing Games
          </div>
          {ongoingTableData?.length > 0 ? (
            <div
              className={`${
                screenWidth >= 767 && "dashboard-ongoing-cont"
              } background-blur`}
            >
              <OngoingTable
                ongoingTableData={ongoingTableData}
                getOngoingGames={handleOngoingResponse}
              />
            </div>
          ) : loading ? (
            <div className="spinner-loading font-cairo-medium">
              <Spin className="loader" />
            </div>
          ) : (
            <div className="dashboard-ongoing-nodata font-cairo-medium">
              You currently have no active games. Ready for another round? Dive
              into the excitement by exploring our range of games using the link
              below and embark on your next Champion’s journey!
            </div>
          )}
        </div>
        {privateGameTableData?.length > 0 && (
          <div className="dashboard-ongoing">
            <div
              className="dashboard-past-title font-cairo-extrabold"
              id="targetSectionId"
            >
              Private Games
            </div>
            <div
              className={`${
                screenWidth >= 767 && "dashboard-ongoing-cont"
              } background-blur`}
            >
              <PrivateGamesTable privateGameTableData={privateGameTableData} />
            </div>
          </div>
        )}
        {pastTableData?.length > 0 && (
          <div className="dashboard-past">
            <div className="dashboard-past-title font-cairo-extrabold">
              Past Games
            </div>
            <div
              className={`${
                screenWidth >= 767 && "dashboard-past-cont"
              } background-blur`}
            >
              <PastTable pastTableData={pastTableData} />
            </div>
          </div>
        )}
        <div className="dashboard-button">
          <FilledButton
            name={"Explore Other Games"}
            btnClass="primary-background"
            type={1}
            functionCall={() => {
              setNavItem(1);
              navigate("/game");
            }}
          />
        </div>
        <div className="dashboard-past"></div>
      </div>
    </div>
  );
};

export default Dashboard;
