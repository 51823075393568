import React, { useEffect, useState } from "react";
import { Dropdown, Spin, Select } from "antd";
import VsIcon from "../../../assets/images/vs-icon.svg";
import { getHeadToHeadData } from "../../../utils/service";
import "./Matches.scss";

const Matches = ({ leagueStandings = [] }) => {
  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState([]);
  const [comparisonData, setComparisonData] = useState();
  const [teamOne, setTeamOne] = useState({
    key: "",
    name: "",
    logo: "",
  });
  const [teamTwo, setTeamTwo] = useState({
    key: "",
    name: "",
    logo: "",
  });
  const [headToHeadData, setHeadToHeadData] = useState([]);
  const [teamsPlayed, setTeamsPlayed] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleTeamOne = (key) => {
    setTeamTwo({
      key: "",
      name: "",
      logo: "",
    });
    const foundObject = leagueStandings.find(
      (item) => item.team_id == key.key
    );
    setTeamOne((prevState) => ({
      ...prevState,
      key: key.key,
      name: foundObject?.team_name,
      logo: foundObject?.team_picture,
    }));
    setComparisonData()
  };

  const handleTeamTwo = (value) => {
    const foundObject = leagueStandings.find((item) => item?.team_id == value);
    setTeamTwo((prevState) => ({
      ...prevState,
      key: value,
      name: foundObject?.team_name,
      logo: foundObject?.team_picture,
    }));
    setIsOpen(false);
  };

  const handleComparisonData = async () => {
    if (teamOne.key && teamTwo.key) {
      const res = await getHeadToHeadData(`${teamOne.key}/${teamTwo.key}`);
      if (res?.data) {
        setComparisonData(res.data);
        setTeamsPlayed(null);
      } else {
        setTeamsPlayed(res);
        setComparisonData(null);
      }
    }
  };

  useEffect(() => {
    if (Array.isArray(leagueStandings) && leagueStandings.length > 0) {
      const newArray = leagueStandings
        .map((item) => ({
          key: item.team_id,
          label: item.team_name,
          logo: item.team_picture,
        }))
        .sort((a, b) => {
          const labelA = a.label.toUpperCase();
          const labelB = b.label.toUpperCase();
          if (labelA < labelB) return -1;
          if (labelA > labelB) return 1;
          return 0;
        });
      setItems(newArray);
      setItems2(newArray);
    }
  }, [leagueStandings]);

  useEffect(() => {
    if (Array.isArray(leagueStandings)) {
      const newArray = leagueStandings
        .map((item) => ({
          key: item.team_id,
          label: (
            <div style={{ opacity: item.team_id == teamOne.key ? "0.5" : "1" }}>
              {item.team_name}
            </div>
          ),
          value: item.team_id,
          logo: item.team_picture,
          disabled: item.team_id == teamOne.key,
        }))
        .filter((item) => item.key !== teamTwo.key); // Filter out selected teamTwo
      setItems2(newArray);
    }
  }, [teamOne, teamTwo, leagueStandings]);

  useEffect(() => {
    if (teamOne.key && teamTwo.key) {
      handleComparisonData();
    }
  }, [teamOne, teamTwo]);

  useEffect(() => {
    if (comparisonData) {
      setHeadToHeadData([
        {
          name: "Wins",
          teamA: comparisonData.wins[teamOne.key],
          teamB: comparisonData.wins[teamTwo.key],
        },
        {
          name: "Lost",
          teamA: comparisonData.losses[teamOne.key],
          teamB: comparisonData.losses[teamTwo.key],
        },
        {
          name: "Tied",
          teamA: comparisonData.draws[teamOne.key],
          teamB: comparisonData.draws[teamTwo.key],
        },
        {
          name: "Goals",
          teamA: comparisonData.goals[teamOne.key],
          teamB: comparisonData.goals[teamTwo.key],
        },
        {
          name: "Clean Sheets",
          teamA: comparisonData.clean_sheets[teamOne.key],
          teamB: comparisonData.clean_sheets[teamTwo.key],
        },
      ]);
    }
  }, [comparisonData]);

  return (
    <div className="matches black-color">
      <div className="matches-teams">
        <div className="matches-teams-line"></div>
        <Dropdown
          menu={{
            onClick: handleTeamOne,
            items,
          }}
          overlayClassName="matches-teams-dropdown"
          onBlur={() => setIsOpen(false)}
        >
          {teamOne.logo === "" ? (
            <div className="teamLogo">
              <h1 className="pickTeam">Pick team</h1>
            </div>
          ) : (
            <div className="team-icon">
              <img
                src={teamOne.logo}
                alt="Team One Icon"
                className="image-cover-full"
              />
            </div>
          )}
        </Dropdown>
        <div className="matches-teams-line"></div>
        <div>
          <div className="matches-teams-vs primary-background">
            <img src={VsIcon} alt="VS Icon" className="matches-teams-vs-icon" />
          </div>
        </div>
        <div className="matches-teams-line"></div>
        {teamTwo.logo === "" ? (
          <Select
            options={items2}
            placeholder={
              <div className="second-teamLogo">
                <h1 className="pickTeam">Pick team</h1>
              </div>
            }
            onChange={handleTeamTwo}
            popupClassName="matches-teams-second-menu"
            className="matches-teams-second"
            open={isOpen}
            onDropdownVisibleChange={(open) => setIsOpen(open)}
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
          />
        ) : (
          <div
            className="team-icon"
            onClick={() => setIsOpen(true)} // Open dropdown on click
          >
            <img
            onClick={() => setIsOpen(true)}
              src={teamTwo.logo}
              alt="Team Two Icon"
              className="image-cover-full"
            />
          </div>
        )}
        <div className="matches-teams-line"></div>
      </div>
      {comparisonData ? (
        <div className="matches-details">
          <div className="matches-details-cont">
            <div className="matches-details-cont-numbers primary-color font-cairo-bold">
              {comparisonData.total_matches[teamOne.key]}
            </div>
            <div className="matches-details-cont-text font-cairo-normal">
              Total Matches
            </div>
          </div>
          <div className="matches-details-title font-cairo-medium">
            <div>{teamOne.name}</div>
            <div>{teamTwo.name}</div>
          </div>
          <div className="matches-details-players">
            {headToHeadData.map((item, index) => (
              <div
                className="matches-details-players-items font-cairo-normal"
                key={index}
              >
                <div className="matches-details-players-items-num1 text-start">
                  {item.teamA}
                </div>
                <div className="matches-details-players-lines">
                  <div className="matches-details-players-items-line"></div>
                  <div className="matches-details-players-items-name">
                    {item.name}
                  </div>
                  <div className="matches-details-players-items-line"></div>
                </div>
                <div className="matches-details-players-items-num2 text-end">
                  {item.teamB}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : !teamsPlayed?.teams_played ? (
        <div className="matches-cont-error font-cairo-medium">
          {teamsPlayed?.teams_played === false && (
            <div>
              {teamOne.name} and {teamTwo.name} have not yet faced each other
              in a European Championship match
            </div>
          )}
        </div>
      ) : (
        (teamOne.key === "" || teamTwo.key === "") &&
        teamOne.logo !== "" && (
          <div className="spinner-loading font-cairo-medium">
            <Spin className="loader" />
          </div>
        )
      )}
    </div>
  );
};

export default Matches;
