import { useEffect, useState } from "react";
import ExpandedDetails from "../ExpandedDetails";
import { CountTimer } from "../../../commonComponents";
import { BorderedButton } from "../../../commonComponents";
import { getToken } from "../../../../utils/localStorage";
import start from "../../../../assets/images/sort_icon.png";
import filter from "../../../../assets/images/Filter-icon.svg";
import { useScreenWidth } from "../../../../utils/useScreenWidth";
import { Table, Popover, Checkbox, Slider, Radio, Spin } from "antd";
import PremierLeagueIcon from "../../../../assets/images/premier_league.svg";
import { ReactComponent as FootballIcon } from "../../../../assets/images/football-icon.svg";
import { ReactComponent as BasketBallIcon } from "../../../../assets/images/basketball_icon.svg";
import {
  getPublicGamesForUser,
  getPublicGamesForPublic,
  getPublicGamesForUserFilter,
  gameLeaguesData,
} from "../../../../utils/service";
import "./GameTable.scss";
import { Link } from "react-router-dom";

const stakeCheckboxOptions = [
  { label: "Sort Lowest to Highest", value: "low_to_high" },
  { label: "Sort Highest to Lowest", value: "high_to_low" },
];
const potCheckboxOptions = [
  { label: "Lowest - Highest", value: "low_to_high" },
  { label: "Highest - Lowest", value: "high_to_low" },
];
const deadlineCheckboxOptions = [
  { label: "Earliest - Latest", value: "latest" },
  { label: "Latest - Earliest", value: "oldest" },
];

const GameTable = () => {
  const [expandedRowsKey, setExpandedRowsKey] = useState([]);
  const [expandOnMobile, setExpandOnMobile] = useState({
    key: null,
    expand: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [gameTableData, setGameTableData] = useState();
  const [filterData, setFilterData] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [leaguesResponse, setLeaguesResponse] = useState();
  const screenWidth = useScreenWidth();
  const isUser = getToken();

  const handleSportsCheckbox = (checkedValues) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      sports: checkedValues,
    }));
  };
  const handleCompetitonsCheckbox = (checkedValues) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      competition: checkedValues,
    }));
  };
  const handleStakeValue = (e) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      stake: e.target.value,
    }));
  };
  const handlePotValue = (e) => {
    setFilterData((prevFilterData) => {
      const newFilterData = { ...prevFilterData };
      if ("stake" in newFilterData) {
        delete newFilterData.stake;
      }
      newFilterData.pot = e.target.value;
      return newFilterData;
    });
  };
  const handleDeadlineValue = (e) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      deadline: e.target.value,
    }));
  };
  const getGameTableData = async () => {
    if (isUser) {
      const response = await getPublicGamesForUser(currentPage);
      const modifiedGameData = response?.games?.map((game) => ({
        ...game,
        key: game.id,
      }));
      setGameTableData(modifiedGameData);
    } else {
      const response = await getPublicGamesForPublic(currentPage);
      const modifiedGameData = response?.data?.games?.map((game) => ({
        ...game,
        key: game.id,
      }));
      setGameTableData(modifiedGameData);
    }
  };

  const expandedRowRender = () => {
    return <ExpandedDetails expandedRowsKey={expandedRowsKey} />;
  };
  const handleMobileExpand = (key, value) => {
    setExpandOnMobile({
      key: key,
      expand: value,
    });
    if (value) {
      const matchedData = gameTableData?.find((item) => item.id === key);
      setExpandedRowsKey(matchedData);
    } else {
      setExpandedRowsKey([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleStakeSlider = (value) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      stake_range: value,
    }));
  };
  const handleAfterStakeSlider = (value) => {
    if (value == 0) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
      }));
    } else {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        stake_range: value.toString(),
      }));
    }
  };
  const competitionsContent = (
    <div>
      <Checkbox.Group
        options={leaguesResponse}
        onChange={handleCompetitonsCheckbox}
        className="game-table-checkbox"
      />
    </div>
  );
  const sportsContent = (
    <div>
      <Checkbox.Group
        onChange={handleSportsCheckbox}
        className="game-table-checkbox"
      >
        <Checkbox
          value="football"
          className="game-table-sports-checkboxes-item"
        >
          {/* <FootballIcon className="game-table-sports-checkboxes-image iconFootbll" /> */}
          Football
        </Checkbox>
        <Checkbox
          value="basketball"
          className="game-table-sports-checkboxes-item"
        >
          {/* <BasketBallIcon className="game-table-sports-checkboxes-image iconFootbll" /> */}
          Basketball
        </Checkbox>
      </Checkbox.Group>
    </div>
  );
  const deadlineContent = (
    <Radio.Group
      options={deadlineCheckboxOptions}
      onChange={handleDeadlineValue}
      className="game-table-stakeradio"
    />
  );
  const potContent = (
    <Radio.Group
      options={potCheckboxOptions}
      onChange={handlePotValue}
      className="game-table-stakeradio"
    />
  );
  const marks = {
    0: {
      style: {
        color: "#fff",
      },
      label: "£0",
    },
    500: {
      style: {
        color: "#fff",
      },
      label: <strong>£500+</strong>,
    },
  };
  const stakeContent = (
    <div>
      <Slider
        defaultValue={0}
        marks={marks}
        step={10}
        max={500}
        onChange={handleStakeSlider}
        onAfterChange={handleAfterStakeSlider}
        className="game-table-stake-slider"
      />
      <Radio.Group
        options={stakeCheckboxOptions}
        onChange={handleStakeValue}
        className="game-table-stakeradio"
      />
    </div>
  );
  const columns = [
    {
      title: "Game Ref",
      dataIndex: "title",
      width: "120px",
      render: (_, record) => {
        return (
          <div>
            {record.is_turbo && (
              <div className="game-table-turbotag font-cairo-bold">Turbo</div>
            )}
            <div>{record.title}</div>
          </div>
        );
      },
    },
    {
      title: (
        <Popover
          overlayClassName="game-table-popover-menu game-table-sports"
          content={sportsContent}
          placement="bottom"
          trigger={["click"]}
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            Sport
            <img className="filterStyle" src={filter} />
          </div>
        </Popover>
      ),
      dataIndex: "sport_id",
      render: (_, record) => {
        return (
          <div className="game-table-sports-cont">
            {record.sport_name.toLowerCase() == "football" ? (
              <FootballIcon className="game-table-sports-cont-icon" />
            ) : (
              <BasketBallIcon className="game-table-sports-cont-icon" />
            )}
            {record.sport_name}
          </div>
        );
      },
    },
    {
      title: (
        <Popover
          content={competitionsContent}
          placement="bottom"
          trigger={["click"]}
          overlayClassName="game-table-popover-menu game-table-competitions"
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            League
            <img className="filterStyle" src={filter} />
          </div>
        </Popover>
      ),
      dataIndex: "competitions",
      render: (_, record) => {
        return (
          <div className="game-table-competitions-items">
            <div className="game-table-competitions-items-image">
              <img
                src={
                  record.season_id == 23614
                    ? PremierLeagueIcon
                    : record.league_image
                }
                alt="League Icon"
                className="game-table-competitions-items-icon"
                style={{ width: record?.sport_id == 5 ? "17px" : "34px" }}
              />
            </div>

            <div>{record.league_name}</div>
          </div>
        );
      },
    },
    {
      title: (
        <Popover
          overlayClassName="game-table-popover-menu game-table-stake"
          content={stakeContent}
          placement="bottom"
          trigger={["click"]}
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            Stake
            <img className="filterStyle" src={filter} alt="Filter Icon" />
          </div>
        </Popover>
      ),
      dataIndex: "stake",
      render: (_, record) => {
        return (
          <div>
            {record.stake == "0.0" ? "Free*" : `£${Math.floor(record.stake)}`}
          </div>
        );
      },
    },
    {
      title: (
        <Popover
          overlayClassName="game-table-popover-menu game-table-deadline"
          content={deadlineContent}
          placement="bottom"
          trigger={["click"]}
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            Starts In
            <img className="filterStyle" src={start} alt="Filter Icon" />
          </div>
        </Popover>
      ),
      dataIndex: "start_date",
      render: (_, record) => {
        return <CountTimer record={record.start_date} />;
      },
    },
    {
      title: (
        <Popover
          overlayClassName="game-table-popover-menu game-table-pot"
          content={potContent}
          placement="bottom"
          trigger={["click"]}
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            Pot
            <img className="filterStyle" src={start} alt="Filter Icon" />
          </div>
        </Popover>
      ),
      dataIndex: "pot",
      render: (_, record) => {
        return <div>£{Math.floor(record.pot)}</div>;
      },
    },
    {
      title: "",
      dataIndex: "key",
      key: "key",
      render: (_, record) => {
        return (
          <div onClick={() => handleExpandToggle(record.key, record.is_joined)}>
            <BorderedButton
              name={record?.is_joined ? "Game Joined" : "Join Game"}
              link={"#"}
              btnHeight={"37px"}
              btnClass={"game-table-action-button"}
            />
          </div>
        );
      },
    },
  ];
  const handleExpandToggle = (key, is_joined) => {
    const isRowExpanded = expandedRowKeys.includes(key);
    if (isRowExpanded || is_joined) {
      setExpandedRowKeys(expandedRowKeys.filter((k) => k !== key));
      setExpandedRowsKey([]);
    } else {
      setExpandedRowKeys([...expandedRowKeys, key]);
      const matchedData = gameTableData?.find((item) => item.id === key);
      setExpandedRowsKey(matchedData);
    }
  };
  const handleFilteredGameData = async () => {
    const queryParams = Object.entries(filterData)
      .filter(([key, value]) => {
        return (
          (Array.isArray(value) && value.length > 0) ||
          (typeof value === "string" && value !== "")
        );
      })
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const encodedArray = value
            .map((v) => `${encodeURIComponent(key)}[]=${encodeURIComponent(v)}`)
            .join("&");
          return encodedArray;
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join("&");
    const response = await getPublicGamesForUserFilter(
      currentPage,
      queryParams
    );
    if (response.games) {
      const modifiedGameData = response?.games?.map((game) => ({
        ...game,
        key: game.id,
      }));
      setGameTableData(modifiedGameData);
    }
  };
  const getLeaguesData = async () => {
    if (!filterData?.sports || filterData?.sports.length === 2) {
      const res = await gameLeaguesData(``);
      const leaguesName = res?.data?.data?.map((item) => {
        return item.name;
      });
      setLeaguesResponse(leaguesName);
    } else {
      const res = await gameLeaguesData(`?sport_id=${filterData?.sports[0]}`);
      const leaguesName = res?.data?.data?.map((item) => {
        return item.name;
      });
      setLeaguesResponse(leaguesName);
    }
  };
  useEffect(() => {
    getGameTableData();
    getLeaguesData();
  }, [currentPage]);

  useEffect(() => {
    if (filterData) {
      handleFilteredGameData();
      getLeaguesData();
    }
  }, [filterData]);

  return (
    <>
      {gameTableData?.length > 0 ? (
        <>
          <div
            className={`${
              screenWidth >= 992 && "game-table container-border"
            } position-relative background-blur`}
          >
            {screenWidth >= 992 ? (
              <Table
                columns={columns}
                dataSource={gameTableData}
                expandable={{
                  expandedRowRender,
                  rowExpandable: (record) => !record.is_joined, // Prevent row expansion if game is joined
                  expandedRowKeys: expandedRowKeys,
                  onExpand: handleExpandToggle,
                }}
                onRow={(record) => ({
                  onClick: () => {
                    const newExpandedRowKeys = expandedRowKeys.includes(
                      record.key
                    )
                      ? []
                      : [record.key];
                    setExpandedRowKeys(newExpandedRowKeys);
                  },
                })}
                pagination={false}
                // pagination={{
                //   current: currentPage,
                //   total: gameTableData?.total_records,
                //   onChange: handlePageChange,
                // }}
              />
            ) : (
              <>
                <div className="popOverContainerMain">
                  <div className="popOverContainer">
                    <div style={{ width: "29%" }}>
                      <Popover
                        overlayClassName="game-table-popover-menu game-table-sports"
                        content={sportsContent}
                        placement="bottomLeft"
                        trigger={["click"]}
                        autoAdjustOverflow={false}
                      >
                        <div
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          Sport
                          <img
                            className="filterStyle"
                            src={filter}
                            alt="Filter Icon"
                          />
                        </div>
                      </Popover>
                    </div>
                    <div style={{ width: "39%" }}>
                      <Popover
                        content={competitionsContent}
                        placement="bottomLeft"
                        trigger={["click"]}
                        overlayClassName="game-table-popover-menu game-table-competitions"
                        autoAdjustOverflow={false}
                      >
                        <div
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          League
                          <img
                            className="filterStyle"
                            src={filter}
                            alt="Filter Icon"
                          />
                        </div>
                      </Popover>
                    </div>
                    <div style={{ width: "29%" }}>
                      <Popover
                        overlayClassName="game-table-popover-menu game-table-stake"
                        content={stakeContent}
                        placement="left"
                        trigger={["click"]}
                        autoAdjustOverflow={false}
                      >
                        <div
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          Stake
                          <img
                            className="filterStyle"
                            src={filter}
                            alt="Filter Icon"
                          />
                        </div>
                      </Popover>
                    </div>
                  </div>
                  <div className="popOverSub">
                    <div style={{ width: "49%" }}>
                      <Popover
                        overlayClassName="game-table-popover-menu game-table-deadline"
                        content={deadlineContent}
                        placement="bottomLeft"
                        trigger={["click"]}
                      >
                        <div
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          Starts In
                          <img
                            className="filterStyle"
                            src={start}
                            alt="Filter Icon"
                          />
                        </div>
                      </Popover>
                    </div>
                    <div style={{ width: "49%" }}>
                      <Popover
                        overlayClassName="game-table-popover-menu game-table-pot"
                        content={potContent}
                        placement="bottomLeft"
                        trigger={["click"]}
                      >
                        <div
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          Pot
                          <img
                            className="filterStyle"
                            src={start}
                            alt="Filter Icon"
                          />
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
                <div className="gametable-mobile popOverMai">
                  {gameTableData?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="gametable-mobile-title font-cairo-bold"
                          key={index + 1}
                        >
                          {item.title}
                        </div>
                        <div className="gametable-mobile-cont" key={index}>
                          <div className="gametable-mobile-cont-items">
                            {item?.is_turbo && (
                              <div className="game-table-turbotag font-cairo-bold">
                                Turbo
                              </div>
                            )}
                            <div className="gametable-mobile-cont-items-title">
                              League
                            </div>
                            <div className="gametable-mobile-cont-items-icon">
                              <img
                                src={
                                  item.season_id == 23614
                                    ? PremierLeagueIcon
                                    : item.league_image
                                }
                                alt="League Icon"
                                className="image-cover-full"
                              />
                            </div>
                            <div>{item.league_name}</div>
                          </div>
                          <div className="gametable-mobile-cont-items">
                            <div className="gametable-mobile-cont-items-title">
                              Sport
                            </div>
                            <div>{item.sport_name}</div>
                          </div>
                          <div className="gametable-mobile-cont-items">
                            <div className="gametable-mobile-cont-items-title">
                              Stake
                            </div>
                            {/* <div>£{Math.floor(item.stake)}</div>
                             */}
                            <div>
                              {item.stake == "0.0"
                                ? "Free*"
                                : `£${Math.floor(item.stake)}`}
                            </div>
                          </div>
                          <div className="gametable-mobile-cont-items">
                            <div className="gametable-mobile-cont-items-title">
                              Start In
                            </div>
                            <CountTimer record={item.start_date} />
                          </div>
                          <div className="gametable-mobile-cont-items">
                            <div className="gametable-mobile-cont-items-title">
                              Pot
                            </div>
                            <div>£{Math.floor(item.pot)}</div>
                          </div>
                          {item.is_joined ? (
                            <div className="gametable-mobile-cont-items justify-center">
                              <BorderedButton
                                name={"Game Joined"}
                                link={"#"}
                                btnHeight={"54px"}
                                btnClass={"gametable-mobile-button"}
                              />
                            </div>
                          ) : (
                            <div
                              className="gametable-mobile-cont-items justify-center"
                              onClick={() =>
                                handleMobileExpand(
                                  item.id,
                                  !expandOnMobile.expand
                                )
                              }
                            >
                              <BorderedButton
                                name={"Join Game"}
                                link={"#"}
                                btnHeight={"54px"}
                                btnClass={"gametable-mobile-button"}
                              />
                            </div>
                          )}
                        </div>
                        {expandOnMobile.key == item.key &&
                          expandOnMobile.expand && (
                            <ExpandedDetails
                              expandedRowsKey={expandedRowsKey}
                            />
                          )}
                      </>
                    );
                  })}
                </div>
              </>
            )}
            <div className="game-table-freetext font-cairo-medium">
              *Participation is restricted to UK residents aged 18 and above who
              have had their account verified on Champion’s Choice and have a
              valid UK bank account.See full T&Cs{" "}
              <Link
                to={"/termsandconditions"}
                className="link-style grey-color"
              >
                here
              </Link>
            </div>
          </div>
        </>
      ) : gameTableData?.length == 0 ? (
        <div className="game-table-nodata font-cairo-medium">
          No Future Games Available Right Now! Why not start your own game?
          Create a private one, invite your mates, and let the thrilling
          showdown begin!
        </div>
      ) : (
        <div className="spinner-loading font-cairo-medium">
          <Spin className="loader" />
        </div>
      )}
    </>
  );
};
export default GameTable;
