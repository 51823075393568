import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import EditIcon from "../../../../assets/images/edit.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./MyPreferences.scss";

const myPreferencesSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .test("valid-phone", "Invalid phone number", (value) => {
      try {
        const phoneNumber = parsePhoneNumber(value);
        return phoneNumber && phoneNumber.isValid();
      } catch (error) {
        return false;
      }
    }),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string().required("Password is required"),
});
const MyPreferences = ({
  userProfileData,
  setUserProfileData,
  userUpdatedData,
  setUserUpdatedData,
  setValidationErrors,
  validationErrors,
}) => {
  const [notificationButton, setNotificationButton] = useState();
  const [textMessagesButton, setTextMessagesButton] = useState();
  const [loading, setLoading] = useState(false);
  const [emailbutton, setEmailbutton] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [isValid, setIsValid] = useState(true);

  const validateEmail = (e) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isEmailValid = emailRegex.test(e.target.value);
    if (!isEmailValid) {
      setErrorMessage("Invalid email address");
      setValidationErrors({
        ...validationErrors,
        email: "Invalid email address",
      });
      setIsValid(false);
    } else {
      setErrorMessage("");
      setValidationErrors({
        ...validationErrors,
        email: "",
      });
      setIsValid(true);
    }
  };

  const handleFormSubmit = async (field, values) => {
    // setLoading(true);
    // const updatedValues = {
    //   ...userUpdatedData,
    //   [field]: values,
    // };
    // const updatedProfileValues = {
    //   ...userProfileData,
    //   [field]: values,
    // };
    // setUserProfileData(updatedProfileValues);
    // setUserUpdatedData(updatedValues);
  };
  const handleInputChange = async (field, e, form) => {
    if (field == "email") {
      validateEmail(e);
    } else if (field == "phone") {
      try {
        await myPreferencesSchema.validateAt("phoneNumber", {
          ["phoneNumber"]: e,
        });
        // No validation error, clear previous error message if any
        form.setFieldError("phoneNumber", "");
        setValidationErrors({
          ...validationErrors,
          phone: "",
        });
      } catch (error) {
        // Validation error, set the error message
        setValidationErrors({
          ...validationErrors,
          phone: "Invalid phone number",
        });
        form.setFieldError("phoneNumber", error.message);
      }
    }

    const updatedValues = {
      ...userUpdatedData,
      [field]: field == "phone" ? e : e.target.value,
    };
    const updatedProfileValues = {
      ...userProfileData,
      [field]: field == "phone" ? e : e.target.value,
    };
    // setUserProfileData(updatedProfileValues);
    setUserUpdatedData(updatedValues);
  };
  useEffect(() => {
    if (userProfileData) {
      setNotificationButton(userProfileData?.notification);
      setEmailbutton(userProfileData?.email_notification);
      setTextMessagesButton(userProfileData?.text_messages);
    }
  }, [userProfileData]);

  return (
    <div className="my-preferences">
      <div className="my-preferences-title font-cairo-medium">
        My Preferences
      </div>
      <div className="my-preferences-bottom">
        <div className="my-preferences-bottom-header font-cairo-medium">
          External Communication
          <div className="my-preferences-bottom-line"></div>
        </div>
        <div className="my-preferences-bottom-content">
          <div className="my-preferences-bottom-content-left">
            <div className="my-preferences-bottom-div">
              <div className="font-cairo-medium">Notifications</div>
              <div className="my-preferences-buttons">
                <div
                  className={`${
                    notificationButton
                      ? "my-preferences-buttons-on"
                      : "my-preferences-buttons-off"
                  }`}
                  onClick={() => {
                    setNotificationButton(true);
                    handleFormSubmit("notification", true);
                  }}
                >
                  On
                </div>
                <div
                  className={`${
                    !notificationButton
                      ? "my-preferences-buttons-on"
                      : "my-preferences-buttons-off"
                  }`}
                  onClick={() => {
                    handleFormSubmit("notification", false);
                    setNotificationButton(false);
                  }}
                >
                  Off
                </div>
              </div>
            </div>
            <div className="my-preferences-bottom-div">
              <div className="font-cairo-medium">Text Messages</div>
              <div className="my-preferences-buttons">
                <div
                  className={`${
                    textMessagesButton
                      ? "my-preferences-buttons-on"
                      : "my-preferences-buttons-off"
                  }`}
                  onClick={() => {
                    setTextMessagesButton(true);
                    handleFormSubmit("text_messages", true);
                  }}
                >
                  On
                </div>
                <div
                  className={`${
                    !textMessagesButton
                      ? "my-preferences-buttons-on"
                      : "my-preferences-buttons-off"
                  }`}
                  onClick={() => {
                    setTextMessagesButton(false);
                    handleFormSubmit("text_messages", false);
                  }}
                >
                  Off
                </div>
              </div>
            </div>
            <div className="my-preferences-bottom-div">
              <div className="font-cairo-medium">Email</div>
              <div className="my-preferences-buttons">
                <div
                  className={`${
                    emailbutton
                      ? "my-preferences-buttons-on"
                      : "my-preferences-buttons-off"
                  }`}
                  onClick={() => {
                    setEmailbutton(true);
                    handleFormSubmit("email_notification", true);
                  }}
                >
                  On
                </div>
                <div
                  className={`${
                    !emailbutton
                      ? "my-preferences-buttons-on"
                      : "my-preferences-buttons-off"
                  }`}
                  onClick={() => {
                    setEmailbutton(false);
                    handleFormSubmit("email_notification", false);
                  }}
                >
                  Off
                </div>
              </div>
            </div>
          </div>
          <div className="my-preferences-bottom-content-right">
            {userProfileData ? (
              <Formik
                initialValues={{
                  phoneNumber: userProfileData?.phone,
                  email: userProfileData ? userProfileData.email : "",
                }}
                validationSchema={myPreferencesSchema}
                onSubmit={handleFormSubmit}
                validateOnChange={true}
                validateOnBlur={true}
              >
                <Form>
                  {textMessagesButton && (
                    <div className="my-preferences-fields-cont position-relative">
                      <div className="my-preferences-fields-cont-items">
                        <label
                          htmlFor="phoneNumber"
                          className="my-preferences-field-label font-cairo-medium"
                        >
                          Phone No.
                        </label>
                        <Field name="phoneNumber">
                          {({ field, form }) => (
                            <PhoneInput
                              containerStyle={{ backgroundColor: "#000" }}
                              textInputStyle={{ color: "#fff" }}
                              {...field}
                              id="phoneNumber"
                              value={userProfileData?.phone}
                              onChange={(value) => {
                                handleInputChange("phone", value, form);
                                form.setFieldValue("phoneNumber", value);
                              }}
                              className="my-preferences-phone-field phone-number-dropdown font-cairo-normal"
                            />
                          )}
                        </Field>
                        <div className="my-preferences-edit-icon">
                          <img src={EditIcon} alt="Edit Icon" />
                        </div>
                      </div>

                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="error-message error-padding"
                      />
                    </div>
                  )}
                  {emailbutton && (
                    <div className="my-preferences-fields-cont position-relative">
                      <div className="my-preferences-fields-cont-items">
                        <label
                          htmlFor="email"
                          className="my-preferences-field-label font-cairo-medium"
                        >
                          Email
                        </label>
                        <Field
                          type="email"
                          value={userProfileData ? userProfileData.email : ""}
                          id="email"
                          name="email"
                          className="my-preferences-input-field font-cairo-normal extra-padding"
                          placeholder="johnkenedy@gmail.com"
                          onChange={(e) => handleInputChange("email", e)}
                        />
                        <div className="my-preferences-edit-icon">
                          <img src={EditIcon} alt="Edit Icon" />
                        </div>
                      </div>
                      {/* <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message error-padding"
                        /> */}
                      {!isValid && (
                        <div className="error-message">{errorMessage}</div>
                      )}
                    </div>
                  )}
                  {/* <div className="my-preferences-fields-cont position-relative">
                      <div className="my-preferences-fields-cont-items">
                        <label
                          htmlFor="password"
                          className="my-preferences-field-label font-cairo-medium"
                        >
                          Password
                        </label>
                        <Field
                          type={togglePassword ? "text" : "password"}
                          id="password"
                          name="password"
                          className="my-preferences-input-field font-cairo-normal"
                          placeholder="••••••••"
                        ></Field>
                        <div className="my-preferences-edit-icon">
                          <img src={EditIcon} alt="Edit Icon" />
                        </div>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error-message error-padding"
                      />
                    </div> */}
                </Form>
              </Formik>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPreferences;
