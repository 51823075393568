import { sendEventsToConversionAPI } from "../utils/service";
import ReactGA from "react-ga4";

export const sendMetaEvents = async (event_title) => {
  const res = await sendEventsToConversionAPI(event_title);
};

export const sendGoogleAnalyticsEvents = (eventDetails) => {
  ReactGA.event({
    ...eventDetails,
  });
};

export const checkEnvironemnt = () => {
  const URL =
    process.env.REACT_APP_CUSTOM_ENV === "production"
      ? "https://api.championschoice.co.uk"
      : "https://api-stag.championschoice.co.uk";
  return URL;
};
