import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { InternalNavbar, Footer } from "../commonComponents";
import "./Layout.scss";

const MainLayout = ({ children }) => {
  const [backgroundImageUrl, setBackgroundImageUrl] =
    useState("main-background1");
  const location = useLocation();
  const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setBackgroundImageLoaded(true);
  };
  useEffect(() => {
    setBackgroundImageLoaded(false); // Reset to false when route changes
    if (location.pathname === "/" || location.pathname === "/insights") {
      setBackgroundImageUrl("main-background1");
    } else if (
      location.pathname === "/game" ||
      location.pathname === "/dashboard" ||
      location.pathname === "/privategame"
    ) {
      setBackgroundImageUrl("main-background2");
    } else {
      setBackgroundImageUrl("main-background3");
    }
  }, [location.pathname]);

  return (
    <div className={`white-color ${backgroundImageUrl}`} id="scrollToTop">
      <InternalNavbar />
      <div
        className={`background-placeholder ${
          backgroundImageLoaded ? "loaded" : ""
        }`}
      ></div>
      {children}
      <Footer />
      <img
        src={backgroundImageUrl}
        alt="Background"
        style={{ display: "none" }}
        onLoad={handleImageLoad}
      />
    </div>
  );
};

export default MainLayout;
