import React from "react";
import { Modal } from "antd";
import "./RuleExample.scss";

const RuleExample = ({ visible, closeModal, title, descriptions }) => {
  return (
    <>
      <Modal
        open={visible}
        title={title}
        onCancel={closeModal}
        wrapClassName="rule-modal"
        className="rule-modal-inner"
        footer={null}
        maskStyle={{
          backdropFilter: "blur(5px)",
        }}
      >
        <div className="rule-modal-details font-cairo-medium">
          {descriptions}
        </div>
      </Modal>
    </>
  );
};
export default RuleExample;
