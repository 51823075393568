import React, { useEffect, useState } from "react";
import { useCountdown } from "../../../helpers/timer";
import "./PromoCounter.scss";

const PromoCounter = (props) => {
  const firstTimestamp = new Date(props.record).getTime();
  const now = new Date().getTime();

  // Convert the event start dates to timestamps and sort them
  const eventTimestamps =
    props?.eventDate &&
    props?.eventDate
      .map((event) => ({
        start: new Date(event.start_date).getTime(),
        event: event,
      }))
      .filter((event) => event.start > now) // Only consider future events
      .sort((a, b) => a.start - b.start); // Sort the events by start date

  const secondTimestamp =
    eventTimestamps && eventTimestamps.length > 0
      ? eventTimestamps[0].start
      : null;

  const [activeTimestamp, setActiveTimestamp] = useState(firstTimestamp);
  const [days, hours, minutes, seconds] = useCountdown(activeTimestamp);

  const checkTime = () => {
    const now = new Date().getTime();
    // If current time exceeds the first timestamp
    if (now >= firstTimestamp) {
      // If there is a second event, switch to it
      if (secondTimestamp && now < secondTimestamp) {
        setActiveTimestamp(secondTimestamp);
      } else {
        setActiveTimestamp(null); // No more future events
      }
    }
  };

  useEffect(() => {
    checkTime();
    const intervalId = setInterval(checkTime, 1000);
    return () => clearInterval(intervalId);
  }, [firstTimestamp, secondTimestamp, activeTimestamp]);

  return (
    <div className="banner-subtext">
      {props?.screenWidth > 756 && <div>Starts in </div>}
      <>
        &nbsp;
        <span className="time-unit">
          {activeTimestamp !== null ? Math.max(days, 0) : 0}
        </span>{" "}
        Days &nbsp;
        <span className="time-unit">
          {activeTimestamp !== null ? Math.max(hours, 0) : 0}
        </span>{" "}
        Hrs &nbsp;
        <span className="time-unit">
          {activeTimestamp !== null ? Math.max(minutes, 0) : 0}
        </span>{" "}
        Min &nbsp;
        <span className="time-unit">
          {activeTimestamp !== null ? Math.max(seconds, 0) : 0}
        </span>{" "}
        Sec
      </>
    </div>
  );
};

export default PromoCounter;
