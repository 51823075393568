import { toast } from "react-toastify";
import CustomToastWrapper from "../components/commonComponents/Toaster/CustomToast"; // Ensure the correct path

const notify = (message, type) => {
  const options = {
    position: "top-center",
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
    closeButton: false,
  };

  // if (type === "success") {
    options.autoClose = 2000;
  // } else {
  //   options.autoClose = false;
  // }

  toast(
    (props) => (
      <CustomToastWrapper
        closeToast={props.closeToast}
        message={message}
        type={type}
      />
    ),
    options
  );
};

export default notify;
