import React, { useEffect, useState } from "react";
import { Select } from "antd";
import "./UserDepositLimit.scss";

const dropdown1Options = [
  { value: "-1", label: "No Limit" },
  { value: "10", label: "£ 10" },
  { value: "20", label: "£ 20" },
  { value: "30", label: "£ 30" },
  { value: "40", label: "£ 40" },
  { value: "50", label: "£ 50" },
  { value: "100", label: "£ 100" },
  { value: "200", label: "£ 200" },
  { value: "500", label: "£ 500" },
  { value: "1000", label: "£ 1000" },
  { value: "5000", label: "£ 5,000" },
  { value: "10000", label: "£ 10,000" },
];

const UserDepositLimit = ({
  userProfileData,
  setUserProfileData,
  userUpdatedData,
  setUserUpdatedData,
  title,
}) => {
  const [dailyValue, setDailylValue] = useState();
  const [weeklyValue, setWeeklylValue] = useState();
  const [monthlyValue, setMonthlylValue] = useState();

  const handleDailylChange = (value) => {
    let updatedValues;
    if (
      userUpdatedData?.deposit_limit_daily ||
      userUpdatedData?.deposit_limit_weekly ||
      userUpdatedData?.deposit_limit_monthly
    ) {
      updatedValues = {
        ...userUpdatedData,
        deposit_limit_daily: value,
      };
    } else {
      updatedValues = {
        deposit_limit_daily: value,
      };
    }
    setUserUpdatedData(updatedValues);
    setDailylValue(value);
  };
  const handleWeeklylChange = (value) => {
    let updatedValues;
    if (
      userUpdatedData?.deposit_limit_daily ||
      userUpdatedData?.deposit_limit_weekly ||
      userUpdatedData?.deposit_limit_monthly
    ) {
      updatedValues = {
        ...userUpdatedData,
        deposit_limit_weekly: value,
      };
    } else {
      updatedValues = {
        deposit_limit_weekly: value,
      };
    }
    setUserUpdatedData(updatedValues);
    setWeeklylValue(value);
  };
  const handleMonthlylChange = (value) => {
    let updatedValues;
    if (
      userUpdatedData?.deposit_limit_daily ||
      userUpdatedData?.deposit_limit_weekly ||
      userUpdatedData?.deposit_limit_monthly
    ) {
      updatedValues = {
        ...userUpdatedData,
        deposit_limit_monthly: value,
      };
    } else {
      updatedValues = {
        deposit_limit_monthly: value,
      };
    }
    setUserUpdatedData(updatedValues);
    setMonthlylValue(value);
    // if (value >= weeklyValue) {
    //   const updatedValues = {
    //     ...userUpdatedData,
    //     deposit_limit_monthly: value,
    //   };
    //   setUserUpdatedData(updatedValues);
    // } else {
    //   toast.warn("Your monthly limit should be greater than weekly limit", {
    //     position: "top-center",
    //     autoClose: 3000,
    //   });
    // }
    // setMonthlylValue(value);
  };
  useEffect(() => {
    if (userProfileData) {
      setDailylValue(
        userProfileData?.deposit_limit_daily != 0 &&
          userProfileData?.deposit_limit_daily != "No Limit"
          ? `£ ${userProfileData?.deposit_limit_daily}`
          : "No Limit"
      );
      setWeeklylValue(
        userProfileData?.deposit_limit_weekly != 0 &&
          userProfileData?.deposit_limit_weekly != "No Limit"
          ? `£ ${userProfileData?.deposit_limit_weekly}`
          : "No Limit"
      );
      setMonthlylValue(
        userProfileData?.deposit_limit_monthly != 0 &&
          userProfileData?.deposit_limit_monthly != "No Limit"
          ? `£ ${userProfileData?.deposit_limit_monthly}`
          : "No Limit"
      );
    }
  }, [userProfileData]);
  

  return (
    <div className="deposit-limits">
      <div className="deposit-limits-text font-cairo-medium">{title}</div>
      <div className="deposit-limits-description font-cairo-normal">
        Set your deposit limits with Champion’s Choice to manage your spending
        effectively.
        <br /> Choose daily, weekly, or monthly limits to ensure responsible
        gaming.
      </div>
      <div className="deposit-limits-title font-cairo-medium">
        Your Current Limits:
      </div>
      <div className="deposit-limits-dropdowns">
        <div>
          <div className="deposit-limits-dropdowns-label font-cairo-medium">
            Daily
          </div>
          <Select
            value={dailyValue}
            options={dropdown1Options}
            onChange={handleDailylChange}
            className="deposit-limits-dropdown-filter"
            popupClassName="dropdown-popup-style deposit-limits-dropdown-filter-popupmenu"
            placement="bottomRight"
            onKeyDown={(e) => e.preventDefault()}
            onPopupScroll={(e) => e.preventDefault()}
          />
        </div>
        <div>
          <div className="deposit-limits-dropdowns-label font-cairo-medium">
            Weekly
          </div>
          <Select
            value={weeklyValue}
            options={dropdown1Options}
            onChange={handleWeeklylChange}
            className="deposit-limits-dropdown-filter"
            popupClassName="dropdown-popup-style deposit-limits-dropdown-filter-popupmenu"
            placement="bottomRight"
          />
        </div>
        <div>
          <div className="deposit-limits-dropdowns-label font-cairo-medium">
            Monthly
          </div>
          <Select
            value={monthlyValue}
            options={dropdown1Options}
            onChange={handleMonthlylChange}
            className="deposit-limits-dropdown-filter"
            popupClassName="dropdown-popup-style deposit-limits-dropdown-filter-popupmenu"
            placement="bottomRight"
          />
        </div>
      </div>
    </div>
  );
};

export default UserDepositLimit;
