import React, { useState, useEffect } from "react";
import { Drawer, Space } from "antd";
import { useNavigate, Link } from "react-router-dom";
import FilledButton from "../FilledButton/FilledButton";
import Logo from "../../../assets/images/auths/logo.svg";
import HamburgIcon from "../../../assets/images/hamburg-icon.svg";
import { setNavItem, getNavItem } from "../../../utils/localStorage";
import "./ExternalNavbar.scss";

const navData = [
  {
    name: "Home",
    link: "/",
    key: 0,
  },
  {
    name: "Games",
    link: "/game",
    key: 1,
  },
  {
    name: "Insights",
    link: "/insights",
    key: 2,
  },
  {
    name: "Dashboard",
    link: "#",
    key: 3,
  },
  {
    name: "Rules",
    key: 4,
    link: "/rules",
  },
];
const ExternalNavbar = () => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("top");
  const navItem = getNavItem();

  const showDrawer = () => {
    setOpen(true);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  const onClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const handleLogin = () => {
    setNavItem(null);
    navigate("/login");
  };
  const handleSignup = () => {
    setNavItem(null);
    navigate("/signup");
  };
  const handlePopup = () => {
    navigate("/login");
  };
  useEffect(() => {
    if (!navItem) {
      setNavItem(0);
    }
  }, []);

  return (
    <nav className="navigation-bar">
      <div className="externallogo">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="externalnav-main">
        <ul className="nav-links">
          {navData.map((item, index) => {
            return (
              <li
                key={index}
                className="externalnav-links-list"
                onClick={index == 3 ? handlePopup : null}
              >
                <Link
                  to={item.link}
                  className="link-style externalnav-links-list-item"
                  onClick={() => setNavItem(item.key)}
                >
                  {item.name}
                </Link>
                {navItem == item.key && (
                  <div className="nav-links-below-line"></div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="right-side">
        <button
          className="externalnav-signup-button black-background"
          onClick={handleSignup}
        >
          <div className="button-text">Sign up</div>
        </button>
        <FilledButton
          name={"Login"}
          id="loginBtn"
          type={4}
          functionCall={handleLogin}
          btnClass={"externalnav-login-button"}
        />
      </div>
      <div className="external-hamburg">
        <div onClick={showDrawer}>
          <img src={HamburgIcon} alt="Hamburg Icon" />
        </div>
        <Drawer
          placement={placement}
          width={500}
          onClose={onClose}
          className="external-mobile-drawer"
          open={open}
          extra={
            <Space>
              <button
                className="mobile-signup-button black-background"
                onClick={handleSignup}
              >
                <div className="exbutton-text">Sign Up</div>
              </button>
              <FilledButton
                name={"Login"}
                type={4}
                functionCall={handleLogin}
                btnClass={"externalnav-login-button"}
              />
            </Space>
          }
        >
          <div className="externalnav-mobile">
            <ul className="mobile-nav-links">
              {navData?.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="mobile-links-list"
                    onClick={index == 3 ? handlePopup : null}
                  >
                    <Link
                      to={item.link}
                      className="link-style externalnav-links-list-item"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </Drawer>
      </div>
    </nav>
  );
};

export default ExternalNavbar;
