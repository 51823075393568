import React from "react";
import { useCountdown } from "../../../helpers/timer";
import "./CountTimer.scss";

const CountTimer = (props) => {
  const [days, hours, minutes] = useCountdown(props.record);

  return (
    <>
      {props.type === 2 ? (
        <div className="counttimer-inprogress">
          {minutes < 0 ? (
            <div className="font-cairo-bold primary-color">
              Round in progress
            </div>
          ) : (
            <div className="font-cairo-bold primary-color counttimer-remaining">
              <span className="white-color font-cairo-normal counttimer-remaining-text">
                Round selections close in:
              </span>
              {days}d  {hours}h  {minutes}m
            </div>
          )}
        </div>
      ) : (
        <div className="counttimer-deadline-content">
          <div className="counttimer-deadline-content-cont">
            <div className="counttimer-deadline-content-cont-timer">{days}</div>
            <div className="counttimer-deadline-content-cont-label">Days</div>
          </div>
          <div className="counttimer-deadline-content-cont">
            <div className="counttimer-deadline-content-cont-timer">
              {hours}
            </div>
            <div className="counttimer-deadline-content-cont-label">Hours</div>
          </div>
          <div className="counttimer-deadline-content-cont">
            <div className="counttimer-deadline-content-cont-timer">
              {minutes}
            </div>
            <div className="counttimer-deadline-content-cont-label">
              Minutes
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export { CountTimer };
