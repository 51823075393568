import React, { useState } from "react";
import * as Yup from "yup";
import { Spin } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { forgetPassword } from "../../../utils/service";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ReactComponent as ErrorIcon } from "../../../assets/images/error-icon.svg";
import { FilledButton } from "../../commonComponents";
import notify from "../../../utils/notificationService"
const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Email is invalid").required("Email is required"),
});

const ForgetPasswordForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  const handleFormSubmit = async (values) => {
    setLoading(true);
    var data = new FormData();
    const URL = window.location.origin;
    data.append("email", values.email);
    data.append("redirect_url", `${URL}/resetpassword`);
    const res = await forgetPassword(data);
    if (res.status === 200) {
      setLoading(false);
      notify("A link to reset your password has been sent to your inbox.","success")
      setTimeout(() => {
        navigate("/login");
      }, 4000);
    } else if (!res.success) {
      notify(res?.errors[0],"error")
      setLoading(false);
    } else {
      console.log("Failed==>", res);
    }
  };
  return (
    <div>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={handleFormSubmit}
      >
        <Form>
          <div className="forgetpassword-fields-cont aligncontent-baseline">
            <Field
              type="email"
              id="email"
              name="email"
              className="login-input-field no-outline"
              placeholder="Email Address"
            />
            <ErrorMessage name="email" component="div">
              {(msg) => (
                <div className="error-message">
                  <ErrorIcon className="error-icon-margin" />
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div>
          <div className="forget-password-text">
            <Link to={"/login"} className="link-style white-color ">
              Back to Login
            </Link>
          </div>
          <div className="forgetpassword-button-cont">
            <FilledButton
              name={
                loading ? (
                  <div className="auth-loading">
                    <Spin className="loader" />
                  </div>
                ) : (
                  "Submit"
                )
              }
              btnClass="primary-background"
              type={5}
              btnType={"submit"}
            ></FilledButton>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ForgetPasswordForm;
