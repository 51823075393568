import React, { useEffect, useState } from "react";
import { Tabs, Spin } from "antd";
import TimeOut from "./TimeOut/TimeOut";
import SelfExclusion from "./SelfExclusion/SelfExclusion";
import AccountClosure from "./AccountClosure/AccountClosure";
import { useScreenWidth } from "../../../utils/useScreenWidth";
import { userGamblingData, updateSaferGambling } from "../../../utils/service";
import {
  BorderedButton,
  SimpleFilledButton,
  UserDepositLimit,
} from "../../commonComponents";
import DepositAlert from "../../modals/DepositAlert/DepositAlert";
import notify from "../../../utils/notificationService"
import "./SaferGambling.scss";

const SaferGambling = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [userProfileData, setUserProfileData] = useState();
  const [userUpdatedData, setUserUpdatedData] = useState();
  const [depositAlertText, setDepositAlertText] = useState("");
  const [depositAlertTitle, setDepositAlertTitle] = useState("");
  const [isDepositAlertOpen, setDepositAlertOpen] = useState(false);
  const screenWidth = useScreenWidth();

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const tabItems = [
    {
      label: "Deposit Limits",
      key: 1,
      children: (
        <UserDepositLimit
          userProfileData={userProfileData}
          setUserProfileData={setUserProfileData}
          userUpdatedData={userUpdatedData}
          setUserUpdatedData={setUserUpdatedData}
          title={"Deposit Limits"}
        />
      ),
    },
    {
      label: "Time Out",
      key: 2,
      children: (
        <TimeOut
          userProfileData={userProfileData}
          setUserProfileData={setUserProfileData}
          userUpdatedData={userUpdatedData}
          setUserUpdatedData={setUserUpdatedData}
        />
      ),
    },
    {
      label: "Self-Exclusion",
      key: 3,
      children: (
        <SelfExclusion
          userProfileData={userProfileData}
          setUserProfileData={setUserProfileData}
          userUpdatedData={userUpdatedData}
          setUserUpdatedData={setUserUpdatedData}
        />
      ),
    },
    {
      label: "Account Closure",
      key: 4,
      children: (
        <AccountClosure
          userProfileData={userProfileData}
          setUserProfileData={setUserProfileData}
          userUpdatedData={userUpdatedData}
          setUserUpdatedData={setUserUpdatedData}
        />
      ),
    },
  ];

  const openDepositAlertModal = () => {
    setDepositAlertOpen(true);
  };
  const closeDepositAlertModal = () => {
    setDepositAlertOpen(false);
  };
  const getGamblingData = async () => {
    const res = await userGamblingData();
    setUserProfileData(res?.data);
  };

  const handleUpdateSaferGambling = async () => {
    setLoading(true);
    const response = await updateSaferGambling(userUpdatedData);
    if (response?.success) {
      if (userUpdatedData?.deposit_limit_daily) {
        if (response.not_first) {
          openDepositAlertModal();
          setDepositAlertTitle("Deposit Limit Increase Notice");
        } else {
          notify(response?.message,"success")
        }
      } else if (userUpdatedData?.time_out_period) {
        setDepositAlertTitle("Time-Out Period Active");
        // setDepositAlertTitle("Deposit Limit Increase Notice");
        openDepositAlertModal();
      }

      getGamblingData();
      setUserUpdatedData();
      setLoading(false);
      setDepositAlertText(response.message);
    } else {
      notify(response?.message,"error")
      setLoading(false);
    }
  };
  useEffect(() => {
    getGamblingData();
    window.scrollTo(0, 0);
  }, []);
  const heightStylesMobile = {
    height:
      activeTab === 1
        ? "620px"
        : activeTab === 2
        ? "445px"
        : activeTab === 3
        ? "670px"
        : "630px",
  };
  const heightStyles = {
    height:
      activeTab === 1
        ? "540px"
        : activeTab === 2
        ? "360px"
        : activeTab === 3 && "510px",
  };

  return (
    <div className="safer-gambling">
      <DepositAlert
        visible={isDepositAlertOpen}
        closeModal={closeDepositAlertModal}
        description={depositAlertText}
        title={depositAlertTitle}
      />
      <div className="safer-gambling-title font-cairo-extrabold">
        Safer Gambling
      </div>
      <div
        className="safer-gambling-cont background-blur"
        style={screenWidth <= 767 ? heightStylesMobile : heightStyles}
      >
        <Tabs
          activeKey={activeTab}
          onChange={handleTabChange}
          items={tabItems}
          className="safer-gambling-cont-tab font-cairo-normal"
        />
      </div>
      <div className="safer-gambling-buttons">
        <SimpleFilledButton
          name={"Cancel"}
          btnClass="safer-gambling-buttons-cancel font-cairo-medium"
          functionCall={getGamblingData}
        />
        <BorderedButton
          name={
            loading ? (
              <div className="auth-loading">
                <Spin className="loader" />
              </div>
            ) : activeTab === 1 ? (
              "Save My Limits"
            ) : activeTab === 2 ? (
              "Confirm Time Out"
            ) : activeTab === 3 ? (
              "Confirm Self-Exclusion"
            ) : (
              "Confirm Account Closure"
            )
          }
          btnClass="safer-gambling-buttons-confirm font-cairo-medium"
          type={7}
          functionCall={handleUpdateSaferGambling}
        />
      </div>
    </div>
  );
};

export default SaferGambling;
