import React from "react";
import { Spin } from "antd";

const Loading = () => {
  return (
    <div className="spinner-loading font-cairo-medium">
      <Spin className="loader" />
    </div>
  );
};

export default Loading;
