import React, { useState, useEffect } from "react";
import DeleteIcon from "../../../assets/images/delete-icon.svg";
import { useScreenWidth } from "../../../utils/useScreenWidth";
import { useMyContext } from "../../../helpers/Context/MyContext";
import {
  getAllNotifications,
  deleteNotification,
  allNotificationsSeen,
  userData,
} from "../../../utils/service";
import { Button } from "antd";
import "./Notifications.scss";

const Notifications = () => {
  const screenWidth = useScreenWidth();
  const [notificationsData, setNotificationsData] = useState();
  const { contextData, setContextData } = useMyContext();

  const userAllNotifications = async () => {
    const response = await getAllNotifications();
    if (response) {
      setNotificationsData(response.notifications);
    }
  };
  const handleDeleteNotification = async (id) => {
    const resp = await deleteNotification(id);
    if (resp.data?.success) {
      userAllNotifications();
    }
  };
  const handleDeleteAll = async () => {
    const notificationsId = notificationsData.map((obj) => obj.id);
    const resp = await deleteNotification(notificationsId);
    if (resp.data?.success) {
      userAllNotifications();
    }
  };

  const handleNotificationsSeen = async () => {
    const notificationsId = notificationsData?.map((obj) => obj.id);
    const notificationsObject = {
      notification_ids: notificationsId,
    };
    const resp = await allNotificationsSeen(notificationsObject);
    getUserData();
  };
  const getUserData = async () => {
    const res = await userData();
    setContextData({
      ...contextData,
      userData: res,
    });
  };
  useEffect(() => {
    userAllNotifications();
    if (contextData?.userData?.notification_count > 0) {
      handleNotificationsSeen();
    }
  }, [contextData]);
  return (
    <div className="notifications">
      <div className="notifications-title font-cairo-extrabold">
        Notifications
      </div>
      <div className="notifications-main  background-blur container-border">
        <div className="notifications-cont">
          {notificationsData && notificationsData.length > 0 ? (
            notificationsData.map((item) => {
              const formatedDate = item.time.split(" ");
              return screenWidth > 767 ? (
                <div className="notifications-cont-item" key={item.id}>
                  <div className="notifications-cont-item-details">
                    <div className="notifications-cont-item-details-top">
                      <div className="notifications-cont-item-details-top-title font-cairo-bold">
                        {item.title}
                      </div>
                      <div className="notifications-cont-item-details-top-right font-cairo-normal">
                        <div className="padding-x1">
                          Date:
                          <span className="grey-color"> {formatedDate[0]}</span>
                        </div>
                        <div>
                          Time:
                          <span className="grey-color"> {formatedDate[1]}</span>
                        </div>
                      </div>
                    </div>
                    <div className="notifications-cont-item-details-bottom font-cairo-normal">
                      {item.body}
                    </div>
                  </div>
                  <div
                    className="notifications-cont-item-delete cursor-pointer"
                    onClick={() => handleDeleteNotification([item.id])}
                  >
                    <div className="notifications-cont-item-delete-icon">
                      <img
                        src={DeleteIcon}
                        alt="Delete Icon"
                        className="notifications-cont-item-delete-icon-remove"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="notifications-cont-item" key={item.id}>
                  <div className="notifications-cont-item-cont">
                    <div className="notifications-cont-item-details-top-title font-cairo-bold">
                      {item.title}
                    </div>
                  </div>
                  <div className="notifications-cont-item-details">
                    <div className="notifications-cont-item-details-top"></div>
                    <div className="notifications-cont-item-details-bottom font-cairo-normal">
                      {item.body}
                    </div>
                  </div>
                  <div className="notifications-cont-item-delete">
                    <div className="notifications-cont-item-details-top-right">
                      <div className="padding-x1">
                        Date:
                        <span className="grey-color"> {formatedDate[0]}</span>
                      </div>
                      <div>
                        Time:
                        <span className="grey-color"> {formatedDate[1]}</span>
                      </div>
                    </div>
                    <div
                      className="notifications-cont-item-delete-icon cursor-pointer"
                      onClick={() => handleDeleteNotification(item.id)}
                    >
                      <img
                        src={DeleteIcon}
                        alt="Delete Icon"
                        className="notifications-cont-item-delete-icon-remove"
                      />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="notifications-no-noti">
              You currently have no notifications.
            </div>
          )}
        </div>
        {notificationsData?.length > 0 && (
          <div className="notifications-delete-all">
            <Button
              type="text"
              className="notifications-delete-button font-cairo-bold"
              onClick={handleDeleteAll}
            >
              Delete All
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
