import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ResetPasswordForm from "./ResetPasswordForm";
import { ExternalNavbar } from "../../commonComponents";
import ContactUsModal from "../../modals/ContactUs/ContactUsModal";
import { getToken } from "../../../utils/localStorage";
import "./ResetPassword.scss";

const ResetPassword = () => {
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
  const user_token = getToken();
  const navigate = useNavigate();
  const isAuthenticated = user_token ? true : false;

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/");
  //   }
  // }, []);
  const openContactUsModal = () => {
    setIsContactUsModalOpen(true);
  };

  const closeContactUsModal = () => {
    setIsContactUsModalOpen(false);
  };
  return (
    <div className="black-background white-color padding-bottom2 resetpassword-background">
      <ExternalNavbar />
      <div>
        <div className="resetpassword-title">Reset Your Password</div>
      </div>
      <div className="resetpassword-main-cont background-blur">
        <div className="resetpassword-steps-cont"></div>
        <div className="resetpassword-form-cont">
          <div className="resetpassword-form-title">Enter a new password</div>
          <ResetPasswordForm />
          <div className="resetpassword-already-member">
            Need customer support?
            <Link
              to={"#"}
              className="grey-color link-style-underline"
              onClick={openContactUsModal}
            >
              &nbsp;Contact us
            </Link>
          </div>
        </div>
      </div>
      <ContactUsModal
        visible={isContactUsModalOpen}
        closeModal={closeContactUsModal}
      />
    </div>
  );
};

export default ResetPassword;
