import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import "./DiscountButton.scss";

const DiscountButton = ({
  name,
  link,
  btnHeight,
  btnClass,
  functionCall,
  actualAmount,
  strikeAmount,
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(link);
  };
  return (
    <Button
      className={`simplefilledbutton tranform-skew20 font-cairo-bold ${btnClass} discount-btn`}
      onClick={functionCall}
      style={{ height: btnHeight ? btnHeight : null }}
    >
      {name === "Submit & Pay" ? (
        <div className="remove-transform">
          <p>
            {name} <span className="strike-through">£{strikeAmount}</span> <span className="font-green">£
            {actualAmount}</span>
          </p>
        </div>
      ) : (
        <div className="remove-transform">{name}</div>
      )}
    </Button>
  );
};

export default DiscountButton;
