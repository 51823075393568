import React from "react";
import { Table } from "antd";
import { BorderedButton } from "../../../commonComponents";
import { useScreenWidth } from "../../../../utils/useScreenWidth";
import PremierLeagueIcon from "../../../../assets/images/premier_league.svg";
import "./PastTable.scss";

const PastTable = ({ pastTableData }) => {
  const screenWidth = useScreenWidth();

  const columns = [
    {
      title: "League",
      dataIndex: "competition",
      render: (_, record) => {
        return (
          <div className="ongoing-table-competitions">
            <img
              src={
                record.competition.id == 23614
                  ? PremierLeagueIcon
                  : record.competition.image_path
              }
              alt="League Icon"
              className="ongoing-table-competitions-image"
              style={{
                width: record?.sport_id == 5 ? "14px" : "36px",
                height: record?.sport_id == 5 ? "25px" : "36px",
              }}
            />
            <div>{record.competition.name}</div>
          </div>
        );
      },
    },
    {
      title: "Game Title",
      dataIndex: "game_title",
    },
    {
      title: "Pot",
      dataIndex: "pot",
      render: (_, record) => {
        return <div>£{Math.floor(record.pot)}</div>;
      },
    },
    {
      title: "Round",
      dataIndex: "round",
    },
    {
      title: "My Selections",
      dataIndex: "teams",
      render: (_, record) => {
        return record?.teams?.map((item, index) => {
          return (
            <div key={index + 1}>
              <div className="past-table-selection">
                <img
                  src={item.image}
                  alt="Team Icon"
                  className="ongoing-table-selections-image"
                />
                <div>{item.name}</div>
              </div>
              <div className="ongoing-table-selections-opponent">
                vs {item?.opponent?.name} &nbsp; &nbsp;
                <span className="white-color">
                  {item.opponent?.my_score} -&nbsp;
                  {item.opponent?.score}
                </span>
              </div>
            </div>
          );
        });
      },
    },
    {
      title: "Outcome",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <div className="past-table-conts">
            <div className="past-table-message font-cairo-medium">
              {record?.message}
            </div>
            <div>
              <BorderedButton
                name={"View All Selections"}
                link={"/dashboard/allselections"}
                btnHeight={"45px"}
                btnClass={"past-table-actions-button"}
                dataToSend={{
                  id: record.id,
                  title: record.game_title,
                  round: record.round,
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="ongoing-table position-relative">
      {screenWidth >= 767 ? (
        <Table
          columns={columns}
          dataSource={pastTableData}
          className="past-table-cont font-cairo-normal"
          rowClassName="past-table-row"
          pagination={false}
        />
      ) : (
        <div className="past-mobile">
          {pastTableData?.map((item, index) => {
            return (
              <div
                className="past-mobile-cont font-cairo-normal"
                key={index + 1}
              >
                <div className="past-mobile-cont-items">
                  <div className="past-mobile-cont-items-title">League</div>
                  <div>{item.competition.name}</div>
                </div>
                <div className="past-mobile-cont-items">
                  <div className="past-mobile-cont-items-title">Game Title</div>
                  <div>{item.game_title}</div>
                </div>
                <div className="past-mobile-cont-items">
                  <div className="past-mobile-cont-items-title">Pot</div>
                  <div>£{Math.floor(item.pot)}</div>
                </div>
                <div className="past-mobile-cont-items">
                  <div className="past-mobile-cont-items-title">Round</div>
                  <div>{item.round}</div>
                </div>
                <div className="past-mobile-cont-items">
                  <div className="past-mobile-cont-items-title">
                    My Selection
                  </div>
                  <div>
                    {item.teams.map((team, index) => {
                      return (
                        <>
                          <div className="past-mobile-teams" key={index + 1}>
                            <img
                              src={team.image}
                              alt="Team Icon"
                              className="past-mobile-teamicon"
                            />
                            <span>{team.name}</span>
                          </div>
                          <div className="ongoing-table-selections-opponent">
                            vs {team?.opponent?.name} &nbsp; &nbsp;
                            <span className="white-color">
                              {team.opponent?.my_score} -&nbsp;
                              {team.opponent?.score}
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="past-mobile-cont-items justify-center">
                  <BorderedButton
                    name={"View All Selections"}
                    link={"/dashboard/allselections"}
                    btnHeight={"54px"}
                    btnClass={"past-mobile-button"}
                    dataToSend={{
                      id: item.id,
                      title: item.title,
                      round: item.round,
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default PastTable;
