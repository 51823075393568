import React, { useState } from "react";
import * as Yup from "yup";
import { Spin } from "antd";
import { sendGoogleAnalyticsEvents } from "../../../helpers/genericFunctions";
import { Link, useNavigate } from "react-router-dom";
import { FilledButton } from "../../commonComponents";
import { loginWithEmail } from "../../../utils/service";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import {
  setToken,
  getURL,
  setNavItem,
  getNavItem,
} from "../../../utils/localStorage";
import notify from "../../../utils/notificationService"
import { ReactComponent as ErrorIcon } from "../../../assets/images/error-icon.svg";

const SignupSchema = Yup.object().shape({
  email: Yup.string().required("Email or Username is required"),
  password: Yup.string().required("Password is required"),
});

const LoginForm = () => {
  const navigate = useNavigate();
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const privatelink = getURL();

  const handleFormSubmit = async (values) => {
    const eventDetails = {
      category: "engagement",
      action: "login",
      label: "Login",
      email: values.email,
    };
    sendGoogleAnalyticsEvents(eventDetails);
    setLoading(true);
    var data = new FormData();
    data.append("email", values.email);
    data.append("password", values.password);
    const res = await loginWithEmail(data);
    if (res.status === 200) {
      setToken(res?.headers["authorization"]);
      setLoading(false);
      if (privatelink) {
        navigate(privatelink);
        setNavItem(3);
      } else {
        if (getNavItem() == 3) {
          navigate("/dashboard");
        } else if (getNavItem() == 8) {
          navigate("/safergambling");
        } else {
          navigate("/");
        }
      }
    } else if (!res.success) {
      notify(res?.errors[0],"error")
      setLoading(false);
    } else {
      console.log("Failed==>", res);
    }
  };
  const showPassword = () => {
    setTogglePassword(!togglePassword);
  };

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={handleFormSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="fields-cont">
              <Field
                type="text"
                id="email"
                name="email"
                className={`login-input-field no-outline ${
                  errors.email && touched.email ? "error" : ""
                }`}
                placeholder="Email or Username"
              />
              <ErrorMessage name="email" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div className="fields-contPass position-relative">
              <Field
                type={togglePassword ? "text" : "password"}
                id="password"
                name="password"
                className={`login-input-field no-outline ${
                  errors.password && touched.password ? "error" : ""
                }`}
                placeholder="Password"
              ></Field>
              {togglePassword ? (
                <EyeOutlined
                  className="login-password-icon"
                  onClick={showPassword}
                />
              ) : (
                <EyeInvisibleOutlined
                  className="login-password-icon"
                  onClick={showPassword}
                />
              )}
              <ErrorMessage name="password" component="div">
                {(msg) => (
                  <div className="error-message">
                    <ErrorIcon className="error-icon-margin" />
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div className="forget-password forgetFlex">
              <Link
                to={"/forgetpassword"}
                className="link-style white-color styleFoget"
                onClick={() => setNavItem(null)}
              >
                Forgot Password?
              </Link>
            </div>
            <div>
              <FilledButton
                name={
                  loading ? (
                    <div className="auth-loading">
                      <Spin className="loader" />
                    </div>
                  ) : (
                    "Login"
                  )
                }
                btnClass="primary-background"
                type={5}
                btnType={"submit"}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
