import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ExternalNavbar } from "../../commonComponents";
import ForgetPasswordForm from "./ForgetPasswordForm";
import ContactUsModal from "../../modals/ContactUs/ContactUsModal";
import { getToken } from "../../../utils/localStorage";
import "./ForgetPassword.scss";

const ForgetPassword = () => {
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
  const user_token = getToken();
  const navigate = useNavigate();
  const isAuthenticated = user_token ? true : false;

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/");
  //   }
  // }, []);
  const openContactUsModal = () => {
    setIsContactUsModalOpen(true);
  };

  const closeContactUsModal = () => {
    setIsContactUsModalOpen(false);
  };
  return (
    <div className="black-background white-color padding-bottom2 login-background">
      <ExternalNavbar />
      <div>
        <div className="forgetpassword-title">Forgot Password?</div>
      </div>
      <div className="forgetpassword-main-cont background-blur">
        <div className="forgetpassword-steps-cont"></div>
        <div className="forgetpassword-form-cont">
          <div className="forgetpassword-form-title">
            Enter the email address associated with your account and we’ll send
            you a link to reset your password.
          </div>
          <ForgetPasswordForm />
          <div className="already-member">
            Need additional help? &nbsp;
            <Link
              to={"#"}
              className="grey-color link-style-underline"
              onClick={openContactUsModal}
            >
              {" "}
              Contact us
            </Link>
          </div>
        </div>
      </div>
      <ContactUsModal
        visible={isContactUsModalOpen}
        closeModal={closeContactUsModal}
      />
    </div>
  );
};

export default ForgetPassword;
