import React from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { FilledButton, BorderedButton } from "../../commonComponents";
import "./InsufficientBalance.scss";

const InsufficientBalance = ({ visible, closeModal, amount }) => {
  const navigate = useNavigate();

  return (
    <>
      <Modal
        open={visible}
        title={"Insufficient Funds!"}
        onCancel={closeModal}
        wrapClassName="depinsufficientbalanceositsuccess-modal"
        className="insufficientbalance-modal-inner"
        footer={null}
        maskStyle={{
          backdropFilter: "blur(5px)",
        }}
      >
        <div className="deposit-modal-buttons">
          <BorderedButton
            name={"Cancel"}
            btnClass="alert-buttons-discard"
            functionCall={closeModal}
          />
          <FilledButton
            name={"Deposit Now"}
            btnClass="primary-background home-buttons-read-all"
            type={2}
            functionCall={() => {
              navigate("/payment");
            }}
          />
        </div>
      </Modal>
    </>
  );
};
export default InsufficientBalance;
