import {
  getApiWithAuth,
  getApiWithoutAuth,
  postApiWithoutAuth,
  postApiWithAuth,
  patchApiWithAuth,
  deleteApiWithAuth,
  putApiWithAuth,
} from "./api";
import {
  FORGET_PASSWORD,
  LEAGUES_DATA,
  RESET_PASSWORD,
  STANDINGS_DATA,
  USER_DATA,
  USER_LOGIN,
  USER_REGISTER,
  FIXTURES_DATA,
  RESULTS_DATA,
  INSIGHTS_HTH_DATA,
  CONTACT_US,
  START_DATE_PRIVATE_GAME,
  CREATE_PRIVATE_GAME,
  UPDATE_USER,
  GET_PRIVATE_GAME_ID,
  GET_PUBLIC_GAMES,
  JOIN_GAME,
  GET_ONGOING_GAMES,
  GET_PAST_GAMES,
  GET_GAME_ACTIVITIES,
  GET_PRIVATE_GAME_BY_LINK,
  GET_TEAMS_PROBABILITY,
  GET_Availability,
  GET_PRIVATE_GAME,
  GET_ALL_SELECTIONS,
  USER_GAMBLING_DATA,
  UPDATE_SAFER_GAMBLING,
  REMAINING_TEAMS,
  DELETE_PRIVATE_GAME,
  WALLET,
  ADD_CARD,
  ALL_TRANSACTION,
  GET_EMAIL_AVAILABILITY,
  SEND_INVITE,
  BASKETBALL_STATS,
  GET_NOTIFICATIONS,
  NOTIFICATIONS_SEEN,
  REFERRAL_LINK,
  META_EVENTS,
  CREATE_PAYMENT,
  PAYMENT_AMOUNT_CHECKS,
  EXTERNAL_AMOUNT_WITHDRAW,
  DEPOSIT_CHARGE,
  AMOUNT_WITHDRAW,
  APPLY_PROMO_CODE,
  GET_PUBLIC_SATRTED_GAMES,
  GET_ALL_SELECTIONS_FOR_PUBLIC
} from "./apiEndPoints";

export const loginWithEmail = (data) => postApiWithoutAuth(USER_LOGIN, data);
export const signupWithEmail = (data) =>
  postApiWithoutAuth(USER_REGISTER, data);
export const forgetPassword = (data) =>
  postApiWithoutAuth(FORGET_PASSWORD, data);
export const resetPassword = (data) => postApiWithoutAuth(RESET_PASSWORD, data);
export const userData = () => getApiWithAuth(USER_DATA);
export const leaguesData = (sport_id) =>
  getApiWithoutAuth(`${LEAGUES_DATA}?sport_id=${sport_id}`);
export const gameLeaguesData = (data) =>
  getApiWithoutAuth(`${LEAGUES_DATA}${data}`);
export const standingsData = (data) =>
  getApiWithoutAuth(`${STANDINGS_DATA}/${data}`);
export const getFixturesData = (data) =>
  getApiWithoutAuth(`${FIXTURES_DATA}/${data}`);
export const getResultsData = (data) =>
  getApiWithoutAuth(`${RESULTS_DATA}/${data}`);
export const getHeadToHeadData = (data) =>
  getApiWithoutAuth(`${INSIGHTS_HTH_DATA}/${data}`);
export const contactUsApi = (data) => postApiWithoutAuth(CONTACT_US, data);
export const createPrivateGame = (data) =>
  postApiWithAuth(CREATE_PRIVATE_GAME, data);
export const getStartDate = (data, mode) =>
  getApiWithAuth(`${START_DATE_PRIVATE_GAME}/${data}/?is_turbo=${mode}`);
export const updateUserData = (data) => patchApiWithAuth(UPDATE_USER, data);
export const getPrivateGameLink = () => getApiWithAuth(GET_PRIVATE_GAME_ID);
export const getPublicGamesForUser = (data) =>
  getApiWithAuth(`${GET_PUBLIC_GAMES}${data}`);
export const getPublicGamesForUserFilter = (currentPage, data) =>
  getApiWithAuth(`${GET_PUBLIC_GAMES}${currentPage}&${data}`);
export const getPublicGamesForPublic = (data) =>
  getApiWithoutAuth(`${GET_PUBLIC_GAMES}${data}`);
export const joinGame = (gameID, data) =>
  postApiWithAuth(`game/${gameID}${JOIN_GAME}`, data);
export const getOngoingGames = () => getApiWithAuth(`${GET_ONGOING_GAMES}`);
export const getPastGames = () => getApiWithAuth(`${GET_PAST_GAMES}`);
export const editGame = (gameID, data) =>
  patchApiWithAuth(`${CREATE_PRIVATE_GAME}${gameID}`, data);
export const getGameActivitiesByDate = (to_date, from_date) =>
  getApiWithAuth(`${GET_GAME_ACTIVITIES}${to_date}&${from_date}`);
export const getAllGameActivities = (data) =>
  getApiWithAuth(`${GET_GAME_ACTIVITIES}${data}`);
export const getPrivateGameByLink = (data) =>
  getApiWithAuth(`${GET_PRIVATE_GAME_BY_LINK}${data}`);
export const getProbabilityData = (data) =>
  getApiWithAuth(`/fixture/${data}${GET_TEAMS_PROBABILITY}`);
export const getUsername = () => getApiWithAuth(`${GET_Availability}`);
export const getPrivateGames = () => getApiWithAuth(`${GET_PRIVATE_GAME}`);
export const getAllSelections = (data) =>
  getApiWithAuth(`/game/${data}${GET_ALL_SELECTIONS}`);
export const getAllSelectionsForPublic = (data) =>
  getApiWithoutAuth(`/game/${data}${GET_ALL_SELECTIONS_FOR_PUBLIC}`);
export const getPreviousSelections = (data) =>
  getApiWithAuth(`/game/${data}${GET_ALL_SELECTIONS}?previous_selection=true`);
export const userGamblingData = () => getApiWithAuth(USER_GAMBLING_DATA);
export const updateSaferGambling = (data) =>
  postApiWithAuth(UPDATE_SAFER_GAMBLING, data);
export const getRemainingTeams = (data) =>
  getApiWithAuth(`/game/${data}/${REMAINING_TEAMS}`);
export const deletePrivateGame = (gameId) =>
  deleteApiWithAuth(`${DELETE_PRIVATE_GAME}${gameId}`);
export const getWalletData = () => getApiWithAuth(`${WALLET}`);
export const addCard = (data) => postApiWithAuth(ADD_CARD, data);
export const allTransactionData = (type, data) =>
  getApiWithAuth(`${ALL_TRANSACTION}${type}&${data}`);
export const allTransactionDataByDate = (type, to_date, from_date) =>
  getApiWithAuth(`${ALL_TRANSACTION}${type}&${to_date}&${from_date}`);
export const getValidEmail = (data) =>
  postApiWithoutAuth(GET_EMAIL_AVAILABILITY, data);
export const sendInvite = (data) => postApiWithAuth(SEND_INVITE, data);
export const getBasketballStats = () =>
  getApiWithoutAuth(`${BASKETBALL_STATS}`);
export const getAllNotifications = () => getApiWithAuth(`${GET_NOTIFICATIONS}`);
export const deleteNotification = (notificationArray) =>
  deleteApiWithAuth(`${GET_NOTIFICATIONS}/delete?ids=[${notificationArray}]`);
export const allNotificationsSeen = (notificationArray) =>
  putApiWithAuth(`${NOTIFICATIONS_SEEN}`, notificationArray);
export const getReferralLink = () => getApiWithAuth(`${REFERRAL_LINK}`);
export const sendEventsToConversionAPI = (data) =>
  postApiWithAuth(META_EVENTS, data);
export const createPayment = (data) => postApiWithAuth(CREATE_PAYMENT, data);
export const handlePaymentAmountCheck = (data) =>
  postApiWithAuth(PAYMENT_AMOUNT_CHECKS, data);
export const externalPaymentWithdraw = (data) =>
  postApiWithAuth(EXTERNAL_AMOUNT_WITHDRAW, data);
export const depositChargeAmount = (data) =>
  postApiWithAuth(DEPOSIT_CHARGE, data);
export const withdrawAmount = (data) => postApiWithAuth(AMOUNT_WITHDRAW, data);
export const applyPromoCode = (data) => postApiWithAuth(APPLY_PROMO_CODE, data);
export const startedPublicGamesData = () =>
  getApiWithoutAuth("all_past_games");
export const getStartedPublicGamesForPublic = (data) =>
  getApiWithoutAuth(`${GET_PUBLIC_SATRTED_GAMES}${data}`);
export const getStartedPublicGamesForPublicFilter = (currentPage, data) =>
  getApiWithAuth(`${GET_PUBLIC_SATRTED_GAMES}${currentPage}&${data}`);