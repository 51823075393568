import React, { useState, useEffect } from "react";
import GameRule from "./GameRule/GameRule";
import { Button, Input, Space, Spin } from "antd";
import { setNavItem } from "../../../utils/localStorage";
import RuleExample from "../../modals/RuleExample/RuleExample";
import { gameRulesData } from "../../../assets/data/gameRules";
import GameObjectiveIcon from "../../../assets/images/game-objective.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/search .svg";
import "./Rules.scss";

const Rules = () => {
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
  const [rules, setRules] = useState(gameRulesData);
  const [searchtext, setSearchText] = useState();
  const [loading, setLoading] = useState(false);

  const [modalDetails, setModalDetails] = useState({
    title: "",
    description: "",
  });
  const openContactUsModal = () => {
    setIsContactUsModalOpen(true);
  };
  const closeContactUsModal = () => {
    setIsContactUsModalOpen(false);
  };
  const handleInvitation = async () => {
    const filteredData = gameRulesData.filter((item) =>
      item.question.toLowerCase().includes(searchtext.toLowerCase())
    );
    setRules(filteredData);
  };

  const handleKeyPress = (e) => {
    // if (e.key === "Enter") {
    handleInvitation();
    // }
  };
  useEffect(() => {
    setNavItem(4);
  }, []);
  return (
    <div className="rules">
      <RuleExample
        visible={isContactUsModalOpen}
        closeModal={closeContactUsModal}
        title={modalDetails?.title}
        descriptions={modalDetails?.description}
      />
      <div className="rules-master font-cairo-medium primary-color">
        MASTER THE GAME
      </div>
      <div className="rules-heading font-cairo-extrabold">
        Rules & Guidelines For Your Winning Journey
      </div>
      <div className="rules-objective background-blur">
        <div className="rules-objective-left">
          <div className="rules-objective-left-title font-cairo-medium">
            Game Objective
          </div>
          <div className="rules-objective-left-details font-cairo-normal">
            Outperform all other players by predicting a winning team out of a
            league’s weekly fixture using strategic thinking as, once selected,
            a team cannot be chosen again in the game. May the sharpest Champs
            win!
          </div>
        </div>
        <div className="rules-objective-right">
          <div>
            <img src={GameObjectiveIcon} alt="Game Objective" />
          </div>
        </div>
      </div>
      <div className="rules-game">
        <div className="rules-game-title font-cairo-extrabold rulesTop">
          Game Rules
        </div>
        <div className="rules-div borderRefrel">
          <Space.Compact className="rules-referBorder">
            <Input
              className="rules-div-compact-input remove-transform15 font"
              placeholder="Search for Rules e.g. “How do I win?"
              onChange={(e) => setSearchText(e.target.value)}
              type="email"
              value={searchtext}
              onKeyUp={handleKeyPress} // Listen for Enter key press
            />
            <Button
              type="primary"
              className="rules-div-compact-button inviteHover"
              onClick={handleInvitation}
              htmlType="submit"
            >
              <span className="remove-transform15 font-cairo-bold">
                {loading ? (
                  <div className="auth-loading" style={{ marginTop: "0.5rem" }}>
                    <Spin className="loader" />
                  </div>
                ) : (
                  <SearchIcon />
                )}
              </span>
            </Button>
          </Space.Compact>
        </div>
        {rules.map((item, index) => (
          <GameRule
            stepNumber={item.number}
            stepDetails={item.details}
            stepDirection={item.direction}
            isLast={index === gameRulesData.length - 1}
            key={index}
            openContactUsModal={openContactUsModal}
            setModalDetails={setModalDetails}
            question={item.question}
          />
        ))}
      </div>
    </div>
  );
};

export default Rules;
