import React, { useState, useEffect } from "react";
import { Select } from "antd";
import "./SelfExclusion.scss";

const timePeriodOptions = [
  { value: "6", label: "6 Months" },
  { value: "12", label: "1 Year" },
  { value: "24", label: "2 Years" },
  { value: "36", label: "3 Years" },
  { value: "48", label: "4 Years" },
  { value: "60", label: "5 Years" },
];

const SelfExclusion = ({
  userProfileData,
  setUserProfileData,
  userUpdatedData,
  setUserUpdatedData,
}) => {
  const [selfExclusionValue, setSelfExclusionValue] = useState();
  const handleMonthChange = (value) => {
    const updatedValues = {
      // ...userUpdatedData,
      self_exclusion_period: value,
    };
    setUserUpdatedData(updatedValues);
    setSelfExclusionValue(value);
  };
  useEffect(() => {
    if (userProfileData) {
      setSelfExclusionValue(
        userProfileData?.self_exclusion_period == null
          ? "Select Self Exclusion Period"
          : `${userProfileData?.self_exclusion_period} Year`
      );
    }
  }, [userProfileData]);
  return (
    <div className="self-exclusion">
      <div className="self-exclusion-title">Self-Exclusion</div>
      <div className="self-exclusion-description">
        <div className="self-exclusion-padding">
          At Champion's Choice, we want to provide a gaming experience that
          suits your individual needs. If you feel you may be developing a
          gambling issue, our self-exclusion option is available to you. You can
          prevent access to your account for a minimum of 6 months and up to 5
          years, during which we will do our best to prevent any accounts from
          being reopened.
        </div>
        <div className="self-exclusion-padding">
          Please note you will not be able to re access your account before this
          period has ended.
        </div>
        <div className="self-exclusion-padding">
          Alternatively, if you wish to restrict your gaming for a shorter
          period, our Time Out feature allows you to block account access for up
          to 30 days.
          <div>
            If you wish to permanently close your account, please visit our
            account closure page.
          </div>
        </div>
        <div className="self-exclusion-padding">
          To self-exclude from all online gambling companies licensed in the UK,
          we recommend using Gamstop. You can visit their website here &nbsp;
          <a
            href="https://www.gamstop.co.uk"
            target="_blank"
            className="self-exclusion-description-link"
            rel="noreferrer"

          >
            www.gamstop.co.uk
          </a>
        </div>
      </div>
      <div className="self-exclusion-select">
        <div className="self-exclusion-select-label font-cairo-medium">
          Your Self-Exclusion Period
        </div>
        <Select
          value={selfExclusionValue}
          options={timePeriodOptions}
          onChange={handleMonthChange}
          className="self-exclusion-dropdown-filter"
          popupClassName="dropdown-popup-style self-exclusion-dropdown-filter-popupmenu"
          placement="topRight"
        />
      </div>
    </div>
  );
};

export default SelfExclusion;
