import React from "react";
import { Modal } from "antd";
import "./WithdrawSuccess.scss";

const WithdrawSuccess = ({ visible, closeModal }) => {
  return (
    <>
      <Modal
        open={visible}
        title={"Withdrawal Successful"}
        onCancel={closeModal}
        wrapClassName="withdrawsuccess-modal"
        className="withdrawsuccess-modal-inner"
        footer={null}
        maskStyle={{
          backdropFilter: "blur(5px)",
        }}
      >
        <div className="verification-warning-modal-details font-cairo-medium">
          Your withdrawal request has been processed successfully. Please allow
          3-5 business days for the funds to appear in your account.
        </div>
      </Modal>
    </>
  );
};
export default WithdrawSuccess;
