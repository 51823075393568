import { useEffect, useState } from "react";
import { BorderedButton } from "../../../commonComponents";
import { getToken } from "../../../../utils/localStorage";
import start from "../../../../assets/images/sort_icon.png";
import filter from "../../../../assets/images/Filter-icon.svg";
import { useScreenWidth } from "../../../../utils/useScreenWidth";
import { Table, Popover, Checkbox, Slider, Radio, Spin } from "antd";
import PremierLeagueIcon from "../../../../assets/images/premier_league.svg";
import { ReactComponent as FootballIcon } from "../../../../assets/images/football-icon.svg";
import { ReactComponent as BasketBallIcon } from "../../../../assets/images/basketball_icon.svg";
import {
  getStartedPublicGamesForPublic,
  getPublicGamesForUserFilter,
  getStartedPublicGamesForPublicFilter,
  gameLeaguesData
} from "../../../../utils/service";
import "./PastGameTable.scss";
import { Link } from "react-router-dom";

const potCheckboxOptions = [
  { label: "Lowest - Highest", value: "low_to_high" },
  { label: "Highest - Lowest", value: "high_to_low" },
];

const statusCheckboxOptions = [
  { label: "Completed", value: true },
  { label: "Ongoing", value: false },
];


const PastGameTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [gameTableData, setGameTableData] = useState();
  const [filterData, setFilterData] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [leaguesResponse, setLeaguesResponse] = useState();
  const screenWidth = useScreenWidth();
  const isUser = getToken();


  const getLeaguesData = async () => {
    if (!filterData?.sports || filterData?.sports.length === 2) {
      const res = await gameLeaguesData(``);
      const leaguesName = res?.data?.data?.map((item) => {
        return item.name;
      });
      setLeaguesResponse(leaguesName);
    } else {
      const res = await gameLeaguesData(`?sport_id=${filterData?.sports[0]}`);
      const leaguesName = res?.data?.data?.map((item) => {
        return item.name;
      });
      setLeaguesResponse(leaguesName);
    }
  };

  const handleSportsCheckbox = (checkedValues) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      sports: checkedValues,
    }));
  };
  const handleStatusCheckbox = (checkedValues) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      finished: checkedValues,
    }));
  };
  const handleCompetitonsCheckbox = (checkedValues) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      competition: checkedValues,
    }));
  };
  const handlePotValue = (e) => {
    setFilterData((prevFilterData) => {
      const newFilterData = { ...prevFilterData };
      if ("stake" in newFilterData) {
        delete newFilterData.stake;
      }
      newFilterData.pot = e.target.value;
      return newFilterData;
    });
  };

  const handleStatusValue = (e) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      finished: e.target.value,
    }));
  };
  
  const getGameTableData = async () => {
    if (isUser) {
      const response = await getStartedPublicGamesForPublic(currentPage);
      const modifiedGameData = response?.data?.games?.map((game) => ({
        ...game,
        key: game.id,
      }));
      setGameTableData(modifiedGameData);
    } else {
      const response = await getStartedPublicGamesForPublic(currentPage);
      const modifiedGameData = response?.data?.games?.map((game) => ({
        ...game,
        key: game.id,
      }));
      setGameTableData(modifiedGameData);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const competitionsContent = (
    <div>
      <Checkbox.Group
        options={leaguesResponse}
        onChange={handleCompetitonsCheckbox}
        className="game-table-checkbox"
      />
    </div>
  );
  const sportsContent = (
    <div>
      <Checkbox.Group
        onChange={handleSportsCheckbox}
        className="game-table-checkbox"
      >
        <Checkbox
          value="football"
          className="game-table-sports-checkboxes-item"
        >
          {/* <FootballIcon className="game-table-sports-checkboxes-image iconFootbll" /> */}
          Football
        </Checkbox>
        <Checkbox
          value="basketball"
          className="game-table-sports-checkboxes-item"
        >
          {/* <BasketBallIcon className="game-table-sports-checkboxes-image iconFootbll" /> */}
          Basketball
        </Checkbox>
      </Checkbox.Group>
    </div>
  );

  const potContent = (
    <Radio.Group
      options={potCheckboxOptions}
      onChange={handlePotValue}
      className="game-table-stakeradio"
    />
  );

  const statusContent = (
    <Radio.Group
      options={statusCheckboxOptions}
      value={filterData?.finished}
      onChange={handleStatusValue}
      className="game-table-stakeradio"
    />
  );
  const columns = [
    {
      title: "Game Ref",
      dataIndex: "title",
      width: "120px",
      render: (_, record) => {
        return (
          <div>
            {record.is_turbo && (
              <div className="game-table-turbotag font-cairo-bold">Turbo</div>
            )}
            <div>{record.title}</div>
          </div>
        );
      },
    },
    {
      title: (
        <Popover
          overlayClassName="game-table-popover-menu game-table-sports"
          content={sportsContent}
          placement="bottom"
          trigger={["click"]}
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            Sport
            <img className="filterStyle" src={filter} />
          </div>
        </Popover>
      ),
      dataIndex: "sport_id",
      render: (_, record) => {
        return (
          <div className="game-table-sports-cont">
            {record.sport_name.toLowerCase() == "football" ? (
              <FootballIcon className="game-table-sports-cont-icon" />
            ) : (
              <BasketBallIcon className="game-table-sports-cont-icon" />
            )}
            {record.sport_name}
          </div>
        );
      },
    },
    {
      title: (
        <Popover
          content={competitionsContent}
          placement="bottom"
          trigger={["click"]}
          overlayClassName="game-table-popover-menu game-table-competitions"
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            League
            <img className="filterStyle" src={filter} />
          </div>
        </Popover>
      ),
      dataIndex: "competitions",
      render: (_, record) => {
        return (
          <div className="game-table-competitions-items">
            <div className="game-table-competitions-items-image">
              <img
                src={
                  record.season_id == 23614
                    ? PremierLeagueIcon
                    : record.league_image
                }
                alt="League Icon"
                className="game-table-competitions-items-icon"
                style={{ width: record?.sport_id == 5 ? "17px" : "34px" }}
              />
            </div>

            <div>{record.league_name}</div>
          </div>
        );
      },
    },
    {
      title: (
        <Popover
          overlayClassName="game-table-popover-menu game-table-pot"
          content={potContent}
          placement="bottom"
          trigger={["click"]}
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            Pot
            <img className="filterStyle" src={start} alt="Filter Icon" />
          </div>
        </Popover>
      ),
      dataIndex: "pot",
      render: (_, record) => {
        return <div>£{Math.floor(record.pot)}</div>;
      },
    },
    {
      title: "Entries",
      dataIndex: "joined_players",
      render: (_, record) => {
        return (
          <div className="ongoing-table-entries-cont justify-content-center">
            {record?.remaining_players
              ?.toString()
              ?.split("")
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                  >
                    {item}
                  </div>
                );
              })}
            <div className="ongoing-table-entries-centerline"></div>
            {record.round_number > 1
              ? record?.max_players
                  ?.toString()
                  ?.split("")
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                      >
                        {item}
                      </div>
                    );
                  })
              : record?.remaining_players
                  ?.toString()
                  ?.split("")
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                      >
                        {item}
                      </div>
                    );
                  })}
          </div>
        );
      },
    },
    {
      title: (
        <Popover
          overlayClassName="game-table-popover-menu game-table-sports"
          content={statusContent}
          placement="bottom"
          trigger={["click"]}
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            Status
            <img className="filterStyle" src={filter} />
          </div>
        </Popover>
      ),
      dataIndex: "sport_id",
      render: (_, record) => {
        return (
          <div className="game-table-sports-cont justify-content-center">
            {record.is_finished ? "Completed" : "Ongoing"}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "key",
      key: "key",
      render: (_, record) => {
        return (
          <div onClick={() => {}}>
            <BorderedButton
              name={"View Game"}
              link={`/allselections/${record.id}?title=${record.title}`}
              btnHeight={"37px"}
              btnClass={"game-table-action-button"}
              dataToSend={{ id: record.id, title: record.title }}
            />
          </div>
        );
      },
    },
  ];

  const handleFilteredGameData = async () => {
    const queryParams = Object.entries(filterData)
      .filter(([key, value]) => {
        return (
          (Array.isArray(value) && value.length > 0) ||
          (typeof value === "string" && value !== "") ||
          (key === "finished" && typeof value === "boolean")
        );
      })
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const encodedArray = value
            .map((v) => `${encodeURIComponent(key)}[]=${encodeURIComponent(v)}`)
            .join("&");
          return encodedArray;
        } else if (key === "finished") {
          return `${encodeURIComponent(key)}=${value}`;
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join("&");
  
    const response = await getStartedPublicGamesForPublicFilter(
      currentPage,
      queryParams
    );
    if (response.games) {
      const modifiedGameData = response?.games?.map((game) => ({
        ...game,
        key: game.id,
      }));
      setGameTableData(modifiedGameData);
    }
  };
  
  useEffect(() => {
    getGameTableData();
    getLeaguesData();
  }, [currentPage]);

  useEffect(() => {
    if (filterData) {
      handleFilteredGameData();
      getLeaguesData();

    }
  }, [filterData]);

  return (
    <>
      {gameTableData?.length > 0 ? (
        <>
          <div
            className={`${
              screenWidth >= 992 && "game-table container-border"
            } position-relative background-blur`}
          >
            {screenWidth >= 992 ? (
              <Table
                columns={columns}
                dataSource={gameTableData}
                onRow={(record) => ({
                  onClick: () => {
                    const newExpandedRowKeys = expandedRowKeys.includes(
                      record.key
                    )
                      ? []
                      : [record.key];
                    setExpandedRowKeys(newExpandedRowKeys);
                  },
                })}
                pagination={false}
                // pagination={{
                //   current: currentPage,
                //   total: gameTableData?.total_records,
                //   onChange: handlePageChange,
                // }}
              />
            ) : (
              <>
                <div className="popOverContainerMain">
                  <div className="popOverContainer">
                    <div style={{ width: "40%" }}>
                      <Popover
                        overlayClassName="game-table-popover-menu game-table-sports"
                        content={sportsContent}
                        placement="bottomLeft"
                        trigger={["click"]}
                        autoAdjustOverflow={false}
                      >
                        <div
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          Sport
                          <img
                            className="filterStyle"
                            src={filter}
                            alt="Filter Icon"
                          />
                        </div>
                      </Popover>
                    </div>
                    <div style={{ width: "58%" }}>
                      <Popover
                        content={competitionsContent}
                        placement="bottomLeft"
                        trigger={["click"]}
                        overlayClassName="game-table-popover-menu game-table-competitions"
                        autoAdjustOverflow={false}
                      >
                        <div
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          League
                          <img
                            className="filterStyle"
                            src={filter}
                            alt="Filter Icon"
                          />
                        </div>
                      </Popover>
                    </div>
                  </div>
                  <div className="popOverSub">
                    <div style={{ width: "49%" }}>
                      <Popover
                        overlayClassName="game-table-popover-menu game-table-pot"
                        content={potContent}
                        placement="bottomLeft"
                        trigger={["click"]}
                      >
                        <div
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          Pot
                          <img
                            className="filterStyle"
                            src={start}
                            alt="Filter Icon"
                          />
                        </div>
                      </Popover>
                    </div>
                    <div style={{ width: "49%" }}>
                    <Popover
                        content={statusContent}
                        placement="bottomLeft"
                        trigger={["click"]}
                        overlayClassName="game-table-popover-menu game-table-competitions"
                        autoAdjustOverflow={false}
                      >
                        <div
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          Status
                          <img
                            className="filterStyle"
                            src={filter}
                            alt="Filter Icon"
                          />
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
                <div className="gametable-mobile popOverMai">
                  {gameTableData?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="gametable-mobile-title font-cairo-bold"
                          key={index + 1}
                        >
                          {item.title}
                        </div>
                        <div className="gametable-mobile-cont" key={index}>
                          <div className="gametable-mobile-cont-items">
                            {item?.is_turbo && (
                              <div className="game-table-turbotag font-cairo-bold">
                                Turbo
                              </div>
                            )}
                            <div className="gametable-mobile-cont-items-title">
                              League
                            </div>
                            <div className="gametable-mobile-cont-items-icon">
                              <img
                                src={
                                  item.season_id == 23614
                                    ? PremierLeagueIcon
                                    : item.league_image
                                }
                                alt="League Icon"
                                className="image-cover-full"
                              />
                            </div>
                            <div>{item.league_name}</div>
                          </div>
                          <div className="gametable-mobile-cont-items">
                            <div className="gametable-mobile-cont-items-title">
                              Sport
                            </div>
                            <div>{item.sport_name}</div>
                          </div>
                          <div className="gametable-mobile-cont-items">
                            <div className="gametable-mobile-cont-items-title">
                              Pot
                            </div>
                            <div>£{Math.floor(item.pot)}</div>
                          </div>
                          <div className="gametable-mobile-cont-items">
                            <div className="gametable-mobile-cont-items-title">
                              Entries
                            </div>
                            <div className="ongoing-table-entries-cont justify-content-center">
                              {item?.remaining_players
                                ?.toString()
                                ?.split("")
                                .map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                                    >
                                      {item}
                                    </div>
                                  );
                                })}
                              <div className="ongoing-table-entries-centerline"></div>
                              {item.round_number > 1
                                ? item?.max_players
                                    ?.toString()
                                    ?.split("")
                                    .map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                                        >
                                          {item}
                                        </div>
                                      );
                                    })
                                : item?.remaining_players
                                    ?.toString()
                                    ?.split("")
                                    .map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="ongoing-table-entries-cont-number container-border font-cairo-bold"
                                        >
                                          {item}
                                        </div>
                                      );
                                    })}
                            </div>
                          </div>
                          <div className="gametable-mobile-cont-items">
                            <div className="gametable-mobile-cont-items-title">
                              Status
                            </div>
                            <div>{item?.is_finished ? "Completed": "Ongoing"}</div>
                          </div>
                          <div className="gametable-mobile-cont-items justify-center">
                            <BorderedButton
                              name={"View Game"}
                              link={`/allselections/${item.id}?title=${item.title}`}
                              btnHeight={"54px"}
                              btnClass={"past-mobile-button"}
                              dataToSend={{ id: item.id, title: item.title }}
                              // btnClass={"gametable-mobile-button"}
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            )}
            {/* <div className="game-table-freetext font-cairo-medium">
              *Participation is restricted to UK residents aged 18 and above who
              have had their account verified on Champion’s Choice and have a
              valid UK bank account.See full T&Cs{" "}
              <Link
                to={"/termsandconditions"}
                className="link-style grey-color"
              >
                here
              </Link>
            </div> */}
          </div>
        </>
      ) : gameTableData?.length == 0 ? (
        <div className="game-table-nodata font-cairo-medium">
          No Future Games Available Right Now! Why not start your own game?
          Create a private one, invite your mates, and let the thrilling
          showdown begin!
        </div>
      ) : (
        <div className="spinner-loading font-cairo-medium">
          <Spin className="loader" />
        </div>
      )}
    </>
  );
};
export default PastGameTable;
