import React, { useEffect, useState } from "react";
import { Button, Input, Space, Spin } from "antd";
import { BorderedButton } from "../../commonComponents";
import XIcon from "../../../assets/images/x.svg";
import FacebookIcon from "../../../assets/images/faceook.svg";
import WhatsAppIcon from "../../../assets/images/whatsApp.svg";
import ProfileIcon from "../../../assets/images/auths/user.svg";
import { sendInvite, getReferralLink } from "../../../utils/service";
import "./Referral.scss";
import ReferralModal from "../../modals/ReferralRule/ReferralModal";
import notify from "../../../utils/notificationService"

const Referral = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [friendEmail, setFriendEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [userReferralCode, setUserReferralCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleReferralCode = async () => {
    const response = await getReferralLink({ email: friendEmail });
    if (response.success) {
      setUserReferralCode(response.referal_code);
    }
  };

  useEffect(() => {
    handleReferralCode();
  }, []);

  const message =
    "Use my promo code to get 50% off your first entry on Champion’s Choice. Click the link below to sign up !";
  const shareUrl =
    process.env.REACT_APP_CUSTOM_ENV === "production"
      ? `https://www.championschoice.co.uk/signup?promo=${userReferralCode}`
      : `https://develop.championschoice.co.uk/signup?promo=${userReferralCode}`;

  //const shareUrl = `https://develop.championschoice.co.uk/signup?promo=${userReferralCode}`

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleWhatsAppShare = () => {
    if (!userReferralCode) {
      notify("Join your first game to unlock this feature.","error")
    } else {
      window.open(
        `https://api.whatsapp.com/send?text=${encodeURIComponent(
          message + " " + shareUrl
        )}`,
        "_blank"
      );
    }
  };

  const handleCopyClick = () => {
    if (!userReferralCode) {
      notify("Join your first game to unlock this feature.","error")
    }
    if (userReferralCode) {
      const textToCopy = document.querySelector("#textToCopy");
      const range = document.createRange();
      range.selectNode(textToCopy);
      window.getSelection().removeAllRanges(); // Clear any existing selections
      window.getSelection().addRange(range);
      try {
        document.execCommand("copy");
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000); // Reset the copied state after 2 seconds
      } catch (error) {
        console.error("Failed to copy text:", error);
      }
    }

    window.getSelection().removeAllRanges(); // Clear the selection
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const emailChecking = (email) => {
    setFriendEmail(email);
    if (email.trim() === "") {
      setEmailError("Email is required");
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
      setFriendEmail(email);
    }
  };

  const handleInvitation = async () => {
    if (!userReferralCode) {
      notify("Join your first game to unlock this feature.","error")
    }
    if (userReferralCode) {
      setLoading(true);
      if (emailError === "" && friendEmail !== "") {
        const response = await sendInvite({ email: friendEmail });
        if (response.success) {
          notify(response?.message,"success")
          setFriendEmail("");
          setLoading(false);
        } else {
          notify(response?.message,"error")
          setLoading(false);
        }
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleInvitation();
    }
  };

  const handleFacebookShare = () => {
    if (!userReferralCode) {
      notify("Join your first game to unlock this feature.","error")
    } else {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}&quote=${encodeURIComponent(message)}`,
        "_blank"
      );
    }
  };

  const handleTwitterShare = () => {
    if (!userReferralCode) {
      notify("Join your first game to unlock this feature.","error")
    } else {
      window.open(
        `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          shareUrl
        )}&text=${encodeURIComponent(message)}`,
        "_blank"
      );
    }
  };

  return (
    <React.Fragment>
      <div className="referral font-cairo-normal">
        {!loading && (
          <React.Fragment>
            <div className="referral-cont container-border background-blur">
              <div className="referral-cont-image">
                <img
                  src={ProfileIcon}
                  className="referral-cont-image-icon"
                  alt="Profile Icon"
                />
              </div>
              <div className="referral-cont-title font-cairo-extrabold">
                Invite Your Friends!
              </div>
              <div className="referral-cont-refertext font-cairo-normal">
                Get 50% off when your mates join their first game!
              </div>
              <div
                className="referral-cont-terms font-cairo-normal"
                onClick={handleOpenModal}
              >
                <span className="referral-cont-terms-link font-cairo-bold pointer">
                  T&Cs apply.
                </span>
                <ReferralModal
                  visible={openModal}
                  closeModal={handleCloseModal}
                />
              </div>
            </div>
            <div className="refrelMain">
              <div className="referral-bottom container-border background-blur w">
                <div className="referral-bottom-div borderRefrel">
                  <Space.Compact className="referBorder">
                    <Input
                      className="referral-bottom-div-compact-input remove-transform15 font"
                      placeholder="Enter their email address"
                      onChange={(e) => emailChecking(e.target.value)}
                      type="email"
                      value={friendEmail}
                      onKeyUp={handleKeyPress} // Listen for Enter key press
                    />
                    <Button
                      type="primary"
                      className="referral-bottom-div-compact-button inviteHover"
                      onClick={handleInvitation}
                      htmlType="submit"
                    >
                      <span className="remove-transform15 font-cairo-bold">
                        {loading ? (
                          <div
                            className="auth-loading"
                            style={{ marginTop: "0.5rem" }}
                          >
                            <Spin className="loader" />
                          </div>
                        ) : (
                          "Send invite"
                        )}
                      </span>
                    </Button>
                  </Space.Compact>
                  {emailError !== "" && (
                    <div className="error-message">{emailError}</div>
                  )}
                </div>
                <div className="referral-bottom-link">
                  <div className="referral-bottom-link-share font-cairo-bold">
                    Or share your referral code:
                  </div>
                  <div className="referral-bottom-link-copy">
                    <div id="textToCopy">
                      {userReferralCode ? userReferralCode : "XXXXXXXXXXXX"}
                    </div>
                    <BorderedButton
                      name={isCopied ? "Copied!" : "Copy"}
                      functionCall={handleCopyClick}
                      btnHeight={"32px"}
                      btnClass={"referral-bottom-link-button"}
                    />
                  </div>
                </div>
                <div className="referral-socials">
                  <div
                    className="referral-socials-cont"
                    onClick={handleFacebookShare}
                  >
                    <img
                      src={FacebookIcon}
                      alt="Social Icons"
                      className="referral-socials-cont-icon pointer"
                    />
                    <div className="referral-socials-name font-cairo-normal">
                      Facebook
                    </div>
                  </div>
                  <div
                    className="referral-socials-cont pointer"
                    onClick={handleTwitterShare}
                  >
                    <img
                      src={XIcon}
                      alt="Social Icons"
                      className="referral-socials-cont-icon"
                    />
                    <div className="referral-socials-name font-cairo-normal">
                      X
                    </div>
                  </div>
                  <div
                    className="referral-socials-cont pointer"
                    onClick={handleWhatsAppShare}
                  >
                    <img
                      src={WhatsAppIcon}
                      alt="Social Icons"
                      className="referral-socials-cont-icon"
                    />
                    <div className="referral-socials-name font-cairo-normal">
                      WhatsApp
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default Referral;
