import React, { useState, useRef } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import TrueLayerLogo from "../../../../assets/images/bank_transfer.png";
import "./Withdraw.scss";

const Withdraw = ({
  handleErrors,
  errorWithdrawCheck,
  userData,
  withdrawAccountError,
  handleWithdrawAccount,
  setSortCode,
}) => {
  const inputOneRef = useRef(null);
  const inputTwoRef = useRef(null);
  const inputThreeRef = useRef(null);
  const [error, setError] = useState("");

  const handleChange = (value, field) => {
    if (/^\d{0,2}$/.test(value)) {
      setSortCode((prevState) => ({
        ...prevState,
        [field]: value,
      }));
      setError("");

      if (value.length === 2) {
        if (field === "one") {
          inputTwoRef.current.focus();
        } else if (field === "two") {
          inputThreeRef.current.focus();
        }
      }
    } else {
      setError(" Each field must contain two digits only");
    }
  };
  const handleBlur = (value, field) => {
    if (value.length === 1) {
      setError(`${field} field must contain two digits`);
    }
  };

  return (
    <div className="withdraw">
      <div className="withdraw-title font-cairo-medium">Withdrawal</div>
      <div>
        <div className="payment-field-label">
          <span>Withdrawal Amount</span>
          <span className="font-cairo-bold font14">Min: £5.00 </span>
        </div>
        <input
          name="depositAmount"
          className="payment-input-field"
          onChange={(e) => {
            handleErrors(e.target.value, "withdraw");
          }}
          placeholder="£ 0.00"
          type="number"
        />
        {errorWithdrawCheck !== "" && (
          <div className="payment-error">{errorWithdrawCheck}</div>
        )}
      </div>
      {userData?.payment_source_id === "" ? (
        <>
          <div>
            <div className="payment-field-label">
              <span>Account Number</span>
            </div>
            <input
              name="depositAmount"
              className="payment-input-field"
              onChange={(e) => {
                handleWithdrawAccount(e.target.value);
              }}
              placeholder="00000000"
            />
            {withdrawAccountError !== "" && (
              <div className="payment-error">{withdrawAccountError}</div>
            )}
          </div>
          <div>
            <div className="payment-field-label">
              <span>Sort Code</span>
            </div>
            <div className="withdraw-sortcode">
              <input
                name="depositAmount"
                className="payment-input-field withdraw-sort-field"
                onChange={(e) => {
                  handleChange(e.target.value, "one");
                }}
                onBlur={(e) => handleBlur(e.target.value, "First")}
                placeholder="00"
                ref={inputOneRef}
              />
              <input
                name="depositAmount"
                className="payment-input-field withdraw-sort-field"
                onChange={(e) => {
                  handleChange(e.target.value, "two");
                }}
                onBlur={(e) => handleBlur(e.target.value, "Second")}
                placeholder="00"
                ref={inputTwoRef}
              />
              <input
                name="depositAmount"
                className="payment-input-field withdraw-sort-field"
                onChange={(e) => {
                  handleChange(e.target.value, "three");
                }}
                onBlur={(e) => handleBlur(e.target.value, "Third")}
                placeholder="00"
                ref={inputThreeRef}
              />
            </div>
            {error !== "" && <div className="payment-error">{error}</div>}
          </div>
        </>
      ) : null}

      <div>
        <div className="payment-field-label">Withdrawal Method</div>
        {/*<Select
            style={{ width: "100%" }}
            className="payment-dropdown-filter"
            popupClassName="dropdown-popup-style payment-dropdown-filter-popupmenu"
            // onChange={handlePaymentMethod}
            value={paymentMethod}
          >
            <Option value="debitcard">Debit Card</Option>
            <Option value="applypay">Apple Pay</Option>
            <Option value="googlepay">Google Pay</Option>
          </Select> */}
        <div className="deposit-truelayer-cont">
          <img
            src={TrueLayerLogo}
            alt="TrueLayer Logo"
            className="deposit-field-logo"
          />
          <div>Instant Bank Transfer</div>
        </div>
        {userData?.payment_source_id !== "" && (
          <div className="withdraw-text font-cairo-normal">
            <InfoCircleOutlined className="withdraw-info-icon" />
            Funds will be sent to the same bank account used for your deposit
          </div>
        )}
      </div>
      {/* <div className="position-relative">
        <Formik
          initialValues={{
            address: "",
            city: "",
            postcode: "",
          }}
          validationSchema={infoSchema}
          onSubmit={handleFormSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="signup-infomation">
                To set up your account successfully, please make sure your home
                address matches your official and personal documentation.
              </div>
              <Field name="address">
                {({ field, form }) => (
                  <PlacesSearch
                    setFormValues={form.setFieldValue}
                    setUserUpdatedData={setUserUpdatedData}
                    userUpdatedData={userUpdatedData}
                  />
                )}
              </Field>
            </Form>
          )}
        </Formik>
      </div> */}
    </div>
  );
};

export default Withdraw;
