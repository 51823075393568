import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import "./SimpleFilledButton.scss";

const SimpleFilledButton = ({
  id,
  name,
  link,
  btnHeight,
  btnClass,
  functionCall,
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(link);
  };
  const defaultId =
  id ||
  `filled-button-${
    typeof name === "string"
      ? name.toLowerCase().replace(/\s+/g, "-")
      : Date.now()
  }`;
  return (
    <Button
    id={defaultId}
      className={`simplefilledbutton tranform-skew20 font-cairo-bold ${btnClass}`}
      onClick={functionCall ? functionCall : handleNavigation}
      style={{ height: btnHeight ? btnHeight : null }}
    >
      <div className="remove-transform">{name}</div>
    </Button>
  );
};

export default SimpleFilledButton;
