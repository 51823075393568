import * as Yup from "yup";

export const passwordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "At least 1 lowercase letter is required")
    .matches(/\d/, "At least 1 number is required")
    .matches(/[A-Z]/, "At least 1 uppercase letter is required"),
});
