import React, { useState } from "react";
import * as Yup from "yup";
import EditIcon from "../../../../assets/images/edit.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { passwordValidationSchema } from "../../../authComponents/ValidationsSchema/ValidationsSchema";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import "react-phone-number-input/style.css";
import "./ContactDetails.scss";

const contactDetailsSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is invalid")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    )
    .required("Email is required"),
  password: passwordValidationSchema.fields.password,
  current_password: Yup.string(),
  confirmnewpassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "New password must match"
  ),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .test("valid-phone", "Invalid phone number", (value) => {
      try {
        const phoneNumber = parsePhoneNumber(value);
        return phoneNumber && phoneNumber.isValid();
      } catch (error) {
        return false;
      }
    }),
});

const ContactDetails = ({
  userProfileData,
  setUserProfileData,
  userUpdatedData,
  setUserUpdatedData,
  setValidationErrors,
  validationErrors,
}) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleCurrentPassword, setToggleCurrentPassword] = useState(false);
  const [toggleNewPassword, setToggleNewPassword] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isPassValid, setIsPassValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordFieldFocused, setIsPasswordFieldFocused] = useState(false);
  const handleFormSubmit = () => {};

  const validateEmail = (e) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isEmailValid = emailRegex.test(e.target.value);

    if (!isEmailValid) {
      setErrorMessage("Invalid email address");
      setValidationErrors({
        ...validationErrors,
        email: "Invalid email address",
      });
      setIsValid(false);
    } else {
      setErrorMessage("");
      setValidationErrors({
        ...validationErrors,
        email: "",
      });
      setIsValid(true);
    }
  };
  const validatePassword = (e, field) => {
    const passwordValue = e.target.value;
    const updatedValues = {
      ...userUpdatedData,
      [field]: e.target.value,
    };
    setUserUpdatedData(updatedValues);

    if (
      !(
        passwordValue.length >= 8 &&
        /[a-z]/.test(passwordValue) &&
        /\d/.test(passwordValue) &&
        /[A-Z]/.test(passwordValue)
      )
    ) {
      setValidationErrors({
        ...validationErrors,
        password:
          "New password must contain at least 8 characters, one uppercase, one lowercase and one number",
      });
      setIsPassValid(false);
    } else {
      setIsPassValid(true);
      setValidationErrors({
        ...validationErrors,
        password: "",
      });
    }
  };

  const showCurrentPassword = () => {
    setToggleCurrentPassword(!togglePassword);
  };
  const showPassword = () => {
    setTogglePassword(!togglePassword);
  };
  const showNewPassword = () => {
    setToggleNewPassword(!toggleNewPassword);
  };
  const handleInputChange = async (field, e, form) => {
    if (field == "email") {
      validateEmail(e);
    } else if (field === "password" || field === "current_password") {
      validatePassword(e, field);
    } else if (field == "phone") {
      try {
        await contactDetailsSchema.validateAt("phoneNumber", {
          ["phoneNumber"]: e,
        });
        // No validation error, clear previous error message if any
        form.setFieldError("phoneNumber", "");
        setValidationErrors({
          ...validationErrors,
          phone: "",
        });
      } catch (error) {
        // Validation error, set the error message
        setValidationErrors({
          ...validationErrors,
          phone: "Invalid Phone number",
        });
        form.setFieldError("phoneNumber", error.message);
      }
    }
    const updatedValues = {
      ...userUpdatedData,
      [field]: field == "phone" ? e : e.target.value,
    };
    const updatedProfileValues = {
      ...userProfileData,
      [field]: field == "phone" ? e : e.target.value,
    };
    // setUserProfileData(updatedProfileValues);
    setUserUpdatedData(updatedValues);
  };
  const matchPassword = (value) => {
    if (value == userUpdatedData.password) {
      setValidationErrors({
        ...validationErrors,
        confirmPassword: "Matched",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        confirmPassword: "Passwords do not match",
      });
    }
  };

  return (
    <div className="contact-details">
      <div className="contact-details-text font-cairo-medium">
        Contact details
      </div>
      <div>
        {userProfileData ? (
          <Formik
            initialValues={{
              email: "",
              password: "",
              phoneNumber: userProfileData?.phone,
              current_password: "",
              confirmnewpassword: "",
            }}
            validationSchema={contactDetailsSchema}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {({ errors, touched, values, handleBlur }) => (
              <Form>
                <div className="fields-contact position-relative">
                  <label htmlFor="email" className="field-label">
                    Email Address
                  </label>
                  <Field
                    value={userProfileData ? userProfileData.email : ""}
                    type="email"
                    id="email"
                    name="email"
                    className={`contact-details-input-field no-outline`}
                    placeholder="johnkenedy@gmail.com"
                    onChange={(e) => handleInputChange("email", e)}
                  />
                  <div className="contact-details-edit-icon">
                    <img src={EditIcon} alt="Edit Icon" />
                  </div>
                  {!isValid && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </div>
                <div className="fields-contact position-relative">
                  <label htmlFor="phoneNumber" className="signup-field-label">
                    Phone no.
                  </label>
                  <Field name="phoneNumber">
                    {({ field, form }) => (
                      <PhoneInput
                        containerStyle={{ backgroundColor: "#000" }}
                        textInputStyle={{ color: "#fff" }}
                        {...field}
                        id="phoneNumber"
                        value={userProfileData?.phone}
                        onChange={(value) => {
                          handleInputChange("phone", value, form);
                          form.setFieldValue("phoneNumber", value);
                        }}
                        className="contact-details-input-field phone-number-dropdown"
                      />
                    )}
                  </Field>
                  <div className="contact-details-edit-icon1">
                    <img src={EditIcon} alt="Edit Icon" />
                  </div>
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="fields-contact position-relative">
                  <label htmlFor="current_password" className="field-label">
                    Current Password
                  </label>
                  <Field
                    value={
                      userProfileData ? userProfileData.current_password : ""
                    }
                    type={toggleCurrentPassword ? "text" : "password"}
                    id="current_password"
                    name="current_password"
                    className={`contact-details-input-field no-outline password-font${
                      errors.current_password && touched.current_password
                        ? "error"
                        : ""
                    }`}
                    placeholder="••••••••"
                    onChange={(e) => handleInputChange("current_password", e)}
                  />
                  {toggleCurrentPassword ? (
                    <EyeOutlined
                      className="contact-details-password-icon"
                      onClick={showCurrentPassword}
                    />
                  ) : (
                    <EyeInvisibleOutlined
                      className="contact-details-password-icon"
                      onClick={showCurrentPassword}
                    />
                  )}
                </div>
                <div
                  className="fields-contact position-relative"
                  style={{
                    height:
                      touched.password && isPasswordFieldFocused && "140px",
                  }}
                >
                  <label htmlFor="password" className="field-label">
                    New Password
                  </label>
                  <Field
                    value={userProfileData ? userProfileData.password : ""}
                    type={togglePassword ? "text" : "password"}
                    id="password"
                    name="password"
                    className={`contact-details-input-field no-outline password-font${
                      errors.password && touched.password ? "error" : ""
                    }`}
                    placeholder="••••••••"
                    onKeyUp={(e) => validatePassword(e, "password")}
                    onFocus={(e) => {
                      setIsPasswordFieldFocused(true);
                      handleBlur(e);
                    }}
                    onBlur={(e) => {
                      setIsPasswordFieldFocused(false);
                      handleBlur(e);
                    }}
                  />
                  {togglePassword ? (
                    <EyeOutlined
                      className="contact-details-password-icon"
                      onClick={showPassword}
                    />
                  ) : (
                    <EyeInvisibleOutlined
                      className="contact-details-password-icon"
                      onClick={showPassword}
                    />
                  )}
                  {touched.password && isPasswordFieldFocused && (
                    <div className="password-conditions font-cairo-medium">
                      <div
                        className={`condition ${
                          values.password.length >= 8 ? "valid" : "invalid"
                        }`}
                      >
                        {values.password.length >= 8 ? (
                          <CheckOutlined className="signup-correct" />
                        ) : (
                          <CloseOutlined className="signup-wrong" />
                        )}{" "}
                        At least 8 characters
                      </div>
                      <div
                        className={`condition ${
                          /[a-z]/.test(values.password) ? "valid" : "invalid"
                        }`}
                      >
                        {/[a-z]/.test(values.password) ? (
                          <CheckOutlined className="signup-correct" />
                        ) : (
                          <CloseOutlined className="signup-wrong" />
                        )}{" "}
                        At least 1 lowercase letter
                      </div>
                      <div
                        className={`condition ${
                          /\d/.test(values.password) ? "valid" : "invalid"
                        }`}
                      >
                        {/\d/.test(values.password) ? (
                          <CheckOutlined className="signup-correct" />
                        ) : (
                          <CloseOutlined className="signup-wrong" />
                        )}{" "}
                        At least 1 number
                      </div>
                      <div
                        className={`condition ${
                          /[A-Z]/.test(values.password) ? "valid" : "invalid"
                        }`}
                      >
                        {/[A-Z]/.test(values.password) ? (
                          <CheckOutlined className="signup-correct" />
                        ) : (
                          <CloseOutlined className="signup-wrong" />
                        )}{" "}
                        At least 1 uppercase letter
                      </div>
                    </div>
                  )}
                </div>
                <div className="fields-contact position-relative">
                  <label htmlFor="confirmnewpassword" className="field-label">
                    Confirm New Password
                  </label>
                  <Field
                    value={
                      userProfileData ? userProfileData.confirmnewpassword : ""
                    }
                    type={toggleNewPassword ? "text" : "password"}
                    id="confirmnewpassword"
                    name="confirmnewpassword"
                    className={`contact-details-input-field no-outline password-font${
                      errors.confirmnewpassword && touched.confirmnewpassword
                        ? "error"
                        : ""
                    }`}
                    placeholder="••••••••"
                    onChange={(e) => matchPassword(e.target.value)}
                  />
                  {toggleNewPassword ? (
                    <EyeOutlined
                      className="contact-details-password-icon"
                      onClick={showNewPassword}
                    />
                  ) : (
                    <EyeInvisibleOutlined
                      className="contact-details-password-icon"
                      onClick={showNewPassword}
                    />
                  )}
                  {validationErrors?.confirmPassword == "Matched" ? (
                    <div className="password-matched">Password match</div>
                  ) : validationErrors?.confirmPassword == "" ? (
                    ""
                  ) : (
                    <div className="error-message">Passwords do not match</div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        ) : null}
      </div>
    </div>
  );
};

export default ContactDetails;
