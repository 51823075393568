import React from "react";
import { Modal } from "antd";
import "./ExcludedWarningModal.scss";

const ExcludedWarningModal = ({ visible, closeModal }) => {
  return (
    <>
      <Modal
        open={visible}
        title={"Account Access Restricted"}
        onCancel={closeModal}
        wrapClassName="excluded-warning-modal"
        className="excluded-warning-modal-inner"
        footer={null}
        maskStyle={{
          backdropFilter: "blur(5px)",
        }}
      >
        <div className="verification-warning-modal-details font-cairo-medium">
          Our records indicate you are registered with GamStop. Due to this, you
          cannot join games, create private games or deposit funds on our
          platform until your self-exclusion period has ended. For assistance,
          please contact our support team.
        </div>
       
      </Modal>
    </>
  );
};
export default ExcludedWarningModal;
