import Cookies from "js-cookie";

export const setToken = (token) => {
  // localStorage.setItem("loginToken", token);
  Cookies.set("user_token", token, {
    domain:
      process.env.NODE_ENV !== "development"
        ? ".championschoice.co.uk"
        : "localhost",
    secure: true,
  });
};

export const setURL = (url) => {
  localStorage.setItem("privatelink", url);
};
export const setExCluded = (bool) => {
  localStorage.setItem("isExcluded", String(bool));
};
export const setPromoApplied = (bool) => {
  localStorage.setItem("promoApplied", String(bool));
};
export const setNavItem = (url) => {
  localStorage.setItem("navItem", url);
};
export const getToken = () => {
  return Cookies.get("user_token");
};
export const getURL = () => {
  return localStorage.getItem("privatelink");
};
export const getExcluded = () => {
  return localStorage.getItem("isExcluded");
};
export const getPromoApplied = () => {
  return localStorage.getItem("promoApplied");
};
export const getNavItem = () => {
  return localStorage.getItem("navItem");
};
export const removeToken = () => {
  localStorage.removeItem("loginToken");
};
export const removeURL = () => {
  localStorage.removeItem("privatelink");
};
export const removeNavItem = () => {
  localStorage.removeItem("navItem");
};
export const removeExcluded = () => {
  localStorage.removeItem("isExcluded");
};
export const removePromoApplied = () => {
  localStorage.removeItem("promoApplied");
};
