import React from "react";
import FilledButton from "../FilledButton";
import { useNavigate } from "react-router-dom";
import { setNavItem } from "../../../utils/localStorage";
import { useScreenWidth } from "../../../utils/useScreenWidth";
import "./HomeNews.scss";

const HomeNews = ({ page }) => {
  const screenWidth = useScreenWidth();
  const navigate = useNavigate();
  const handleNavigation = () => {
    if (page === "home") {
      setNavItem(2);
      navigate("/insights");
    } else {
      setNavItem(1);
      navigate("/game");
    }
  };
  return (
    <div
      className={`home-news ${
        screenWidth >= 767 ? "home-news-bg" : "home-news-bg-mobile"
      }`}
    >
      <div>
        <div className="home-news-title font-cairo-medium primary-color">
          MASTER THE GAME
        </div>
        <div className="home-news-prematch font-cairo-extrabold">
          Up Your Strategy
        </div>
        <div className="home-news-button">
          <FilledButton
            name={page == "home" ? "Browse Insights" : "Browse All Games"}
            btnClass="primary-background padding-x6"
            type={1}
            functionCall={handleNavigation}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeNews;
