import { useParams } from "react-router-dom";
import Logo from "../../../assets/images/auths/logo.svg";
import "./MobileRedirect.scss";
import { useEffect } from "react";

const MobileRedirect = () => {
  const { id } = useParams();
  useEffect(() => {
    window.location.replace(`cc://DashboardInner/${id}`);
  }, []);
  return (
    <div className="mobile-redirect-main">
      <div className="mobile-redirect-inner">
        <img src={Logo} alt="Logo" height={"100%"} width={"100%"} />
      </div>
      <h1>Redirecting to Mobile App...</h1>
    </div>
  );
};

export default MobileRedirect;
