import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Select } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import JoinGameModal from "../../modals/JoinGameModal/JoinGameModal";
import { BorderedButton, FilledButton } from "../../commonComponents";
import PrivateGameLinkModal from "../../modals/PrivateGameLink/PrivateGameLinkModal";
import {
  leaguesData,
  getStartDate,
  createPrivateGame,
  gameLeaguesData,
} from "../../../utils/service";
import { useMyContext } from "../../../helpers/Context/MyContext";
import DepositAlert from "../../modals/DepositAlert/DepositAlert";
import { sendGoogleAnalyticsEvents } from "../../../helpers/genericFunctions";
import notify from "../../../utils/notificationService"
import "./PrivateGame.scss";

const { Option } = Select;

const sportsOptions = [
  {
    value: "3",
    label: "Football",
  },
  // {
  //   value: "Cricket",
  //   label: "Cricket",
  // },
  // {
  //   value: "5",
  //   label: "Basketball",
  // },
];
const privateGameSchema = Yup.object().shape({
  sports: Yup.string().required("Please add the sports"),
  competition: Yup.string().required("Please select your league"),
  gametitle: Yup.string()
    .required("Please add your game title")
    .max(10, "Game title must be at most 10 characters"),
  stake: Yup.number()
    .typeError("Must be a number")
    .required("Please add your stake")
    .positive("Stake cannot be negative")
    .min(5, "Please add stake minimum of £5"),
  startdate: Yup.string().required("Please select the start date"),
  is_turbo: Yup.boolean().required(
    "Please enter the expected numbers of players"
  ),
  // .positive("Numbers of players cannot be negative")
  // .min(10, "Expected number of players must be at least 10"),
});

const PrivateGame = () => {
  const [startDate, setStartDate] = useState();
  const [gameMode, setGameMode] = useState(null);
  const [sportValue, setSportValue] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [gameDetails, setGameDetails] = useState(false);
  const [startDataValue, setStartDateValue] = useState();
  const [privateGameLink, setPrivateGameLink] = useState();
  const [leaguesResponse, setLeaguesResponse] = useState();
  const [competitionValue, setCompetitionValue] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inGameRoundNumber, setInGameRoundNumber] = useState();
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
  const { contextData, setContextData } = useMyContext();
  const [depositAlertText, setDepositAlertText] = useState("");
  const [isDepositAlertOpen, setDepositAlertOpen] = useState(false);
  const [depositAlertTitle, setDepositAlertTitle] = useState("");

  const openContactUsModal = () => {
    setIsContactUsModalOpen(true);
  };
  const closeContactUsModal = () => {
    setIsContactUsModalOpen(false);
  };

  const openDepositAlertModal = () => {
    setDepositAlertOpen(true);
  };
  const closeDepositAlertModal = () => {
    setDepositAlertOpen(false);
  };
  const handleSportChange = (form, value) => {
    setSportValue(value);
    getLeaguesData(value);
    if (value == 5) {
      setCompetitionValue({ label: "NBA" });
      form.setFieldValue("competition", 2024);
      setGameMode({ label: "Turbo" });
      form.setFieldValue("is_turbo", false);
    } else {
      setCompetitionValue();
    }
  };
  const handleStartDate = async (value, mode) => {
    const res = await getStartDate(value, mode);
    setStartDate(res?.round_start_dates);
  };
  const handleCompetitionChange = (form, value) => {
    setCompetitionValue(value)
    handleStartDate(value, "false");
    if (value?.toString().length == 4) {
      setSportValue("5");
      form.setFieldValue("sports", 5);
      setGameMode({ label: "Turbo" });
      form.setFieldValue("is_turbo", false);
    } else {
      if (value == 22842) {
        setGameMode({ label: "Turbo" });
        form.setFieldValue("is_turbo", true);
      }
      setSportValue("3");
      form.setFieldValue("sports", 3);
    }
  };
  const handleGameMode = (form, value) => {
    setGameMode(value);
    handleStartDate(competitionValue, value);
  };
  const handleStartDateChange = (value) => {
    setStartDateValue(value);
  };
  const handleFormSubmit = async (values, { resetForm }) => {
    var data = new FormData();
    data.append("sport_id", values.sports);
    data.append("season_id", values.competition);
    data.append("title", values.gametitle);
    data.append("stake", values.stake);
    data.append("is_turbo", values.is_turbo);
    data.append("start_date", values.startdate);
    data.append("round_number", 1);
    data.append("in_game_round_number", inGameRoundNumber);
    const res = await createPrivateGame(data);
    if (res?.success) {
      setPrivateGameLink(res?.game?.private_link);
      setGameDetails(res?.game);
      resetForm();
      setSportValue();
      setCompetitionValue();
      setStartDateValue();
      openContactUsModal();
      const eventDetails = {
        category: "game_created",
        action: "button_click",
        label: "Private Game Creation",
        email: contextData?.userData?.email,
        username: contextData?.userData?.username,
      };
      sendGoogleAnalyticsEvents(eventDetails);
    } else if (!res?.success) {
      if (res?.errors) {
        setDepositAlertTitle("Time-Out Period Active");
        setDepositAlertText(res.message);
        openDepositAlertModal();
      } else {
        notify(res?.message,"error")
      }
      resetForm();
      setSportValue();
      setCompetitionValue();
      setStartDateValue();
    } else {
      notify("Something went wrong, Please try agai later!","error")
    }
  };

  const getLeaguesData = async (sport_id) => {
    const res = await leaguesData(sport_id);
    setLeaguesResponse(res?.data?.data);
  };

  const getAllLeaguesData = async () => {
    const res = await gameLeaguesData("");
    setLeaguesResponse(res?.data?.data);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setPrivateGameLink("");
    setIsCopied(false);
  };
  const handleDiscard = () => {
    window.history.back();
  };
  const handlePrivateDates = (value, form, option) => {
    let selectedStartDate;
    const searchDateMatch = option.children.match(/^\d{4}-\d{2}-\d{2}/);
    const searchedDate = searchDateMatch[0];
    selectedStartDate = startDate.find((item) =>
      item.start_date.includes(searchedDate)
    );

    form.setFieldValue("startdate", selectedStartDate?.start_date);
    handleStartDateChange(selectedStartDate?.start_date);
    setInGameRoundNumber(selectedStartDate?.round_number);
  };

  useEffect(() => {
    if (!sportValue) {
      getAllLeaguesData();
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (leaguesResponse) {
      handleStartDate(`${leaguesResponse[0]?.current_season_id}`, "false");
    }
  }, [leaguesResponse]);

  return (
    <div className="private-game">
      <PrivateGameLinkModal
        visible={isModalVisible}
        closeModal={closeModal}
        privateGameLink={privateGameLink}
        setIsCopied={setIsCopied}
        isCopied={isCopied}
      />
      <JoinGameModal
        visible={isContactUsModalOpen}
        closeModal={closeContactUsModal}
        gameDetails={gameDetails}
        privateGame={true}
        showLinkModal={showModal}
        openContactUsModal={openContactUsModal}
      />
      <DepositAlert
        visible={isDepositAlertOpen}
        closeModal={closeDepositAlertModal}
        description={depositAlertText}
        title={depositAlertTitle}
      />
      <div className="private-game-title font-cairo-extrabold">
        Create Private Game
      </div>
      <div className="private-game-cont container-border background-blur">
        <div className="private-game-cont-title font-cairo-bold">
          Create your own game and invite your friends to join!
        </div>
        <Formik
          initialValues={{
            sports: "",
            competition: "",
            gametitle: "",
            stake: "",
            is_turbo: "",
            startdate: "",
          }}
          validationSchema={privateGameSchema}
          onSubmit={handleFormSubmit}
        >
          <Form>
            <div className="private-game-fields-wrap">
              <div className="private-game-fields-cont">
                <label
                  htmlFor="sports"
                  className="private-game-fields-cont-label"
                >
                  Sport
                </label>
                <Field name="sports">
                  {({ field, form }) => (
                    <Select
                      value={sportValue}
                      placeholder="Select Sport"
                      options={sportsOptions}
                      onChange={(event, { value, options }) => {
                        form.setFieldValue("sports", value);
                        handleSportChange(form, value);
                      }}
                      className="private-game-fields-cont-dropdown"
                      popupClassName="dropdown-popup-style private-game-fields-cont-dropdown-popupmenu font-cairo-mormal "
                      placement="bottomRight"
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="sports"
                  component="div"
                  className="private-game-fields-cont-error-message"
                />
              </div>
              <div className="private-game-fields-cont">
                <label
                  htmlFor="competition"
                  className="private-game-fields-cont-label"
                >
                  League
                </label>
                <Field name="competition">
                  {({ field, form }) => (
                    <Select
                      value={competitionValue}
                      placeholder="Select League"
                      onChange={(event, { value, options }) => {
                        form.setFieldValue("competition", value);
                        handleCompetitionChange(form, value);
                      }}
                      className="private-game-fields-cont-dropdown"
                      style={{ color: "red" }}
                      popupClassName="dropdown-popup-style private-game-fields-cont-dropdown-popupmenu font-cairo-normal"
                      placement="bottomRight"
                    >
                      {leaguesResponse?.map((league, index) => (
                        <Option key={index} value={league?.current_season_id}>
                          {league.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Field>
                <ErrorMessage
                  name="competition"
                  component="div"
                  className="private-game-fields-cont-error-message"
                />
              </div>
            </div>
            <div className="private-game-fields-wrap">
              <div className="private-game-fields-cont">
                <label
                  htmlFor="is_turbo"
                  className="private-game-fields-cont-label"
                >
                  Game Mode
                </label>
                <Field name="is_turbo">
                  {({ field, form }) => (
                    <Select
                      value={gameMode}
                      placeholder="Select Game Mode"
                      onChange={(event, { value, options }) => {
                        form.setFieldValue("is_turbo", value);
                        handleGameMode(form, value);
                      }}
                      className="private-game-fields-cont-dropdown"
                      style={{ color: "red" }}
                      popupClassName="dropdown-popup-style private-game-fields-cont-dropdown-popupmenu font-cairo-normal"
                      placement="bottomRight"
                      disabled={
                        sportValue == 5 || competitionValue == 22842
                          ? true
                          : false
                      }
                    >
                      <Option value={false}>Standard</Option>
                      <Option value={true}>Turbo</Option>
                    </Select>
                  )}
                </Field>

                <ErrorMessage
                  name="is_turbo"
                  component="div"
                  className="private-game-fields-cont-error-message"
                />
              </div>
              <div className="private-game-fields-cont">
                <label
                  htmlFor="gametitle"
                  className="private-game-fields-cont-label"
                >
                  Game Title
                </label>
                <Field
                  type="text"
                  id="gametitle"
                  name="gametitle"
                  className="private-game-field width-100"
                  placeholder="Enter a game title"
                />
                <ErrorMessage
                  name="gametitle"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>
            <div className="private-game-fields-wrap private-game-last-fields">
              <div className="private-game-fields-cont private-game-last-field">
                <label
                  htmlFor="stake"
                  className="private-game-fields-cont-label "
                >
                  Stake
                </label>
                <Field
                  type="text"
                  id="stake"
                  name="stake"
                  className="private-game-field width-100"
                  placeholder="£ 10"
                />
                <ErrorMessage
                  name="stake"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="private-game-fields-cont private-game-last-field">
                <label
                  htmlFor="statedate"
                  className="private-game-fields-cont-label"
                >
                  Start Date
                </label>
                <Field name="startdate">
                  {({ field, form }) => (
                    <Select
                      value={startDataValue}
                      placeholder="Select Start Date"
                      onChange={(value, option) => {
                        handlePrivateDates(value, form, option);
                      }}
                      className="private-game-fields-cont-dropdown"
                      style={{ color: "red" }}
                      popupClassName="dropdown-popup-style private-game-fields-cont-dropdown-popupmenu font-cairo-mormal private-game-fields-cont-dropdown-date"
                      placement="bottomRight"
                    >
                      {startDate?.map((league, index) => (
                        <Option key={index} value={league?.start_date}>
                          {`${league?.start_date?.replace(/ .*/, "")} ${
                            sportValue == 3 ? "Match" : ""
                          } Week ${league?.round_number}`}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Field>
                <ErrorMessage
                  name="startdate"
                  component="div"
                  className="private-game-fields-cont-error-message"
                />
              </div>
            </div>

            <div className="private-game-button-cont">
              <BorderedButton
                name={"Discard"}
                btnClass={"private-game-button-cont-discard"}
                functionCall={handleDiscard}
              />
              <FilledButton
                name={"Continue"}
                btnClass="primary-background"
                type={3}
                btnType={"submit"}
              />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default PrivateGame;
