import React from "react";
import TrueLayerLogo from "../../../../assets/images/bank_transfer.png";
import "./Deposit.scss";

const Deposit = ({ handleErrors, errorCheck }) => {
  return (
    <div className="deposit">
      <>
        <div className="deposit-text font-cairo-medium">Deposit</div>
        <div>
          <div className="payment-field-label">
            <span>Deposit Amount</span>
            <span className="font-cairo-bold font14">Min: £5.00 </span>
          </div>
          <input
            name="depositAmount"
            className="payment-input-field"
            onChange={(e) => {
              handleErrors(e.target.value, "deposit");
            }}
            placeholder="£ 0.00"
            type="number"
          />
          {errorCheck !== "" && (
            <div className="payment-error">{errorCheck}</div>
          )}
        </div>
        <div>
          <div className="payment-field-label">Deposit Method</div>
          {/*<Select
            style={{ width: "100%" }}
            className="payment-dropdown-filter"
            popupClassName="dropdown-popup-style payment-dropdown-filter-popupmenu"
            // onChange={handlePaymentMethod}
            value={paymentMethod}
          >
            <Option value="debitcard">Debit Card</Option>
            <Option value="applypay">Apple Pay</Option>
            <Option value="googlepay">Google Pay</Option>
          </Select> */}

          <div className="deposit-truelayer-cont">
            <img
              src={TrueLayerLogo}
              alt="TrueLayer Logo"
              className="deposit-field-logo"
            />
            <div>Instant Bank Payment</div>
          </div>
        </div>
        {/* {paymentMethod === "debitcard" && (
          <>
            <div>
              <label className="payment-field-label">Registered Cards</label>
              <Select
                style={{ width: "100%" }}
                className="payment-dropdown-filter"
                popupClassName="dropdown-popup-style payment-dropdown-filter-popupmenu"
                onChange={handleRegisteredCards}
                value={registeredCards}
              >
                <Option value="4562">**** 4562 Mastercard Debit</Option>
              </Select>
            </div>
            {registeredCards == "Select Your Card" && (
              <div className="payment-button">
                <SimpleFilledButton
                  name={"Add Card"}
                  link={"/dashboard/allselections"}
                  btnHeight={"45px"}
                  btnClass={"payment-button-btnsubmit"}
                  functionCall={handleAddCard}
                />
              </div>
            )}
          </>
        )} */}
        {/* {registeredCards !== "Select Your Card" ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            <Form>
              <div>
                <div className="payment-fields">
                  <div className="two-fields">
                    <label className="payment-field-label">Expiry Month</label>
                    <Field
                      as={Input}
                      name="cardExpiryMonth"
                      className="payment-input-field"
                    />
                  </div>
                  <div className="two-fields">
                    <label className="payment-field-label">Expiry Year</label>
                    <Field
                      as={Input}
                      name="cardExpiryYear"
                      className="payment-input-field"
                    />
                  </div>
                </div>
                <ErrorMessage
                  name="cardExpiryMonth"
                  component="div"
                  className="payment-error"
                />
                <ErrorMessage
                  name="cardExpiryYear"
                  component="div"
                  className="payment-error"
                />
              </div>
              <div>
                <label className="payment-field-label">
                  Card Security Number
                </label>
                <Field
                  as={Input}
                  name="cardSecurityNumber"
                  className="payment-input-field"
                />
                <ErrorMessage
                  name="cardSecurityNumber"
                  component="div"
                  className="payment-error"
                />
              </div>

              <div className="payment-button">
                <BorderedButton
                  type="primary"
                  htmlType="submit"
                  name={"Acknowledge and Deposit"}
                  link={"/dashboard/allselections"}
                  btnHeight={"45px"}
                  btnClass={"payment-button-btnsubmit"}
                />
              </div>
            </Form>
          </Formik>
        ) : null} */}
      </>
    </div>
  );
};

export default Deposit;
